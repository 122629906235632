import { useClickOutside } from 'hooks/useClickOutside';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components/macro';
import { appContext } from 'views/App';
import { ArrowDownIcon } from '../Icons/ArrowDownIcon/ArrowDownIcon';
import { Paragraph } from '../Paragraph/Paragraph';
import { DropdownOptionList } from './DropdownOptionList/DropdownOptionList';

export interface DropdownProps {
  label?: string;
  options: string[];
  mode?: 'primary' | 'secondary' | 'full';
  optionsPosition?: 'left' | 'right';
  size?: 'xs' | 's' | 'm' | 'l';
  isWithArrow?: boolean;
  className?: string;
  defaultOption?: string;
  handleSelectedOption?: (selectedOption: string) => void;
  disabled?: boolean;
}
interface IconContainerProps {
  isOptionsOpened: boolean;
}

interface SelectedOptionProps {
  mode: 'primary' | 'secondary' | 'full';
  optionsPosition?: 'left' | 'right';
}
const StyledDropdown = styled.div<SelectedOptionProps>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${({ mode }) => (mode === 'full' ? 'column' : 'row')};
  align-items: ${({ mode }) => (mode === 'full' ? 'flex-start' : 'center')};
  justify-content: flex-end;
  width: ${({ mode }) => (mode === 'full' ? '100%' : 'max-content')};
  position: relative;
  background: ${({ mode }) =>
    mode === 'full' ? 'var(--grey-opacity)' : 'transparent'};
  padding: ${({ mode }) => (mode === 'full' ? '8px 16px' : '0')};
  border: ${({ mode }) =>
    mode === 'full' ? '1px solid var(--grey-opacity)' : 'null'};
  border-radius: ${({ mode }) => (mode === 'full' ? '12px' : '0')};
  gap: ${({ mode }) => (mode === 'full' ? '5px' : '0')};
  .arrowIcon {
    margin-left: 5px;
  }
  .label {
    margin-right: 5px;
  }
`;
const SelectedOption = styled.div<SelectedOptionProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ mode }) =>
    mode === 'full' ? 'space-between' : 'flex-end'};
  background-color: ${({ mode, theme }) =>
    mode === 'secondary' ? theme.primary : 'transparent'};
  padding: ${({ mode }) => (mode === 'secondary' ? '6px 12px' : '0')};
  border: ${({ mode }) =>
    mode === 'secondary' ? '1px solid var(--secondary--green)' : 'none'};
  border-radius: ${({ mode }) => (mode === 'primary' ? '0' : '24px')};
  width: ${({ mode }) => (mode === 'full' ? '100%' : 'auto')};
`;
const IconContainer = styled.div<IconContainerProps>`
  ${({ isOptionsOpened }) =>
    isOptionsOpened
      ? css`
          transform: rotate(180deg);
          transition: transform 0.3s;
        `
      : css`
          transform: rotate(0deg);
          transition: transform 0.3s;
        `}
`;

export const Dropdown = ({
  label,
  options,
  mode = 'secondary',
  optionsPosition = 'right',
  size = 's',
  isWithArrow = true,
  className,
  defaultOption,
  handleSelectedOption,
  disabled = false,
}: DropdownProps) => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;

  const { t } = useTranslation();
  const dropdownRef = useRef(null);
  const [dropdownOptions, setDropdownOptions] = useState<string[] | []>([]);
  const [isOptionsOpened, setIsOptionOpened] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>(options[0]);

  const handleOptionChange = (option: string) => {
    setSelectedOption(option);
    setIsOptionOpened(false);
    handleSelectedOption && handleSelectedOption(option);
  };

  useEffect(() => {
    if (defaultOption) setSelectedOption(defaultOption);
  }, [defaultOption]);

  useEffect(() => {
    setDropdownOptions(options);
  }, [options]);

  useClickOutside(dropdownRef, () => {
    setIsOptionOpened(false);
  });

  return (
    <StyledDropdown className={className} ref={dropdownRef} mode={mode}>
      {label && (
        <Paragraph
          color="var(--dropdown-label)"
          dimension={size}
          className="label"
        >
          {label}
        </Paragraph>
      )}
      <SelectedOption
        onClick={() => !disabled && setIsOptionOpened(!isOptionsOpened)}
        mode={mode}
      >
        <Paragraph
          dimension={mode === 'full' ? 'm' : size}
          color={
            (mode === 'primary' &&
              (userData?.appSettings?.primary_color
                ? userData?.appSettings?.primary_color
                : 'var(--green)')) ||
            (mode === 'secondary' &&
              (userData?.appSettings?.primary_color
                ? '#fff'
                : 'var(--black-always)')) ||
            'var(--black)'
          }
          weight="bold"
        >
          {t(selectedOption)}
        </Paragraph>
        {isWithArrow && (
          <IconContainer isOptionsOpened={isOptionsOpened}>
            <ArrowDownIcon
              stroke={
                mode === 'primary'
                  ? userData?.appSettings?.primary_color
                    ? userData?.appSettings?.primary_color
                    : 'var(--green)'
                  : mode === 'full'
                  ? 'var(--black)'
                  : userData?.appSettings?.primary_color
                  ? '#fff'
                  : 'var(--black-always)'
              }
              className="arrowIcon"
            />
          </IconContainer>
        )}
      </SelectedOption>
      <DropdownOptionList
        isOptionsOpened={isOptionsOpened}
        dropdownOptions={dropdownOptions}
        selectedOption={selectedOption}
        mode={mode}
        optionsPosition={optionsPosition}
        handleSelectedOptionChange={handleOptionChange}
      />
    </StyledDropdown>
  );
};
