import styled from 'styled-components/macro';
import { ReportListItem } from './ReportListItem/ReportListItem';
import { ReportProps } from 'types/Report';

export interface ReportListProps {
  reportList?: ReportProps[];
  className?: string;
}

const StyledReportList = styled.ul`
  overflow-y: auto;
`;

export const ReportList = ({ reportList, className }: ReportListProps) => {
  if (!reportList) return;
  return (
    <StyledReportList className={className}>
      {reportList.map((report) => (
        <ReportListItem key={report.hash} report={report} />
      ))}
    </StyledReportList>
  );
};
