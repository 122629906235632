import { ChangeEvent, MutableRefObject } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TickCircleIcon } from '../Icons/TickCircleIcon/TickCircleIcon';

interface CheckboxProps {
  mode?: 'light' | 'dark';
  text: string;
  isChecked?: boolean;
  onToggle: (e: ChangeEvent<HTMLInputElement>) => void;
  anchor?: string;
  forwardedRef?: MutableRefObject<HTMLInputElement>;
  error?: boolean;
  isInternalLink?: boolean;
  isButton?: boolean;
  onLabelButtonClick?: () => void;
  ckeckboxId?: string;
  defaultCheckmarkColor?: string;
}

interface StyledLabelProps {
  isLink: boolean;
  mode: 'light' | 'dark';
}

const StyledLabel = styled.label<StyledLabelProps>`
  color: ${({ isLink, mode }) =>
    isLink
      ? 'var(--green)'
      : mode === 'light'
      ? 'var(--white-text)'
      : 'var(--black)'};
  font-size: 1.2rem;
  cursor: pointer;
  width: max-content;
  display: flex;
  align-items: center;
  font-weight: 700;
  .checkboxIcon {
    margin-right: 18px;
  }
`;

const StyledInput = styled.input`
  display: none;
`;

const StyledLink = styled(Link)`
  color: var(--green);
  text-decoration: none;
  font-size: 1.5rem;
`;

const StyledInternalLink = styled.a`
  color: var(--green);
  text-decoration: none;
`;
const StyledButton = styled.button`
  color: var(--green);
  text-decoration: none;
  border: none;
  background-color: transparent;
  font-size: 1.5rem;
`;

export const Checkbox: React.FC<CheckboxProps> = ({
  mode = 'light',
  text,
  onToggle,
  anchor,
  forwardedRef,
  error,
  isInternalLink,
  isButton,
  onLabelButtonClick,
  isChecked,
  ckeckboxId = 'input',
  defaultCheckmarkColor = 'var(--black)',
}) => {
  const LinkComponent = () => {
    return (
      <div>
        {isButton ? (
          <StyledButton onClick={onLabelButtonClick}>{text}</StyledButton>
        ) : isInternalLink ? (
          <StyledInternalLink href={anchor}>{text}</StyledInternalLink>
        ) : (
          <StyledLink to={anchor}>{text}</StyledLink>
        )}
      </div>
    );
  };
  return (
    <div>
      <StyledLabel htmlFor={ckeckboxId} isLink={Boolean(anchor)} mode={mode}>
        <TickCircleIcon
          stroke={
            (error && 'var(--error)') ||
            (isChecked
              ? 'var(--green)'
              : mode === 'light'
              ? 'var(--white-text)'
              : defaultCheckmarkColor)
          }
          className={'checkboxIcon'}
        />
        {anchor || isButton ? <LinkComponent /> : text}
      </StyledLabel>
      <StyledInput
        id={ckeckboxId}
        type="checkbox"
        onChange={(e) => onToggle(e)}
        ref={forwardedRef}
      />
    </div>
  );
};
