import { Button } from 'components/Button/Button';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

const StyledBottomContainer = styled.div`
  padding: 20px 0;
  .nextButton {
    margin-bottom: 20px;
  }
`;

interface VerifyCarOwnerBottomContainerProps {
  handleIsTermChecked: (isTermChecked: boolean) => void;
  isTermChecked: boolean;
}

export const VerifyCarOwnerBottomContainer = ({
  handleIsTermChecked,
  isTermChecked,
}: VerifyCarOwnerBottomContainerProps) => {
  const { t } = useTranslation();
  return (
    <StyledBottomContainer>
      <Button type="submit" className="nextButton">
        {t('next')}
      </Button>
      <Checkbox
        text={t('signUpTermAndConditions')}
        onToggle={() => handleIsTermChecked(!isTermChecked)}
        mode="dark"
        anchor="/agb"
        error={isTermChecked === false}
        isChecked={isTermChecked}
      />
    </StyledBottomContainer>
  );
};
