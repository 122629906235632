import { useTranslation } from 'react-i18next';
import { Confirm } from '../../../Confirm/Confirm';
import { SuccessDriverCard } from 'components/Cards/SuccessDriverCard/SuccessDriverCard';
import { SuccessCard } from 'components/Cards/SuccessCard/SuccesCard';
import { useNavigate } from 'react-router-dom';
import { useUploadingFileProgress } from 'hooks/useUploadingFileProgress';
import { Popup } from 'components/Popup/Popup';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { useContext, useEffect, useState } from 'react';
import { appContext } from 'views/App';

export const SuccessfulOrder = () => {
  const appContextStore = useContext(appContext);
  const reportDetails = appContextStore?.reportDetails;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { uploadedFilesCount } = useUploadingFileProgress();
  const [isUploadingFilesInProgress, setIsUploadingFilesInProgress] =
    useState<boolean>(true);
  useEffect(() => {
    const addedFilesCount = Number(sessionStorage.getItem('addedFilesCount'));
    if (addedFilesCount) {
      if (uploadedFilesCount < addedFilesCount) {
        setIsUploadingFilesInProgress(true);
      } else {
        setIsUploadingFilesInProgress(false);
      }
    }
  }, [uploadedFilesCount]);
  return (
    <Confirm
      title={t('congratulations')}
      subtitle={
        (reportDetails?.type === 1 && t('successOrderSubtitle')) ||
        (reportDetails?.type === 2 && t('confirmTicket')) ||
        (reportDetails?.type === 3 && t('gasStationSuccessReport'))
      }
      card={
        reportDetails?.type === 1 ? (
          <SuccessDriverCard status={t('confirmTowingStatus')} />
        ) : (
          <SuccessCard />
        )
      }
      customerContent={
        <Popup
          isCloseButton={false}
          isPopupOpened={isUploadingFilesInProgress}
          type={'info'}
          customerContent={
            <Paragraph color="var(--black)" textAlign="center" dimension="l">
              {t('loadingMediaPopup')}
            </Paragraph>
          }
          isWithOutButton
        />
      }
      onContinueClick={() => navigate('/')}
    />
  );
};
