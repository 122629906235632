import { useState } from 'react';

interface Position {
  latitude: number;
  longitude: number;
}
export const useGetLocation = () => {
  const [position, setPosition] = useState<Position | null>(null);
  const [isPositionError, setIsPositionError] = useState<boolean>(false);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude || 0;
          const longitude = position.coords.longitude || 0;
          setPosition({ latitude, longitude });
          setIsPositionError(false);
          return true;
        },
        (error) => {
          if (error) setIsPositionError(true);
          return false;
        },
      );
      setIsPositionError(false);
    } else {
      setIsPositionError(false);
      return false;
    }
  };

  return { position, getLocation, isPositionError };
};
