import { phoneValidation } from 'data/regexPatterns';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Dropdown, DropdownProps } from '../Dropdown/Dropdown';
import { Input, InputProps } from '../Input/Input';

interface PhoneNumberInputProps extends InputProps {
  phonePrefix?: string;
  handlePrefixChange?: (prefix: string) => void;
}

interface StyledDropdownProps extends DropdownProps {
  textColor?: string;
  phonePrefix?: string;
}

const StyledDropdown = (props: DropdownProps) => <Dropdown {...props} />;
const OverstyledDropdown = styled(StyledDropdown)<StyledDropdownProps>`
  min-width: 45px;

  p {
    color: ${({ textColor }) => `${textColor}`};
  }
  ul {
    max-height: 135px;
    overflow: auto;
  }
  &:after {
    content: '';
    height: 20px;
    width: 2px;
    margin-left: 2px;
    background-color: ${({ textColor }) => `${textColor}`};
  }
`;

export const PhoneNumberInput = ({
  phonePrefix,
  handlePrefixChange,
  ...props
}: PhoneNumberInputProps) => {
  const { t } = useTranslation();
  const numberPrefix = (
    <OverstyledDropdown
      options={['+41']}
      mode="primary"
      optionsPosition="left"
      size="l"
      isWithArrow={false}
      textColor={props.mode === 'light' ? 'var(--black)' : 'var(--white-text)'}
      defaultOption={phonePrefix}
      handleSelectedOption={handlePrefixChange}
    />
  );

  return (
    <Input
      name="phoneNumber"
      {...props}
      label={t('phoneLabel')}
      leftContent={numberPrefix}
      placeholder={t('phonePlaceholder')}
      validation={phoneValidation}
      validationErrorMessage={t('phoneValidationError')}
      isRequired
      requiredErrorMessage={t('requiredError')}
    />
  );
};
