import { Card } from 'components/Cards/Card/Card';
import { GradientCircle } from 'components/Icons/GradientCircle/GradientCircle';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { UserInfo } from 'components/UserInfo/UserInfo';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import referAFriendImg from 'assets/img/refer-a-friend.png';

interface StyledReferCardProps {
  background?: string;
}

const StyledReferCard = styled.div<StyledReferCardProps>`
  padding: 18px 24px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;

  &:after {
    content: '';
    position: absolute;
    background: ${(props) => `url(${props.background}) no-repeat top center`};
    background-size: contain;
    right: 15px;
    top: 130px;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 167px;
    height: 95px;
    border-radius: 12px;
  }
`;

const CardInfoBar = styled.div`
  margin-top: 10px;
  position: absolute;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  & p {
    margin-left: 12px;
  }
  top: 28px;
  right: 15px;
`;

export const ReferCard = () => {
  const { t } = useTranslation();
  return (
    <Card topContainerColor="var(--green-gradient)">
      <StyledReferCard background={referAFriendImg}>
        <UserInfo layout={'horizontal-column'} color="var(--black)" />
        <CardInfoBar>
          <GradientCircle />
          <Paragraph
            weight="bolder"
            color={'var(--black)'}
            dimension={'m'}
            textTransform={'uppercase'}
          >
            {t('referAndPromote')}
          </Paragraph>
        </CardInfoBar>
      </StyledReferCard>
    </Card>
  );
};
