import { Layout } from 'components/Layout/Layout';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { Input } from 'components/Input/Input';
import { UploadInput } from 'components/UploadInput/UploadInput';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TermPopup } from 'components/TermPopup/TermPopup';
import { generateNewFile } from 'utils/generateNewFile';
import { VerifyCarOwnerHeading } from './VerifyCarOwnerHeading/VerifyCarOwnerHeading';
import { VerifyCarOwnerPayments } from './VerifyCarOwnerPayments/VerifyCarOwnerPayments';
import { VerifyCarOwnerBottomContainer } from './VerifyCarOwnerBottomContainer/VerifyCarOwnerBottomContainer';
import { appContext } from 'views/App';

export const VerifyCarOwner = () => {
  const appContextStore = useContext(appContext);
  const carOwner = appContextStore?.carOwner;
  const setCarOwner = appContextStore?.setCarOwner;

  const { t } = useTranslation();
  const methods = useForm();
  const { getValues, handleSubmit, setValue, watch } = methods;
  const [isTermPopupOpened, setIsTermPopupOpened] = useState<boolean>(false);
  const [isTermChecked, setIsTermChecked] = useState<boolean | null>(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<number>(
    carOwner?.paymentType || 1,
  );
  const navigate = useNavigate();
  const { hash } = useParams();
  const handleNextClick = () => {
    if (isTermChecked) {
      const fullAddress = `${getValues('street')} ${getValues(
        'streetNumber',
      )},${getValues('zipCode')},${getValues('city')}`;
      setCarOwner({
        ...carOwner,
        fullName: getValues('fullName'),
        fullAddress,
        contactDetails: getValues('contactDetails'),
        hash,
        paymentType: selectedPaymentMethod,
        street: getValues('street'),
        streetNumber: getValues('streetNumber'),
        city: getValues('city'),
        zipCode: getValues('zipCode'),
      });
      navigate(`/verify-signature/${hash}`);
    } else {
      setIsTermChecked(false);
      setIsTermPopupOpened(true);
    }
  };

  useEffect(() => {
    if (!carOwner) return;
    setValue('fullName', carOwner.fullName);
    setValue('fullAddress', carOwner.fullAddress);
    setValue('contactDetails', carOwner.contactDetails);
    setValue('street', carOwner?.street);
    setValue('streetNumber', carOwner?.streetNumber);
    setValue('zipCode', carOwner?.zipCode);
    setValue('city', carOwner?.city);
    setSelectedPaymentMethod(carOwner?.paymentType);
  }, [carOwner]);

  const handleTermConfirm = () => {
    setIsTermChecked(true);
    setIsTermPopupOpened(false);
  };
  useEffect(() => {
    const fullAddress = `${getValues('street')} ${getValues(
      'streetNumber',
    )},${getValues('zipCode')},${getValues('city')}`;
    setCarOwner({
      ...carOwner,
      fullName: getValues('fullName'),
      fullAddress,
      contactDetails: getValues('contactDetails'),
      hash,
      paymentType: selectedPaymentMethod,
      street: getValues('street'),
      streetNumber: getValues('streetNumber'),
      city: getValues('city'),
      zipCode: getValues('zipCode'),
    });
  }, [
    watch('fullName'),
    watch('street'),
    watch('streetNumber'),
    watch('zipCode'),
    watch('city'),
    watch('contactDetails'),
    selectedPaymentMethod,
  ]);

  const handleDriverIdPhotoChange = (files: File[]) => {
    if (files?.length > 0) {
      const file = files[0];
      const newFile = generateNewFile(file);
      setCarOwner({ ...carOwner, driverIdPhoto: newFile });
    } else {
      setCarOwner({ ...carOwner, driverIdPhoto: undefined });
    }
  };
  const handlePlatePhotoChange = (files: File[]) => {
    if (files?.length > 0) {
      const file = files[0];
      const newFile = generateNewFile(file);
      setCarOwner({ ...carOwner, platePhoto: newFile });
    } else {
      setCarOwner({ ...carOwner, platePhoto: undefined });
    }
  };
  return (
    <Layout
      isBackBtn
      mode="top"
      outsideElements={
        <TermPopup
          isTermPopupOpened={isTermPopupOpened}
          handleTermConfirm={handleTermConfirm}
          handleTermDecline={() => setIsTermPopupOpened(false)}
        />
      }
    >
      <VerifyCarOwnerHeading />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleNextClick)} id="createCarOwnerForm">
          <Input
            name="fullName"
            mode="light"
            label={t('fullName')}
            placeholder={t('fullNamePlaceholder')}
            requiredErrorMessage={t('requiredErrorMessage')}
            bottomMarting="12"
          />
          <Input
            name="contactDetails"
            mode="light"
            label={t('emailAddress')}
            placeholder={t('emailPlaceholder')}
            requiredErrorMessage={t('requiredErrorMessage')}
            bottomMarting="12"
          />
          <Input
            name="street"
            label={t('street')}
            mode="light"
            placeholder={t('streetPlaceholder')}
            requiredErrorMessage={t('requiredError')}
            type="text"
            bottomMarting="12"
          />
          <Input
            name="streetNumber"
            label={t('streetNumber')}
            mode="light"
            placeholder={t('streetNumberPlaceholder')}
            requiredErrorMessage={t('requiredError')}
            type="text"
            bottomMarting="12"
          />
          <Input
            name="zipCode"
            label={t('zipCode')}
            mode="light"
            placeholder={t('zipCodePlaceholder')}
            requiredErrorMessage={t('requiredError')}
            type="text"
            bottomMarting="12"
          />
          <Input
            name="city"
            label={t('city')}
            mode="light"
            placeholder={t('cityPlaceholder')}
            requiredErrorMessage={t('requiredError')}
            type="text"
            bottomMarting="12"
          />
          <UploadInput
            name="driverIdPhoto"
            label={t('driverId')}
            mode="light"
            bottomMarting="12"
            addedMedia={
              carOwner?.driverIdPhoto ? [carOwner?.driverIdPhoto] : []
            }
            handleMediaChange={(files) => handleDriverIdPhotoChange(files)}
          />
          <UploadInput
            name="platePhoto"
            label={t('carRegistration')}
            mode="light"
            bottomMarting="12"
            addedMedia={carOwner?.platePhoto ? [carOwner?.platePhoto] : []}
            handleMediaChange={(files) => handlePlatePhotoChange(files)}
          />
          <VerifyCarOwnerPayments
            selectedPaymentMethod={selectedPaymentMethod}
            handleSelectedPaymentChange={(paymentId) =>
              setSelectedPaymentMethod(paymentId)
            }
          />
          <VerifyCarOwnerBottomContainer
            isTermChecked={isTermChecked}
            handleIsTermChecked={(isTermChecked) =>
              setIsTermChecked(isTermChecked)
            }
          />
        </form>
      </FormProvider>
    </Layout>
  );
};
