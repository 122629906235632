import { gql } from '@apollo/client';

export const CREATE_DRIVER_PICKED_CAR_PRESIGNED_REQUEST = gql`
  mutation CreateDriverPickedCarPresignedRequest(
    $hash: String!
    $files: [String!]
  ) {
    createDriverPickedCarPresignedRequest(files: $files, hash: $hash) {
      fileName
      id
      url
    }
  }
`;
