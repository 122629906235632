import { ClientsIcon } from 'components/Icons/ClientsIcon/ClientsIcon';
import { GasStationIcon } from 'components/Icons/GasStationIcon/GasStationIcon';
import { HomeIcon } from 'components/Icons/HomeIcon/HomeIcon';
import { ProfileIcon } from 'components/Icons/ProfileIcon/ProfileIcon';
import { TicketIcon } from 'components/Icons/TicketIcon/TicketIcon';
import { TransactionIcon } from 'components/Icons/TransactionIcon/TransactionIcon';
import { TruckIcon } from 'components/Icons/TruckIcon/TruckIcon';
import { IconProps } from 'components/Icons/types';

type MenuItem = {
  path: string;
  icon: (props: IconProps) => JSX.Element;
  accountType: 'user' | 'all' | 'notUser' | 'superAdmin';
  additionalLinks?: string[];
};

export const menu: MenuItem[] = [
  {
    path: '/',
    icon: HomeIcon,
    accountType: 'all',
  },
  {
    path: '/history/active',
    icon: TruckIcon,
    accountType: 'all',
  },
  {
    path: '/history/tickets',
    icon: TicketIcon,
    accountType: 'all',
  },
  {
    path: '/history/station',
    icon: GasStationIcon,
    accountType: 'superAdmin',
  },
  {
    path: '/history/station',
    icon: GasStationIcon,
    accountType: 'user',
  },
  {
    path: '/history/deactive',
    icon: TransactionIcon,
    accountType: 'all',
  },

  {
    path: '/clients',
    icon: ClientsIcon,
    accountType: 'superAdmin',
  },

  {
    path: '/profile',
    icon: ProfileIcon,
    accountType: 'all',
    additionalLinks: [
      '/edit-profile',
      '/refer-friend-flow',
      '/parking',
      '/licenses',
    ],
  },
];
