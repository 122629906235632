import { IconProps } from '../types';

export const CloseIcon = ({
  size = '13',
  fill = 'var(--black)',
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.94063 6.00017L12.8006 1.14017C12.9099 1.01264 12.9669 0.848589 12.9604 0.680805C12.954 0.513021 12.8844 0.353862 12.7657 0.235132C12.6469 0.116403 12.4878 0.0468473 12.32 0.0403665C12.1522 0.0338857 11.9882 0.0909567 11.8606 0.200174L7.00063 5.06017L2.14063 0.193508C2.0131 0.0842899 1.84905 0.0272195 1.68126 0.0337003C1.51348 0.0401811 1.35432 0.109736 1.23559 0.228465C1.11686 0.347195 1.04731 0.506354 1.04083 0.674138C1.03434 0.841922 1.09142 1.00597 1.20063 1.13351L6.06063 6.00017L1.19397 10.8602C1.12418 10.9199 1.0675 10.9935 1.02748 11.0762C0.987468 11.1589 0.964981 11.249 0.961434 11.3408C0.957888 11.4326 0.973359 11.5242 1.00687 11.6097C1.04039 11.6953 1.09123 11.773 1.1562 11.8379C1.22117 11.9029 1.29887 11.9537 1.38442 11.9873C1.46997 12.0208 1.56152 12.0363 1.65334 12.0327C1.74515 12.0292 1.83524 12.0067 1.91795 11.9667C2.00066 11.9266 2.0742 11.87 2.13397 11.8002L7.00063 6.94017L11.8606 11.8002C11.9882 11.9094 12.1522 11.9665 12.32 11.96C12.4878 11.9535 12.6469 11.8839 12.7657 11.7652C12.8844 11.6465 12.954 11.4873 12.9604 11.3195C12.9669 11.1518 12.9099 10.9877 12.8006 10.8602L7.94063 6.00017Z"
        fill={fill}
      />
    </svg>
  );
};
