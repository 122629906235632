import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

const SidebarItemElement = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 100%;
`;

interface SidebarItemMainComponentProps {
  children: ReactNode;
  path?: string;
}

export const SidebarItemMainComponent = ({
  children,
  path,
}: SidebarItemMainComponentProps) => {
  return path ? (
    <Link to={path}>{children}</Link>
  ) : (
    <SidebarItemElement>{children}</SidebarItemElement>
  );
};
