import styled from 'styled-components';
import failedCircle from 'assets/img/failed-circle.svg';
import { Heading } from 'components/Heading/Heading';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { Button } from 'components/Button/Button';
import { device } from 'styles/devices';
import { useNavigate } from 'react-router-dom';
import { ParkingTicketsLayout } from 'views/ParkingTickets/ParkingTicketsLayout/ParkingTicketsLayout';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { appContext } from 'views/App';

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  min-height: calc(100vh - 80px - 60px);
  h2 {
    font-size: 26px;
  }
  @media ${device.laptop} {
    min-height: calc(100vh - 247px - 92px);
    h2 {
      font-size: 40px;
    }
    p {
      font-size: 20px;
    }
  }
`;
const StyledImage = styled.img`
  margin-bottom: 15px;
  width: 200px;
  height: 200px;
  margin: 0 auto;
`;
const StyledButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .successButton {
    color: var(--white);
  }
  button {
    @media ${device.laptop} {
      width: 323px;
    }
  }
  @media ${device.laptop} {
    flex-direction: row;
    justify-content: center;
  }
`;
const StyledMainContent = styled.div`
  margin: auto 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const PaymentFailed = () => {
  const appContextStore = useContext(appContext);
  const setIsError = appContextStore?.setIsError;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [qrCodeId, setQrCodeId] = useState<string | undefined>(undefined);
  useEffect(() => {
    const selectedQrCodeId = sessionStorage.getItem('qrCodeId');
    if (selectedQrCodeId) {
      setQrCodeId(selectedQrCodeId);
    }
  }, []);
  return (
    <ParkingTicketsLayout>
      <StyledContent>
        <StyledMainContent>
          <StyledImage src={failedCircle} alt="Success" />
          <Heading
            color="var(--black)"
            textAlign="center"
            dimension="xl"
            isWithPaddingBottom={false}
          >
            {t('failedViewTitle')}
          </Heading>
          <Paragraph color="var(--black)" textAlign="center" dimension="l">
            {t('failedViewSubtitle')}
          </Paragraph>
        </StyledMainContent>
        <StyledButtonContainer>
          <Button
            className="successButton"
            onBtnClick={() =>
              qrCodeId
                ? navigate(`/ticket-details/${qrCodeId}`)
                : setIsError(true)
            }
          >
            {t('failedViewButton')}
          </Button>
        </StyledButtonContainer>
      </StyledContent>
    </ParkingTicketsLayout>
  );
};
