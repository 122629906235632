import {
  ChangeEvent,
  MouseEvent,
  ReactElement,
  ReactNode,
  TextareaHTMLAttributes,
  useRef,
} from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components/macro';
import { IconButton } from '../IconButton/IconButton';

interface StyledContainerProps {
  mode?: 'light' | 'dark' | 'transparent';
  isInputFailed?: boolean;
  bottomMarting?: string;
}
interface StyledInputContainerProps {
  label?: string;
  disabled?: boolean;
  isUploadInput?: boolean;
  mode?: 'light' | 'dark' | 'transparent';
}
export interface InputProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name?: string;
  mode?: 'light' | 'dark' | 'transparent';
  label?: string;
  icon?: ReactElement;
  onIconClick?: (event: MouseEvent<HTMLElement>) => void;
  isRequired?: boolean;
  validation?: RegExp;
  validationErrorMessage?: string;
  requiredErrorMessage?: string;
  isUploadInput?: boolean;
  children?: ReactNode;
  rightContent?: ReactNode;
  leftContent?: ReactNode;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  inputClassName?: string;
  bottomMarting?: string;
  contentClassName?: string;
}
export const StyledContainer = styled.div<StyledContainerProps>`
  border-radius: 12px;
  border: ${({ isInputFailed, mode }) =>
    (isInputFailed && '1px solid var(--error)') ||
    (mode === 'light' && `1px solid var(--grey-opacity)`) ||
    (mode === 'dark' && `1px solid var(--grey)`) ||
    (mode === 'transparent' && `none`)};
  background: ${({ mode }) =>
    (mode === 'dark' && 'var(--white-opacity)') ||
    (mode === 'light' && 'var(--grey-opacity)') ||
    (mode === 'transparent' && 'transparent')};
  padding: ${({ mode }) => (mode === 'transparent' ? '0' : '0 10px 0 16px;')};
  color: ${({ mode }) => (mode === 'dark' ? 'var(--white)' : 'var(--black)')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${({ mode }) => (mode === 'transparent' ? 'auto' : '100%')};
  margin-bottom: ${({ bottomMarting }) => `${bottomMarting}px`};
`;
const StyledContentContainer = styled.div<StyledInputContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${({ label }) => (label ? 'space-between' : 'center')};
  align-items: ${({ label, mode }) =>
    label || mode === 'transparent' ? 'flex-start' : 'center'};
  height: ${({ isUploadInput, mode }) =>
    isUploadInput || mode === 'transparent' ? 'auto' : '60px'};
  gap: ${({ mode }) => (mode === 'transparent' ? '0' : '5px')};
  padding: 8px 0;
`;
const StyledLabel = styled.span<StyledInputContainerProps>`
  font-size: var(--text-s);
  font-weight: 400;
  color: ${({ disabled, mode }) =>
    (disabled && 'var(--black)') ||
    (mode === 'light' && 'var(--black)') ||
    (mode !== 'light' && 'var(--white-text)')};
  text-align: right;
  line-height: 13px;
`;
const StyledTextInput = styled.textarea<InputProps>`
  width: 100%;
  min-width: 100%;
  background: transparent;
  outline: none;
  border: none;
  font-size: ${({ mode }) =>
    mode === 'transparent' ? 'var(--text-s)' : 'var(--text-l)'};
  line-height: ${({ mode }) => (mode === 'transparent' ? '16px' : '24px')};
  font-weight: ${({ mode }) => (mode === 'transparent' ? '400' : '600')};
  letter-spacing: 0.6px;
  display: ${({ isUploadInput }) => (isUploadInput ? 'none' : 'block')};
  color: ${({ mode, disabled }) =>
    mode === 'dark' || (mode === 'transparent' && !disabled)
      ? 'var(--white-text)'
      : 'var(--black)'};
  ::placeholder,
  ::-webkit-input-placeholder {
    color: grey;
  }
  ::-webkit-calendar-picker-indicator {
    display: none;
  }
  transition: color 9999s linear, background-color 9999s linear;
  transition-delay: 9999s;
  &:disabled {
    color: ${({ mode, disabled }) =>
      mode === 'dark' || (mode === 'transparent' && !disabled)
        ? 'var(--white-text)'
        : 'var(--black)'};
    -webkit-text-fill-color: ${({ mode, disabled }) =>
      mode === 'dark' || (mode === 'transparent' && !disabled)
        ? 'var(--white-text)'
        : 'var(--black)'};
    -webkit-opacity: 1;
    opacity: 1;
  }
`;
const StyledErrorSpan = styled.span`
  color: var(--error);
  font-size: 9px;
  line-height: 7px;
`;
const StyledInputContainer = styled.div`
  width: 100%;
`;

const StyledInputContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const StyledLabelContainer = styled.div``;

export const Textarea = ({
  label,
  mode = 'dark',
  icon,
  onIconClick,
  name,
  isRequired,
  validation,
  validationErrorMessage,
  requiredErrorMessage,
  children,
  rightContent,
  leftContent,
  onChange,
  inputClassName,
  bottomMarting = '0',
  contentClassName,
  ...htmlProps
}: InputProps) => {
  const fieldRef = useRef<HTMLTextAreaElement | null>(null);
  const {
    register,
    formState: { errors },
    getValues,
  } = useFormContext();
  const { ref, ...restFormProps } = register(name);
  const pattern =
    validation && validationErrorMessage
      ? { pattern: { value: validation, message: validationErrorMessage } }
      : null;
  const isInputFailed = Boolean(errors[name]);

  const handleValueCopy = () => {
    const value = getValues();
    navigator.clipboard.writeText(value[name]);
  };

  return (
    <StyledContainer
      mode={mode}
      isInputFailed={isInputFailed}
      className={inputClassName}
      bottomMarting={bottomMarting}
    >
      <StyledContentContainer
        label={label}
        mode={mode}
        className={contentClassName}
      >
        {label || errors[name] ? (
          <StyledLabelContainer>
            {label ? (
              <StyledLabel disabled={htmlProps.disabled} mode={mode}>
                {label}
              </StyledLabel>
            ) : (
              <div />
            )}
            <StyledErrorSpan>
              {errors[name] &&
                errors[name].type === 'pattern' &&
                ` - ${validationErrorMessage}`}
              {errors[name] &&
                errors[name].type === 'required' &&
                requiredErrorMessage &&
                ` - ${requiredErrorMessage}`}
            </StyledErrorSpan>
          </StyledLabelContainer>
        ) : (
          <div />
        )}
        <StyledInputContent>
          {leftContent}
          <StyledInputContainer onClick={htmlProps.disabled && handleValueCopy}>
            <StyledTextInput
              ref={(e: HTMLTextAreaElement | null) => {
                ref(e);
                fieldRef.current = e;
                register(name, {
                  ...pattern,
                  required: isRequired,
                  onChange: onChange,
                });
              }}
              {...restFormProps}
              {...htmlProps}
              mode={mode}
              name={name}
              id={name}
            ></StyledTextInput>
          </StyledInputContainer>
        </StyledInputContent>
        {children}
      </StyledContentContainer>
      {icon ? <IconButton onClick={onIconClick}>{icon}</IconButton> : <div />}
      {rightContent}
    </StyledContainer>
  );
};
