import { SuccessCard } from 'components/Cards/SuccessCard/SuccesCard';
import { UnsuccessCard } from 'components/Cards/UnsuccessCard/UnsuccessCard';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Confirm } from '../../../Confirm/Confirm';
import { useContext } from 'react';
import { appContext } from 'views/App';

export const PlateStatus = () => {
  const appContextStore = useContext(appContext);
  const checkedPlate = appContextStore?.checkedPlate;
  const setCheckedPlate = appContextStore?.setCheckedPlate;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { status } = useParams();
  const isSuccessStatus = status === '1';
  const successButton = {
    onContinueClick: () => {
      setCheckedPlate(null);
      navigate('/');
    },
  };
  const failedButtons = {
    onDeclineClick: () => {
      setCheckedPlate(null);
      navigate('/add-report/2');
    },
    onConfirmClick: () => {
      setCheckedPlate(null);
      navigate('/add-report/1');
    },
  };

  return (
    <Confirm
      title={
        <span>
          {t('registerPlate')}: <br /> {checkedPlate}
        </span>
      }
      subtitle={t(
        isSuccessStatus ? 'isAllowedToParking' : 'isNotAllowedToParking',
      )}
      card={isSuccessStatus ? <SuccessCard /> : <UnsuccessCard />}
      {...(isSuccessStatus ? { ...successButton } : { ...failedButtons })}
      declineText={t('startTicket')}
      confirmText={t(isSuccessStatus ? 'finish' : 'startTowing')}
    />
  );
};
