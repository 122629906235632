import { ReportProps } from 'types/Report';
import { MainInformation } from './MainInformation/MainInformation';
import { TimeInformation } from './TimeInformation/TimeInformation';
import { PaymentInformation } from './PaymentInformation/PaymentInformation';
import { AdditionalInformation } from './AdditionalInformation/AdditionalInformation';
import styled from 'styled-components';

const StyledReportDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

interface ReportDetailsProps {
  report?: ReportProps;
  eta?: string;
}

export const ReportDetails = ({ report, eta }: ReportDetailsProps) => {
  return (
    <StyledReportDetails>
      <MainInformation report={report} eta={eta} />
      <TimeInformation report={report} />
      <PaymentInformation report={report} />
      <AdditionalInformation report={report} />
    </StyledReportDetails>
  );
};
