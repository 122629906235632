import { Layout } from 'components/Layout/Layout';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { MainInformation } from './MainInformation/MainInformation';
import { AdditionalInformation } from './AdditionalInformation/AdditionalInformation';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <Layout
      mode="top"
      isBackBtn
      viewTitle={t('aboutUs')}
      backButtonMode="text"
      isWithBottomPadding={false}
    >
      <StyledContainer>
        <MainInformation />
        <AdditionalInformation />
      </StyledContainer>
    </Layout>
  );
};
