import { useState, MouseEvent } from 'react';
import { EyeSlashIcon } from '../Icons/EyeSlashIcon/EyeSlashIcon';
import { Input, InputProps } from '../Input/Input';
import { useTranslation } from 'react-i18next';

export const PasswordInput = ({
  name,
  label,
  placeholder,
  ...props
}: InputProps) => {
  const [isPasswordDisplayed, setIsPasswordDisplayed] =
    useState<boolean>(false);
  const handlePasswordShowClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setIsPasswordDisplayed(!isPasswordDisplayed);
  };
  const { t } = useTranslation();
  return (
    <Input
      name={name ? name : 'password'}
      type={isPasswordDisplayed ? 'text' : 'password'}
      label={label ? label : t('password')}
      placeholder={placeholder}
      icon={<EyeSlashIcon />}
      onIconClick={(e: MouseEvent<HTMLElement>) => handlePasswordShowClick(e)}
      {...props}
    />
  );
};
