import { Paragraph } from 'components/Paragraph/Paragraph';
import { ToggleSwitch } from 'components/ToggleSwitch/ToggleSwitch';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

const StyledSwitchContainer = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;
const StyledSwitchTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

interface SwitchContainerProps {
  icon: ReactNode;
  title: string;
  isChecked: boolean;
  handleToggleClick: () => void;
}

export const SwitchContainer = ({
  icon,
  title,
  isChecked,
  handleToggleClick,
}: SwitchContainerProps) => {
  const { t } = useTranslation();
  return (
    <StyledSwitchContainer>
      <StyledSwitchTitle>
        {icon}
        <Paragraph dimension="l" color="var(--black)">
          {t(title)}
        </Paragraph>
      </StyledSwitchTitle>
      <ToggleSwitch
        isChecked={isChecked}
        handleToggleClick={handleToggleClick}
      />
    </StyledSwitchContainer>
  );
};
