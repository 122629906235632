import styled from 'styled-components';

interface IosSwitchProps {
  isActive: boolean;
  onToggle: () => void;
}

const SwitchContainer = styled.div`
  width: 51px;
`;

const Label = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
  width: 51px;
  height: 31px;
  border-radius: 60px;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
  }
  &:before {
    right: 0;
    background-color: #f1f1f1;
    border-radius: 60px;
    transition: background-color 0.4s;
  }
  &:after {
    top: 2px;
    left: 2px;
    width: 27px;
    height: 27px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transition: all 0.4s;
  }
`;

const Input = styled.input`
  visibility: hidden;
  position: absolute;
  margin-left: -9999px;
  &:checked + ${Label}:before {
    background-color: ${(props) => props.theme.primary};
  }
  &:checked + ${Label}:after {
    transform: translateX(20px);
  }
`;

export const IosSwitch = ({ isActive = false, onToggle }: IosSwitchProps) => {
  return (
    <SwitchContainer>
      <Input
        id="switch"
        type="checkbox"
        checked={isActive}
        onChange={onToggle}
      />
      <Label htmlFor="switch"></Label>
    </SwitchContainer>
  );
};
