import {
  FC,
  HTMLAttributes,
  MouseEvent as ReactMouseEvent,
  TouchEvent as ReactTouchEvent,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useWindowSize } from '../../hooks/useWindowSize';
import { IconButton } from '../IconButton/IconButton';
import { Paragraph } from '../Paragraph/Paragraph';
import { CloseIcon } from '../Icons/CloseIcon/CloseIcon';
import { appContext } from 'views/App';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SignatureProps extends HTMLAttributes<HTMLCanvasElement> {}
interface PointerPos {
  x: number;
  y: number;
}

const StyledCanvasContainer = styled.div`
  position: relative;
  .crearSignatureButton {
    position: absolute;
    right: 10px;
    top: 10px;
  }
`;
const StyledCanvas = styled.canvas`
  max-height: 320px;
  border-radius: 12px;
  background-color: var(--grey-opacity);
`;
const StyledParagraph = styled(Paragraph)`
  position: absolute;
  top: 8px;
  left: 16px;
`;

export const Signature: FC<SignatureProps> = ({ ...props }) => {
  const appContextStore = useContext(appContext);
  const parkingData = appContextStore?.parkingData;
  const setParkingData = appContextStore?.setParkingData;

  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const canvas = canvasRef.current;
  const isDrawingRef = useRef<boolean>(false);
  const pointerPosRef = useRef<PointerPos>({
    x: 0,
    y: 0,
  });
  const lastPointerPosRef = useRef<PointerPos>(pointerPosRef.current);
  const { width: windowWidth } = useWindowSize();
  const CONTAINER_PADDING = 26;
  const CANVAS_WIDTH = windowWidth - CONTAINER_PADDING * 2;
  const { t } = useTranslation();

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.lineWidth = 2;

    const renderCanvas = () => {
      if (isDrawingRef.current) {
        ctx.moveTo(lastPointerPosRef.current.x, lastPointerPosRef.current.y);
        ctx.lineTo(pointerPosRef.current.x, pointerPosRef.current.y);
        ctx.stroke();
        lastPointerPosRef.current = pointerPosRef.current;
      }
    };

    (function drawLoop() {
      requestAnimationFrame(drawLoop);
      renderCanvas();
    })();

    ['touchstart', 'touchend', 'touchmove'].forEach((touchEvent: string) => {
      document.body.addEventListener(
        touchEvent,
        (e) => {
          if (e.target === canvas) e.preventDefault();
        },
        { passive: false },
      );
    });
  }, []);

  const getMousePos = (
    canvas: HTMLCanvasElement,
    mouseEvent: ReactMouseEvent<HTMLCanvasElement>,
  ) => {
    const rect = canvas.getBoundingClientRect();
    return {
      x: mouseEvent.clientX - rect.left,
      y: mouseEvent.clientY - rect.top,
    };
  };
  const getTouchPos = (
    canvas: HTMLCanvasElement,
    touchEvent: ReactTouchEvent<HTMLCanvasElement>,
  ) => {
    const rect = canvas.getBoundingClientRect();
    return {
      x: touchEvent.touches[0].clientX - rect.left,
      y: touchEvent.touches[0].clientY - rect.top,
    };
  };
  const handleMouseDown = (e: ReactMouseEvent<HTMLCanvasElement>) => {
    isDrawingRef.current = true;
    lastPointerPosRef.current = getMousePos(canvas, e);
  };
  const handleMouseUp = () => {
    isDrawingRef.current = false;
    canvas.toBlob((blob) => {
      const file = new File([blob], 'image.png', { type: 'image/png' });
      setParkingData({ ...parkingData, signature: file });
    });
  };
  const handleMouseMove = (e: ReactMouseEvent<HTMLCanvasElement>) => {
    pointerPosRef.current = getMousePos(canvas, e);
  };
  const handleTouchStart = (e: ReactTouchEvent<HTMLCanvasElement>) => {
    pointerPosRef.current = getTouchPos(canvas, e);
    const touch = e.touches[0];
    const mouseEvent = new MouseEvent('mousedown', {
      clientX: touch.clientX,
      clientY: touch.clientY,
      bubbles: true,
    });
    canvas.dispatchEvent(mouseEvent);
  };
  const handleTouchMove = (e: ReactTouchEvent<HTMLCanvasElement>) => {
    const touch = e.touches[0];
    const mouseEvent = new MouseEvent('mousemove', {
      clientX: touch.clientX,
      clientY: touch.clientY,
      bubbles: true,
    });
    canvas.dispatchEvent(mouseEvent);
  };
  const handleTouchEnd = () => {
    const mouseEvent = new MouseEvent('mouseup', { bubbles: true });
    canvas.dispatchEvent(mouseEvent);
  };
  const clearAll = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.beginPath();
  };
  return (
    <StyledCanvasContainer>
      <IconButton onClick={clearAll} className="crearSignatureButton">
        <CloseIcon />
      </IconButton>
      <StyledCanvas
        ref={canvasRef}
        onMouseDown={(e) => handleMouseDown(e)}
        onMouseUp={handleMouseUp}
        onMouseMove={(e) => handleMouseMove(e)}
        onTouchStart={(e) => handleTouchStart(e)}
        onTouchMove={(e) => handleTouchMove(e)}
        onTouchEnd={handleTouchEnd}
        width={CANVAS_WIDTH}
        height={320}
        {...props}
      />
      <StyledParagraph color="var(--black)">{t('signature')}</StyledParagraph>
    </StyledCanvasContainer>
  );
};
