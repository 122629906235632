import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $phonePrefix: String
    $uploadedImage: Upload
    $companyName: String!
    $companyAddress: String!
  ) {
    updateUser(
      input: {
        first_name: $firstName
        last_name: $lastName
        phone_number: $phoneNumber
        phone_prefix: $phonePrefix
        company_name: $companyName
        company_address: $companyAddress
      }
      uploadedImage: $uploadedImage
    ) {
      id
      firstName: first_name
      lastName: last_name
      phonePrefix: phone_prefix
      phoneNumber: phone_number
      avatar
      companyName: company_name
      companyAddress: company_address
    }
  }
`;
