import { IconProps } from '../types';

export const HomeIcon = ({
  size = '24',
  fill = 'var(--white)',
  stroke = 'var(--white)',
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9933 3.19943L13.9936 3.19962L19.7535 7.22955C19.7535 7.22957 19.7536 7.2296 19.7536 7.22962C20.2228 7.55809 20.6671 8.09665 20.9945 8.72383C21.3219 9.35104 21.5102 10.0242 21.5102 10.5999V17.3799C21.5102 19.6538 19.6641 21.4999 17.3902 21.4999H6.61023C4.33778 21.4999 2.49023 19.6452 2.49023 17.3699V10.4699C2.49023 9.9355 2.66062 9.29642 2.95881 8.6908C3.25686 8.08545 3.6613 7.55682 4.08775 7.22419L4.08786 7.2241L9.09648 3.31518C9.0967 3.31501 9.09692 3.31484 9.09714 3.31467C10.434 2.27937 12.603 2.22535 13.9933 3.19943ZM12.0002 19.2499C12.6864 19.2499 13.2502 18.6861 13.2502 17.9999V14.9999C13.2502 14.3138 12.6864 13.7499 12.0002 13.7499C11.3141 13.7499 10.7502 14.3138 10.7502 14.9999V17.9999C10.7502 18.6861 11.3141 19.2499 12.0002 19.2499Z"
        fill={fill}
        stroke={stroke}
      />
    </svg>
  );
};
