import { gql } from '@apollo/client';

export const CREATE_LICENSE = gql`
  mutation CreateLicense(
    $beneficiary: String!
    $carName: String!
    $plate: String!
    $userId: ID!
  ) {
    createLicense(
      input: {
        beneficiary: $beneficiary
        car_name: $carName
        plate: $plate
        user: { connect: $userId }
      }
    ) {
      id
    }
  }
`;
