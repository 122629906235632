import styled from 'styled-components/macro';
import logo from 'assets/img/logo.svg';
import car from 'assets/img/green-car.png';
import { Heading } from 'components/Heading/Heading';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/Button/Button';
import { useNavigate } from 'react-router-dom';

const StyledContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: var(--white);
  padding: 20px;
  text-align: center;
  .title {
    padding: 4vh 0;
  }
`;
const StyledCarImage = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const PageNotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <StyledContainer>
      <img src={logo} />
      <Heading
        color="var(--black)"
        dimension="l"
        textAlign="center"
        className="title"
      >
        {t('404')}
      </Heading>
      <Button onBtnClick={() => navigate('/')}>{t('home')}</Button>
      <StyledCarImage src={car} />
    </StyledContainer>
  );
};
