import { Popup } from 'components/Popup/Popup';
import { Button } from 'components/Button/Button';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import * as htmlToImage from 'html-to-image';
import styled from 'styled-components/macro';

const StyledQrCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const StyledQrCode = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    width: 250px;
    height: 250px;
  }
`;

interface GenerateQrCodeButtonProps {
  parkingUuid: string;
}

export const GenerateQrCodeButton = ({
  parkingUuid,
}: GenerateQrCodeButtonProps) => {
  const { t } = useTranslation();
  const qrCodeRef = useRef<HTMLDivElement>(null);
  const [isQrCodePopupOpened, setIsQrCodePopupOpened] =
    useState<boolean>(false);
  const downloadQRCode = () => {
    htmlToImage
      .toPng(qrCodeRef.current)
      .then(function (dataUrl) {
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'qr-code.png';
        link.click();
      })
      .catch(function (error) {
        console.error('Error generating QR code:', error);
      });
  };
  return (
    <>
      <Button
        onBtnClick={() => setIsQrCodePopupOpened(true)}
        className="generateButton"
      >
        {t('generateQrCode')}
      </Button>{' '}
      <Popup
        type="info"
        isPopupOpened={isQrCodePopupOpened}
        onDecline={() => setIsQrCodePopupOpened(false)}
        customerContent={
          <StyledQrCodeContainer>
            <StyledQrCode ref={qrCodeRef}>
              <QRCode
                value={`https://app.falsch-parker.ch/parking/${parkingUuid}`}
                size={300}
              />
            </StyledQrCode>
            <Button onBtnClick={downloadQRCode}>{t('downloadQrCode')}</Button>
          </StyledQrCodeContainer>
        }
        isWithOutButton={true}
      />
    </>
  );
};
