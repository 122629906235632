import { ThemeContext } from 'context/ThemeContext';
import { menu } from 'data/menu';
import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ArrowRightIcon } from '../Icons/ArrowRightIcon/ArrowRightIcon';
import React from 'react';
import { appContext } from 'views/App';

const List = styled.ul`
  display: flex;
  background-color: var(--black-dark);
  width: 100%;
  position: fixed;
  bottom: 0;
  height: 82px;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  z-index: 1001;
  border-top: 1px solid ${(props) => props.theme.primary};
  transform: translateX(-100%);
  transition: all 0.3s ease-in-out;
  &.toggleMenu {
    transform: translateX(0px);
  }
  a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const ToggleList = styled.div`
  position: absolute;
  right: -15px;
  top: 0px;
  background: ${(props) => props.theme.primary};
  border-radius: 0px 10px 10px 0px;
  padding: 30px 5px 30px 5px;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  &.toggleMenu {
    right: -5px;
    transform: rotate(180deg);
  }
`;
export const MenuBar = () => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;

  const { isMenuToggle, toggleMenuBar } = useContext(ThemeContext);
  const location = useLocation();

  return (
    <List className={isMenuToggle && `toggleMenu`}>
      <ToggleList
        className={isMenuToggle && `toggleMenu`}
        onClick={toggleMenuBar}
      >
        <ArrowRightIcon stroke="black" />
      </ToggleList>
      {userData &&
        menu.map((menuItem, i) => {
          const isAdditionalLink = menuItem?.additionalLinks?.find(
            (link) => link === location.pathname,
          );
          const isGasStationMenuItem = menuItem.path === '/history/station';

          if (
            isGasStationMenuItem &&
            userData?.role === 'user' &&
            !userData?.gasStationUser
          )
            return <React.Fragment key={i}></React.Fragment>;
          const isActive =
            location.pathname === menuItem.path || isAdditionalLink;
          if (userData?.role !== 'user' && menuItem.accountType === 'user')
            return <React.Fragment key={i}></React.Fragment>;
          if (userData?.role === 'user' && menuItem.accountType === 'notUser')
            return <React.Fragment key={i}></React.Fragment>;
          if (
            userData?.role !== 'superAdmin' &&
            menuItem.accountType === 'superAdmin'
          )
            return <React.Fragment key={i}></React.Fragment>;
          return (
            <Link to={menuItem.path} key={i}>
              <menuItem.icon
                fill={
                  isActive
                    ? userData?.appSettings?.primary_color
                      ? userData?.appSettings?.primary_color
                      : 'var(--green)'
                    : 'var(--white-icon)'
                }
                stroke={
                  isActive
                    ? userData?.appSettings?.primary_color
                      ? userData?.appSettings?.primary_color
                      : 'var(--green)'
                    : 'var(--white-icon)'
                }
                backgroundColor={'var(--black-dark)'}
                size="24"
              />
            </Link>
          );
        })}
    </List>
  );
};
