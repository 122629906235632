export const qrCodeTickets = {
  cardriverDetailsTitle: 'Dichiarare il conducente del veicolo',
  carDriverDetailsDescription:
    "Se non siete responsabili per l'indennità di gestione, potete trasferire l'indennità di gestione al conducente del veicolo entro 20 giorni.",
  firstName: 'Cognome',
  lastName: 'Nome',
  streetName: 'Via',
  streetNumber: 'Numero civico',
  zipCode: 'CAP',
  city: 'Città',
  email: 'Email',
  carDriverInfo:
    'Tutti i campi contrassegnati con un asterisco sono obbligatori.',
  legalInformation: 'Ulteriori informazioni legali',
  legalInformationElement:
    "Se la vostra targa è bloccata, avete 24 ore per comunicarci i vostri dati del titolare tramite il modulo online o via e-mail all'indirizzo info@falsch-parker.ch. In caso contrario, potrebbero essere applicati costi aggiuntivi. \nParcheggio illegittimo su proprietà privata (art. 641 + 926 + 927 + 928 CC) in base alla cessione ex art. 164 ss. CO.",
  next: 'Avanti',
  back: 'Indietro',
  chedckDetailsTitle: 'Verificare e inviare le informazioni',
  checkDetailsDescription: 'Si prega di verificare le informazioni.',
  ticketNumberQR: 'Numero di indennità di gestione',
  plate: 'Targa',
  countryCode: 'Codice paese',
  notes: 'Nota',
  fineAmount: 'Importo della multa',
  zurichCityPolice: 'Polizia della città di Zurigo',
  placeAndDateOfTheOffense: "Luogo e data dell'infrazione",
  streetAndStreetNumber: 'Via, numero civico',
  zipCodeAndCity: 'CAP, città',
  timeAndDate: 'Data, ora',
  personalDetailsOfTheDriver: 'Dettagli personali del conducente',
  name: 'Cognome / Nome',
  addressSuffix: 'Supplemento indirizzo',
  checkDetailsInfo:
    "Confermo che le mie informazioni sono corrette. Chi accusa falsamente una persona innocente di un reato davanti all'autorità per indurre un'azione penale contro di essa, si rende colpevole di reato (art. 303 codice penale).*",
  send: 'Inviare',
  failedViewTitle: 'Pagamento fallito :(',
  failedViewSubtitle: 'Per favore riprova o contattaci',
  failedViewButton: 'Riprova',
  objectionRaiseTitle: "Presentare un'obiezione",
  objectionRaiseSubtitle:
    "Ritenete che l'indennità di gestione sia ingiustificata o errata?",
  objectionRaiseDescription:
    "Se la vostra obiezione riguarda l'uso di un'app per il parcheggio, vi preghiamo di allegare la ricevuta della transazione rilevante fornita dall'app oppure di caricarla come file. Abbiamo bisogno di questo documento per valutare la vostra obiezione.",
  note: 'La vostra obiezione',
  notePlaveholder:
    'Si prega di giustificare la vostra obiezione (massimo 2000 caratteri)',
  objectionRaiseUpload: 'Allega un file',
  addFile: 'Aggiungi file',
  addFileDesc: '(Max. 5 * 10MB, formati consentiti: pdf, png, jpg, bmp)',
  successPaymentTitle: 'Il pagamento è stato completato con successo!',
  successPaymentSubtitle: 'Grazie per il vostro pagamento',
  paymentInfoTitle: 'Pagare / Informazioni di pagamento',
  paymentInfoSubtitle:
    "Avete le seguenti opzioni per pagare l'indennità di gestione:",
  paymentMethodTicket: 'Carta di credito, Postfinance Card, Twint o E-Banking',
  pay: 'Paga',
  eBanking: 'E-Banking',
  bankAccountNumber: 'Numero di conto',
  yourReferenceNo: 'Il vostro numero di riferimento',
  amount: 'Importo',
  inFavorOf: 'A favore di',
  paymentSlip: 'Bollettino di pagamento',
  paymentSlipDesc:
    "Se l'indennità di gestione non viene pagata entro 2 giorni dall'infrazione, invieremo automaticamente un'indennità di gestione con bollettino di pagamento alla persona responsabile.",
  acceptAGB: 'Accetto i termini e condizioni',
  congratulations: 'Congratulazioni!',
  successCarOwnerAddingSubtitle:
    'Le informazioni sul conducente del veicolo sono state trasmesse',
  successObjectionAddingSubtitle:
    'La vostra obiezione è stata inviata con successo',
  ticketDetailsTitle: "Dettagli dell'indennità di gestione",
  ticketDetaildNumber: 'Numero di indennità di gestione',
  parkingOffendersEmployees: 'Dipendenti di Falschparker',
  placeAndDateOfTheParkingViolation:
    "Luogo e data dell'infrazione di parcheggio",
  objection: 'Obiezione',
  payNow: 'Paga ora',
  ticketTitle: 'Si prega di inserire la \n targa',
  or: 'E',
  plateNumber: 'Targa',
  noTicketTitle: 'Nessuna indennità di gestione trovata',
  noTicketDesc:
    'Purtroppo, non abbiamo trovato alcuna indennità di gestione con le informazioni inserite. Per favore, scansiona nuovamente il codice QR e controlla il numero di UE.',
};
