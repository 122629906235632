import styled from 'styled-components/macro';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useEffect, useState } from 'react';

interface Item extends ReactImageGalleryItem {
  embedUrl?: string;
}

const itemProps = {
  originalClass: 'customerImage',
  bulletClass: 'customerBullet',
  renderItem: (item: Item) => renderCustomerItem(item),
};

const renderCustomerItem = (item: Item) =>
  item?.embedUrl ? (
    <video
      width="100%"
      height="300"
      controls
      autoPlay
      loop
      muted
      playsInline
      data-poster="/icon/apple-touch-icon.png"
      preload="none"
    >
      <source data-src={item.embedUrl} src={item.embedUrl} type="video/mp4" />
    </video>
  ) : (
    <img className="image-gallery-image" src={item.original} />
  );

interface GalleryProps {
  galleryImages?: string[];
  height?: string;
  width?: string;
}

const StyledSlider = styled.div<GalleryProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: 20px;
  background-color: var(--sub--black-opacity);
  text-align: center;
  margin: 0 auto;
  .customerImage {
    height: ${({ height }) => height};
    max-height: ${({ height }) => height};
    img {
      border-radius: 20px;
      height: ${({ height }) => height};
      max-height: ${({ height }) => height};
    }
  }
  .image-gallery-bullets {
    background: rgba(0, 0, 0, 0.25);
    width: max-content;
    padding: 6px 8px;
    border-radius: 20px;
    .active {
      background-color: ${(props) => props.theme.primary};
      border: none;
      width: 6px;
      height: 6px;
    }
  }
  .customerBullet {
    background-color: var(--white);
    border: none;
    width: 6px;
    height: 6px;
  }
`;
export const Gallery = ({
  galleryImages = [],
  height = '300px',
  width = '90%',
}: GalleryProps) => {
  const imageFormats = [
    '.jpg',
    '.jpeg',
    '.png',
    '.svg',
    '.tif',
    '.tiff',
    '.eps',
    '.jp2',
  ];
  const [images, setImages] = useState<ReactImageGalleryItem[] | undefined>(
    undefined,
  );
  useEffect(() => {
    if (!galleryImages) return;
    const img = galleryImages.map((image) => {
      return {
        original: image,
        ...itemProps,
        embedUrl: imageFormats.some((el) => image.includes(el)) ? null : image,
      };
    });
    setImages(img);
  }, [galleryImages]);

  if (!images) return;
  return (
    <StyledSlider height={height} width={width}>
      <ImageGallery
        items={images}
        showBullets={true}
        showIndex={false}
        showThumbnails={false}
        lazyLoad={true}
        showPlayButton={false}
        showNav={false}
        showFullscreenButton={false}
      />
    </StyledSlider>
  );
};
