export const getReportStatusColor = (status: string) => {
  switch (status) {
    case 'new':
      return 'grey-status';
    case 'accepted':
      return 'secondary--lime--green';
    case 'inProgress':
      return 'camel';
    case 'cancelled':
      return 'red';
    case 'emptyRun':
      return 'red';
    case 'towedAway':
      return 'green-dark';
    case 'finished':
      return 'green';
  }
};
