import { Card } from 'components/Cards/Card/Card';
import styled from 'styled-components/macro';
import successImg from 'assets/img/success.png';

interface StyledSuccessCardProps {
  background?: string;
}

const StyledSuccessCard = styled.div<StyledSuccessCardProps>`
  &:after {
    content: '';
    position: absolute;
    background: ${(props) => `url(${props.background}) no-repeat top center`};
    background-size: contain;
    top: 22px;
    left: 0;
    right: 0;
    width: 141px;
    height: 141px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const SuccessCard = () => {
  return (
    <Card>
      <StyledSuccessCard background={successImg} />
    </Card>
  );
};
