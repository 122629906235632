import styled from 'styled-components/macro';
import { Heading } from 'components/Heading/Heading';
import { useTranslation } from 'react-i18next';

const StyledHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
`;
export const ClientListHeading = () => {
  const { t } = useTranslation();
  return (
    <StyledHeadingContainer>
      <Heading color="var(--black)" isWithPaddingBottom={false}>
        {t('clients')}
      </Heading>
    </StyledHeadingContainer>
  );
};
