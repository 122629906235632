export const getPaymentStatusColor = (status: string) => {
  switch (status) {
    case 'open':
      return 'grey-status';
    case 'paid':
      return 'green';
    case 'notPaid':
      return 'red';
    case 'cancelled':
      return 'red';
  }
};
