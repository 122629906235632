import { MouseEvent, ReactNode } from 'react';
import styled from 'styled-components/macro';

export interface IconButtonProps {
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  children: ReactNode;
  className?: string;
}

const StyledIconButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
`;

export const IconButton = ({
  onClick,
  children,
  className,
}: IconButtonProps) => (
  <StyledIconButton onClick={onClick} className={className}>
    {children}
  </StyledIconButton>
);
