import { SuccessCard } from 'components/Cards/SuccessCard/SuccesCard';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Confirm } from 'views/Confirm/Confirm';
import { useEffect } from 'react';
import localforage from 'localforage';

export const CarOwnerContractSuccess = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    localforage.setItem('uploadedFilesAmount', 0);
  }, []);
  return (
    <Confirm
      title={t('congratulations')}
      subtitle={t('successCarOwenrContractSubtitle')}
      card={<SuccessCard />}
      onContinueClick={() => navigate('/history/deactive')}
      confirmButtonId="confirmCreatingCarOwnerButton"
    />
  );
};
