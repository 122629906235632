import { Layout } from 'components/Layout/Layout';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { ContractButtonContainer } from './ContractButtonContainer/ContractButtonContainer';
import { ContractMainContent } from './ContractMainContent/ContractMainContent';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 670px;
  .userSignature {
    width: 50%;
  }
`;

export const Contract = () => {
  const { t } = useTranslation();
  return (
    <Layout
      mode="top"
      isBackBtn
      backButtonMode="text"
      viewTitle={t('contract')}
      isWithBottomPadding={false}
    >
      <StyledContainer>
        <ContractMainContent />
        <ContractButtonContainer />
      </StyledContainer>
    </Layout>
  );
};
