import { Heading } from 'components/Heading/Heading';
import { useTranslation } from 'react-i18next';

export const NoNotification = () => {
  const { t } = useTranslation();
  return (
    <Heading
      color="var(--black)"
      textAlign="center"
      className="noNotificationsTitle"
    >
      {t('noNotification')}
    </Heading>
  );
};
