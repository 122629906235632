import { gql } from '@apollo/client';

export const UPDATE_LICENSE = gql`
  mutation UpdateLicense(
    $beneficiary: String
    $carName: String
    $plate: String
    $id: ID!
  ) {
    updateLicense(
      id: $id
      input: { beneficiary: $beneficiary, car_name: $carName, plate: $plate }
    ) {
      id
    }
  }
`;
