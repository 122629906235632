import { Position } from 'types/general';

export const getAddress = async (position: Position) => {
  const key = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position[0]},${position[1]}&key=${key}`,
  );
  const data = await response.json();
  const address = data.results[0]?.address_components;
  if (!address) return null;
  const streetNumber = address?.find((data) =>
    data.types.find((type) => type === 'street_number'),
  );
  const street = address?.find((data) =>
    data.types.find((type) => type === 'route'),
  );
  const zipCode = address?.find((data) =>
    data.types.find((type) => type === 'postal_code'),
  );
  const city = address?.find((data) =>
    data.types.find((type) => type === 'locality'),
  );
  const addressData = {
    street: street?.long_name,
    streetNumber: streetNumber?.short_name,
    zipCode: zipCode?.long_name,
    city: city?.long_name,
  };
  return addressData;
};
