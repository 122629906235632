import { IconProps } from '../types';

export const ClockIcon = ({
  size = '24',
  fill = 'var(--white)',
}: IconProps) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48.000000 48.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,48.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none"
      >
        <path
          d="M213 466 c4 -10 7 -21 7 -26 0 -4 21 -10 46 -13 82 -9 164 -102 164
   -187 -1 -96 -90 -190 -181 -190 -24 0 -29 -4 -29 -25 0 -20 5 -25 26 -25 78 0
   167 58 206 134 29 54 31 155 4 206 -41 79 -106 124 -196 136 -48 6 -53 5 -47
   -10z"
        />
        <path d="M124 445 c-7 -18 3 -35 22 -35 15 0 17 9 8 34 -8 20 -23 21 -30 1z" />
        <path d="M44 379 c-7 -12 12 -29 34 -29 18 0 15 27 -4 34 -21 8 -22 8 -30 -5z" />
        <path
          d="M264 263 c-52 -58 -56 -60 -101 -27 -20 14 -22 14 -32 -5 -10 -17 -5
   -24 36 -56 25 -19 50 -34 55 -33 5 2 37 37 73 78 58 67 63 76 48 88 -9 6 -20
   12 -23 12 -4 0 -29 -26 -56 -57z"
        />
        <path d="M7 303 c-16 -15 -6 -33 18 -33 28 0 34 24 9 34 -19 7 -19 7 -27 -1z" />
        <path
          d="M4 194 c3 -9 6 -17 6 -19 0 -2 9 -1 20 2 30 8 25 33 -6 33 -20 0 -25
   -4 -20 -16z"
        />
        <path
          d="M54 125 c-14 -10 -16 -17 -8 -25 13 -13 44 -3 44 15 0 21 -15 26 -36
   10z"
        />
        <path
          d="M123 54 c-3 -8 0 -21 6 -27 10 -9 16 -8 26 7 8 10 12 23 9 27 -9 15
   -34 10 -41 -7z"
        />
      </g>
    </svg>
  );
};
