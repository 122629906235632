import { useAuthContext } from 'context/auth';
import { useContext, useEffect } from 'react';
import { Dashboard } from '../Dashboard/Dashboard';
import { SignIn } from '../SignIn/SignIn';
import { appContext } from 'views/App';

export const Welcome = () => {
  const appContextStore = useContext(appContext);
  const setReportDetails = appContextStore?.setReportDetails;

  const { isSignedIn } = useAuthContext();

  useEffect(() => {
    setReportDetails(null);
  }, []);

  if (isSignedIn) {
    return <Dashboard />;
  }
  return <SignIn />;
};
