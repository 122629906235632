import { SuccessCard } from 'components/Cards/SuccessCard/SuccesCard';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Confirm } from '../Confirm/Confirm';
import { appContext } from 'views/App';

export const SuccessfulContract = () => {
  const appContextStore = useContext(appContext);
  const setCarOwner = appContextStore?.setCarOwner;

  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    setCarOwner(null);
  }, []);
  return (
    <Confirm
      title={t('congratulations')}
      subtitle={t('successContractSubtitle')}
      card={<SuccessCard />}
      onContinueClick={() => navigate('/')}
    />
  );
};
