import { gql } from '@apollo/client';

export const USER_NOTIFICATIONS = gql`
  query ($orderBy: SortOrder!) {
    userNotifications(orderBy: [{ column: CREATED_AT, order: $orderBy }]) {
      id
      text
      textEn: text_en
      displayed
      createdAt: created_at
      report {
        hash
        user {
          avatar
        }
        driverMap {
          driver {
            avatar
          }
        }
      }
    }
  }
`;
