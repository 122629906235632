import styled from 'styled-components/macro';
import { ChangeEvent, useState, ReactNode, useEffect } from 'react';
import { IconButton, IconButtonProps } from 'components/IconButton/IconButton';
import { Input } from 'components/Input/Input';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from 'components/Icons/CloseIcon/CloseIcon';
import { Popup } from 'components/Popup/Popup';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { AddIcon } from 'components/Icons/AddIcon/AddIcon';

interface UploadInputProps {
  mode?: 'dark' | 'transparent' | 'light';
  content?: ReactNode;
  label?: string;
  name: string;
  handleMediaChange?: (media: File[]) => void;
  addedMedia?: File[];
  mediaType?: string;
  bottomMarting?: string;
  isRequired?: boolean;
}

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 10px 0;
`;

const StyledImageContainer = styled.div`
  position: relative;
  margin-left: 12px;
  &:first-child {
    margin-left: 0;
  }
`;

const StyledIconButton = ({
  className,
  children,
  onClick,
}: IconButtonProps) => (
  <IconButton className={className} onClick={onClick}>
    {children}
  </IconButton>
);

const OverstyledIconButton = styled(StyledIconButton)`
  position: absolute;
  right: 4px;
  top: 4px;
  background-color: var(--white);
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledDefaultContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 15px;
  width: 100%;
`;
const StyledContent = styled.div`
  width: 100%;
`;
const StyledLabel = styled.label`
  position: absolute;
  top: -20px;
  right: 0;
  max-width: 113px;
  background-color: rgba(1, 213, 75, 0.2);
  padding: 8px 10px;
  border-radius: 12px;
  z-index: 1;
`;

const StyledPhotosContainer = styled.div`
  display: flex;
`;
const StyledImage = styled.img`
  border-radius: 5px;
  object-fit: cover;
`;
const StyledFileContainer = styled.div`
  position: relative;
  margin-top: 6px;
  &:first-child {
    margin-top: 6px;
  }
`;
const StyledMainContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 158px;
`;

export const UploadFileInput = ({
  mode = 'light',
  content,
  label,
  name,
  handleMediaChange,
  addedMedia = [],
  bottomMarting = '0',
}: UploadInputProps) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<File[] | []>(addedMedia);
  const [isPopupOpened, setIsPopupOpened] = useState<boolean>(false);

  const handleChange = (file: File) => {
    const fileSize = file?.size / 1000000;
    if (fileSize > 50) {
      setIsPopupOpened(true);
    } else {
      setFiles((prev) => [...prev, file]);
    }
  };

  const handleFileRemove = (image: File) => {
    const newImages = files.filter((item) => item !== image);
    if (!newImages?.length) {
      setFiles([]);
    } else {
      setFiles(newImages);
    }
  };

  useEffect(() => {
    if (!files) return;
    handleMediaChange && handleMediaChange(files);
  }, [files]);

  return (
    <div>
      <Input
        label={mode === 'transparent' ? undefined : label}
        name={name}
        type="file"
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          handleChange(e.target.files[0])
        }
        mode={mode}
        bottomMarting={bottomMarting}
      >
        {content ? (
          <StyledContent>
            <label htmlFor={name}>{content}</label>
          </StyledContent>
        ) : (
          <StyledMainContainer>
            {files && Boolean(files.length) && files.length < 5 && (
              <StyledLabel htmlFor={name}>
                <Paragraph color="var(--green)" weight="bolder" dimension="xs">
                  {t('addFile')}
                </Paragraph>
              </StyledLabel>
            )}
            {files && Boolean(files.length) && (
              <>
                <StyledContainer>
                  <StyledPhotosContainer>
                    {files
                      .filter((file: File) => file.type.includes('image'))
                      ?.map((file: File) => {
                        const fileURL = file ? URL.createObjectURL(file) : '';
                        return (
                          <StyledImageContainer key={fileURL}>
                            <StyledImage src={fileURL} width={49} height={49} />

                            <OverstyledIconButton
                              onClick={() => handleFileRemove(file)}
                            >
                              <CloseIcon size="6" fill="black" />
                            </OverstyledIconButton>
                          </StyledImageContainer>
                        );
                      })}
                  </StyledPhotosContainer>
                </StyledContainer>
                <>
                  {files
                    .filter((file: File) => !file.type.includes('image'))
                    ?.map((file: File) => {
                      const fileURL = file ? URL.createObjectURL(file) : '';
                      return (
                        <StyledFileContainer key={fileURL}>
                          <Paragraph color="var(--black)">
                            {file.name}
                          </Paragraph>
                          <OverstyledIconButton
                            onClick={() => handleFileRemove(file)}
                          >
                            <CloseIcon size="6" fill="black" />
                          </OverstyledIconButton>
                        </StyledFileContainer>
                      );
                    })}
                </>
              </>
            )}
            {(!files || !files.length) && (
              <label htmlFor={name}>
                <StyledDefaultContent>
                  <AddIcon />
                  <Paragraph
                    color="var(--sub--black)"
                    textAlign="center"
                    dimension="m"
                  >
                    {t('addFileDesc')}
                  </Paragraph>
                </StyledDefaultContent>
              </label>
            )}
          </StyledMainContainer>
        )}
      </Input>
      <Popup
        isPopupOpened={isPopupOpened}
        type="info"
        content={t('tooBigFile')}
        confirmText="ok"
        onDecline={() => setIsPopupOpened(false)}
      />
    </div>
  );
};
