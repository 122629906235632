import { gql } from '@apollo/client';

export const DRIVER_PLATE_CHECK = gql`
  mutation DriverPlateCheck(
    $latitude: Float!
    $longitude: Float!
    $plate: String!
  ) {
    driverPlateCheck(latitude: $latitude, longitude: $longitude, plate: $plate)
  }
`;
