import { gql } from '@apollo/client';

export const CREATE_BECOME_DRIVER_APPLICATION = gql`
  mutation CreateBecomeDriverApplication(
    $firstName: String!
    $lastName: String!
    $fullAddress: String!
    $street: String!
    $streetNumber: String!
    $zipCode: String!
    $city: String!
    $dateOfBirth: DateTime!
    $licensePhotoFront: Upload!
    $governmentIdFront: Upload!
    $licensePhotoBack: Upload!
    $governmentIdBack: Upload!
  ) {
    createBecomeDriverApplication(
      input: {
        first_name: $firstName
        last_name: $lastName
        address_full: $fullAddress
        street: $street
        street_number: $streetNumber
        zip_code: $zipCode
        city: $city
        date_of_birth: $dateOfBirth
      }
      license_photo_front: $licensePhotoFront
      government_id_front: $governmentIdFront
      license_photo_back: $licensePhotoBack
      government_id_back: $governmentIdBack
    ) {
      id
    }
  }
`;
