import { gql } from '@apollo/client';

export const USER_PARKING_ZONE_ALL = gql`
  query UserParkingZoneAll {
    userParkingZoneAll(orderBy: [{ column: CREATED_AT, order: ASC }]) {
      id
      type
      name
      fullAddress: address_full
      streetName: street_name
      streetNumber: street_number
      zipCode: zip_code
      city
      latitude
      longitude
      user {
        id
        firstName: first_name
        lastName: last_name
        companyName: company_name
      }
    }
  }
`;
