import { Button } from 'components/Button/Button';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';

const StyledButtonContainer = styled.div`
  display: flex;
  .cancelButton {
    margin-right: 16px;
  }
`;

interface LanguageButtonContainerProps {
  handleLanguageChange: MouseEventHandler;
}

export const LanguageButtonContainer = ({
  handleLanguageChange,
}: LanguageButtonContainerProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <StyledButtonContainer>
      <Button
        mode="inverted"
        onBtnClick={() => navigate('/profile')}
        className="cancelButton"
      >
        {t('cancelButton')}
      </Button>
      <Button onBtnClick={handleLanguageChange}>{t('save')}</Button>
    </StyledButtonContainer>
  );
};
