import styled from 'styled-components/macro';
import { ReportType } from '../ReportType/ReportType';
import { ReportImageSkeleton } from 'skeletons/ReportImageSkeleton';

const StyledPhotoWrapper = styled.div`
  position: relative;
  display: flex;
  padding-right: 6px;
`;

const StyledImage = styled.img`
  width: 120px;
  border-radius: 4px;
  object-fit: cover;
`;

interface ReportPhotoWrapperProps {
  reportType?: number;
  image?: string;
  isIntersecting: boolean;
}

export const ReportPhotoWrapper = ({
  reportType,
  image,
  isIntersecting,
}: ReportPhotoWrapperProps) => {
  return (
    <StyledPhotoWrapper>
      <ReportType reportType={reportType} />
      {isIntersecting ? (
        <StyledImage src={image || ''} height={225} />
      ) : (
        <ReportImageSkeleton />
      )}
    </StyledPhotoWrapper>
  );
};
