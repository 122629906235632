import { gql } from '@apollo/client';

export const PARKING_ZONE = gql`
  query ($id: ID!) {
    parkingZone(id: $id) {
      uuid
      id
      name
      streetName: street_name
      streetNumber: street_number
      zipCode: zip_code
      city
      type
      latitude
      longitude
      notes
      photos {
        photo
        id
      }
    }
  }
`;
