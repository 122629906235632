import styled from 'styled-components/macro';
import coinsImage from 'assets/img/history-coins.png';
import { Paragraph } from 'components/Paragraph/Paragraph';

export interface HistoryListItemProps {
  title?: string;
  date?: string;
  amount?: number;
}

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
`;
const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const ImageContainer = styled.div`
  background: var(--black);
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const HistoryListItem = ({
  title = 'Monthly Reward',
  date = '22 Sep 2022, 13:43 PM',
  amount = 20,
}: HistoryListItemProps) => (
  <MainContainer>
    <LeftContainer>
      <ImageContainer>
        <img src={coinsImage} alt="Coins" width={33} height={33} />
      </ImageContainer>
      <TextContainer>
        <Paragraph color="var(--black)" weight="bolder">
          {title}
        </Paragraph>
        <Paragraph color="var(--sub--black)" dimension="s">
          {date}
        </Paragraph>
      </TextContainer>
    </LeftContainer>
    <Paragraph color="var(--black)" dimension="xl" weight="bold">
      {amount} CHF
    </Paragraph>
  </MainContainer>
);
