import { IconProps } from '../types';

export const CameraIcon = ({
  size = '53',
  fill = 'var(--black)',
  stroke = 'var(--green)',
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 53 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="26.5" cy="26" r="26" fill={stroke} />
      <path
        d="M26.5 22C24.332 22 22.5 23.832 22.5 26C22.5 28.168 24.332 30 26.5 30C28.668 30 30.5 28.168 30.5 26C30.5 23.832 28.668 22 26.5 22Z"
        fill={fill}
      />
      <path
        d="M37.166 16.6667H33.718L30.1087 13.0574C29.9851 12.9334 29.8382 12.835 29.6764 12.7679C29.5146 12.7009 29.3411 12.6665 29.166 12.6667H23.8327C23.6576 12.6665 23.4841 12.7009 23.3223 12.7679C23.1605 12.835 23.0136 12.9334 22.89 13.0574L19.2807 16.6667H15.8327C14.362 16.6667 13.166 17.8627 13.166 19.3334V34.0001C13.166 35.4708 14.362 36.6668 15.8327 36.6668H37.166C38.6367 36.6668 39.8327 35.4708 39.8327 34.0001V19.3334C39.8327 17.8627 38.6367 16.6667 37.166 16.6667ZM26.4993 32.6667C22.886 32.6667 19.8327 29.6134 19.8327 26.0001C19.8327 22.3867 22.886 19.3334 26.4993 19.3334C30.1127 19.3334 33.166 22.3867 33.166 26.0001C33.166 29.6134 30.1127 32.6667 26.4993 32.6667Z"
        fill={fill}
      />
    </svg>
  );
};
