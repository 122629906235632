import styled from 'styled-components';
import { Paragraph } from '../Paragraph/Paragraph';
import { User } from 'types/User';
import { useTranslation } from 'react-i18next';
import person from 'assets/img/person.svg';
import {
  UserNameSkeleton,
  UserRoleSkeleton,
} from 'skeletons/UserInfoSkeletons';
import { useContext } from 'react';
import { appContext } from 'views/App';

interface UserInfoProps {
  layout: 'horizontal' | 'vertical' | 'horizontal-column' | 'avatar';
  color?: string;
  align?: 'flex-start' | 'center' | 'flex-end';
  size?: 'xs' | 's' | 'm';
  user?: User;
  onUserInfoClick?: () => void;
}
interface UserDetailsContainerProps {
  layoutContainer: string;
}
interface UserInfoContainerProps {
  align?: string;
}
interface UserAvatarImgProps {
  avatarSize?: string;
}

const UserInfoContainer = styled.div<UserInfoContainerProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => align};
  z-index: 1;
  .userRole {
    margin-top: 4px;
  }
`;
const UserDetailsContainer = styled.div<UserDetailsContainerProps>`
  display: flex;
  flex-direction: ${({ layoutContainer }) =>
    (layoutContainer === 'horizontal' && 'row') ||
    (layoutContainer === 'vertical' && 'column')};
  justify-content: ${({ layoutContainer }) =>
    layoutContainer === 'horizontal' && 'space-between'};
  align-items: center;
  flex: 1;
`;
const UserDetails = styled.div<UserDetailsContainerProps>`
  display: flex;
  flex-direction: ${({ layoutContainer }) =>
    layoutContainer === 'horizontal' ? 'row' : 'column'};
  align-items: ${({ layoutContainer }) =>
    layoutContainer === 'horizontal-column' ? 'flex-start' : 'center'};
`;
const UserAvatar = styled.div<UserDetailsContainerProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: ${({ layoutContainer }) =>
    layoutContainer === 'vertical' || layoutContainer === 'horizontal-column'
      ? '12px'
      : ''};
  margin-right: ${({ layoutContainer }) =>
    layoutContainer === 'horizontal' ? '12px' : ''};
  &:before {
    content: '';
    position: absolute;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    background-color: ${(props) => props.theme.primary};
    inset: -2px;
    z-index: 1;
    border-radius: 50%;
    opacity: 1;
  }
`;
const UserAvatarImg = styled.img<UserAvatarImgProps>`
  position: relative;
  z-index: 1;
  width: ${({ avatarSize }) => avatarSize};
  height: ${({ avatarSize }) => avatarSize};
  border-radius: 50%;
`;
const UserAbout = styled.div<UserDetailsContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: ${({ layoutContainer }) =>
    (layoutContainer === 'horizontal' && 'flex-start') ||
    (layoutContainer === 'vertical' && 'center')};
  max-width: ${({ layoutContainer }) =>
    layoutContainer === 'horizontal-column' ? '110px' : 'auto'};
`;
export const UserInfo = ({
  layout,
  color = 'var(--white-text)',
  align = 'flex-start',
  size = 's',
  user,
  onUserInfoClick,
}: UserInfoProps) => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;

  const { t } = useTranslation();
  const userDetails = user ? user : userData;
  const avatarSize =
    (size === 'xs' && '32px') ||
    (size === 's' && '41px') ||
    (size === 'm' && '72px');
  const avatar =
    userDetails?.avatar === 'no photo' ? person : userDetails?.avatar;
  return (
    <UserInfoContainer align={align} onClick={onUserInfoClick}>
      {layout === 'avatar' ? (
        <UserAvatar layoutContainer={layout}>
          <UserAvatarImg src={avatar} avatarSize={avatarSize} />
        </UserAvatar>
      ) : (
        <UserDetailsContainer layoutContainer={layout}>
          <UserDetails layoutContainer={layout}>
            <UserAvatar layoutContainer={layout}>
              <UserAvatarImg src={avatar} avatarSize={avatarSize} />
            </UserAvatar>
            <UserAbout layoutContainer={layout}>
              {userDetails ? (
                <Paragraph color={color} dimension="l" weight="bold">
                  {userDetails?.firstName} {userDetails?.lastName}
                </Paragraph>
              ) : (
                <UserNameSkeleton />
              )}
              {userDetails ? (
                <Paragraph
                  color={color}
                  dimension={layout === 'vertical' ? 'm' : 'xs'}
                  weight="normal"
                  textTransform="capitalize"
                  className="userRole"
                >
                  {t(userDetails?.role)}
                </Paragraph>
              ) : (
                <UserRoleSkeleton />
              )}
            </UserAbout>
          </UserDetails>
        </UserDetailsContainer>
      )}
    </UserInfoContainer>
  );
};
