import { Card } from 'components/Cards/Card/Card';
import styled from 'styled-components/macro';
import whiteNotebook from 'assets/img/whiteNotebook.png';
import blackNotebook from 'assets/img/blackNotebook.png';
import { useContext } from 'react';
import { ThemeContext } from 'context/ThemeContext';

interface StyledFailedReportCardProps {
  background?: string;
}

const StyledFailedReportCard = styled.div<StyledFailedReportCardProps>`
  &:after {
    content: '';
    position: absolute;
    background: ${(props) => `url(${props.background}) no-repeat top center`};
    background-size: contain;
    top: 42px;
    left: 0;
    right: 0;
    width: 200px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const FailedReportCard = () => {
  const { isDarkMode } = useContext(ThemeContext);
  return (
    <Card>
      <StyledFailedReportCard
        background={isDarkMode ? whiteNotebook : blackNotebook}
      />
    </Card>
  );
};
