import { ReactNode, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { MenuBar } from '../MenuBar/MenuBar';
import map from 'assets/img/map.jpg';
import { BackButton } from '../BackButton/BackButton';
import { Paragraph, ParagraphProps } from '../Paragraph/Paragraph';
import { LoadingAnimation } from '../LoadingAnimation/LoadingAnimation';
import { useWindowHeight } from 'hooks/useWindowHeight';
import { AppWrapper } from 'AppWrapper';
import { useLocation, useParams } from 'react-router-dom';
import { appContext } from 'views/App';

interface LayoutProps {
  backgroundContent?: ReactNode;
  children: ReactNode;
  isBackBtn?: boolean;
  isMenuBar?: boolean;
  userBarContent?: ReactNode;
  isWithMapBackground?: boolean;
  mode?: 'top' | 'medium' | 'standard' | 'bottom' | 'one-third';
  topContent?: ReactNode;
  topContentPosition?: 'center' | 'right';
  topContentSize?: 's' | 'm' | 'l' | 'xl';
  backButtonMode?: 'back' | 'text';
  viewTitle?: string;
  isWithBottomPadding?: boolean;
  backBtnLink?: string;
  customerTopHeight?: number;
  isWithAppWrapper?: boolean;
  isWithHeightAnimation?: boolean;
  outsideElements?: ReactNode;
  isWithLoadingAnimation?: boolean;
  handleContentEnd?: (isContentEnd: boolean) => void;
}

interface StyledContentProps {
  isMenuBar: boolean;
  isWithMapBackground?: boolean;
  height?: string;
  isWithBottomPadding?: boolean;
  isWithHeightAnimation?: boolean;
}

interface StyledTopContentProps {
  height?: string;
  topContentPosition?: 'center' | 'right';
  isWithMapBackground?: boolean;
  topContentHeight?: string;
}
const StyledContainer = styled.div`
  position: relative;
  height: 100%;
  background-color: var(--black);
`;

const StyledBackgroundContent = styled.div`
  background-color: var(--black-darkmode);
  height: 100vh;
`;

const StyledContent = styled.div<StyledContentProps>`
  position: absolute;
  bottom: ${({ isMenuBar }) => (isMenuBar ? '0px' : '0')};
  width: 100%;
  height: ${({ isMenuBar, height }) =>
    isMenuBar ? `calc(${height} - 62px + 5px)` : `${height}`};
  transition: ${({ isWithHeightAnimation }) =>
    isWithHeightAnimation && 'height 0.5s'};
  background-color: ${({ isWithMapBackground }) =>
    isWithMapBackground ? 'var(--black-always)' : 'var(--white)'};
  border-radius: 32px 32px 0px 0px;
  overflow-y: auto;
  padding: ${({ isWithBottomPadding }) =>
    isWithBottomPadding ? '26px' : '26px 26px 0'};
  z-index: 1000;
  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    height: 50%;
  }
`;

const StyledTopContent = styled.div<StyledTopContentProps>`
  position: absolute;
  width: 100%;
  text-align: ${({ topContentPosition }) => topContentPosition};
  bottom: ${({ height, topContentHeight }) =>
    `calc(${height} - ${topContentHeight} - 5px)`};
  @media (orientation: landscape) and (hover: none) and (pointer: coarse) {
    bottom: ${({ topContentHeight }) => `calc(55% - ${topContentHeight})`};
  }
  z-index: 1001;
  & img {
    max-height: 210px;
    object-fit: contain;
    padding-bottom: 10px;
  }
  :before {
    content: '';
    position: absolute;
    width: 100%;
    height: ${({ topContentHeight }) => topContentHeight};
    bottom: 5px;
    left: 0;
    background-color: ${({ isWithMapBackground }) =>
      isWithMapBackground ? 'var(--black-always)' : 'var(--white)'};
    z-index: -1;
    border-radius: 32px 32px 0px 0px;
  }
`;
const UserBar = styled.div`
  position: absolute;
  top: 11px;
  left: 0;
  z-index: 1001;
  width: 100%;
  padding: 0 26px;
`;

const StyledParagraph = ({ className, children }: ParagraphProps) => (
  <Paragraph
    className={className}
    dimension="xl"
    weight="bold"
    color="var(--white-text)"
  >
    {children}
  </Paragraph>
);
const OverstyledParagraph = styled(StyledParagraph)`
  position: absolute;
  top: 12px;
  left: 55px;
`;

export const Layout = ({
  backgroundContent,
  children,
  isBackBtn = false,
  isMenuBar = false,
  isWithMapBackground = false,
  mode = 'standard',
  topContent,
  topContentPosition = 'right',
  topContentSize = 'm',
  userBarContent,
  backButtonMode = 'back',
  viewTitle,
  isWithBottomPadding = true,
  backBtnLink,
  customerTopHeight,
  isWithAppWrapper = true,
  isWithHeightAnimation = false,
  outsideElements,
  isWithLoadingAnimation = true,
  handleContentEnd,
}: LayoutProps) => {
  const appContextStore = useContext(appContext);
  const isLoading = appContextStore?.isLoading;
  const setNewUser = appContextStore?.setNewUser;

  const { windowHeight } = useWindowHeight();
  const bottomHeight = windowHeight - (windowHeight / 100) * 70;
  const height = customerTopHeight
    ? `${windowHeight - customerTopHeight}px`
    : (mode === 'medium' && `${windowHeight - 270}px`) ||
      (mode === 'standard' && `${windowHeight - 115}px`) ||
      (mode === 'top' && `${windowHeight - 70}px`) ||
      (mode === 'bottom' && `${bottomHeight}px`) ||
      (mode === 'one-third' && `${windowHeight - 145}px`);
  const topContentHeight =
    (topContentSize === 's' && '45px') ||
    (topContentSize === 'm' && '85px') ||
    (topContentSize === 'l' && '125px') ||
    (topContentSize === 'xl' && '245px');

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/sign-up' || location.pathname === '/agb')
      return;
    setNewUser(null);
  }, []);

  const contentRef = useRef<HTMLDivElement>(null);
  const handleContentScroll = () => {
    if (contentRef && contentRef?.current) {
      if (
        contentRef?.current?.scrollTop +
          contentRef?.current?.clientHeight +
          1 >=
        contentRef?.current?.scrollHeight
      ) {
        handleContentEnd && handleContentEnd(true);
      } else {
        handleContentEnd && handleContentEnd(false);
      }
    }
  };

  const { type } = useParams();
  useEffect(() => {
    contentRef?.current?.scrollTo({ top: 0 });
  }, [type]);

  return (
    <StyledContainer>
      {userBarContent && <UserBar>{userBarContent}</UserBar>}
      {isBackBtn && (
        <BackButton
          as={backButtonMode}
          textColor="white-text"
          link={backBtnLink}
        />
      )}
      {viewTitle && <OverstyledParagraph>{viewTitle}</OverstyledParagraph>}
      <StyledBackgroundContent>
        {isWithMapBackground ? (
          <img src={map} width={'100%'} alt="Map" />
        ) : (
          backgroundContent
        )}
      </StyledBackgroundContent>
      {topContent && (
        <StyledTopContent
          height={height}
          topContentPosition={topContentPosition}
          isWithMapBackground={isWithMapBackground}
          topContentHeight={topContentHeight}
        >
          {topContent}
        </StyledTopContent>
      )}
      <StyledContent
        isMenuBar={isMenuBar}
        isWithMapBackground={isWithMapBackground}
        height={height}
        isWithBottomPadding={isWithBottomPadding}
        isWithHeightAnimation={isWithHeightAnimation}
        onScroll={() => handleContentScroll()}
        ref={contentRef}
      >
        {children}
      </StyledContent>
      {isMenuBar && <MenuBar />}{' '}
      {isLoading && isWithLoadingAnimation && <LoadingAnimation />}
      {isWithAppWrapper && <AppWrapper />}
      {outsideElements}
    </StyledContainer>
  );
};
