import { Status } from 'components/Status/Status';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

const StyledStatus = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
interface ClientCashbackStatusProps {
  clientCashback?: boolean;
}
export const ClientCashbackStatus = ({
  clientCashback,
}: ClientCashbackStatusProps) => {
  const { t } = useTranslation();
  return (
    <StyledStatus>
      <Status
        color={clientCashback ? 'green' : 'red'}
        customText={t(clientCashback ? 'cashbackOn' : 'cashbackOff')}
        size="xs"
      />
    </StyledStatus>
  );
};
