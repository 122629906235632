import { gql } from '@apollo/client';

export const LICENSES = gql`
  query {
    licenses {
      id
      beneficiary
      carName: car_name
      plate
      parkingsMap {
        parking {
          id
          name
        }
      }
    }
  }
`;
