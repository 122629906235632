import { gql } from '@apollo/client';

export const GET_NEW_NOTIFICATION = gql`
  query {
    getNewNotification {
      id
      text
      textEn: text_en
      displayed
      created_at
      updated_at
      user {
        id
        first_name
        last_name
        company_name
        company_address
        email
        email_verified_at
        verified
        rank
        balance
        phone_prefix
        phone_number
        ref_code
        avatar
        created_at
        updated_at
      }
      report {
        id
        type
        hash
        plate
        car_name
        status
        phone_prefix
        phone_number
        address_full
        street
        street_number
        zip_code
        city
        latitude
        longitude
        signature
        created_at
        updated_at
      }
    }
  }
`;
