import {
  ReactNode,
  useEffect,
  useState,
  createContext,
  Dispatch,
  SetStateAction,
} from 'react';
import { useUpdateDriverLocalization } from 'hooks/useUpdateDriverLocalization';
import { ThemeProvider } from 'styled-components';
import TagManager from 'react-gtm-module';
import { CarOwner } from 'types/CarOwner';
import { ReportDetails } from 'types/Report';
import { NewUser, User } from 'types/User';
import { NewNotification } from 'types/Notification';
import { ThemeProps } from 'types/general';
import { ParkingZone } from 'types/ParkingZone';

interface AppProps {
  children: ReactNode;
}
export interface Theme {
  primary: string;
  secondary: string;
}

export type AppState = {
  reportDetails: ReportDetails;
  setReportDetails: Dispatch<SetStateAction<ReportDetails>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  userData: User;
  setUserData: Dispatch<SetStateAction<User>>;
  carOwner: CarOwner;
  setCarOwner: Dispatch<SetStateAction<CarOwner>>;
  parkingData: ParkingZone;
  setParkingData: Dispatch<SetStateAction<ParkingZone>>;
  checkedPlate: string;
  setCheckedPlate: Dispatch<SetStateAction<string>>;
  isError: boolean;
  setIsError: Dispatch<SetStateAction<boolean>>;
  newUser: NewUser;
  setNewUser: Dispatch<SetStateAction<NewUser>>;
  newNotification: NewNotification;
  setNewNotification: Dispatch<SetStateAction<NewNotification>>;
  theme: ThemeProps;
  setTheme: Dispatch<SetStateAction<ThemeProps>>;
};

export const appContext = createContext<AppState>({} as AppState);

export const App = ({ children }: AppProps) => {
  useUpdateDriverLocalization();

  const defaultTheme = {
    primary: 'rgba(1, 213, 75, 1)',
    secondary: 'rgba(1, 213, 75, 1)',
  };

  const [reportDetails, setReportDetails] = useState<ReportDetails>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userData, setUserData] = useState<User>(null);
  const [carOwner, setCarOwner] = useState<CarOwner>(null);
  const [parkingData, setParkingData] = useState<ParkingZone>(null);
  const [checkedPlate, setCheckedPlate] = useState<string>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [newUser, setNewUser] = useState<NewUser>(null);
  const [newNotification, setNewNotification] = useState<NewNotification>(null);
  const [theme, setTheme] = useState<ThemeProps>(defaultTheme);

  useEffect(() => {
    if (!userData && !userData?.appSettings) return;
    setTheme({
      primary: userData?.appSettings?.primary_color || 'rgba(1, 213, 75, 1)',
      secondary:
        userData?.appSettings?.secondary_color || 'rgba(1, 213, 75, 1)',
    });
  }, [userData]);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID,
    };
    let swVersion = null;
    if ('serviceWorker' in navigator) {
      const swURL = navigator?.serviceWorker?.controller?.scriptURL;
      const version = swURL?.split('?v=');
      swVersion = version && version?.length > 1 ? version[1] : null;
    }
    TagManager.initialize(tagManagerArgs, {
      dataLayer: {
        swVersion: swVersion ? swVersion : process.env.REACT_APP_SW_VERSION,
      },
      dataLayerName: 'Service Worker Version',
    });
  }, []);

  return (
    <appContext.Provider
      value={{
        reportDetails,
        setReportDetails,
        isLoading,
        setIsLoading,
        userData,
        setUserData,
        carOwner,
        setCarOwner,
        parkingData,
        setParkingData,
        checkedPlate,
        setCheckedPlate,
        isError,
        setIsError,
        newUser,
        setNewUser,
        newNotification,
        setNewNotification,
        theme,
        setTheme,
      }}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </appContext.Provider>
  );
};
