import { InputHTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components/macro';

type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
type HeadingSize = 's' | 'm' | 'l' | 'xl' | 'xxl';
type HeadingWeight = 'normal' | 'bolder' | 'bold';
type HeadingAlign = 'left' | 'center' | 'right';

export interface HeadingProps extends InputHTMLAttributes<HTMLHeadingElement> {
  tag?: HeadingTag;
  dimension?: HeadingSize;
  weight?: HeadingWeight;
  color?: string;
  textAlign?: HeadingAlign;
  children: ReactNode;
  isWithPaddingBottom?: boolean;
}

const StyledHeading = styled.h2<HeadingProps>`
  font-size: ${(props) => `var(--heading-${props.dimension})`};
  font-weight: ${(props) => props.weight};
  color: ${(props) => props.color};
  text-align: ${(props) => props.textAlign};
  padding-bottom: ${({ isWithPaddingBottom }) =>
    isWithPaddingBottom ? '15px' : '0'};
`;

export const Heading = ({
  tag = 'h2',
  dimension = 'm',
  weight = 'bolder',
  textAlign = 'left',
  color = 'var(--white)',
  children,
  isWithPaddingBottom = true,
  ...props
}: HeadingProps) => (
  <StyledHeading
    as={tag}
    dimension={dimension}
    weight={weight}
    textAlign={textAlign}
    color={color}
    isWithPaddingBottom={isWithPaddingBottom}
    {...props}
  >
    {children}
  </StyledHeading>
);
