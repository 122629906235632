import { Paragraph } from 'components/Paragraph/Paragraph';
import { useTranslation } from 'react-i18next';

interface ReportDetailProps {
  detailLabel?: string;
  detailValue?: string;
}

export const ReportDetail = ({
  detailLabel,
  detailValue,
}: ReportDetailProps) => {
  const { t } = useTranslation();
  return (
    <div>
      <Paragraph
        color={'var(--sub--black)'}
        textTransform={'capitalize'}
        dimension={'xs'}
        className="paragraph"
      >
        {t(detailLabel)}
      </Paragraph>
      <Paragraph
        weight={'bolder'}
        dimension={'m'}
        color={'var(--black)'}
        className="paragraph"
      >
        {detailValue}
      </Paragraph>
    </div>
  );
};
