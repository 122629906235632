import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const ActivateParkingUuid = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();
  useEffect(() => {
    if (!uuid) return;
    sessionStorage.setItem('parkingUuid', uuid);
    navigate('/activate-parking');
  }, [uuid]);
  return <></>;
};
