import styled from 'styled-components/macro';
import arrow from 'assets/img/arrow.png';
import { ParkingZone } from 'types/ParkingZone';
import { useState } from 'react';
import { ClientAddress } from './ClientAddressList/ClientAddress/ClientAddress';
import { ClientAddressList } from './ClientAddressList/ClientAddressList';

const StyledInfoItem = styled.div`
  margin-bottom: 5px;
`;
const StyledArrowIcon = styled.img<{ isOpened: boolean }>`
  position: absolute;
  right: 0;
  transform: ${({ isOpened }) => (isOpened ? 'rotate(0deg)' : 'rotate(90deg)')};
`;

interface ClientParkingZonesProps {
  clientParkingZones: ParkingZone[];
}

export const ClientParkingZones = ({
  clientParkingZones,
}: ClientParkingZonesProps) => {
  const [isParkingsOpened, setIsParkingsOpened] = useState<boolean>(false);
  return (
    <>
      {clientParkingZones && clientParkingZones.length > 0 && (
        <StyledInfoItem>
          {clientParkingZones.length > 1 && (
            <StyledArrowIcon
              onClick={(e) => {
                e.preventDefault();
                setIsParkingsOpened((prev) => !prev);
              }}
              src={arrow}
              width={20}
              height={20}
              isOpened={isParkingsOpened}
            />
          )}
          {isParkingsOpened ? (
            <ClientAddressList
              clientParkingZones={clientParkingZones}
              handleOnListClick={() => setIsParkingsOpened(false)}
            />
          ) : (
            <ClientAddress
              parkingIndex={0}
              clientParking={clientParkingZones[0]}
              handleOnParkingClick={() => setIsParkingsOpened(true)}
            />
          )}
        </StyledInfoItem>
      )}
    </>
  );
};
