export const getPaymentStatusIndex = (status: string) => {
  switch (status) {
    case 'notPaid':
      return '1';
    case 'open':
      return '2';
    case 'paid':
      return '3';
    case 'paidOnTheSpot':
      return '4';
    case 'cancelled':
      return '5';
  }
};
