import { Heading } from 'components/Heading/Heading';
import { Button } from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const NoParkingContent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <Heading color="var(--black)" textAlign="center">
        {t('parkingNotExist')}
      </Heading>
      <Button
        onBtnClick={() => navigate('/parking-details')}
        className="addPropertyButton"
      >
        {t('addPropertyButton')}
      </Button>
    </>
  );
};
