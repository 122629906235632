import { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_DRIVER_LOCALIZATION } from 'graphql/mutations/updateDriverLocalization';
import { UPDATE_DRIVER_LOCALIZATION_TIME } from 'data/updateDriverLocalizationTime';
import { appContext } from 'views/App';

export const useUpdateDriverLocalization = () => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;

  const [interval, setInterval] = useState<number>(0);

  const [updateDriverLocalization] = useMutation(UPDATE_DRIVER_LOCALIZATION, {
    onCompleted: () => {
      setTimeout(() => {
        setInterval((prev) => prev + 1);
      }, UPDATE_DRIVER_LOCALIZATION_TIME);
    },
  });

  useEffect(() => {
    if (!userData) return;
    if (userData?.role === '1') return;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async function async(position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        updateDriverLocalization({ variables: { latitude, longitude } });
      });
    }
  }, [interval, userData]);

  return;
};
