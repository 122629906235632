import { Heading, HeadingProps } from 'components/Heading/Heading';
import { Paragraph, ParagraphProps } from 'components/Paragraph/Paragraph';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { ParkingTicketsLayout } from 'views/ParkingTickets/ParkingTicketsLayout/ParkingTicketsLayout';

const StyledHeading = ({ children, className }: HeadingProps) => (
  <Heading color="var(--black-always)" weight="bold" className={className}>
    {children}
  </Heading>
);

const StyledParagraph = ({ children, className }: ParagraphProps) => (
  <Paragraph color="var(--black-always)" className={className}>
    {children}
  </Paragraph>
);

const OverstyledHeading = styled(StyledHeading)`
  border-bottom: 1px solid var(--secondary-grey);
  line-height: 25px;
  margin-bottom: 16px;
`;

const OverstyledParagraph = styled(StyledParagraph)`
  line-height: 21px;
  padding-bottom: 16px;
  white-space: pre-wrap;
`;

export const PaymentAGB = () => {
  const { t } = useTranslation();
  return (
    <ParkingTicketsLayout>
      <OverstyledHeading>{t('termsCondition')}</OverstyledHeading>
      <OverstyledParagraph>{t('agbContent')}</OverstyledParagraph>
    </ParkingTicketsLayout>
  );
};
