import { SuccessOrderCard } from 'components/Cards/SuccessOrderCard/SuccessOrderCard';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Confirm } from 'views/Confirm/Confirm';

export const SuccessfulSignUp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Confirm
      title={t('congratulations')}
      subtitle={t('accountCreatedSuccesfully')}
      card={<SuccessOrderCard />}
      onContinueClick={() => navigate('/parking-details')}
    />
  );
};
