import styled from 'styled-components/macro';
import { MouseEventHandler, ReactElement } from 'react';
import { ArrowRightIcon } from '../Icons/ArrowRightIcon/ArrowRightIcon';
import { ComplexButtonTextContent } from './ComplexButtonTextContent/ComplexButtonTextContent';

export interface ComplexButtonProps {
  onComplexBtnClick?: MouseEventHandler;
  title?: string;
  subtitle?: string;
  icon?: ReactElement;
  image?: string;
  isWithArrowIcon?: boolean;
  mode?: 'light' | 'dark';
  imageSrc?: string;
  size?: 'small' | 'medium';
  className?: string;
  id?: string;
}

const StyledComplexButton = styled.button<ComplexButtonProps>`
  border: ${({ mode }) =>
    mode === 'dark' ? 'none' : '1px solid var(--sub--black-opacity)'};
  border-radius: 20px;
  padding: 24px 17px;
  background: ${({ mode }) =>
    mode === 'dark' ? 'var(--black)' : 'var(--grey-very-light)'};
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .arrowIcon {
    margin-left: 19px;
    min-width: 12px;
  }
`;
const IconContainer = styled.div`
  background: ${(props) => props.theme.primary};
  min-width: 48px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ComplexButton = ({
  onComplexBtnClick,
  title,
  subtitle,
  icon,
  isWithArrowIcon,
  mode = 'dark',
  imageSrc,
  size = 'medium',
  className,
  id,
}: ComplexButtonProps) => {
  return (
    <StyledComplexButton
      onClick={onComplexBtnClick}
      mode={mode}
      size={size}
      className={className}
      data-eventcategory={id}
    >
      <StyledContainer>
        {icon && <IconContainer>{icon}</IconContainer>}
        {imageSrc && (
          <img
            src={imageSrc}
            alt="Button Image"
            width={size === 'medium' ? 74 : 47}
            height={size === 'medium' ? 74 : 47}
          />
        )}
        <ComplexButtonTextContent
          mode={mode}
          title={title}
          subtitle={subtitle}
        />
      </StyledContainer>
      {isWithArrowIcon && (
        <ArrowRightIcon
          stroke="var(--black)"
          height="24"
          width="12"
          className={'arrowIcon'}
        />
      )}
    </StyledComplexButton>
  );
};
