import { gql } from '@apollo/client';

export const UPDATE_PARKING_ZONE_TOWING_EMPTY_RUN_CASHBACK = gql`
  mutation UpdateParkingZoneTowingEmptyRunCashback(
    $id: ID!
    $towingEmptyRunCashbackValue: Float
  ) {
    UpdateParkingZoneTowingEmptyRunCashback(
      id: $id
      towing_empty_run_cashback_value: $towingEmptyRunCashbackValue
    ) {
      id
      towingEmptyRunCashbackValue: towing_empty_run_cashback_value
    }
  }
`;
