import { Card } from 'components/Cards/Card/Card';
import { UserInfo } from 'components/UserInfo/UserInfo';
import styled from 'styled-components/macro';
import bagOfMoneyImg from 'assets/img/bag-of-money.png';

interface StyledSuccessRewardCardProps {
  background?: string;
}

const StyledSuccessRewardCard = styled.div<StyledSuccessRewardCardProps>`
  padding: 18px 24px;
  &:after {
    content: '';
    position: absolute;
    background: ${(props) => `url(${props.background}) no-repeat top center`};
    background-size: contain;
    top: -43px;
    bottom: 0;
    right: 0px;
    width: 186px;
    height: 229px;
  }
`;

export const SuccessRewardCard = () => {
  return (
    <Card>
      <StyledSuccessRewardCard background={bagOfMoneyImg}>
        <UserInfo layout={'horizontal-column'} color="var(--black)" />
      </StyledSuccessRewardCard>
    </Card>
  );
};
