export const dataProtection = {
  dataProtectionContent: `I. Introduzione
Quando utilizzi l'app Falschparker, ci affidi i tuoi dati personali. Ci impegniamo a mantenere questa fiducia. Pertanto, vogliamo prima aiutarti a comprendere le nostre pratiche di protezione dei dati.

In questa informativa sulla privacy, descriviamo quali dati personali ("dati") raccogliamo, come vengono utilizzati e condivisi, e quali opzioni hai riguardo a tali dati. Ti consigliamo di leggere questo testo insieme alla nostra panoramica sulla privacy, che spiega i punti chiave delle nostre pratiche di protezione dei dati.

II. Panoramica
A. Ambito
Questa informativa sulla privacy si applica agli utenti dei servizi di TowShare Group GmbH in Svizzera, inclusi gli utenti dell'app Falschparker, dei siti web, delle funzionalità o di altri servizi.

B. Responsabile dei dati e trasferimento dei dati
TowShare Group GmbH (Schützengasse 4, 8001 Zurigo, Svizzera) è il responsabile dei dati raccolti in relazione all'uso dei servizi di TowShare Group GmbH in Svizzera.

TowShare Group GmbH opera e tratta i dati personali in Svizzera. Indipendentemente da dove ti trovi, i tuoi dati ti accompagnano sempre, permettendoci di offrirti un'esperienza fluida. Rispettiamo anche i tuoi diritti di protezione dei dati, sia che ti trovi in Svizzera o altrove.

Per garantire un livello equivalente di protezione dei dati ovunque utilizzi i nostri servizi, TowShare Group GmbH in Svizzera ha implementato le seguenti misure:

1. Politiche e procedure per limitare l'accesso e il trattamento dei dati personali a scopi definiti;
2. formazione specifica del personale responsabile della gestione dei dati personali;
3. protezione affidabile dei dati durante il loro trasferimento tra l'app Falschparker e i nostri server e durante la loro elaborazione sui nostri server. Questo include la crittografia e l'uso di dati pseudonimizzati per proteggere l'identità completa degli utenti da divulgazioni non autorizzate.

Per semplificare l'esercizio dei tuoi diritti in materia di protezione dei dati, puoi indirizzare le tue richieste attraverso uno dei canali sopra menzionati a TowShare Group GmbH, che le elaborerà centralmente per conto del responsabile competente. Domande, commenti e reclami riguardanti l'uso dei dati da parte di TowShare Group GmbH possono essere inviati via e-mail a info@towshare.ch e info@falsch-parker.ch. Puoi anche utilizzare questa e-mail per contattare il responsabile della protezione dei dati di TowShare Group GmbH.

Inoltre, i dati di localizzazione precisi raccolti durante la creazione di compensazioni per costi di gestione e ordini di rimozione tramite il dispositivo dell'utente sono associati all'account del conducente del carro attrezzi, anche se l'utente non ha attivato la raccolta di dati di localizzazione precisi sul proprio dispositivo. Questi dati sono utilizzati per finalità come la creazione di ricevute, il supporto clienti, il rilevamento di frodi, questioni assicurative e controversie legali.

3. Dati da altre fonti. Questo include:

Utenti che partecipano ai nostri programmi di raccomandazione. Ad esempio, se un utente raccomanda un'altra persona, riceviamo i dati della persona raccomandata da questo utente.
Titolari di account Falschparker che richiedono servizi per conto di altri utenti (come inquilini, dipendenti, amici o familiari) o che consentono ad altri utenti di richiedere o ricevere servizi tramite i loro account aziendali.
Fornitori che ci aiutano a verificare l'identità degli utenti, le loro informazioni di background e la loro idoneità lavorativa, o che verificano gli utenti in relazione a sanzioni, lotta al riciclaggio di denaro o requisiti di conoscenza del cliente.

Nome dell'azienda: TowShare Group GmbH
Indirizzo: Schützengasse 4
CAP: 8001
Città: Zurigo
Paese: Svizzera

B. Come utilizziamo i dati personali
Falschparker utilizza i dati per fornire servizi di rimozione affidabili e professionali, nonché altri prodotti e servizi. Utilizziamo questi dati anche per:
promuovere la sicurezza dei nostri utenti e servizi
fornire supporto clienti  
per la ricerca e lo sviluppo  
per facilitare la comunicazione tra gli utenti 
per il marketing e la pubblicità
inviare comunicazioni non di marketing agli utenti
in relazione a procedimenti legali

Utilizziamo i dati raccolti come segue:
1. Per fornire i nostri servizi. L'app Falschparker utilizza i dati per fornire, personalizzare, mantenere e migliorare i propri servizi.

Questo include l'uso dei dati per le seguenti finalità:
Creazione/aggiornamento degli account
Abilitare le compensazioni per costi di gestione e i servizi di rimozione, come:
l'uso dei dati di localizzazione per navigare fino ai trasgressori, calcolare il tempo di arrivo stimato e monitorare il progresso degli ordini di rimozione.
Funzioni che includono la condivisione di dati, come la condivisione del nome e del numero di telefono del conducente del carro attrezzi per facilitare l'ordine di rimozione, il profilo dell'utente e i dettagli di rimozione per condividere l'orario di arrivo stimato e le recensioni degli utenti.
La ricerca di conducenti di carro attrezzi adatti per gli utenti che ordinano servizi di rimozione. I conducenti possono essere assegnati in base alla loro disponibilità, posizione e altri fattori come la probabilità di accettazione di un incarico, il loro comportamento o preferenze passate.
Fornire aggiornamenti dell'app Falschparker
Condurre le operazioni necessarie per mantenere i nostri servizi, inclusa la risoluzione di bug e problemi operativi.

Falschparker svolge le attività sopra indicate perché necessarie per soddisfare i termini dei nostri accordi con gli utenti, in accordo con essi, o per proteggere gli interessi legittimi di Falschparker e dei suoi utenti.
2. Sicurezza e protezione. Utilizziamo i dati per garantire la sicurezza e l'integrità dei nostri servizi e utenti. Ciò include:
La verifica dell'identità degli utenti durante la creazione dell'account, ad esempio tramite un contratto di cessione o di locazione, per garantire che i nostri servizi non vengano utilizzati da utenti che si spacciano per altri, rappresentando un rischio per la sicurezza.

Possiamo anche utilizzare la tecnologia di riconoscimento facciale per facilitare il processo di accesso e impedire l'uso non autorizzato dei nostri servizi.

3. Supporto clienti. Falschparker utilizza le informazioni raccolte (incluse le registrazioni delle chiamate) per fornire supporto clienti, indagare e gestire le preoccupazioni degli utenti, nonché monitorare e migliorare le nostre risposte e processi di supporto clienti.

Falschparker svolge le attività sopra indicate perché necessarie per soddisfare i termini dei nostri accordi con gli utenti o nell'interesse legittimo di Falschparker di monitorare e migliorare i propri servizi di assistenza clienti.
4. Ricerca e sviluppo. Utilizziamo dati personali per testare, ricercare, analizzare e sviluppare prodotti e il machine learning per migliorare l'esperienza utente. Questo ci aiuta a rendere i nostri servizi più piacevoli e intuitivi, a migliorare la sicurezza dei nostri servizi e a sviluppare nuovi servizi e funzionalità.

Falschparker svolge le attività sopra indicate per proteggere gli interessi legittimi di TowShare Group GmbH in merito al miglioramento e allo sviluppo di nuovi servizi e funzionalità.
5. Facilitare la comunicazione tra utenti e conducenti di carro attrezzi. Un conducente di carro attrezzi può, ad esempio, inviare un messaggio di testo a un utente o chiamarlo per confermare il luogo di rimozione di un veicolo.

TowShare Group GmbH svolge le attività sopra indicate perché necessarie per soddisfare i termini dei nostri accordi con gli utenti.
6. Marketing e pubblicità. TowShare Group GmbH utilizza i dati per promuovere i propri servizi e quelli dei partner di Falschparker. Questo può includere:
L'invio di e-mail, SMS, notifiche push e messaggi in-app o altre comunicazioni di marketing o promozionali per prodotti, servizi, funzionalità, offerte, promozioni, concorsi, notizie ed eventi di TowShare Group GmbH.

Questo può includere l'uso di dati di localizzazione, cronologia degli ordini e dati di utilizzo per inviare comunicazioni di marketing personalizzate basate sugli interessi e caratteristiche osservati o dedotti degli utenti.

C. Cookie e tecnologie di terze parti
Falschparker e i suoi partner utilizzano cookie e altre tecnologie di identificazione nelle nostre app, siti web, e

-mail e pubblicità online.
I cookie sono piccoli file di testo memorizzati sui browser o dispositivi dai siti web, app, media online e pubblicità. Falschparker utilizza cookie e tecnologie simili per:
Autenticare gli utenti
Memorizzare le preferenze e le impostazioni degli utenti
Determinare la popolarità dei contenuti
Valutare l'efficacia delle campagne pubblicitarie
Analizzare le visualizzazioni delle pagine e le tendenze e sviluppare una comprensione generale del comportamento online e degli interessi delle persone che interagiscono con i nostri servizi
Consentiamo anche ad altri di fornirci servizi di misurazione e analisi, di mostrare pubblicità su internet per nostro conto e di monitorare e valutare le prestazioni di tali pubblicità. Queste entità possono utilizzare cookie, web beacon, SDK e altre tecnologie per identificare il dispositivo dei visitatori sul nostro sito e su altri siti e servizi.
Per ulteriori informazioni sull'uso dei cookie e delle altre tecnologie descritte in questa sezione, consulta la nostra Politica sui cookie.

D. Condivisione e divulgazione dei dati
Alcuni dei servizi e funzionalità di Falschparker richiedono che condividiamo dati con altri utenti o che lo facciamo su richiesta di un utente. Possiamo anche condividere questi dati con le nostre affiliate, controllate e partner quando ciò è richiesto per motivi legali o in relazione a reclami o controversie.
Falschparker può condividere i dati come segue:
1. Con altri utenti

Questo include la condivisione delle seguenti informazioni:
Nome, cognome, indirizzo, codice postale, valutazioni, nonché il luogo di rimozione del veicolo segnalato ai conducenti di carro attrezzi.

Forniamo inoltre a clienti e conducenti di carro attrezzi ricevute che includono informazioni come coordinate GPS, immagini e video, nome e numero di telefono del conducente del carro attrezzi. Ulteriori informazioni possono essere aggiunte quando richiesto dalla legge.

2. Su richiesta dell'utente

Questo include la condivisione di dati con:

Emergenze: Offriamo funzionalità che consentono agli utenti di condividere i propri dati in caso di emergenza o dopo determinati incidenti con la polizia, i vigili del fuoco e i servizi di emergenza. Consulta "Opzioni e trasparenza" e "Condivisione dei dati in caso di emergenza".
3. Al pubblico

Domande o commenti degli utenti inviati tramite forum pubblici come i blog o le pagine social di Falschparker possono essere visibili al pubblico. Questo include anche i dati contenuti nelle domande o nei commenti inviati.
4. Al titolare dell'account Falschparker

Quando un utente riceve servizi tramite un account appartenente a un'altra parte, possiamo condividere le sue informazioni di ordine, inclusi i dati di localizzazione, con il proprietario di quell'account. Questo avviene, ad esempio, nei seguenti casi:
Un utente utilizza il profilo aziendale Falschparker App for Business del proprio datore di lavoro.

E. Conservazione e cancellazione dei dati
Falschparker conserva i dati degli utenti per tutto il tempo necessario ai fini sopra descritti. La durata della conservazione dei dati dipende dalla natura dei dati, dalla categoria dell'utente a cui si riferiscono e dagli scopi per cui abbiamo raccolto i dati.
La durata della conservazione dei dati degli utenti può inoltre essere determinata da requisiti legali e normativi, scopi di sicurezza e prevenzione delle frodi o da problemi legati all'account utente, come saldi in sospeso o reclami o controversie non risolti.

Gli utenti possono richiedere la cancellazione dei loro account in qualsiasi momento. A causa di requisiti legali o normativi o per i motivi descritti in questa politica, Falschparker può conservare i dati degli utenti anche dopo una richiesta di cancellazione.
V. Aggiornamenti della politica sulla privacy
Possiamo aggiornare questa politica sulla privacy di tanto in tanto. Se apportiamo modifiche significative, informeremo gli utenti in anticipo tramite l'app Falschparker o tramite altri mezzi, come l'e-mail. Consigliamo agli utenti di consultare regolarmente questa politica sulla privacy per rimanere informati sulle ultime informazioni sulle nostre pratiche di protezione dei dati.
L'uso dei nostri servizi dopo un aggiornamento costituisce consenso alla politica sulla privacy aggiornata nella misura consentita dalla legge.`,
};
