import { ReportProps } from 'types/Report';
import { ReportDetail } from '../ReportDetail/ReportDetail';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface AdditionalInformationProps {
  report?: ReportProps;
}

export const AdditionalInformation = ({
  report,
}: AdditionalInformationProps) => {
  const { t } = useTranslation();
  const { type } = useParams();
  const isGasStationReport = type === 'station';
  if (!report) return;
  return (
    <>
      {report?.qrCodeId && (
        <ReportDetail title="qrCodeId" value={report?.qrCodeId} />
      )}
      {report?.notes && <ReportDetail title="notes" value={report?.notes} />}
      {isGasStationReport && (
        <ReportDetail
          title="notes"
          value={t(
            report?.inStore ? 'accidentalEvasion' : 'intensionalEvasion',
          )}
        />
      )}
    </>
  );
};
