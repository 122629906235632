import { SuccessView } from 'views/ParkingTickets/SuccessView/SuccessView';
import { useTranslation } from 'react-i18next';

export const SuccessObjectionAdding = () => {
  const { t } = useTranslation();
  return (
    <SuccessView
      title={t('congratulations')}
      subtitle={t('successObjectionAddingSubtitle')}
    />
  );
};
