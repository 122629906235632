import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

interface OptionProps {
  isActive: boolean;
}

const Option = styled.li<OptionProps>`
  border-bottom: 1px solid var(--grey-opacity);
  padding: 8px 0;
  font-size: var(--text-m);
  font-weight: 500;
  color: ${({ isActive, theme }) =>
    isActive ? theme.primary : 'var(--white-text)'};
  &:last-child {
    border: none;
  }
  &:hover {
    color: ${(props) => props.theme.primary};
    cursor: pointer;
  }
`;

interface DropdownOptionProps {
  isOptionSelected?: boolean;
  option?: string;
  handleOnOptionClick?: () => void;
}

export const DropdownOption = ({
  isOptionSelected,
  option,
  handleOnOptionClick,
}: DropdownOptionProps) => {
  const { t } = useTranslation();
  return (
    <Option isActive={isOptionSelected} onClick={handleOnOptionClick}>
      {t(option)}
    </Option>
  );
};
