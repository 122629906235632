import { Dropdown } from 'components/Dropdown/Dropdown';
import { Heading } from 'components/Heading/Heading';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { sortByOptions } from 'data/sortOptions';

const StyledHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 16px;
`;

interface NotificationsHeadingProps {
  handleOrderByChange: (orderBy: string) => void;
}

export const NotificationsHeading = ({
  handleOrderByChange,
}: NotificationsHeadingProps) => {
  const { t } = useTranslation();
  return (
    <StyledHeadingContainer>
      <Heading color="var(--black)" weight="bold" isWithPaddingBottom={false}>
        {t('notifications')}
      </Heading>
      <Dropdown
        mode="primary"
        label={t('sortBy')}
        options={sortByOptions}
        size="xs"
        handleSelectedOption={(option) =>
          handleOrderByChange(option === 'recent' ? 'DESC' : 'ASC')
        }
      />
    </StyledHeadingContainer>
  );
};
