import { Paragraph } from 'components/Paragraph/Paragraph';
import { AccordionElement } from '../AccordionElement/AccordionElement';
import { AccordionElementButton } from '../AccordionElementButton/AccordionElementButton';
import styled from 'styled-components';

interface AccordionElementListProps
  extends Pick<
    React.ComponentProps<typeof Paragraph>,
    'weight' | 'textTransform'
  > {
  isAccordionOpened?: boolean;
  accordionElements?: string[];
  selectedAccordionElements?: string[];
  onTitleClick?: (element: string) => void;
  isAllElementClick?: boolean;
  onElementClick?: (element: string) => void;
  accordionElementTitle?: string;
  isWithSelectIcon?: boolean;
  addButtonTitle?: string;
  onAddButtonClick?: () => void;
}

const StyledAccordionElement = styled(AccordionElement)`
  padding: 10px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  word-wrap: break-word; /* Ensures long words break to the next line */
  white-space: normal; /* Ensures text wraps within the element */
`;

export const AccordionElementList = ({
  isAccordionOpened,
  accordionElements,
  selectedAccordionElements,
  onTitleClick,
  isAllElementClick,
  onElementClick,
  accordionElementTitle,
  isWithSelectIcon,
  addButtonTitle,
  onAddButtonClick,
  weight,
  textTransform,
}: AccordionElementListProps) => {
  if (!isAccordionOpened) return;
  return (
    <ul>
      {accordionElements &&
        accordionElements.map((element, i) => {
          const isSelected = Boolean(
            selectedAccordionElements?.find((item) => item === element),
          );
          return (
            <StyledAccordionElement
              key={i}
              isElementSelected={isSelected}
              element={element}
              weight={weight}
              textTransform={textTransform}
              onTitleClick={onTitleClick}
              isAllElementClick={isAllElementClick}
              onElementClick={onElementClick}
              accordionElementTitle={accordionElementTitle}
              isWithSelectIcon={isWithSelectIcon}
            />
          );
        })}
      <AccordionElementButton
        addButtonTitle={addButtonTitle}
        onAddButtonClick={onAddButtonClick}
      />
    </ul>
  );
};
