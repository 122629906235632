import { GasStationIcon } from 'components/Icons/GasStationIcon/GasStationIcon';
import { MandateIcon } from 'components/Icons/MandateIcon/MandateIcon';
import { TowingIcon } from 'components/Icons/TowingIcon/TowingIcon';
import styled from 'styled-components';

const StyledType = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 5px;
  left: 5px;
  padding: 5px;
  background-color: ${(props) => props.theme.primary};
  border-radius: 50%;
  z-index: 1;
`;

interface ReportTypeProps {
  reportType?: number;
}

export const ReportType = ({ reportType }: ReportTypeProps) => {
  return (
    <StyledType>
      {reportType === 1 && <TowingIcon />}
      {reportType === 2 && <MandateIcon />}
      {!reportType && <GasStationIcon />}
    </StyledType>
  );
};
