import { ArrowDownIcon } from 'components/Icons/ArrowDownIcon/ArrowDownIcon';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

interface IconContainerProps {
  isAccordionOpened?: boolean;
}

const StyledAccordion = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const IconContainer = styled.div<IconContainerProps>`
  padding-left: 10px;
  ${({ isAccordionOpened }) =>
    isAccordionOpened
      ? css`
          svg {
            transform: rotate(180deg);
            transition: transform 0.3s;
          }
        `
      : css`
          svg {
            transform: rotate(0deg);
            transition: transform 0.3s;
          }
        `}
`;

interface AccordionMainComponentProps {
  accordionClassName?: string;
  icon?: ReactNode;
  title?: string;
  isOpened?: boolean;
  handleIsOpenedChange: (isOpened: boolean) => void;
}

export const AccordionMainComponent = ({
  accordionClassName,
  icon,
  title,
  isOpened,
  handleIsOpenedChange,
}: AccordionMainComponentProps) => {
  return (
    <StyledAccordion
      onClick={() => handleIsOpenedChange(!isOpened)}
      className={accordionClassName}
    >
      {icon}
      <Paragraph color="var(--black)" dimension="l" weight="bolder">
        {title}
      </Paragraph>
      <IconContainer isAccordionOpened={isOpened}>
        <ArrowDownIcon stroke="var(--black)" />
      </IconContainer>
    </StyledAccordion>
  );
};
