import { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';
import { CloseIcon } from '../Icons/CloseIcon/CloseIcon';
interface BackButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
  onClick: () => void;
}
const Button = styled.button`
  display: flex;
  background: var(--white);
  border: none;
  align-items: center;
  cursor: pointer;
  padding: 9px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2));
`;

export const CloseButton = ({
  disabled,
  onClick,
  ...props
}: BackButtonProps) => {
  const handleClose = () => {
    onClick();
  };
  return (
    <Button onClick={handleClose} disabled={disabled} {...props}>
      <CloseIcon fill="var(--black-green)" />
    </Button>
  );
};
