import { gql } from '@apollo/client';

export const DRIVER_PICKED_CAR = gql`
  mutation driverPickedCar($hash: String!) {
    driverPickedCar(hash: $hash) {
      status
      hash
    }
  }
`;
