import { Paragraph } from 'components/Paragraph/Paragraph';
import { Status } from 'components/Status/Status';
import { useTranslation } from 'react-i18next';
import { getCashbackStatusColor } from 'utils/getCashbackStatusColor';

interface ReportCashbackStatusProps {
  cashbackStatus?: string;
  cashbackAmount?: number;
}

export const ReportCashbackStatus = ({
  cashbackStatus,
  cashbackAmount,
}: ReportCashbackStatusProps) => {
  const { t } = useTranslation();
  return (
    <div>
      <Paragraph
        color={'var(--sub--black)'}
        textTransform={'capitalize'}
        dimension={'xs'}
        className="paragraph"
      >
        {t('cashbackStatus')}
      </Paragraph>
      <Status
        customText={
          cashbackStatus === 'new' ? `${cashbackAmount} CHF` : t(cashbackStatus)
        }
        mode={'report'}
        size="s"
        color={getCashbackStatusColor(cashbackStatus)}
      />
    </div>
  );
};
