import styled from 'styled-components/macro';
import { Paragraph } from '../Paragraph/Paragraph';

interface StatusProps {
  status?: string;
  size?: 'xxs' | 'xs' | 's' | 'm';
  color?: string;
  mode?: 'card' | 'report';
  className?: string;
  customText?: string;
}

const StyledStatus = styled.div<StatusProps>`
  padding: ${({ mode }) => (mode === 'card' ? '6px 10px' : '8px')};
  border-radius: ${({ mode }) => (mode === 'card' ? '12px' : '18px')};
  background-color: ${({ color }) => `var(--${color}-opacity)`};
  text-align: center;
  max-width: ${({ mode }) => (mode === 'card' ? '150px' : 'auto')};
  height: max-content;
  min-width: ${({ mode }) => (mode === 'card' ? 'auto' : '91px')};
  max-width: ${({ mode }) => (mode === 'card' ? 'auto' : '91px')};
`;

export const Status = ({
  status,
  size = 'm',
  color = 'green',
  mode = 'card',
  className,
  customText,
}: StatusProps) => (
  <StyledStatus color={color} mode={mode} className={className}>
    <Paragraph
      weight="bolder"
      color={`var(--${color})`}
      dimension={size}
      textAlign="center"
    >
      {customText ? customText : status}
    </Paragraph>
  </StyledStatus>
);
