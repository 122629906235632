import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Paragraph } from 'components/Paragraph/Paragraph';

const StyledInfoItem = styled.div`
  margin-bottom: 5px;
`;

interface ClientMainInformationProps {
  clientId: string;
  clientFirstName: string;
  clientLastName: string;
  clientCompanyName?: string;
}

export const ClientMainInformation = ({
  clientId,
  clientFirstName,
  clientLastName,
  clientCompanyName,
}: ClientMainInformationProps) => {
  const { t } = useTranslation();
  return (
    <>
      <StyledInfoItem>
        <Paragraph dimension={'s'} color={'var(--sub--black)'}>
          {t('clientNumber')}
        </Paragraph>
        <Paragraph color="var(--black)" weight="bold">
          {clientId}
        </Paragraph>
      </StyledInfoItem>
      <StyledInfoItem>
        <Paragraph dimension={'s'} color={'var(--sub--black)'}>
          {t('clientFullName')}
        </Paragraph>
        <Paragraph color="var(--black)" weight="bold">
          {clientFirstName} {clientLastName}
        </Paragraph>
      </StyledInfoItem>
      {clientCompanyName && (
        <StyledInfoItem>
          <Paragraph dimension={'s'} color={'var(--sub--black)'}>
            {t('companyName')}
          </Paragraph>
          <Paragraph color="var(--black)" weight="bold">
            {clientCompanyName}
          </Paragraph>
        </StyledInfoItem>
      )}
    </>
  );
};
