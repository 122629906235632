import { useLazyQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { ThemeProvider } from 'styled-components';
import { LoadingAnimation } from 'components/LoadingAnimation/LoadingAnimation';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { Popup } from 'components/Popup/Popup';
import { GET_APP_SETTINGS_BY_PARKING_UUID } from 'graphql/queries/getAppSettingsByParkingUUID';
import { AppSettings } from 'types/general';
import { appContext, Theme } from '../App';
import { ErrorPopup } from 'components/ErrorPopup/ErrorPopup';
import { ActivateParkingForm } from './ActivateParkingForm/ActivateParkingForm';

const StyledContainer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
  & input {
    color: var(--black-text) !important;
    text-align: center;
  }

  .activateParkingPopup {
    & button {
      border-color: ${(props) => props.theme.primary};
      color: ${(props) => props.theme.primary};
    }
    & path {
      fill: ${(props) => props.theme.primary};
    }
  }
`;

export const ActivateParking = () => {
  const appContextStore = useContext(appContext);
  const isError = appContextStore?.isError;
  const isLoading = appContextStore?.isLoading;
  const setIsLoading = appContextStore?.setIsLoading;

  const { t } = useTranslation();
  const [parkingUuid, setParkingUuid] = useState<string | undefined>(undefined);
  const [isPopupOpened, setIsPopupOpened] = useState<boolean>(false);
  const [appSettings, setAppSettings] = useState<AppSettings | undefined>(
    undefined,
  );
  const [theme, setTheme] = useState<Theme>({
    primary: ' rgba(1, 213, 75, 1)',
    secondary: 'rgba(1, 213, 75, 1)',
  });

  const [
    getAppSettingsByParkingUUID,
    { loading: getAppSettingsByParkingUUIDLoading },
  ] = useLazyQuery(GET_APP_SETTINGS_BY_PARKING_UUID, {
    onCompleted: (data) => {
      setAppSettings(data.getAppSettingsByParkingUUID);
      setTheme({
        primary:
          data?.getAppSettingsByParkingUUID?.primary_color ||
          'rgba(1, 213, 75, 1)',
        secondary:
          data?.getAppSettingsByParkingUUID?.secondary_color ||
          'rgba(1, 213, 75, 1)',
      });
    },
  });
  useEffect(() => {
    const uuid = sessionStorage.getItem('parkingUuid');
    setParkingUuid(uuid);
    if (uuid) {
      getAppSettingsByParkingUUID({ variables: { parkingZoneUuid: uuid } });
    }
  }, []);
  useEffect(() => {
    setIsLoading(getAppSettingsByParkingUUIDLoading);
  }, [getAppSettingsByParkingUUIDLoading]);

  if (!parkingUuid) return <></>;
  return (
    <>
      <ThemeProvider theme={theme}>
        <StyledContainer>
          <ActivateParkingForm
            appSettings={appSettings}
            parkingUuid={parkingUuid}
            handlePopupOpened={(isPopupOpened) =>
              setIsPopupOpened(isPopupOpened)
            }
          />
          <Popup
            className="activateParkingPopup"
            isPopupOpened={isPopupOpened}
            customerContent={
              <Paragraph
                color="var(--black)"
                dimension="l"
                weight="bold"
                textAlign="center"
              >
                {t('confirmActivatedParking')}
              </Paragraph>
            }
            type="info"
            onDecline={() => setIsPopupOpened(false)}
            confirmText="ok"
          />
        </StyledContainer>
        {isLoading && <LoadingAnimation />}
        {isError && <ErrorPopup />}
      </ThemeProvider>
    </>
  );
};
