import { gql } from '@apollo/client';

export const UPDATE_PARKING_ZONE = gql`
  mutation UpdateParkingZone(
    $id: ID!
    $name: String
    $streetName: String
    $streetNumber: String
    $city: String
    $zipCode: String
    $fullAddress: String
    $latitude: Float
    $longitude: Float
    $notes: String
  ) {
    updateParkingZone(
      id: $id
      input: {
        name: $name
        street_name: $streetName
        street_number: $streetNumber
        zip_code: $zipCode
        city: $city
        address_full: $fullAddress
        latitude: $latitude
        longitude: $longitude
        notes: $notes
      }
    ) {
      id
    }
  }
`;
