import { gql } from '@apollo/client';

export const LICENSE = gql`
  query License($id: ID!) {
    license(id: $id) {
      id
      beneficiary
      carName: car_name
      plate
    }
  }
`;
