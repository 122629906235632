import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Paragraph } from '../Paragraph/Paragraph';

type StyledAnchorProps = {
  color?: 'green' | 'white' | 'white-text' | 'black' | 'white-always';
  linkWeight?: 'bold' | 'regular';
  linkweight?: string;
  size?: 'xs' | 's';
  textAlign?: 'flex-end' | 'center' | 'flex-start';
  textColor?: 'white-text';
  anchorClassName?: string;
};

interface AnchorProps extends StyledAnchorProps {
  link: string;
  linkTitle: string;
  label?: string;
  icon?: ReactElement;
  anchorClassName?: string;
}

const StyledAnchor = styled.div<StyledAnchorProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ textAlign }) => textAlign};
`;

const StyledLink = styled(Link)<StyledAnchorProps>`
  color: ${({ color }) => `var(--${color})`};
  text-decoration: none;
  font-size: ${({ size }) => `var(--text-${size})`};
  font-weight: ${({ linkweight }) => linkweight};
  line-height: 16px;
  margin-left: 5px;
`;

export const Anchor = ({
  link,
  linkTitle,
  label,
  icon,
  color = 'green',
  linkWeight = 'bold',
  size = 's',
  textAlign = 'flex-start',
  textColor,
  anchorClassName,
}: AnchorProps) => (
  <StyledAnchor textAlign={textAlign}>
    {label && (
      <Paragraph
        color={`var(--${textColor})`}
        dimension={size}
        className="text"
      >
        {label}
      </Paragraph>
    )}
    {icon}
    <StyledLink
      to={link}
      color={color}
      linkweight={linkWeight}
      size={size}
      className={anchorClassName}
    >
      {linkTitle}
    </StyledLink>
  </StyledAnchor>
);
