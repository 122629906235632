import { IconProps } from '../types';
export const CopyIcon = ({
  size = '20',
  stroke = 'var(--black)',
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 10.75V14.25C13.3333 17.1667 12.1666 18.3333 9.24996 18.3333H5.74996C2.83329 18.3333 1.66663 17.1667 1.66663 14.25V10.75C1.66663 7.83332 2.83329 6.66666 5.74996 6.66666H9.24996C12.1666 6.66666 13.3333 7.83332 13.3333 10.75Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3333 5.74999V9.24999C18.3333 12.1667 17.1666 13.3333 14.25 13.3333H13.3333V10.75C13.3333 7.83332 12.1666 6.66666 9.24996 6.66666H6.66663V5.74999C6.66663 2.83332 7.83329 1.66666 10.75 1.66666H14.25C17.1666 1.66666 18.3333 2.83332 18.3333 5.74999Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
