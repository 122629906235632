import { Button } from 'components/Button/Button';
import { useTranslation } from 'react-i18next';

interface ConfirmContinueButtonProps {
  onContinueClick?: () => void;
  confirmButtonId?: string;
  confirmText?: string;
}
export const ConfirmContinueButton = ({
  onContinueClick,
  confirmButtonId,
  confirmText,
}: ConfirmContinueButtonProps) => {
  const { t } = useTranslation();
  if (!onContinueClick) return;
  return (
    <Button onBtnClick={onContinueClick} id={confirmButtonId}>
      {confirmText ? confirmText : t('finish')}
    </Button>
  );
};
