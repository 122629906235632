import { gql } from '@apollo/client';

export const PARKING_ZONES_GEO = gql`
  query ParkingZonesGeo($latitude: Float!, $longitude: Float!) {
    parkingZonesGeo(latitude: $latitude, longitude: $longitude) {
      id
      type
      name
      fullAddress: address_full
      streetName: street_name
      streetNumber: street_number
      zipCode: zip_code
      city
      latitude
      longitude
      user {
        id
        firstName: first_name
        lastName: last_name
        companyName: company_name
      }
    }
  }
`;
