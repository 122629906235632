import { IconButton, IconButtonProps } from 'components/IconButton/IconButton';
import { Button, ButtonProps } from 'components/Button/Button';
import { ArrowRightIcon } from 'components/Icons/ArrowRightIcon/ArrowRightIcon';
import { UserInfo } from 'components/UserInfo/UserInfo';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useUserNewNotifications } from 'hooks/useUserNewNotifications';
import { useTranslation } from 'react-i18next';
import { NotificationsIcon } from 'components/Icons/NotificationsIcon/NotificationsIcon';
import { useContext } from 'react';
import { appContext } from 'views/App';

const StyledButton = ({ className, children, onBtnClick }: ButtonProps) => (
  <Button className={className} mode="inverted" onBtnClick={onBtnClick}>
    {children}
  </Button>
);
const StyledIconButton = ({
  className,
  children,
  onClick,
}: IconButtonProps) => (
  <IconButton className={className} onClick={onClick}>
    {children}
  </IconButton>
);

const OverstyledButton = styled(StyledButton)<{ color: string }>`
  width: max-content;
  padding: 5px 15px;
  height: auto;
  margin: 16px auto 0;
  min-height: initial;
  border: ${({ color }) => `1px solid ${color}`};
  color: ${({ color }) => color};
  .arrowRightIcon {
    margin-left: 16px;
  }
`;
const StyledUserInfo = styled.div`
  margin: auto;
`;
const OverstyledIconButton = styled(StyledIconButton)`
  position: absolute;
  right: 30px;
`;

export const ProfileUserBar = () => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isNewNotifications } = useUserNewNotifications();
  return (
    <div>
      <OverstyledIconButton onClick={() => navigate('/notifications')}>
        <NotificationsIcon
          isNewNotifications={isNewNotifications}
          stroke={
            userData?.appSettings?.primary_color ? '#fff' : 'var(--green)'
          }
        />
      </OverstyledIconButton>
      <StyledUserInfo>
        <UserInfo layout="vertical" />
        <OverstyledButton
          onBtnClick={() => navigate('/edit-profile')}
          color={userData?.appSettings?.primary_color ? '#fff' : 'var(--green)'}
        >
          {t('editProfile')}
          <ArrowRightIcon
            stroke={
              userData?.appSettings?.primary_color ? '#fff' : 'var(--green)'
            }
            height="14"
            width="7"
            className="arrowRightIcon"
          />
        </OverstyledButton>
      </StyledUserInfo>
    </div>
  );
};
