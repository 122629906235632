import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { useEffect, useState } from 'react';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { CompanyContact } from 'components/CompanyContact/CompanyContact';

const StyledBottomContainer = styled.div`
  height: 26px;
`;
export const AdditionalInformation = () => {
  const { t } = useTranslation();
  const [swVersion, setSwVersion] = useState<string | null>(null);
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      const swURL = navigator?.serviceWorker?.controller?.scriptURL;
      const version = swURL?.split('?v=');
      setSwVersion(version && version?.length > 1 ? version[1] : null);
    }
  }, []);
  return (
    <div>
      <Paragraph color="var(--black)" dimension="xs">
        {t('appVersion')}: {swVersion}
      </Paragraph>
      <CompanyContact />
      <StyledBottomContainer />
    </div>
  );
};
