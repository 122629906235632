import { Button } from 'components/Button/Button';
import styled from 'styled-components/macro';

const StyledButtonContainer = styled.div`
  margin-top: 16px;
  display: flex;
  .whatsAppButton {
    margin-right: 16px;
  }
`;

const StyledPhoneLink = styled.a`
  text-decoration: none;
  color: var(--black);
`;

export const CompanyContact = () => (
  <StyledButtonContainer>
    <Button className="whatsAppButton">
      <StyledPhoneLink href={`whatsapp://send?phone=+41445014270`}>
        Whats App
      </StyledPhoneLink>
    </Button>
    <Button>
      <StyledPhoneLink href={`tel:+41445014270`}>
        +41 44 501 42 70
      </StyledPhoneLink>
    </Button>
  </StyledButtonContainer>
);
