import { Paragraph } from 'components/Paragraph/Paragraph';
import { FC } from 'react';
import styled from 'styled-components';
import person from 'assets/img/person.svg';

interface NotificationProps {
  avatar?: string;
  text: string;
  date: string;
  onNotificationClick: () => void;
}

const StyledListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  .date {
    margin-top: 3px;
  }
`;
const StyledImg = styled.img`
  width: 46px;
  height: 46px;
  min-width: 46px;
  object-fit: cover;
  border-radius: 50%;
`;
const StyledImageContainer = styled.div`
  background-color: ${(props) => props.theme.primary};
  width: 48px;
  min-width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
`;

export const Notification: FC<NotificationProps> = ({
  avatar,
  text,
  date,
  onNotificationClick,
}) => {
  const avatarPhoto = !avatar || avatar === 'no photo' ? person : avatar;
  return (
    <StyledListItem onClick={onNotificationClick}>
      {avatarPhoto && (
        <StyledImageContainer>
          <StyledImg src={avatarPhoto} alt={'User' || 'Person'} />
        </StyledImageContainer>
      )}
      <div>
        <Paragraph color="var(--black)" weight="bold">
          {text}
        </Paragraph>
        <Paragraph color="var(--sub--black)" dimension="s" className="date">
          {date}
        </Paragraph>
      </div>
    </StyledListItem>
  );
};
