import { Paragraph } from 'components/Paragraph/Paragraph';
import styled from 'styled-components/macro';

const StyledParkingDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
`;

interface SelectParkingZoneItemContentProps {
  parkingName: string;
  parkingFullAddress: string;
}

export const SelectParkingZoneItemContent = ({
  parkingName,
  parkingFullAddress,
}: SelectParkingZoneItemContentProps) => {
  return (
    <StyledParkingDetails>
      <Paragraph color="var(--black)" weight="bold">
        {parkingName}
      </Paragraph>
      <Paragraph color="var(--black)" weight="bold">
        {parkingFullAddress}
      </Paragraph>
    </StyledParkingDetails>
  );
};
