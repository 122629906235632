import { Button } from 'components/Button/Button';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

const StyledButtonsContainer = styled.div`
  margin-top: 16px;
  display: flex;
  .cancelButton {
    margin-right: 16px;
  }
`;

interface ConfirmPickedCarButtonContainerProps {
  handleCancelOrder: MouseEventHandler;
  handleConfirmOrder: MouseEventHandler;
  reportStatus?: string;
}

export const ConfirmPickedCarButtonContainer = ({
  handleCancelOrder,
  handleConfirmOrder,
  reportStatus,
}: ConfirmPickedCarButtonContainerProps) => {
  const { t } = useTranslation();
  return (
    <StyledButtonsContainer>
      <Button
        onBtnClick={handleCancelOrder}
        mode="inverted"
        className="cancelButton"
      >
        {t('cancelSelectParking')}
      </Button>
      <Button
        onBtnClick={handleConfirmOrder}
        id={
          reportStatus === 'accepted'
            ? 'confirmCarPicked'
            : 'confirmCarParkArrival'
        }
      >
        {t(reportStatus === 'accepted' ? 'startTowing' : 'arrived')}
      </Button>
    </StyledButtonsContainer>
  );
};
