import { useLazyQuery } from '@apollo/client';
import { useAuthContext } from 'context/auth';
import { ME } from 'graphql/queries/me';
import { useContext, useEffect } from 'react';
import defaultAvatar from 'assets/img/person.svg';
import { getRole } from 'utils/getRole';
import { useTranslation } from 'react-i18next';
import { ErrorPopup } from 'components/ErrorPopup/ErrorPopup';
import { useNavigate } from 'react-router-dom';
import { NewNotificationPopup } from 'components/NewNotificationPopup/NewNotificationPopup';
import { useNewNotification } from 'hooks/useNewNotification';
import { DownloadAppPopup } from 'components/DownloadAppPopup/DownloadAppPopup';
import { appContext } from 'views/App';

export const AppWrapper = () => {
  const appContextStore = useContext(appContext);
  const setUserData = appContextStore?.setUserData;
  const setIsLoading = appContextStore?.setIsLoading;

  const { i18n } = useTranslation();
  const { setIsSignedIn, setAuthToken } = useAuthContext();
  const navigate = useNavigate();

  const [me, { data }] = useLazyQuery(ME, {
    fetchPolicy: 'network-only',
    onError: (error) => {
      if (error.message.includes('Unauthenticated')) {
        setIsSignedIn(false);
        setAuthToken(null);
        setIsLoading(false);
        navigate('/');
      }
      throw new Error(error.message);
    },
  });

  useEffect(() => {
    me();
  }, []);

  useEffect(() => {
    if (!data) return;
    const user = data.me;
    const role = getRole(user.rank);
    const avatar = data.me.avatar ? data.me.avatar : defaultAvatar;
    setUserData({ ...user, avatar, role });
  }, [data]);

  useEffect(() => {
    const lang = localStorage.getItem('language');
    lang && i18n.changeLanguage(lang);
  }, []);

  useNewNotification();

  return (
    <>
      <ErrorPopup />
      <NewNotificationPopup />
      <DownloadAppPopup />
    </>
  );
};
