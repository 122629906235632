import { useEffect, useState } from 'react';

export const useWindowHeight = () => {
  const [windowHeight, setWindowHeight] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);
  const resizeWindow = () => {
    setWindowHeight(window.innerHeight);
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    window.addEventListener('orientationchange', resizeWindow);
    return () => {
      window.removeEventListener('resize', resizeWindow);
      window.removeEventListener('orientationchange', resizeWindow);
    };
  }, []);

  return { windowHeight, windowWidth };
};
