export const registerSw = async () => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register(
        `sw.js?v=${process.env.REACT_APP_SW_VERSION}`,
        {
          scope: '/',
        },
      );

      if (registration.installing) {
        console.log('Service worker installing');
      }
      if (registration.waiting) {
        console.log('Service worker installed');
      }
      if (registration.active) {
        console.log('Service worker active');
      }

      navigator.serviceWorker.ready.then((reg) => {
        reg.update().then(() => {
          console.log('Service worker updated');
        });
      });
    } catch (error) {
      console.error(`Service worker Registration failed with ${error}`);
    }
  }
};
