import { gql } from '@apollo/client';

export const UPDATE_PARKING_ZONE_GAS_STATION_CASHBACK = gql`
  mutation UpdateParkingZoneGasStationCashback(
    $id: ID!
    $gasStationCashbackValue: Float
  ) {
    updateParkingZoneGasStationCashback(
      id: $id
      gas_station_cashback_value: $gasStationCashbackValue
    ) {
      id
      gasStationCashbackValue: gas_station_cashback_value
    }
  }
`;
