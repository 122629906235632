import { ArrowRightIcon } from 'components/Icons/ArrowRightIcon/ArrowRightIcon';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

const StyledContainer = styled.div`
  border: 1px solid var(--sub--black-opacity);
  border-radius: 20px;
  padding: 24px 17px;
  margin-bottom: 15px;
  position: relative;
`;
const StyledArrow = styled.div`
  position: absolute;
  top: 40px;
  right: 17px;
`;
export const HomeMenuSkeleton = () => (
  <div>
    <StyledContainer>
      <ContentLoader height={'53.4'} width="100%">
        <rect x="0" y="0" rx="24" ry="24" width="48" height="48" />
        <rect x="60" y="4" width="50%" height="18" />
        <rect x="60" y="30" width="70%" height="12" />
      </ContentLoader>
      <StyledArrow>
        <ArrowRightIcon stroke="black" height="24" width="12" />
      </StyledArrow>
    </StyledContainer>
    <StyledContainer>
      <ContentLoader height={'53.4'} width="100%">
        <rect x="0" y="0" rx="24" ry="24" width="48" height="48" />
        <rect x="60" y="4" width="50%" height="18" />
        <rect x="60" y="30" width="70%" height="12" />
      </ContentLoader>
      <StyledArrow>
        <ArrowRightIcon stroke="black" height="24" width="12" />
      </StyledArrow>
    </StyledContainer>
  </div>
);
