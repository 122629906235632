import {
  getReportUpdateDate,
  getReportUpdateTime,
} from 'utils/getReportUpdateDate';

interface DateAndTimeProps {
  date?: string;
}

export const DateAndTime = ({ date }: DateAndTimeProps) => {
  return (
    <>
      {getReportUpdateDate(date)}
      <br />
      {getReportUpdateTime(date)}
    </>
  );
};
