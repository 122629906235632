import { useEffect, useState } from 'react';
import { Popup } from '../Popup/Popup';
import { useTranslation } from 'react-i18next';
import { Paragraph } from '../Paragraph/Paragraph';
import { Button } from '../Button/Button';
import styled from 'styled-components/macro';
import logo from 'assets/img/logo.svg';
import shareIcon from 'assets/img/share-icon-green.png';

const StyledPopupContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;
const StyledSafariContent = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
`;

export const DownloadAppPopup = () => {
  const [supportsPWA, setSupportsPWA] = useState<boolean>(false);
  const [promptInstall, setPromptInstall] = useState(null);
  const [isAppPopupOpened, setIsAppPopupOpened] = useState<boolean>(false);
  const [isSafariBrowser, setIsSafariBrowser] = useState<boolean>(false);
  const { t } = useTranslation();
  const isPwa = () => {
    return ['fullscreen', 'standalone', 'minimal-ui'].some(
      (displayMode) =>
        window.matchMedia('(display-mode: ' + displayMode + ')').matches,
    );
  };
  useEffect(() => {
    if (!supportsPWA) {
      if (isSafariBrowser) {
        const isAppInstalled = localStorage.getItem('isInstalledApp');
        const isFirstSessionOpen = sessionStorage.getItem('isFirstSessionOpen');
        setIsAppPopupOpened(
          !isFirstSessionOpen && isSafariBrowser && !isAppInstalled,
        );
      } else return;
    }
    const isFirstSessionOpen = sessionStorage.getItem('isFirstSessionOpen');
    const isInstalled = isPwa();
    setIsAppPopupOpened(!isInstalled && !isFirstSessionOpen);
  }, [supportsPWA, isSafariBrowser]);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener('beforeinstallprompt', handler);

    return () => window.removeEventListener('transitionend', handler);
  }, []);

  useEffect(() => {
    const ua = window.navigator.userAgent;
    const iOS = ua.match(/iPad/i) || ua.match(/iPhone/i);
    const webkit = ua.match(/WebKit/i);
    const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
    const isInstalled = isPwa();
    setIsSafariBrowser(iOSSafari && !isInstalled);
  }, []);

  useEffect(() => {
    const isInstalled = isPwa();
    if (isInstalled) {
      const isInstalledApp = localStorage.getItem('isInstalledApp');
      if (isInstalledApp) {
        return;
      } else {
        localStorage.setItem('isInstalledApp', 'true');
      }
    }
  }, []);

  const onClick = (evt) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };

  const handlePopupClose = () => {
    setIsAppPopupOpened(false);
    sessionStorage.setItem('isFirstSessionOpen', 'true');
  };
  return (
    <Popup
      customerContent={
        <StyledPopupContent>
          <Paragraph
            color="var(--black)"
            dimension="xl"
            textAlign="center"
            weight="bold"
          >
            {t('downloadAppPopup')}
          </Paragraph>
          <img src={logo} alt="Logo" width={80} height={80} />
          {isSafariBrowser ? (
            <StyledSafariContent>
              <Paragraph color="var(--black)">{t('tap')}</Paragraph>
              <img src={shareIcon} alt="Share Icon" width={30} height={30} />
              <Paragraph color="var(--black)">{t('tapInstruction')}</Paragraph>
            </StyledSafariContent>
          ) : (
            <Button mode="inverted" onBtnClick={(e) => onClick(e)}>
              {t('confirm')}
            </Button>
          )}
        </StyledPopupContent>
      }
      isPopupOpened={isAppPopupOpened}
      onDecline={handlePopupClose}
      type="info"
      declineText={t('ok')}
      isWithOutButton
    />
  );
};
