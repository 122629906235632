/* eslint-disable @typescript-eslint/no-explicit-any */
import { Layout } from 'components/Layout/Layout';
import { Map } from 'components/Map/Map';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Popup } from 'components/Popup/Popup';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { REJECT_REPORT } from 'graphql/mutations/rejectReport';
import { DRIVER_ARRIVED } from 'graphql/mutations/driverArrived';
import { DRIVER_PICKED_CAR } from 'graphql/mutations/driverPickedCar';
import { REPORT } from 'graphql/queries/report';
import { useUploadMutation } from 'hooks/useUploadMutation';
import { useGetLocation } from 'hooks/useGetLocation';
import { CREATE_REPORT_LOG } from 'graphql/mutations/createReportLog';
import { getAddress } from 'utils/getAddress';
import { CREATE_DRIVER_PICKED_CAR_PRESIGNED_REQUEST } from 'graphql/mutations/createDriverPickedCarPresignedRequest';
import { FileData } from 'types/general';
import * as Comlink from 'comlink';
import { DRIVER_PICKED_CAR_VIDEO_UPLOAD } from 'graphql/mutations/driverPickedCarVideoUpload';
import { useUploadingFileProgress } from 'hooks/useUploadingFileProgress';
import { ZURICH_POSITION } from 'data/position';
import { ConfirmPickedCarHeading } from './ConfirmPickedCarHeading/ConfirmPickedCarHeading';
import { ConfirmPickedCarButtonContainer } from './ConfirmPickedCarButtonContainer/ConfirmPickedCarButtonContainer';
import { appContext } from 'views/App';
import * as Sentry from '@sentry/react';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const ConfirmPickedCar = () => {
  const appContextStore = useContext(appContext);
  const reportDetails = appContextStore?.reportDetails;
  const setReportDetails = appContextStore?.setReportDetails;
  const setIsError = appContextStore?.setIsError;
  const setIsLoading = appContextStore?.setIsLoading;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { hash } = useParams();
  const { mutate } = useUploadMutation();
  const { position: userPosition, getLocation } = useGetLocation();
  const [isConfirmPopupOpened, setConfirmIsPopupOpened] =
    useState<boolean>(false);
  const [isConfirmedAction, setIsConfirmedAction] = useState<boolean>(false);
  const [reportStatus, setReportStatus] = useState<string | null>(null);
  const [isVideoUploaded, setIsVideoUploaded] = useState<boolean>(true);
  const [isUploadingFilesInProgress, setIsUploadingFilesInProgress] =
    useState<boolean>(true);

  const { uploadedFilesCount } = useUploadingFileProgress();

  async function initComlinkWorker(fileData: FileData[], files: File[]) {
    const obj = Comlink.wrap(new Worker('/worker.js'));
    await (obj as any).inc(fileData, files);
  }

  const [report, { loading: reportLoading, refetch: reportRefetch }] =
    useLazyQuery(REPORT, {
      onCompleted: (data) => {
        setReportStatus(data.report?.status);
        setReportDetails({
          ...reportDetails,
          id: data.report.id,
        });
      },
      onError: (error) => {
        Sentry.captureException(error);
        setIsError(true);
        setIsLoading(false);
        throw new Error(error.message);
      },
    });

  const [rejectReport, { loading: rejectReportLoading }] = useMutation(
    REJECT_REPORT,
    {
      onCompleted: () => {
        createReportLog({
          variables: {
            id: reportDetails.id,
            information: 'Report User Cancellation',
            latitude: userPosition?.latitude || ZURICH_POSITION?.latitude,
            longitude: userPosition?.longitude || ZURICH_POSITION?.longitude,
          },
        });
      },
      onError: (error) => {
        Sentry.captureException(error);
        setIsError(true);
        setIsLoading(false);
        throw new Error(error.message);
      },
    },
  );

  const [driverArrived, { loading: driverArrivedLoading }] = useMutation(
    DRIVER_ARRIVED,
    {
      onCompleted: () => {
        setIsLoading(false);
        createReportLog({
          variables: {
            id: reportDetails.id,
            information: 'Report Driver Towing Execution',
            latitude: userPosition?.latitude || ZURICH_POSITION.latitude,
            longitude: userPosition?.longitude || ZURICH_POSITION.longitude,
          },
        });
      },
      onError: (error) => {
        setIsError(true);
        setIsLoading(false);
        throw new Error(error.message);
      },
    },
  );
  const [createDriverPickedCarPresignedRequest] = useMutation(
    CREATE_DRIVER_PICKED_CAR_PRESIGNED_REQUEST,
    {
      onCompleted: (data) => {
        const images = reportDetails?.images ? reportDetails?.images : [];
        initComlinkWorker(data.createDriverPickedCarPresignedRequest, images);
      },
      onError: (error) => {
        Sentry.captureException(error);
        setIsError(true);
        setIsLoading(false);
        throw new Error(error.message);
      },
    },
  );

  const [driverPickedCar] = useMutation(DRIVER_PICKED_CAR, {
    onCompleted: (data) => {
      setIsLoading(true);
      createReportLog({
        variables: {
          id: reportDetails.id,
          information: 'Report Driver Towing Start',
          latitude: userPosition?.latitude || 0,
          longitude: userPosition?.longitude || 0,
        },
      });
      const images = reportDetails?.images ? reportDetails?.images : [];
      const filesNames = images?.map((file) => file.name);
      if (filesNames && filesNames?.length > 0) {
        createDriverPickedCarPresignedRequest({
          variables: {
            hash: data.driverPickedCar.hash,
            files: [...filesNames],
          },
        });
      } else {
        setIsLoading(false);
      }
      if (reportDetails?.videos && reportDetails?.videos?.length > 0) {
        setIsVideoUploaded(false);
        setIsLoading(true);
        mutate(DRIVER_PICKED_CAR_VIDEO_UPLOAD, {
          variables: {
            hash: data.driverPickedCar.hash,
            video: reportDetails?.videos[0],
          },
        }).then(() => setIsVideoUploaded(true));
      }
    },
    onError: (error) => {
      Sentry.captureException(error);
      setIsError(true);
      setIsLoading(false);
      throw new Error(error.message);
    },
  });

  const [createReportLog] = useMutation(CREATE_REPORT_LOG, {
    onCompleted: (data) => {
      if (data) {
        if (data.createReportLog.information === 'Report User Cancellation') {
          navigate('/history/active');
        } else if (
          data.createReportLog.information === 'Report Driver Towing Execution'
        ) {
          navigate('/success-driver-order');
        } else {
          reportRefetch({ valiables: { hash } });
        }
      }
    },
    onError: (error) => {
      Sentry.captureException(error);
      setIsError(true);
      setIsLoading(false);
      throw new Error(error.message);
    },
  });

  const handleCancelOrder = () => {
    setIsConfirmedAction(false);
    setConfirmIsPopupOpened(true);
  };

  const handleConfirmOrder = () => {
    setIsConfirmedAction(true);
    if (reportStatus === 'accepted') {
      handleConfirmPopupConfirm('confirm');
    } else {
      setConfirmIsPopupOpened(true);
    }
  };

  const handleConfirmPopupConfirm = async (action?: string) => {
    setConfirmIsPopupOpened(false);
    if (isConfirmedAction || action === 'confirm') {
      if (reportStatus === 'accepted') {
        setIsLoading(true);
        driverPickedCar({
          variables: {
            hash,
          },
        });
      } else {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(async (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            const address = await getAddress([latitude, longitude]);
            if (address) {
              const fullAddress = `${address.street || ' '} ${
                address.streetNumber || ' '
              }, ${address.zipCode || ' '} ${address.city || ' '}`;
              driverArrived({
                variables: {
                  hash,
                  type: 1,
                  name: 'test',
                  adressFull: fullAddress || ' ',
                  streetName: address.street || ' ',
                  streetNumber: address.streetNumber || ' ',
                  zipCode: address.zipCode || ' ',
                  city: address.city || ' ',
                },
              });
            }
          });
        }
      }
    } else {
      rejectReport({
        variables: { hash },
      });
    }
  };

  useEffect(() => {
    if (!isUploadingFilesInProgress && isVideoUploaded) {
      setIsLoading(false);
    }
  }, [isUploadingFilesInProgress, isVideoUploaded]);

  useEffect(() => {
    report({ variables: { hash } });
  }, [hash]);

  useEffect(() => {
    setIsLoading(rejectReportLoading || driverArrivedLoading || reportLoading);
  }, [rejectReportLoading, driverArrivedLoading, reportLoading]);

  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    if (uploadedFilesCount < reportDetails?.images?.length) {
      setIsUploadingFilesInProgress(true);
    } else {
      setIsUploadingFilesInProgress(false);
    }
  }, [uploadedFilesCount]);
  return (
    <Layout
      isBackBtn
      mode="bottom"
      backgroundContent={
        <Map
          height={'75vh'}
          position={
            [
              userPosition?.latitude || ZURICH_POSITION.latitude,
              userPosition?.longitude || ZURICH_POSITION.longitude,
            ] || undefined
          }
        />
      }
      outsideElements={
        <div>
          <Popup
            isPopupOpened={isConfirmPopupOpened}
            onDecline={() => setConfirmIsPopupOpened(false)}
            onConfirm={handleConfirmPopupConfirm}
            content={t(
              isConfirmedAction
                ? reportStatus === 'accepted'
                  ? 'confirmCarPicked'
                  : 'confirmCarParkArrival'
                : 'cancelCarParkArrival',
            )}
            confirmText={reportStatus === 'inProgress' ? 'yes' : 'confirm'}
            declineText={reportStatus === 'inProgress' ? 'no' : 'cancel'}
            type="action"
          />
        </div>
      }
    >
      <StyledContainer>
        <ConfirmPickedCarHeading />
        <ConfirmPickedCarButtonContainer
          handleCancelOrder={handleCancelOrder}
          handleConfirmOrder={handleConfirmOrder}
          reportStatus={reportStatus}
        />
      </StyledContainer>
    </Layout>
  );
};
