import { BackButton } from 'components/BackButton/BackButton';
import { Button, ButtonProps } from 'components/Button/Button';
import { ReferCard } from 'components/Cards/ReferCard/ReferCard';
import { DisabledInput } from 'components/DisabledInput/DisabledInput';
import { Layout } from 'components/Layout/Layout';
import { TimelineList } from './TimelineList/TimelineList';
import { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import shareIcon from 'assets/img/shareIcon.png';
import QRCode from 'react-qr-code';
import { appContext } from 'views/App';

const StyledButton = ({ className, children, onBtnClick }: ButtonProps) => (
  <Button onBtnClick={onBtnClick} className={className}>
    {children}
  </Button>
);

const StyledContainer = styled.div`
  padding-top: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const OverstyledButton = styled(StyledButton)`
  margin-top: 16px;
  height: 53px;
  text-transform: none;
`;

const StyledBottomContainer = styled.div`
  min-height: 95px;
  height: 55px;
  width: 100%;
`;
const StyleImage = styled.img`
  padding-right: 10px;
`;

export const ReferFriendFlow = () => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;

  const { t } = useTranslation();
  const methods = useForm();
  const { setValue } = methods;
  const list = [t('referToPromote'), t('referToPromote'), t('referToPromote')];
  const handleClick = async () => {
    if (navigator.canShare) {
      navigator.share({
        text: `Sie wurden von einem ihrer Kontakte für falsch-parker.ch empfohlen!
Benutzen Sie den Referral Code und profitieren sie von einem schnellen Onboarding: ${userData?.refCode}
Beste Grüsse\nIhr Falschparker Team`,
      });
    }
  };

  useEffect(() => {
    if (!userData) return;
    setValue('referCode', userData?.refCode);
  }, [userData]);

  return (
    <Layout
      isMenuBar
      topContent={<ReferCard />}
      userBarContent={
        <BackButton as="text" textColor="white">
          {t('profile')}
        </BackButton>
      }
      customerTopHeight={200}
    >
      <StyledContainer>
        <TimelineList list={list} />
        <div style={{ width: '100%', textAlign: 'center' }}>
          <QRCode
            size={256}
            style={{
              height: 'auto',
              maxWidth: '40%',
              width: '40%',
              margin: '0 auto 20px',
            }}
            value={`${process.env.REACT_APP_DOMAIN}sign-up/user/${userData?.refCode}`}
            viewBox={`0 0 256 256`}
          />
          <FormProvider {...methods}>
            <DisabledInput
              name="referCode"
              mode="light"
              label={t('referalCode')}
            />
          </FormProvider>
          <OverstyledButton onBtnClick={handleClick}>
            <StyleImage src={shareIcon} alt="Share Icon" height={25} />
            {t('referAFriend')}
          </OverstyledButton>
        </div>
        <StyledBottomContainer />
      </StyledContainer>
    </Layout>
  );
};
