import { createGlobalStyle } from 'styled-components/macro';

const GlobalStyle = createGlobalStyle`
  :root {
  --poopy--green: rgba(140, 213, 64, 1);
  --secondary--poopy--green: rgba(67, 131, 0, 1);
  --lime--green: rgba(202, 226, 73, 1);
  --secondary--lime--green: rgba(138, 163, 6, 1);
  --secondary--lime--green-opacity: rgba(138, 163, 6, 0.2);
  --green: rgba(1, 213, 75, 1);
  --green-dark: 	rgba(0, 77, 0, 1);
  --green-dark-opacity: 	rgba(0, 77, 0, 0.2);
  --grey: rgba(80, 80, 80, 1);
  --grey-light: rgba(230,230,230,1);
  --secondary-grey: rgba(230,230,230,1);
  --grey-dark: rgba(40, 40, 40, 1);
  --grey-dark-opacity: rgba(40, 40, 40, 0.2);
  --grey-status: rgba(40, 40, 40, 1);
  --grey-status-opacity: rgba(40, 40, 40, 0.2);
  --grey-opacity: rgba(230,230,230,0.2);
  --grey-very-light: rgba(248, 248, 248, 1);
  --green-opacity: rgba(1, 213, 75, 0.2);
  --green-more-opacity: rgba(1, 213, 75, 0.1);
  --green-gradient: linear-gradient(92.9deg, #AFEFAA 22.97%, #8DE9BD 73.95%);
  --secondary--green: rgba(28, 69, 43, 1);
  --secondary--green-opacity: rgba(28, 69, 43, 0.2);
  --black: rgba(40, 40, 40, 1);
  --black-text: rgba(40, 40, 40, 1);
  --black-background: rgba(40, 40, 40, 1);
  --black-darkmode: rgba(25, 28, 25, 1);
  --black-always: rgba(40, 40, 40, 1);
  --black-dark:  rgba(40, 40, 40,1);
  --black-green: rgba(0, 0, 0, 1);
  --sub--black: rgba(132, 132, 132, 1);
  --sub--black-opacity: rgba(132, 132, 132, 0.2);
  --white: rgba(255, 255, 255, 1);
  --white-always: rgba(255, 255, 255, 1);
  --white-text: rgba(255, 255, 255, 1);
  --white-icon: rgba(255, 255, 255, 1);
  --white-opacity: rgba(241,241,241,0.2);
  --error: rgba(252,7,95, 1);
  --red: rgba(251, 57, 57, 1);
  --red-opacity: rgba(251, 57, 57, 0.2);
  --camel: rgba(193, 152, 4, 1);
  --camel-opacity: rgba(193, 152, 4, 0.2);
  --card-background: rgba(255, 255, 255, 1);
  --dropdown-label: rgba(40, 40, 40,1);
  --menu-border: transparent;
  --link-blue: rgba(5, 126, 213, 1);
  
  
  

  font-size: 10px;
  --text-xxs:0.9rem;
  --text-xs:1rem;
  --text-s:1.2rem;
  --text-m:1.4rem;
  --text-l:1.6rem;
  --text-xl:1.8rem;
  --text-xxl:2.8rem;
  --heading-s: 1.25rem;
  --heading-m: 1.75rem;
  --heading-l: 2.25rem;
  --heading-xl: 2.8rem;
  --heading-xxl: 3rem;
}

.dark-mode {
  --poopy--green: rgba(140, 213, 64, 1);
  --secondary--poopy--green: rgba(67, 131, 0, 1);
  --lime--green: rgba(202, 226, 73, 1);
  --secondary--lime--green: rgba(138, 163, 6, 1);
  --green: rgba(1, 213, 75, 1);
  --green-dark: 	rgba(153, 255, 153, 1);
  --green-dark-opacity: 	rgba(153, 255, 153, 0.2);
  --grey: rgba(150, 150, 150, 1);
  --grey-status: rgba(230, 230, 230, 1);
  --grey-status-opacity: rgba(230, 230, 230, 0.2);
  --grey-light: rgba(230,230,230,0.2);
  --secondary-grey: rgba(230,230,230,1);
  --grey-dark: rgba(78, 78, 78, 1);
  --grey-dark-opacity: rgba(78, 78, 78, 0.2);
  --grey-opacity: rgba(230,230,230,0.2);
  --grey-very-light: rgba(25, 28, 25, 1);
  --green-opacity: rgba(1, 213, 75, 0.2);
  --green-more-opacity: rgba(1, 213, 75, 0.1);
  --green-gradient: rgba(1, 213, 75, 1) ;
  --secondary--green: rgba(28, 69, 43, 1);
  --secondary--green-opacity: rgba(28, 69, 43, 0.2);
  --black: rgba(255, 255, 255, 1);
  --black-text: rgba(40, 40, 40, 1);
  --black-background: rgba(40, 40, 40, 1);
  --black-always:  rgba(40, 40, 40, 1);
  --black-dark:  rgba(25, 28, 25, 1);
  --black-darkmode: rgba(25, 28, 25, 1);
  --black-green: rgba(1, 213, 75, 1);
  --sub--black: rgba(184, 184, 184, 1);
  --sub--black-opacity: rgba(132, 132, 132, 0.2);
  --white: rgba(40, 40, 40, 1);
  --white-always: rgba(255, 255, 255, 1);
  --white-text: rgba(255, 255, 255, 1);
  --white-icon: rgba(255, 255, 255, 1);
  --white-opacity: rgba(241,241,241,0.2);
  --error: rgba(252,7,95, 1);
  --red: rgba(251, 57, 57, 1);
  --red-opacity: rgba(251, 57, 57, 0.2);
  --camel: rgba(193, 152, 4, 1);
  --camel-opacity: rgba(193, 152, 4, 0.2);
  --card-background: rgba(78, 78, 78, 1);
  --dropdown-label: rgba(184, 184, 184,1);
  --menu-border: rgba(1, 213, 75, 1);  
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html{
  height: -webkit-fill-available; 
}

html,
body {
  font-family: "Plus Jakarta Sans", sans-serif;
  margin: 0;
  padding: 0;
}
input,
label,
button {
  font-family: "Plus Jakarta Sans", sans-serif
}
body {
  background: var(--white);
  color: var(--black);
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

textarea::placeholder { font-family: "Plus Jakarta Sans", sans-serif}
textarea{
  font-family: "Plus Jakarta Sans", sans-serif
}

ul {
  list-style: none;
}

.userback-button {
  top: 70% !important;
}
`;

export default GlobalStyle;
