import { Card } from 'components/Cards/Card/Card';
import { UserInfo } from 'components/UserInfo/UserInfo';
import { Status } from 'components/Status/Status';
import styled from 'styled-components/macro';
import { User } from 'types/User';
import greenCarImg from 'assets/img/green-car-small-two.png';

interface StyledSuccessDriverCardProps {
  background?: string;
}

interface SuccessDriverCardProps {
  size?: 's' | 'm';
  user?: User;
  status?: string;
}

const StyledSuccessDriverCard = styled.div<StyledSuccessDriverCardProps>`
  padding: 18px 24px;
  &:after {
    content: '';
    position: absolute;
    background: ${(props) => `url(${props.background}) no-repeat top center`};
    background-size: contain;
    top: 50%;
    right: -1px;
    width: 167px;
    height: 119px;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
`;

const StyledContainer = styled.div`
  margin-top: 16px;
`;

export const SuccessDriverCard = ({
  size = 'm',
  user,
  status,
}: SuccessDriverCardProps) => {
  return (
    <Card size={size}>
      <StyledSuccessDriverCard background={greenCarImg}>
        <UserInfo
          layout={'horizontal-column'}
          color="var(--black)"
          user={user}
        />
        {status && (
          <StyledContainer>
            <Status status={status} size={'xs'} />
          </StyledContainer>
        )}
      </StyledSuccessDriverCard>
    </Card>
  );
};
