import { MouseEventHandler, ReactNode, useContext } from 'react';
import styled from 'styled-components/macro';
import { appContext } from 'views/App';

export interface ButtonProps {
  mode?: 'default' | 'inverted' | 'alt' | 'disabled';
  width?: 'auto' | '100%';
  textStyle?: 'uppercase' | 'capitalize';
  children?: ReactNode;
  fontSize?: 's' | 'm' | 'l' | 'xl';
  onBtnClick?: MouseEventHandler;
  className?: string;
  type?: 'submit' | 'button';
  disabled?: boolean;
  id?: string;
  isUserAppSettingsColors?: boolean;
}

const StyledButton = styled.button<ButtonProps>`
  width: ${({ width }) => width};
  min-height: ${({ mode }) => (mode === 'alt' ? 'auto' : '48px')};
  text-transform: ${({ textStyle }) => textStyle};
  background: ${({ mode, disabled, theme }) =>
    (mode === 'default' && theme.primary) ||
    (mode === 'inverted' && 'transparent') ||
    (mode === 'alt' && 'rgba(1, 213, 75, 0.2)') ||
    (disabled && 'var(--sub--black)')};
  border: ${({ mode, theme }) =>
    mode === 'inverted' ? `1px solid ${theme.primary}` : 'none'};
  border-radius: 12px;
  font-weight: 600;
  font-size: ${({ fontSize }) => `var(--text-${fontSize})`};
  color: ${({ mode, disabled, theme, isUserAppSettingsColors }) =>
    mode === 'default' || disabled
      ? isUserAppSettingsColors
        ? 'var(--white-always)'
        : 'var(--black-always)'
      : theme.primary};
  padding: 8px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Button = ({
  mode = 'default',
  width = '100%',
  textStyle = 'capitalize',
  fontSize = 'l',
  children,
  onBtnClick,
  className,
  type = 'button',
  disabled = false,
  id,
}: ButtonProps) => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;
  return (
    <StyledButton
      mode={disabled ? 'disabled' : mode}
      width={width}
      textStyle={textStyle}
      fontSize={fontSize}
      onClick={onBtnClick}
      className={className}
      type={type}
      disabled={disabled}
      id={id}
      isUserAppSettingsColors={Boolean(userData?.appSettings?.primary_color)}
    >
      {children}
    </StyledButton>
  );
};
