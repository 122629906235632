import { gql } from '@apollo/client';

export const USER = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      firstName: first_name
      lastName: last_name
      companyName: company_name
      cashback
      gasStationUser: gas_station_user
      qrCode: qr_code
      billingInformations {
        id
        name
        bankName: bank_name
        accountName: account_number
        iban
      }
      parkingZones {
        uuid
        id
        type
        name
        fullAddress: address_full
        streetName: street_name
        streetNumber: street_number
        zipCode: zip_code
        city
        latitude
        longitude
        signature
        contract
        towingFineValue: towing_fine_value
        towingCashbackValue: towing_cashback_value
        ticketFineValue: ticket_fine_value
        ticketCashbackValue: ticket_cashback_value
        gasStationFineValue: gas_station_fine_value
        gasStationCashbackValue: gas_station_cashback_value
        gasStationInStoreFineValue: gas_station_in_store_fine_value
        gasStationInStoreCashbackValue: gas_station_in_store_cashback_value
        towingEmptyRunCashbackValue: towing_empty_run_cashback_value
      }
    }
  }
`;
