import { IconProps } from '../types';

export const PhoneIcon = ({ stroke = 'var(--green)' }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="27px"
    height="24px"
    viewBox="0 0 30 30"
    version="1.1"
  >
    <g id="surface1">
      <path
        style={{
          stroke: stroke,
        }}
        d="M 30 15 C 30 6.722656 23.285156 0.0117188 15 0.0117188 C 6.714844 0.0117188 0 6.722656 0 15 C 0 23.277344 6.714844 29.988281 15 29.988281 C 23.285156 29.988281 30 23.277344 30 15 Z M 30 15 "
      ></path>
      <path
        style={{
          stroke: stroke,
        }}
        d="M 18.019531 18.148438 L 19.742188 16.800781 L 23.046875 21.023438 L 21.320312 22.371094 Z M 18.019531 18.148438 "
      ></path>
      <path
        style={{
          stroke: stroke,
        }}
        d="M 20.429688 23.070312 L 17.128906 18.84375 C 14.132812 18.007812 12.027344 15.316406 11.941406 12.207031 L 8.640625 7.984375 C 8.640625 7.984375 5.511719 12.464844 10.421875 18.742188 C 15.332031 25.023438 20.429688 23.070312 20.429688 23.070312 Z M 20.429688 23.070312 "
      ></path>
      <path
        style={{
          stroke: stroke,
        }}
        d="M 9.527344 7.289062 L 11.25 5.941406 L 14.554688 10.164062 L 12.828125 11.511719 Z M 9.527344 7.289062 "
      ></path>
    </g>
  </svg>
);
