import { gql } from '@apollo/client';

export const ADD_VEHICLE_TO_PARKING = gql`
  mutation AddVehicleToParking(
    $parkingZoneUuid: String!
    $licensePlate: String!
  ) {
    addVehicleToParking(
      parkingZoneUuid: $parkingZoneUuid
      licensePlate: $licensePlate
    ) {
      id
      parking_zone_uuid
      license_plate
      expires_at
      parkingZone {
        id
        uuid
        type
        name
        address_full
        street_name
        street_number
        zip_code
        city
        latitude
        longitude
        signature
        contract
        created_at
        updated_at
      }
    }
  }
`;
