import { useAuthContext } from 'context/auth';
import { useContext, useEffect, useState } from 'react';
import { appContext } from 'views/App';

interface UseAccountType {
  handleAccountType: (role: string) => boolean;
  isThisAccountType: boolean;
}

export const useAccountType = (role?: string): UseAccountType => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;

  const { isSignedIn } = useAuthContext();
  const [isThisAccountType, setIsThisAccountType] = useState(false);

  const handleAccountType = (role: string) =>
    userData?.role === role || role === undefined;

  useEffect(() => {
    if (!isSignedIn || !userData?.role) return;
    const isThisAccountType = handleAccountType(role);
    setIsThisAccountType(isThisAccountType);
  }, [isSignedIn, userData]);

  return {
    handleAccountType,
    isThisAccountType,
  };
};
