import styled from 'styled-components/macro';
import { IconButton, IconButtonProps } from '../IconButton/IconButton';
import { ChangeEvent, useState, ReactNode, useEffect, useContext } from 'react';
import { CameraIcon } from '../Icons/CameraIcon/CameraIcon';
import { Input } from '../Input/Input';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '../Icons/CloseIcon/CloseIcon';
import { Paragraph } from '../Paragraph/Paragraph';
import { PlayIcon } from '../Icons/PlayIcon/PlayIcon';
import { Popup } from '../Popup/Popup';
import { appContext } from 'views/App';

interface UploadInputProps {
  mode?: 'dark' | 'transparent' | 'light';
  content?: ReactNode;
  label?: string;
  name: string;
  isMultiSelect?: boolean;
  handleMediaChange?: (media: File[]) => void;
  addedMedia?: File[];
  onMediaClick?: (media: File) => void;
  mediaType?: string;
  bottomMarting?: string;
  isRequired?: boolean;
  handleNewFile?: (file: File) => void;
  shouldRemoveAllMedia?: boolean;
}

interface StyledContainerProps {
  isImages?: boolean;
  isMultiSelect?: boolean;
}

const StyledContainer = styled.div<StyledContainerProps>`
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
`;

const StyledLabel = styled.label`
  position: absolute;
  top: -20px;
  right: 0;
  max-width: 113px;
  background-color: rgba(1, 213, 75, 0.2);
  padding: 8px 10px;
  border-radius: 12px;
  z-index: 1;
`;

const StyledPhotosContainer = styled.div`
  display: flex;
`;

const StyledImageContainer = styled.div`
  position: relative;
  margin-left: 12px;
  &:first-child {
    margin-left: 0;
  }
`;

const StyledIconButton = ({
  className,
  children,
  onClick,
}: IconButtonProps) => (
  <IconButton className={className} onClick={onClick}>
    {children}
  </IconButton>
);

const OverstyledIconButton = styled(StyledIconButton)`
  position: absolute;
  right: 4px;
  top: 4px;
  background-color: var(--white);
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledImage = styled.img`
  border-radius: 5px;
`;
const StyledVideo = styled.video`
  border-radius: 5px;
`;
const StyledContent = styled.div`
  width: 100%;
`;

export const UploadInput = ({
  mode,
  content,
  label,
  name,
  isMultiSelect = false,
  handleMediaChange,
  addedMedia = [],
  onMediaClick,
  mediaType = 'image',
  bottomMarting = '0',
  handleNewFile,
  isRequired,
  shouldRemoveAllMedia = false,
}: UploadInputProps) => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;

  const { t } = useTranslation();
  const [media, setMedia] = useState<File[] | []>(addedMedia);
  const [isPopupOpened, setIsPopupOpened] = useState<boolean>(false);

  const handleChange = (file: File) => {
    const fileSize = file?.size / 1000000;
    if (fileSize > 100) {
      setIsPopupOpened(true);
    } else {
      handleNewFile && handleNewFile(file);
      if (isMultiSelect) {
        setMedia((old) => [...old, file]);
      } else {
        setMedia([file]);
      }
    }
  };

  const handlePhotoRemove = (image: File) => {
    const newImages = media.filter((item) => item !== image);
    if (!newImages?.length) {
      setMedia(undefined);
    } else {
      setMedia(newImages);
    }
  };

  useEffect(() => {
    handleMediaChange && handleMediaChange(media);
  }, [media]);
  useEffect(() => {
    if (shouldRemoveAllMedia) setMedia([]);
  }, [shouldRemoveAllMedia]);
  return (
    <div>
      <Input
        label={mode === 'transparent' ? undefined : label}
        name={name}
        type="file"
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          handleChange(e.target.files[0])
        }
        mode={mode}
        isRequired={isRequired ? !media?.length : false}
        requiredErrorMessage={t('requiredError')}
        accept={
          mediaType === 'image'
            ? 'image/jpeg,application/pdf,.jpeg,image/png,.png'
            : 'video/mp4,video/x-m4v,video/*'
        }
        bottomMarting={bottomMarting}
      >
        {content ? (
          <StyledContent>
            <label htmlFor={name}>{content}</label>
          </StyledContent>
        ) : (
          <StyledContainer
            isImages={Boolean(media?.length)}
            isMultiSelect={isMultiSelect}
          >
            {media && media[0] && !isMultiSelect && (
              <StyledImageContainer
                onClick={() => onMediaClick && onMediaClick(media[0])}
              >
                {mediaType === 'image' && (
                  <img
                    src={media[0] ? URL.createObjectURL(media[0]) : ''}
                    alt="Uploaded Image"
                    height={90}
                  />
                )}
                {mediaType === 'video' && (
                  <video
                    height={90}
                    width="100%"
                    src={media[0] ? URL.createObjectURL(media[0]) : ''}
                    autoPlay
                    controls={false}
                    muted
                    loop
                    playsInline
                  />
                )}
                <OverstyledIconButton
                  onClick={() => handlePhotoRemove(media[0])}
                >
                  <CloseIcon size="6" fill="black" />
                </OverstyledIconButton>
              </StyledImageContainer>
            )}
            {(!media || !media.length) && (
              <label htmlFor={name}>
                {mediaType === 'image' && (
                  <CameraIcon
                    fill="var(--black-always)"
                    stroke={userData?.appSettings?.primary_color}
                  />
                )}
                {mediaType === 'video' && (
                  <PlayIcon
                    fill="var(--black-always)"
                    stroke={userData?.appSettings?.primary_color}
                  />
                )}
              </label>
            )}
            {media &&
              Boolean(media.length) &&
              isMultiSelect &&
              media.length < 5 && (
                <StyledLabel htmlFor={name}>
                  <Paragraph
                    color="var(--green)"
                    weight="bolder"
                    dimension="xs"
                  >
                    {mediaType === 'image' && t('addPhoto')}
                    {mediaType === 'video' && t('addVideo')}
                  </Paragraph>
                </StyledLabel>
              )}
            {media && Boolean(media.length) && isMultiSelect && (
              <StyledPhotosContainer>
                {media.map((media: File) => {
                  const mediaURL = media ? URL.createObjectURL(media) : '';
                  return (
                    <StyledImageContainer
                      key={mediaURL}
                      onClick={() => onMediaClick && onMediaClick(media)}
                    >
                      {mediaType === 'image' && (
                        <StyledImage src={mediaURL} width={49} height={49} />
                      )}
                      {mediaType === 'video' && (
                        <StyledVideo src={mediaURL} width={49} height={49} />
                      )}
                      <OverstyledIconButton
                        onClick={() => handlePhotoRemove(media)}
                      >
                        <CloseIcon size="6" fill="black" />
                      </OverstyledIconButton>
                    </StyledImageContainer>
                  );
                })}
              </StyledPhotosContainer>
            )}
          </StyledContainer>
        )}
      </Input>
      <Popup
        isPopupOpened={isPopupOpened}
        type="info"
        content={t('tooBigFile')}
        confirmText="ok"
        onDecline={() => setIsPopupOpened(false)}
      />
    </div>
  );
};
