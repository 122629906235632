import { Paragraph } from 'components/Paragraph/Paragraph';
import { UserInfo } from 'components/UserInfo/UserInfo';
import styled from 'styled-components/macro';
import { ReportProps } from 'types/Report';
import carImage from 'assets/img/green-car-small.png';
import { useAccountType } from 'hooks/useAccountType';

const StyledDriveContainer = styled.div`
  min-height: 106px;
  width: 100%;
  border: 1px solid var(--secondary-grey);
  border-radius: 16px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 12px;
  .address {
    max-width: 150px;
  }
`;

const StyledImage = styled.img`
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  width: 63px;
`;

const StyledUserContainer = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: end;
`;
const StyledLine = styled.div`
  position: absolute;
  width: 75%;
  left: 30px;
  top: 35px;
  border-top: 1px dashed var(--black);
`;

interface UserDriverInformationProps {
  report?: ReportProps;
}

export const UserDriverInformation = ({
  report,
}: UserDriverInformationProps) => {
  const { isThisAccountType: isUserAccount } = useAccountType('user');
  if (!report) return;
  return (
    <>
      {isUserAccount && report?.type === 1 && (
        <StyledDriveContainer>
          <StyledLine />
          <div>
            <UserInfo layout="avatar" align="flex-start" />
            <Paragraph
              color="var(--black)"
              weight="bold"
              dimension="s"
              textAlign="left"
              className="address"
            >
              {report?.street}, {report?.streetNumber}
            </Paragraph>
            <Paragraph
              color="var(--sub--black)"
              dimension="xs"
              textAlign="left"
            >
              {report?.createdAt.slice(11, 16)}
            </Paragraph>
          </div>
          <StyledUserContainer>
            <StyledImage src={carImage} />
            <Paragraph
              color="var(--black)"
              weight="bold"
              dimension="s"
              className="address"
              textAlign="right"
            >
              {report?.policeParking
                ? `${report?.policeParking?.streetName}, 
        ${report?.policeParking?.streetNumber}`
                : ''}
            </Paragraph>
            <Paragraph
              color="var(--sub--black)"
              dimension="xs"
              textAlign="right"
            >
              {report?.logs[0]?.createdAt?.slice(11, 16)}
            </Paragraph>
          </StyledUserContainer>
        </StyledDriveContainer>
      )}
    </>
  );
};
