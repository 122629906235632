export const getPhotosFromReportMedia = (media: { photo: string }[]) => {
  const images = media?.map((photo) => checkURL(photo.photo));
  const filteredImages = images.filter((element) => {
    return element !== undefined;
  });
  return filteredImages || [];
};
const checkURL = (url: string) => {
  if (url.match(/\.(jpeg|jpg|gif|png)$/)) return url;
};
