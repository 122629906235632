import { useMutation } from '@apollo/client';
import { Button } from 'components/Button/Button';
import { DownloadDocumentIcon } from 'components/Icons/DownloadDocumentIcon/DownloadDocumentIcon';
import { DOWNLOAD_GAS_STATION_REPORT_PDF } from 'graphql/mutations/downloadGasStationReportPDF';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { appContext } from 'views/App';
import * as Sentry from '@sentry/react';

interface DownloadGasStationReceiptProps {
  handleDownloadFile?: (fileUrl: string) => void;
  reportHash?: string;
}

export const DownloadGasStationReceipt = ({
  handleDownloadFile,
  reportHash,
}: DownloadGasStationReceiptProps) => {
  const { t } = useTranslation();
  const appContextStore = useContext(appContext);
  const setIsLoading = appContextStore?.setIsLoading;
  const setIsError = appContextStore?.setIsError;
  const { type } = useParams();
  const isGasStationReport = type === 'station';

  const [downloadGasStationReportPDF] = useMutation(
    DOWNLOAD_GAS_STATION_REPORT_PDF,
    {
      onCompleted: (data) => {
        if (!data) return;
        handleDownloadFile(data.downloadGasStationReportPDF);
      },
      onError: (error) => {
        Sentry.captureException(error);
        if (error) setIsError(true);
        throw new Error(error.message);
      },
    },
  );
  if (!isGasStationReport) return;
  return (
    <Button
      onBtnClick={() => {
        downloadGasStationReportPDF({
          variables: { hash: reportHash },
        });
        setIsLoading(true);
      }}
      className="downloadButton"
    >
      {t('downloadReceipt')} <DownloadDocumentIcon />
    </Button>
  );
};
