import { gql } from '@apollo/client';

export const CREATE_REPORT_LOG = gql`
  mutation CreateReportLog(
    $id: ID!
    $latitude: Float!
    $longitude: Float!
    $information: String
  ) {
    createReportLog(
      input: {
        latitude: $latitude
        longitude: $longitude
        information: $information
        report: { connect: $id }
      }
    ) {
      id
      information
    }
  }
`;
