import { gql } from '@apollo/client';

export const GAS_STATION_REPORTS_OF_USER = gql`
  query GasStationReportsOfUser(
    $user: ID!
    $idOrPlate: String
    $paymentStatus: [String]
    $first: Int!
    $page: Int
  ) {
    gasStationReportsOfUser(
      user: $user
      first: $first
      page: $page
      idOrPlate: $idOrPlate
      payment_status: $paymentStatus
    ) {
      data {
        id
        hash
        plate
        carName: car_name
        street
        streetNumber: street_number
        zipCode: zip_code
        city
        createdAt: created_at
        updated_at
        bill
        price
        issueTime
        issueDate
        description
        paymentStatus: payment_status
        photos {
          photo
        }
        user {
          id
          firstName: first_name
          lastName: last_name
          email
          phonePrefix: phone_prefix
          phoneNumber: phone_number
          avatar
        }
        parkingZone {
          name
          user {
            id
            firstName: first_name
            lastName: last_name
            companyName: company_name
          }
        }
      }
      paginatorInfo {
        hasMorePages
      }
    }
  }
`;
