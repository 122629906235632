export const circulationCompensation = {
  roadSignTitle: 'Interdiction judiciaire',
  roadSignTextContent:
    'Le stationnement non autorisé est sanctionné conformément aux règles du code de la route. Les infractions à cette interdiction de stationnement, ainsi marquées, sont punies conformément aux sanctions prévues par le code de la route.',
  circulationCompensationBottomTitle: 'Mentions légales',
  circulationCompensationBottomText:
    "La Cour fédérale a établi la base juridique pour l'émission des compensations administratives.",
  circulationCompensationFirstTitle: 'Chère conductrice, cher conducteur,',
  circulationCompensationFirstText:
    'Notre agent de stationnement a constaté que vous avez garé votre véhicule de manière non autorisée sur une place de parking privée, pour laquelle une interdiction judiciaire de stationnement est en vigueur. En raison de la gêne et des démarches occasionnées, une compensation administrative vous est imposée.\n\n Nous vous informons que le non-paiement de cette compensation administrative peut entraîner des coûts supplémentaires, voire une procédure pénale.',
  circulationCompensationSecondTitle:
    "Blocage d'informations pour le titulaire du véhicule / Véhicules étrangers",
  circulationCompensationSecondText:
    "Si les informations concernant le titulaire de votre véhicule sont bloquées auprès de l'Office des véhicules ou si votre véhicule possède des plaques d'immatriculation étrangères, nous vous prions de bien vouloir nous communiquer votre adresse en indiquant la plaque d'immatriculation à l'adresse email suivante : info@falsch-parker.ch dans un délai de deux jours.\n\nDans le cas contraire, nous nous réservons le droit de signaler directement le manquement aux autorités de poursuite pénale.\n\nMerci pour votre compréhension. \n Falsch-Parker.ch",
};
