/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unknown-property */
import {
  DirectionsRenderer,
  DirectionsService,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import pin from 'assets/img/pin.png';
import { useEffect, useMemo, useState } from 'react';
import { debounce } from 'utils/debounce';

export interface MapProps {
  position?: (string | number)[];
  height?: string;
  customerMarkerIcon?: string | google.maps.Icon;
  additionalPossitionMarkerIcon?: string | google.maps.Icon;
  isWithPositionPin?: boolean;
  additionalPossition?: (string | number)[];
  zoom?: number;
  mapClassName?: string;
}

const containerStyle = {
  width: '100%',
  height: '100%',
};

export const Map = ({
  position = [47.373878, 8.545094],
  height = '200px',
  customerMarkerIcon,
  additionalPossitionMarkerIcon,
  isWithPositionPin = true,
  additionalPossition,
  zoom = 15,
  mapClassName,
}: MapProps) => {
  const [response, setResponse] = useState<any>(null);
  const [origin, setOrigin] = useState<string>('');
  const [destination, setDestination] = useState<string>('');
  const [mapRender, setMapRender] = useState<boolean>(false);
  const directionsCallback = (response) => {
    if (response !== null) {
      if (response.status === 'OK') {
        setResponse(response);
      } else {
        console.log('response: ', response);
      }
    }
  };

  useEffect(() => {
    setOrigin(`${position[0]}, ${position[1]}`);
    if (additionalPossition) {
      setDestination(`${additionalPossition[0]}, ${additionalPossition[1]}`);
    }
  }, [position, additionalPossition]);

  useEffect(() => {
    const interval = setInterval(() => {
      setMapRender(false);
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const key = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: key,
  });
  const icon: google.maps.Icon = {
    url: pin,
    size: { equals: () => false, width: 30, height: 40 },
  };

  const Map = useMemo(
    () =>
      isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={
            !additionalPossition && {
              lat: Number(position[0]),
              lng: Number(position[1]),
            }
          }
          zoom={zoom}
          options={{
            fullscreenControl: false,
            panControl: false,
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
          }}
        >
          {isWithPositionPin && (
            <Marker
              position={{
                lat: Number(position[0]),
                lng: Number(position[1]),
              }}
              icon={customerMarkerIcon ? customerMarkerIcon : icon}
            />
          )}
          {additionalPossition && (
            <Marker
              position={{
                lat: Number(additionalPossition[0]),
                lng: Number(additionalPossition[1]),
              }}
              icon={
                additionalPossitionMarkerIcon
                  ? additionalPossitionMarkerIcon
                  : icon
              }
            />
          )}
          {destination !== '' && origin !== '' && (
            <DirectionsService
              options={{
                destination: destination,
                origin: origin,
                travelMode: google.maps.TravelMode.DRIVING,
              }}
              callback={(response) =>
                debounce(() => directionsCallback(response), 5000)
              }
            />
          )}

          {response !== null && additionalPossition && (
            <DirectionsRenderer
              options={{
                directions: response,
                markerOptions: { visible: true },
                preserveViewport: mapRender ? true : false,
                suppressMarkers: true,
              }}
              onDirectionsChanged={() => {
                setTimeout(() => {
                  setMapRender(true);
                }, 3000);
              }}
            />
          )}
        </GoogleMap>
      ) : (
        <div></div>
      ),
    [
      isLoaded,
      position,
      zoom,
      isWithPositionPin,
      customerMarkerIcon,
      additionalPossition,
      additionalPossitionMarkerIcon,
      icon,
      destination,
      origin,
      response,
      directionsCallback,
    ],
  );
  return (
    <div style={{ height: height, width: '100%' }} className={mapClassName}>
      {Map}
    </div>
  );
};
