import { useWindowHeight } from 'hooks/useWindowHeight';
import styled, { keyframes } from 'styled-components/macro';
import logo from 'assets/img/logo.svg';

interface SplashProps {
  onAnimationEnd?: () => void;
}

interface StyledSplashProps {
  windowHeight?: number;
}

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;
const move = keyframes`
  0% { transform: translateY(0px) rotate(0deg); }
  100% { transform: translateY(3000px) rotate(0deg);}
`;

const StyledSplash = styled.div<StyledSplashProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: ${({ windowHeight }) => windowHeight};
  background-color: var(--black-background);
  animation: ${move} 2s 2s forwards;
  position: fixed;
  inset: 0;
  z-index: 99999;
`;
const StyledSplashLogo = styled.img`
  animation: ${spin} 2s 0s 1;
`;
export const Splash = ({ onAnimationEnd }: SplashProps) => {
  const { windowHeight } = useWindowHeight();
  const isSplash = sessionStorage.getItem('splash');
  const handleAnimationEnd = () => {
    if (isSplash) return;
    sessionStorage.setItem('splash', 'true');
    onAnimationEnd && onAnimationEnd();
  };
  if (isSplash) return;
  return (
    <StyledSplash
      onAnimationEnd={handleAnimationEnd}
      windowHeight={windowHeight}
    >
      <StyledSplashLogo
        src={logo}
        width={144}
        height={144}
        alt="Falshparker Logo"
      />
    </StyledSplash>
  );
};
