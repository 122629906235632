import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useWindowHeight } from 'hooks/useWindowHeight';
import { BackButton } from 'components/BackButton/BackButton';
import { useNavigate } from 'react-router-dom';
import { Html5Qrcode } from 'html5-qrcode';
import { useTranslation } from 'react-i18next';

const StyledContainer = styled.div<{ height: number }>`
  height: ${({ height }) => `${height}px`};
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  .scanner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--black-always);
  }
  #qrCodeContainer {
    position: relative;
    width: 300px;
    height: 300px;
    border-radius: 7px;
    overflow: hidden;
  }
  #qr-shaded-region {
    border-width: 50px !important;
  }
  video {
    position: absolute;
    left: 50%;
    top: 50%;
    width: auto !important;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);
  }
`;
const StyledStartButton = styled.button`
  position: absolute;
  top: 11px;
  right: 20px;
  background-color: red;
  border: 0;
  border-radius: 7px;
  padding: 0 5px;
  font-size: var(--text-xs);
  color: white;
  width: auto;
  height: 40px;
`;

export const QrCodeScanner = () => {
  const { windowHeight } = useWindowHeight();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isCameraEnabled, setIsCameraEnabled] = useState<boolean>(false);
  useEffect(() => {
    const config = { fps: 10, qrBox: { width: 200, height: 200 } };
    const html5QrCode = new Html5Qrcode('qrCodeContainer');
    const qrScannerStop = () => {
      if (html5QrCode && html5QrCode.isScanning) {
        html5QrCode
          .stop()
          .then(() => console.log('Scanner stop'))
          .catch(() => 'Scanner stop error');
      }
    };
    const qrScannerSuccess = (decodedText) => {
      const isFaschparkerTicketQRCode = decodedText.includes(
        'https://app.falsch-parker.ch/ticket',
      );
      if (isFaschparkerTicketQRCode) {
        setIsCameraEnabled(false);
        html5QrCode
          .stop()
          .then(() => console.log('Scanner stop'))
          .catch(() => 'Scanner stop error');
        sessionStorage.setItem(
          'qrCodeId',
          decodedText.replaceAll('https://app.falsch-parker.ch/ticket/', ''),
        );
        navigate(-1);
      }
    };
    if (isCameraEnabled) {
      html5QrCode.start(
        { facingMode: 'environment' },
        config,
        qrScannerSuccess,
        (error) => console.log(error),
      );
    } else {
      qrScannerStop();
    }
  }, [isCameraEnabled]);

  return (
    <StyledContainer height={windowHeight}>
      <BackButton as="back" onClick={() => navigate(-1)} />
      <div id="qrCodeContainer"></div>
      <StyledStartButton onClick={() => setIsCameraEnabled(true)}>
        {t('startScanner')}
      </StyledStartButton>
    </StyledContainer>
  );
};
