import { Card } from '../Card/Card';
import parkingsImage from 'assets/img/parkings.png';
import styled from 'styled-components';

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  padding-bottom: 0px !important;
  object-fit: cover !important;
  border-radius: 20px;
`;

export const ParkingAndLicensesCard = () => (
  <Card topContainerColor="transparent" contentAlign="center">
    <StyledImg
      src={parkingsImage}
      alt="Redeem Details"
      width="310px"
      height="173px"
    />
  </Card>
);
