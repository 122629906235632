import styled from 'styled-components/macro';

export const OnboardingCodeStyledContainer = styled.div`
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
`;

export const OnboardingCodeStyledForm = styled.form`
  padding: 10px 0 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 40px;
`;
export const OnboardingCodeStyledFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3vh;
`;

export const ParkingAndLicensesAccordionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 61px;
`;

export const ParkingAndLicensesImg = styled.img`
  padding: 12.5px;
`;
