import { gql } from '@apollo/client';

export const UPDATE_USER_GAS_STATION = gql`
  mutation UpdateUserGasStation($user: ID!, $gasStationUser: Boolean) {
    updateUserGasStation(user: $user, gas_station_user: $gasStationUser) {
      id
      gasStationUser: gas_station_user
    }
  }
`;
