export const faq = {
  faqFisrtQuestion: "Qu'est-ce qu'une indemnité de gestion?",
  faqFirstAnswer:
    "L'indemnité de gestion est une forme de dédommagement en Suisse, qui s'applique en cas d'utilisation illégitime de propriétés privées, comme le stationnement illicite sur des places privées. Elle couvre les frais engagés par la victime pour faire valoir ses droits.",
  faqSecondQuestion: "Quel est le montant de l'indemnité de gestion?",
  faqSecondAnswer:
    "L'indemnité de gestion varie entre 40 et 80 CHF, le montant exact pouvant être défini par le client lui-même.",
  faqThirdQuestion:
    "Est-il nécessaire d'avoir une interdiction judiciaire pour émettre une indemnité de gestion?",
  faqThirdAnswer:
    "Non, une interdiction judiciaire n'est pas nécessaire pour émettre une indemnité de gestion. Cependant, si le parking est également soumis à une interdiction judiciaire, le contrevenant peut, en plus de l'indemnité de gestion, faire l'objet d'une plainte et d'une poursuite pour non-respect d'une interdiction judiciaire.",
  faq4Question: "Combien coûte l'application ou le remorquage d'un véhicule?",
  faq4Answer:
    'Notre service est entièrement gratuit pour nos clients. Aucun frais ne leur est imputé. Les frais sont directement réclamés auprès du contrevenant.',
  faq5Question: 'Quand est-il permis de faire remorquer un véhicule?',
  faq5Answer:
    'Sur des places de stationnement clairement délimitées visuellement et signalé comme zones privées – reconnaissables aux marquages jaunes, indications privées – ainsi que sur les accès aux cours, garages souterrains, etc. Surtout si le conducteur ne peut être identifié et que la place de stationnement est nécessaire.',
  faq6Question: "Qu'est-ce qu'un contrat de cession?",
  faq6Answer:
    'Une cession est un transfert de créance ou une cession à titre de garantie. Cela modifie la relation de créance en transférant la créance à notre organisation. Le cédant (celui qui cède) transfère ainsi la créance au cessionnaire (bénéficiaire de la cession).',
};
