import { useContext, useEffect } from 'react';
import { useAccountType } from './useAccountType';
import { useLazyQuery } from '@apollo/client';
import { GET_NEW_NOTIFICATION } from 'graphql/queries/getNewNotification';
import { appContext } from 'views/App';

export const useNewNotification = () => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;
  const setNewNotification = appContextStore?.setNewNotification;

  const { isThisAccountType } = useAccountType('user');

  const [getNewNotification] = useLazyQuery(GET_NEW_NOTIFICATION, {
    onCompleted: (data) => {
      if (!data) return;
      if (data.getNewNotification) {
        const newNotification = {
          id: data?.getNewNotification?.id,
          text: data?.getNewNotification?.text,
          textEn: data?.getNewNotification?.textEn,
          reportHash: data?.getNewNotification?.report.hash,
        };
        setNewNotification({ ...newNotification });
      }
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!userData || isThisAccountType) return;

    // Call immediately
    getNewNotification();

    const intervalId = setInterval(() => {
      getNewNotification();
    }, 10000); // 10 seconds

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [isThisAccountType, userData, getNewNotification]);

  return;
};
