import { gql } from '@apollo/client';

export const UPDATE_USER_QR_CODE = gql`
  mutation UpdateUserQRCode($user: ID!, $qrCode: Boolean) {
    updateUserQRCode(user: $user, qr_code: $qrCode) {
      id
      qrCode: qr_code
    }
  }
`;
