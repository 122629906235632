import { MutableRefObject, useEffect } from 'react';

export const useClickOutside = (
  element: MutableRefObject<HTMLElement | null>,
  callback: () => void,
) => {
  useEffect(() => {
    const handleClick = (event: MouseEvent): void => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const target = event.target as any;

      if (!element.current?.contains?.(target)) {
        callback();
      }
    };
    window.addEventListener('click', handleClick);

    return () => window.removeEventListener('click', handleClick);
  }, []);
};
