export const faq = {
  faqFisrtQuestion: "Che cos'è un'indennità di gestione?",
  faqFirstAnswer:
    "L'indennità di gestione è una forma di risarcimento in Svizzera che si applica in caso di uso illegittimo di proprietà private, come il parcheggio non autorizzato su posti privati. Copre le spese sostenute dalla parte lesa per far valere i propri diritti.",
  faqSecondQuestion: "Qual è l'importo dell'indennità di gestione?",
  faqSecondAnswer:
    "L'indennità di gestione varia tra 40 e 80 CHF, e l'importo esatto può essere stabilito dal cliente stesso.",
  faqThirdQuestion:
    "È necessario un divieto giudiziario per emettere un'indennità di gestione?",
  faqThirdAnswer:
    "No, un divieto giudiziario non è necessario per emettere un'indennità di gestione. Tuttavia, se il parcheggio è soggetto anche a un divieto giudiziario, il trasgressore potrebbe, oltre all'indennità di gestione, incorrere in una denuncia e in un'azione penale per violazione di un divieto giudiziario.",
  faq4Question: "Quanto costa l'app o far rimuovere un veicolo?",
  faq4Answer:
    'Il nostro servizio è completamente gratuito per i nostri clienti. Non ci sono costi a loro carico. Le spese sono richieste direttamente al trasgressore.',
  faq5Question: 'Quando è permesso far rimuovere un veicolo?',
  faq5Answer:
    'Su posti di parcheggio chiaramente delimitati visivamente e segnalati come aree private – riconoscibili da segnaletica gialla e indicazioni private – e sugli accessi a cortili, garage sotterranei, ecc. Soprattutto quando non è possibile identificare il conducente e il posto è necessario.',
  faq6Question: "Che cos'è un contratto di cessione?",
  faq6Answer:
    'Una cessione è un trasferimento o una cessione a titolo di garanzia. Ciò modifica il rapporto di credito trasferendo il credito alla nostra organizzazione. Il cedente (colui che cede) trasferisce quindi il credito al cessionario (beneficiario della cessione).',
};
