export const getPosition = async (address: string) => {
  const key = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
  const response = await fetch(
    `https://maps.google.com/maps/api/geocode/json?address=${address}&key=${key}`,
  );
  const data = await response.json();
  if (!data) return null;
  if (!data.results[0]) return null;
  const addressLocation = data.results[0]?.geometry?.location;
  if (addressLocation) {
    const addressComponents = data.results[0]?.address_components;
    const street = addressComponents.find((item) =>
      item.types.find((type) => type === 'route'),
    );
    const streetNumber = addressComponents.find((item) =>
      item.types.find((type) => type === 'street_number'),
    );
    const intersection = addressComponents.find((item) =>
      item.types.find((type) => type === 'intersection'),
    );
    if (intersection) {
      const addressData = {
        lat: addressLocation.lat,
        lng: addressLocation.lng,
      };
      return addressData;
    }
    if (!street || !streetNumber) return null;
    const addressData = {
      lat: addressLocation.lat,
      lng: addressLocation.lng,
    };
    return addressData;
  } else {
    return null;
  }
};
