import { Paragraph } from 'components/Paragraph/Paragraph';
import { useTranslation } from 'react-i18next';

interface ReportMainDetailsProps {
  reportPate?: string;
  reportStreet?: string;
  reportStreetNumber?: string;
  reportZipCode?: string;
  reportCity?: string;
}
export const ReportMainDetails = ({
  reportPate,
  reportStreet,
  reportStreetNumber,
  reportZipCode,
  reportCity,
}: ReportMainDetailsProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Paragraph
        textTransform={'capitalize'}
        dimension={'xs'}
        color={'var(--sub--black)'}
        className="paragraph"
      >
        {t('licensePlate')}
      </Paragraph>
      <Paragraph
        textTransform={'uppercase'}
        weight={'bolder'}
        dimension={'m'}
        color={'var(--black)'}
        className="paragraph"
      >
        {reportPate}
      </Paragraph>
      <Paragraph
        color={'var(--sub--black)'}
        textTransform={'capitalize'}
        dimension={'xs'}
        className="paragraph"
      >
        {t('address')}
      </Paragraph>
      <Paragraph
        weight={'bolder'}
        dimension={'m'}
        color={'var(--black)'}
        className="paragraph"
      >
        {reportStreet} {reportStreetNumber}
      </Paragraph>
      <Paragraph
        dimension={'s'}
        color={'var(--sub--black)'}
        className="paragraph"
      >
        {reportZipCode}, {reportCity}
      </Paragraph>
    </>
  );
};
