import { gql } from '@apollo/client';

export const CREATE_PRESIGNED_REQUEST = gql`
  mutation CreatePresignedRequest($files: [String!]) {
    createPresignedRequest(files: $files) {
      fileName
      id
      url
    }
  }
`;
