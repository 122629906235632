import { useMutation } from '@apollo/client';
import { Button } from 'components/Button/Button';
import { DownloadDocumentIcon } from 'components/Icons/DownloadDocumentIcon/DownloadDocumentIcon';
import { GET_USER_CONTRACT_GS } from 'graphql/mutations/getUserContractGS';
import { useAccountType } from 'hooks/useAccountType';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { appContext } from 'views/App';
import * as Sentry from '@sentry/react';

interface DownloadGasStationContractProps {
  handleDownloadFile?: (fileUrl: string) => void;
  reportHash?: string;
}

export const DownloadGasStationContract = ({
  handleDownloadFile,
  reportHash,
}: DownloadGasStationContractProps) => {
  const { t } = useTranslation();
  const appContextStore = useContext(appContext);
  const setIsLoading = appContextStore?.setIsLoading;
  const setIsError = appContextStore?.setIsError;
  const { isThisAccountType: isSuperAdminAccount } =
    useAccountType('superAdmin');
  const { type } = useParams();
  const isGasStationReport = type === 'station';

  const [getUserContractGS] = useMutation(GET_USER_CONTRACT_GS, {
    onCompleted: (data) => {
      if (!data) return;
      handleDownloadFile(data.getUserContractGS);
    },
    onError: (error) => {
      Sentry.captureException(error);
      setIsLoading(false);
      setIsError(true);
      throw new Error(error.message);
    },
  });
  return (
    <>
      {isSuperAdminAccount && isGasStationReport && (
        <Button
          onBtnClick={() => {
            getUserContractGS({
              variables: { hash: reportHash },
            });
            setIsLoading(true);
          }}
          className="downloadButton"
        >
          {t('downloadContract')} <DownloadDocumentIcon />
        </Button>
      )}
    </>
  );
};
