import styled from 'styled-components/macro';
import { Paragraph } from 'components/Paragraph/Paragraph';

interface TimelineListProps {
  list: string[];
}

const StyledList = styled.ul`
  margin: 0 5px 16px;
`;

const StyledListItem = styled.li`
  position: relative;
  padding-left: 30px;
  padding-bottom: 20px;
  &:before {
    position: absolute;
    left: -5px;
    top: 5px;
    content: '';
    border-radius: 50%;
    background: var(--green-gradient);
    height: 10px;
    width: 10px;
    transition: all 500ms ease-in-out;
    z-index: 2;
  }
  &:after {
    content: '';
    width: 2px;
    height: 100%;
    border-left: 1px dashed var(--secondary--green);
    position: absolute;
    top: 7px;
    left: -1px;
    z-index: 1;
  }
  &:last-child {
    &:after {
      height: 0;
    }
  }
`;

export const TimelineList = ({ list }: TimelineListProps) => {
  return (
    <StyledList>
      {list.map((item) => (
        <StyledListItem key={item}>
          <Paragraph
            weight="bolder"
            textTransform="uppercase"
            color="var(--black)"
          >
            {item}
          </Paragraph>
        </StyledListItem>
      ))}
    </StyledList>
  );
};
