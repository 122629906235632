import { IconProps } from '../types';

export const ArrowDownIcon = ({
  stroke = 'var(--green)',
  width = '14',
  height = '7',
  className,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.2807 1.4668L7.93404 5.81346C7.4207 6.3268 6.5807 6.3268 6.06737 5.81346L1.7207 1.4668"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
