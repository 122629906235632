import { gql } from '@apollo/client';

export const CREATE_CAR_OWNER_PRESIGNED_REQUEST = gql`
  mutation CreateCarOwnerPresignedRequest(
    $hash: String!
    $driverIdPhoto: String
    $platePhoto: String
  ) {
    createCarOwnerPresignedRequest(
      driver_id_photo: $driverIdPhoto
      plate_photo: $platePhoto
      hash: $hash
    ) {
      fileName
      id
      url
    }
  }
`;
