import { gql } from '@apollo/client';

export const REPORT_BY_QR_CODE_AND_PLATE = gql`
  query ReportByQRCodeAndPlate($qrCodeId: String!, $plate: String!) {
    reportByQRCodeAndPlate(qr_code_id: $qrCodeId, plate: $plate) {
      id
      type
      hash
      plate
      carName: car_name
      status
      phonePrefix: phone_prefix
      phoneNumber: phone_number
      street
      streetNumber: street_number
      zipCode: zip_code
      city
      latitude
      longitude
      signature
      createdAt: created_at
      updatedAt: updated_at
      paymentStatus: payment_status
      qrCodeId: qr_code_id
      fineValue: fine_value
      notes
      driverMap {
        driver {
          id
          firstName: first_name
          lastName: last_name
          avatar
          phoneNumber: phone_number
          phonePrefix: phone_prefix
        }
        distance
      }
      user {
        id
        firstName: first_name
        lastName: last_name
        email
        phonePrefix: phone_prefix
        phoneNumber: phone_number
        avatar
      }
      photos {
        id
        photo
      }
      updateDate {
        placement
        acceptance
        arrival
        pickup
        execution
        cancellation
      }
      logs {
        latitude
        longitude
        createdAt: created_at
      }

      carOwner {
        id
        paymentType: payment_type
        driverIdPhoto: driver_id_photo
        platePhoto: plate_photo
        fullName: full_name
        fullAddress: address_full
        gender
        contactDetails: contact_details
      }
      policeParking {
        id
        name
        addressFull: address_full
        streetName: street_name
        streetNumber: street_number
        zipCode: zip_code
        city
      }
      parkingZone {
        name
        user {
          id
          firstName: first_name
          lastName: last_name
          companyName: company_name
        }
      }
      userRewards {
        amount
        status
      }
    }
  }
`;
