import { gql } from '@apollo/client';

export const USER_GAS_STATION_REPORTS_ALL = gql`
  query UserGasStationReportAll(
    $order: SortOrder!
    $page: Int
    $first: Int!
    $idOrPlate: String
    $paymentStatus: [String]
  ) {
    userGasStationReportAll(
      orderBy: [{ column: CREATED_AT, order: $order }]
      page: $page
      first: $first
      idOrPlate: $idOrPlate
      payment_status: $paymentStatus
    ) {
      data {
        id
        hash
        plate
        carName: car_name
        street
        streetNumber: street_number
        zipCode: zip_code
        city
        createdAt: created_at
        updated_at
        bill
        price
        issueTime
        issueDate
        description
        paymentStatus: payment_status
        photos {
          photo
        }
        user {
          id
          firstName: first_name
          lastName: last_name
          email
          phonePrefix: phone_prefix
          phoneNumber: phone_number
          avatar
        }
        parkingZone {
          name
          user {
            id
            firstName: first_name
            lastName: last_name
            companyName: company_name
          }
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;
