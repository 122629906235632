import { Card } from 'components/Cards/Card/Card';
import styled from 'styled-components/macro';
import unsuccessImg from 'assets/img/unsuccess.png';

interface StyledUnsuccessCardProps {
  background?: string;
}

const StyledUnsuccessCard = styled.div<StyledUnsuccessCardProps>`
  &:after {
    content: '';
    position: absolute;
    background: ${(props) => `url(${props.background}) no-repeat top center`};
    background-size: contain;
    top: 22px;
    left: 0;
    right: 0;
    width: 200px;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const UnsuccessCard = () => {
  return (
    <Card>
      <StyledUnsuccessCard background={unsuccessImg} />
    </Card>
  );
};
