import { SuccessCard } from 'components/Cards/SuccessCard/SuccesCard';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Confirm } from 'views/Confirm/Confirm';

export const SuccessfulLicenseAdding = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Confirm
      title={t('congratulations')}
      subtitle={t('successLicenseSubtitle')}
      card={<SuccessCard />}
      onContinueClick={() => navigate('/licenses')}
    />
  );
};
