import { useLazyQuery } from '@apollo/client';
import { PARKING_OWNERS } from 'graphql/queries/parkingOwners';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { ClientListItem } from './ClientListItem/ClientListItem';
import { appContext } from 'views/App';
import * as Sentry from '@sentry/react';

const StyledClientsList = styled.ul``;

interface ListProps {
  status?: string;
  searchValue?: string;
  isContentEnd?: boolean;
  isCashbackOnStatus?: boolean;
}

export const ClientList = ({
  status,
  searchValue,
  isContentEnd,
  isCashbackOnStatus,
}: ListProps) => {
  const appContextStore = useContext(appContext);
  const setIsError = appContextStore?.setIsError;
  const setIsLoading = appContextStore?.setIsLoading;

  const [clients, setClients] = useState(null);
  const [page, setPage] = useState<number>(1);
  const [hasMorePages, setHasMorePages] = useState<boolean>(true);

  const [parkingOwners, { loading, fetchMore }] = useLazyQuery(PARKING_OWNERS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!data) return;
      setClients(data.parkingOwners.data);
      setHasMorePages(data.parkingOwners.paginatorInfo.hasMorePages);
    },
    onError: (error) => {
      Sentry.captureException(error);
      setIsLoading(false);
      setIsError(true);
    },
  });

  useEffect(() => {
    parkingOwners({
      variables: {
        first: 5,
        page: 1,
        filter: searchValue || '',
        cashback: isCashbackOnStatus,
      },
    });
  }, [searchValue, status]);

  useEffect(() => {
    if (!isContentEnd) return;
    if (hasMorePages) {
      setIsLoading(true);
      fetchMore({
        variables: {
          first: 5,
          page: page + 1,
          filter: searchValue || '',
          cashback: isCashbackOnStatus,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setIsLoading(false);
          setPage((prev) => prev + 1);
          setClients((prev) => [
            ...prev,
            ...fetchMoreResult.parkingOwners.data,
          ]);
          setHasMorePages(
            fetchMoreResult.parkingOwners.paginatorInfo.hasMorePages,
          );
        },
      });
    }
  }, [isContentEnd]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <StyledClientsList>
      {clients?.map((client) => (
        <ClientListItem client={client} key={client.id} />
      ))}
    </StyledClientsList>
  );
};
