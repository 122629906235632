import { gql } from '@apollo/client';

export const CREATE_PARKING_ZONE_PRESIGNED_REQUEST = gql`
  mutation CreateParkingZonePresignedRequest($files: [String!]) {
    createParkingZonePresignedRequest(files: $files) {
      fileName
      id
      url
    }
  }
`;
