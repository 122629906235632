export const getCashbackStatusColor = (status: string) => {
  switch (status) {
    case 'new':
      return 'green';
    case 'pending':
      return 'grey-status';
    case 'claimed':
      return 'red';
  }
};
