import { gql } from '@apollo/client';

export const REPORTS_OF_USER = gql`
  query ReportsOfUser(
    $user: ID!
    $idOrPlate: String
    $paymentStatus: [String]
    $first: Int!
    $page: Int
  ) {
    reportsOfUser(
      user: $user
      first: $first
      page: $page
      idOrPlate: $idOrPlate
      payment_status: $paymentStatus
    ) {
      data {
        id
        type
        hash
        plate
        carName: car_name
        status
        street
        streetNumber: street_number
        zipCode: zip_code
        city
        signature
        createdAt: created_at
        paymentStatus: payment_status
        updated_at
        driverMap {
          driver {
            firstName: first_name
            lastName: last_name
            avatar
          }
        }
        photos {
          photo
        }
        user {
          firstName: first_name
          lastName: last_name
          companyName: company_name
        }
        carOwner {
          id
          paymentType: payment_type
        }
        userRewards {
          amount
          status
        }
        parkingZone {
          name
          user {
            id
            firstName: first_name
            lastName: last_name
            companyName: company_name
          }
        }
      }
      paginatorInfo {
        hasMorePages
      }
    }
  }
`;
