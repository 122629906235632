import { gql } from '@apollo/client';

export const GET_APP_SETTINGS_BY_PARKING_UUID = gql`
  query GetAppSettingsByParkingUUID($parkingZoneUuid: String!) {
    getAppSettingsByParkingUUID(parkingZoneUuid: $parkingZoneUuid) {
      id
      primary_color
      secondary_color
      logo
    }
  }
`;
