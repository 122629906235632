import styled from 'styled-components/macro';
import { DropdownOption } from '../DropdownOption/DropdownOption';

interface SelectedOptionProps {
  mode: 'primary' | 'secondary' | 'full';
  optionsPosition?: 'left' | 'right';
}

const StyledOptions = styled.ul<SelectedOptionProps>`
  position: absolute;
  top: ${({ mode }) =>
    (mode === 'primary' && '20px') ||
    (mode === 'secondary' && '40px') ||
    (mode === 'full' && '50px')};
  right: ${({ optionsPosition }) => optionsPosition === 'right' && '0'};
  left: ${({ optionsPosition, mode }) =>
    (optionsPosition === 'left' && mode !== 'full' && '-10px') ||
    (mode === 'full' && '0')};
  width: ${({ mode }) => (mode === 'full' ? '100%' : '173px')};
  background-color: var(--black-always);
  color: var(--white);
  border: 1px solid var(--grey-opacity);
  border-radius: 12px;
  padding: 12px;
  z-index: 2;
`;

interface DropdownOptionListProps {
  dropdownOptions?: string[];
  selectedOption?: string;
  mode?: 'primary' | 'secondary' | 'full';
  optionsPosition?: 'left' | 'right';
  handleSelectedOptionChange?: (selectedOption: string) => void;
  isOptionsOpened?: boolean;
}
export const DropdownOptionList = ({
  mode,
  optionsPosition,
  selectedOption,
  dropdownOptions,
  handleSelectedOptionChange,
  isOptionsOpened,
}: DropdownOptionListProps) => {
  if (!isOptionsOpened) return;
  return (
    <StyledOptions mode={mode} optionsPosition={optionsPosition}>
      {dropdownOptions.map((option, i) => {
        const isOptionSelected = option === selectedOption;
        return (
          <DropdownOption
            option={option}
            key={i}
            isOptionSelected={isOptionSelected}
            handleOnOptionClick={() => handleSelectedOptionChange(option)}
          />
        );
      })}
    </StyledOptions>
  );
};
