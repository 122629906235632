import { SuccessOrderCard } from 'components/Cards/SuccessOrderCard/SuccessOrderCard';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Confirm } from 'views/Confirm/Confirm';

export const SuccessfulDriverOrder = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Confirm
      title={t('congratulations')}
      subtitle={t('successDriverOrderSubtitle')}
      card={<SuccessOrderCard />}
      onContinueClick={() => navigate('/')}
    />
  );
};
