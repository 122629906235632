import { useWindowHeight } from 'hooks/useWindowHeight';
import { createContext, useState, ReactNode, useEffect } from 'react';
import styled from 'styled-components';

export const ThemeContext = createContext({
  isDarkMode: false,
  isMenuToggle: true,
  toggleTheme: () => {
    console.log('change theme');
  },
  toggleMenuBar: () => {
    console.log('toggle menu');
  },
});

type ThemeProviderProps = {
  children: ReactNode;
};

const getDarkMode = () => {
  const isDarkMode = JSON.parse(localStorage.getItem('isDarkMode'));
  return isDarkMode;
};

interface StyledBackgroundContentProps {
  windowHeight?: number;
}
const StyledMainContent = styled.main<StyledBackgroundContentProps>`
  height: ${({ windowHeight }) => `${windowHeight}px`};
  overflow-y: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [isDarkMode, setIsDarkMode] = useState<boolean>(getDarkMode());
  const [isMenuToggle, setMenuToggle] = useState<boolean>(true);
  const { windowHeight } = useWindowHeight();

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark-mode');
      localStorage.setItem('isDarkMode', 'true');
    } else {
      document.documentElement.classList.remove('dark-mode');
      localStorage.setItem('isDarkMode', 'false');
    }
  }, [isDarkMode]);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };
  const toggleMenuBar = () => {
    setMenuToggle((isMenuToggle) => !isMenuToggle);
  };
  return (
    <ThemeContext.Provider
      value={{ isDarkMode, toggleTheme, toggleMenuBar, isMenuToggle }}
    >
      <StyledMainContent windowHeight={windowHeight}>
        {children}
      </StyledMainContent>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
