import { CashbackItem } from 'types/Cashback';

export const groupCashbackHistoryByMonth = (history: CashbackItem[]) => {
  const groups = history.reduce(function (r, o) {
    const month = o.updatedAt.split('-')[1];
    const year = o.updatedAt.split('-')[0];
    const group = `${year}-${month}`;
    r[month]
      ? r[month].history.push(o)
      : (r[month] = { group: group, history: [o] });
    return r;
  }, {});

  const result = Object.keys(groups)
    .sort((a, b) => a[0].localeCompare(b[0]))
    .map(function (k) {
      return groups[k];
    });
  return result;
};
