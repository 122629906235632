import { gql } from '@apollo/client';

export const DRIVER_LOCALIZATION = gql`
  query DriverLocalization($hash: String!) {
    driverLocalization(report_hash: $hash) {
      id
      latitude
      longitude
      created_at
      updated_at
      driver {
        id
      }
    }
  }
`;
