import { Heading } from 'components/Heading/Heading';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { useTranslation } from 'react-i18next';

export const ConfirmPickedCarHeading = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Heading color="var(--black)">{t('carkParkLocation')}</Heading>
      <Paragraph color="var(--black)">{t('freeParking')}</Paragraph>
    </div>
  );
};
