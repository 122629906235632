import { gql } from '@apollo/client';

export const USER_REWARDS = gql`
  query UserRewards {
    userRewards {
      id
      amount
      status
      createdAt: created_at
      updatedAt: updated_at
      report {
        id
        type
        hash
        userRewards {
          amount
          status
        }
      }
      gasStationReport {
        id
        userRewards {
          amount
          status
        }
      }
    }
  }
`;
