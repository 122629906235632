import { Paragraph } from 'components/Paragraph/Paragraph';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledDetail = styled.div`
  padding: 12px 0;
  :nth-child(2n) {
    p,
    span {
      text-align: right !important;
    }
    div {
      margin-left: auto;
      p,
      span {
        text-align: center !important;
      }
    }
  }
`;
const StyledSpan = styled.span<{ align?: string }>`
  font-size: var(--text-m);
  color: var(--sub--black);
  text-align: ${({ align }) => align};
  width: 100%;
  display: block;
`;

interface ReportDetailProps {
  title?: string;
  value?: string | number | ReactNode;
  additionalInfo?: ReactNode;
  customerValue?: ReactNode;
}

export const ReportDetail = ({
  title,
  value,
  additionalInfo,
  customerValue,
}: ReportDetailProps) => {
  const { t } = useTranslation();
  return (
    <StyledDetail>
      <Paragraph color="var(--black)" dimension="s">
        {t(title)}
      </Paragraph>
      {customerValue ? (
        customerValue
      ) : (
        <Paragraph color="var(--black)" dimension="m" weight="bold">
          {value}
        </Paragraph>
      )}

      {additionalInfo && <StyledSpan>{additionalInfo}</StyledSpan>}
    </StyledDetail>
  );
};
