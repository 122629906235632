export const circulationCompensation = {
  roadSignTitle: 'Richterliches Verbot',
  roadSignTextContent:
    'Unberechtigtes Parkieren wird gebüsst nach den Regeln des Strassenverkehrsgesetzes. Zuwiederhandlungen gegen dieses so markierte Parkierverbot werden nach den im Strassenverkehrsgesetz vorgesehenen Sanktionen geahndet.',
  circulationCompensationBottomTitle: 'Legal',
  circulationCompensationBottomText:
    'The Swiss Federal Court has established the legal basis for issuing compensation for inconvenience.',
  circulationCompensationFirstTitle: 'Dear vehicle driver,',
  circulationCompensationFirstText:
    'our parking attendant has noted that you have parked your vehicle without authorization in a private parking area where a judicial parking ban is in effect. As a result of the inconvenience caused, you are being charged with a compensation for inconvenience.\n\n We would like to inform you that failure to pay this compensation may result in additional costs and possibly initiation of legal proceedings.',
  circulationCompensationSecondTitle:
    'Information Lock on Vehicle Owners / Foreign Vehicles',
  circulationCompensationSecondText:
    'If your vehicle owner data is subject to an information lock at the Road Traffic Office, or if you own a vehicle with foreign license plates, we kindly ask you to provide us with your address, along with the vehicle registration number, to the following email address ue@falsch-parker.ch within two days.\n\nOtherwise, we reserve the right to report misconduct directly to law enforcement authorities.\n\nThank you for your attention.\nFalsch-Parker.ch',
};
