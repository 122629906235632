import { Card } from 'components/Cards/Card/Card';
import { UserInfo } from 'components/UserInfo/UserInfo';
import styled from 'styled-components/macro';
import rocketImg from 'assets/img/rocket.png';

interface StyledSuccessOrderCardProps {
  background?: string;
}

const StyledSuccessOrderCard = styled.div<StyledSuccessOrderCardProps>`
  padding: 18px 24px;
  &:after {
    content: '';
    position: absolute;
    background: ${(props) => `url(${props.background}) no-repeat top center`};
    background-size: contain;
    top: -64px;
    right: -10px;
    width: 233px;
    height: 233px;
  }
`;

export const SuccessOrderCard = () => {
  return (
    <Card>
      <StyledSuccessOrderCard background={rocketImg}>
        <UserInfo layout={'horizontal-column'} color="var(--black)" />
      </StyledSuccessOrderCard>
    </Card>
  );
};
