import { gql } from '@apollo/client';

export const PARKING_ZONES = gql`
  query {
    parkingZones {
      id
      name
      streetName: street_name
      streetNumber: street_number
      zipCode: zip_code
      city
      type
      uuid
    }
  }
`;
