import { IconProps } from '../types';

export const ScannerIcon = ({
  size = '24',
  stroke = 'var(--black)',
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 9.5V7C2 4.51 4.01 2.5 6.5 2.5H9"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 2.5H17.5C19.99 2.5 22 4.51 22 7V9.5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 16.5V18C22 20.49 19.99 22.5 17.5 22.5H16"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 22.5H6.5C4.01 22.5 2 20.49 2 18V15.5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 7.5V9.5C10.5 10.5 10 11 9 11H7C6 11 5.5 10.5 5.5 9.5V7.5C5.5 6.5 6 6 7 6H9C10 6 10.5 6.5 10.5 7.5Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 7.5V9.5C18.5 10.5 18 11 17 11H15C14 11 13.5 10.5 13.5 9.5V7.5C13.5 6.5 14 6 15 6H17C18 6 18.5 6.5 18.5 7.5Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 15.5V17.5C10.5 18.5 10 19 9 19H7C6 19 5.5 18.5 5.5 17.5V15.5C5.5 14.5 6 14 7 14H9C10 14 10.5 14.5 10.5 15.5Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 15.5V17.5C18.5 18.5 18 19 17 19H15C14 19 13.5 18.5 13.5 17.5V15.5C13.5 14.5 14 14 15 14H17C18 14 18.5 14.5 18.5 15.5Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
