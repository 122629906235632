import { gql } from '@apollo/client';

export const UPDATE_PARKING_ZONE_TICKET_CASHBACK = gql`
  mutation UpdateParkingZoneTicketCashback(
    $id: ID!
    $ticketCashbackValue: Float
  ) {
    updateParkingZoneTicketCashback(
      id: $id
      ticket_cashback_value: $ticketCashbackValue
    ) {
      id
      ticketCashbackValue: ticket_cashback_value
    }
  }
`;
