import { Heading } from 'components/Heading/Heading';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledHeading = styled.div`
  width: 100%;
  border-bottom: 1px solid #e6e6e6;
`;

interface SidebarHeadingProps {
  title: string;
}

export const SidebarHeading = ({ title }: SidebarHeadingProps) => {
  const { t } = useTranslation();
  return (
    <StyledHeading>
      <Heading color="var(--black)" dimension="m" weight="bold">
        {t(title)}
      </Heading>
    </StyledHeading>
  );
};
