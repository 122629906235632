import { gql } from '@apollo/client';

export const UPDATE_USER_BILLING_INFORMATION = gql`
  mutation UpdateUserBillingInformation(
    $id: ID!
    $name: String
    $bankName: String
    $accountNumber: String
    $iban: String
  ) {
    updateUserBillingInformation(
      id: $id
      input: {
        name: $name
        bank_name: $bankName
        account_number: $accountNumber
        iban: $iban
      }
    ) {
      id
    }
  }
`;
