import { Heading } from 'components/Heading/Heading';
import { Button } from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CashbackStatus } from '../Cashback';

const StyledHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  gap: 5px;
  .customerButton {
    background-color: var(--sub--black-opacity);
    color: var(--grey);
  }
  .selectedButton {
    border: 1px solid var(--green);
    background-color: var(--green-opacity);
    color: var(--green);
  }
`;

interface CashbackHeadingProps {
  handleSelectedStatusChange: (status: CashbackStatus) => void;
  selectedCashbackStatus?: CashbackStatus;
}
export const CashbackHeading = ({
  handleSelectedStatusChange,
  selectedCashbackStatus = 'pending',
}: CashbackHeadingProps) => {
  const { t } = useTranslation();

  return (
    <StyledHeading>
      <Heading color="var(--black)" isWithPaddingBottom={false}>
        {t('history')}
      </Heading>
      <StyledButtonContainer>
        <Button
          mode="alt"
          fontSize="s"
          className={`customerButton ${
            selectedCashbackStatus === 'claimed' && 'selectedButton'
          }`}
          onBtnClick={() => handleSelectedStatusChange('claimed')}
        >
          {t('claimed')}
        </Button>
        <Button
          mode="alt"
          fontSize="s"
          className={`customerButton ${
            selectedCashbackStatus === 'pending' && 'selectedButton'
          }`}
          onBtnClick={() => handleSelectedStatusChange('pending')}
        >
          {t('pending')}
        </Button>
      </StyledButtonContainer>
    </StyledHeading>
  );
};
