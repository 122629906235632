import styled from 'styled-components/macro';

const StyledContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
    &::before {
      position: absolute;
      content: '';
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }
  .checkedSlider {
    background-color: ${(props) => props.theme.primary};
    &:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }
`;

interface ToggleSwitchProps {
  isChecked?: boolean;
  handleToggleClick?: () => void;
}

export const ToggleSwitch = ({
  isChecked,
  handleToggleClick,
}: ToggleSwitchProps) => {
  return (
    <StyledContainer onClick={handleToggleClick}>
      <span className={`slider ${isChecked && 'checkedSlider'}`} />
    </StyledContainer>
  );
};
