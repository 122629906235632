import { CandlesIcon } from 'components/Icons/CandlesIcon/CandlesIcon';
import { CoinsIcon } from 'components/Icons/CoinsIcon/CoinsIcon';
import { HomeIcon } from 'components/Icons/HomeIcon/HomeIcon';
import { InfoCircleIcon } from 'components/Icons/InfoCircleIcon/InfoCircleIcon';
import { LanguageSquareIcon } from 'components/Icons/LanguageSquareIcon/LanguageSquareIcon';
import { LicensesIcon } from 'components/Icons/LicensesIcon/LicensesIcon';
import { MessageIcon } from 'components/Icons/MessageIcon/MessageIcon';
import { MessageQuestionIcon } from 'components/Icons/MessageQuestionIcon/MessageQuestionIcon';
import { MoonIcon } from 'components/Icons/MoonIcon/MoonIcon';
import { PersonalCardIcon } from 'components/Icons/PersonalCardIcon/PersonalCardIcon';
import { SecurityUser } from 'components/Icons/SecurityUser/SecurityUser';

export const profileMenu = [
  {
    title: 'preference',
    items: [
      {
        name: 'darkMode',
        type: 'switch',
        icon: MoonIcon,
      },
      {
        name: 'language',
        type: 'link',
        path: '/language',
        icon: LanguageSquareIcon,
      },
    ],
  },
  {
    title: 'general',
    role: 'user',
    items: [
      {
        name: 'cashback',
        type: 'link',
        path: '/cashback',
        icon: CoinsIcon,
      },
      {
        name: 'addNewProperty',
        type: 'link',
        path: '/parking',
        icon: HomeIcon,
      },
      {
        name: 'addNewLicenses',
        type: 'link',
        path: '/licenses',
        icon: LicensesIcon,
      },
    ],
  },
  {
    title: 'general',
    role: 'superAdmin',
    items: [
      {
        name: 'addNewProperty',
        type: 'link',
        path: '/parking',
        icon: HomeIcon,
      },
      {
        name: 'addNewLicenses',
        type: 'link',
        path: '/licenses',
        icon: LicensesIcon,
      },
    ],
  },
  {
    title: 'contact',
    items: [
      {
        name: 'referAFriend',
        type: 'link',
        path: '/refer-friend-flow',
        icon: PersonalCardIcon,
      },
      {
        name: 'FAQ',
        type: 'link',
        icon: MessageIcon,
        path: '/faq',
      },
      {
        name: 'aboutUs',
        type: 'link',
        path: '/about-us',
        icon: InfoCircleIcon,
      },
      {
        name: 'helpSupport',
        type: 'link',
        path: '/help-and-support',
        icon: MessageQuestionIcon,
      },
      {
        name: 'termsCondition',
        type: 'link',
        icon: CandlesIcon,
        path: '/agb',
      },
      {
        name: 'dataProtection',
        type: 'link',
        icon: SecurityUser,
        path: '/data-protection',
      },
      {
        name: 'imprint',
        type: 'link',
        icon: SecurityUser,
        path: '/imprint',
      },
    ],
  },
];
