import { gql } from '@apollo/client';

export const UPDATE_USER_CASHBACK = gql`
  mutation UpdateUserCashback($user: ID!, $cashback: Boolean) {
    updateUserCashback(user: $user, cashback: $cashback) {
      id
      cashback
    }
  }
`;
