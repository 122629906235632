import { IconProps } from '../types';

export const MoonIcon = ({ size = '24', stroke }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.02911 11.92C1.38911 17.07 5.75911 21.26 10.9891 21.49C14.6791 21.65 17.9791 19.93 19.9591 17.22C20.7791 16.11 20.3391 15.37 18.9691 15.62C18.2991 15.74 17.6091 15.79 16.8891 15.76C11.9991 15.56 7.99911 11.47 7.97911 6.63996C7.96911 5.33996 8.23911 4.10996 8.72911 2.98996C9.26911 1.74996 8.61911 1.15996 7.36911 1.68996C3.40911 3.35996 0.699113 7.34996 1.02911 11.92Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
