import parkingImage from 'assets/img/parking.png';
import { Layout } from 'components/Layout/Layout';
import { Heading } from 'components/Heading/Heading';
import { Button } from 'components/Button/Button';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { UploadInput } from 'components/UploadInput/UploadInput';
import { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { appContext } from '../../App';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
const StyledParagraph = styled(Paragraph)`
  margin-bottom: 16px;
`;

export const AddTowingMedia = () => {
  const appContextStore = useContext(appContext);
  const reportDetails = appContextStore?.reportDetails;
  const setReportDetails = appContextStore?.setReportDetails;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const methods = useForm({ mode: 'onSubmit' });
  const { handleSubmit, watch } = methods;
  const { hash } = useParams();

  const onSubmit = () => {
    navigate(`/select-parking/${hash}`);
  };

  useEffect(() => {
    if (watch('carVideo')[0])
      setReportDetails({ ...reportDetails, videos: [watch('carVideo')[0]] });
  }, [watch('carVideo')]);

  useEffect(() => {
    if (watch('carPhoto')[0])
      setReportDetails({
        ...reportDetails,
        images:
          reportDetails?.images && reportDetails?.images?.length > 0
            ? [...reportDetails.images, watch('carPhoto')[0]]
            : [watch('carPhoto')[0]],
      });
  }, [watch('carPhoto')]);

  useEffect(() => {
    setReportDetails({ ...reportDetails, videos: [], images: [] });
  }, []);

  return (
    <Layout
      isBackBtn
      backgroundContent={<img src={parkingImage} width="100%" height="auto" />}
      mode="medium"
    >
      <FormProvider {...methods}>
        <StyledForm onSubmit={handleSubmit(onSubmit)} id="addTowingMediaForm">
          <div>
            <Heading color="var(--black)">{t('uploadVideoAndPhotos')}</Heading>
            <StyledParagraph color="var(--sub--black)" dimension="s">
              {t('uploadVideoAndPhotosInfo')}
            </StyledParagraph>

            <UploadInput
              name="carVideo"
              label={t('carVideo')}
              mode="light"
              onMediaClick={(video) => navigate(`/video-preview/${video.name}`)}
              mediaType="video"
              bottomMarting="16"
            />

            <UploadInput
              name="carPhoto"
              label={t('carPhoto')}
              mode="light"
              isMultiSelect
              onMediaClick={(image) => navigate(`/photo-preview/${image.name}`)}
              bottomMarting="16"
              isRequired
            />
          </div>
          <Button type="submit">{t('next')}</Button>
        </StyledForm>
      </FormProvider>
    </Layout>
  );
};
