import { FailedReportCard } from 'components/Cards/FailedReportCard/FailedReportCard';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Confirm } from '../../../Confirm/Confirm';

export const FailedReport = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Confirm
      title={t('unsuccessReportTitle')}
      subtitle={t('unsuccessReportSubtitle')}
      card={<FailedReportCard />}
      confirmText={t('addParking')}
      declineText={t('cancelButton')}
      onDeclineClick={() => navigate('/')}
      onConfirmClick={() => navigate('/first-parking-details')}
    />
  );
};
