import { gql } from '@apollo/client';

export const UPDATE_PARKING_ZONE_TOWING_FINE = gql`
  mutation UpdateParkingZoneTowingFine($id: ID!, $towingFineValue: Float) {
    updateParkingZoneTowingFine(id: $id, towing_fine_value: $towingFineValue) {
      id
      towingFineValue: towing_fine_value
    }
  }
`;
