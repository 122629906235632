export const agb = {
  agbContent: `
Condizioni Generali (CG) di TowShare Group GmbH e dell'applicazione FalschParker
Data della modifica: 4 settembre 2024

Informazioni importanti in sintesi

Queste Condizioni Generali di Utilizzo ("Condizioni") si applicano alle applicazioni mobili (App) e/o ai siti web di TowShare Group GmbH in Svizzera. Consentono di richiedere servizi di terzi o direttamente tramite l'app FalschParker. Questi servizi sono forniti sotto i marchi del gruppo TowShare. Ulteriori informazioni sono disponibili su questa pagina.

Richiedendo servizi di terzi, si stipula un contratto direttamente con TowShare Group GmbH, che funge da piattaforma tecnologica di mediazione. È possibile richiedere e ricevere questi servizi tramite la piattaforma. In alcuni cantoni, TowShare Group GmbH opera anche come fornitore di servizi di rimozione.

Si prega di leggere attentamente queste Condizioni. Contengono informazioni importanti sui servizi offerti, sui vostri diritti e su altri dettagli rilevanti. Si stipula un contratto con TowShare Group GmbH, una società a responsabilità limitata con sede in Svizzera.

L'app FalschParker è di proprietà di:
- Nome dell'azienda: TowShare Group GmbH
- Indirizzo: Schützengasse 4
- CAP: 8001
- Città: Zurigo
- Paese: Svizzera
- Email: info@falsch-parker.ch

1. Ambito di applicazione
L'app FalschParker e i relativi servizi sono sempre gratuiti per l'utente. Queste Condizioni Generali (CG) regolano il rapporto tra TowShare Group GmbH e gli utenti che utilizzano la piattaforma per emettere compensi amministrativi per i veicoli parcheggiati illegalmente sulla loro area di parcheggio o proprietà privata. Queste CG regolano anche la gestione e il controllo dei parcheggi e la rimozione dei veicoli parcheggiati illegalmente. Questi servizi sono forniti da TowShare Group GmbH per conto di proprietari immobiliari, amministratori, fornitori di servizi e affittuari.

2. Diritto d'uso
L'utente conferma di essere proprietario o affittuario dell'area di parcheggio o della proprietà privata su cui il veicolo è stato parcheggiato illegalmente.

3. Modifiche alle Condizioni
TowShare Group GmbH si riserva il diritto di modificare le CG in qualsiasi momento. La versione attuale è disponibile sul sito web di TowShare Group GmbH e sull'app FalschParker.

4. Controllo dei parcheggi e compensi amministrativi
Gli utenti e gli addetti di TowShare Group GmbH effettuano controlli regolari dei parcheggi per conto dei proprietari o affittuari. In caso di violazione delle regole di parcheggio, viene addebitato un compenso amministrativo, che copre i costi di gestione sostenuti.

5. Diritto di riscossione e cessione dei crediti
TowShare Group GmbH è autorizzata a riscuotere i compensi amministrativi per conto dei proprietari o affittuari, agendo come fatturatore e fornitore di servizi. I clienti (proprietari o affittuari) concedono a TowShare Group GmbH il diritto di riscossione di questi crediti.

6. Regolamento di cashback
I proprietari o affittuari possono ricevere un rimborso (cashback) per i compensi amministrativi riscossi. L'ammontare e le condizioni del cashback vengono definiti contrattualmente.

7. Clausola di esclusione di responsabilità
TowShare Group GmbH non è responsabile per eventuali danni o perdite verificatisi prima della rimozione del veicolo o causati dal parcheggio illegale.

8. Documentazione e divieto giudiziario
In caso di parcheggio illegale, TowShare Group GmbH documenta tutte le operazioni, inclusa la raccolta di geodati e timestamp. Se applicabile, viene indicato un divieto giudiziario.

9. Procedura di contestazione per i trasgressori
I trasgressori possono contestare i compensi amministrativi per iscritto entro 10 giorni lavorativi dalla ricezione, tramite il sito https://falsch-parker.ch o via email all'indirizzo info@falsch-parker.ch.

10. Trasferimento dei costi
L'utente trasferisce a TowShare Group GmbH, mediante cessione, il diritto di calcolare e far valere i costi nei confronti del trasgressore. Nessun costo è a carico dell'utente.

11. Concessione dell'accesso al veicolo
L'utente si impegna a consentire all'azienda di rimozione l'accesso al veicolo per eseguire il servizio.

12. Disponibilità della piattaforma
TowShare Group GmbH non garantisce che la piattaforma sia sempre disponibile o priva di errori. Potrebbero esserci limitazioni dovute a ragioni tecniche o operative.

13. Protezione dei dati
TowShare Group GmbH tratta i dati personali in conformità con le normative vigenti sulla privacy e la propria politica sulla privacy. Tutti i dati vengono trattati in modo confidenziale, rispettano i più elevati standard di crittografia e sono conservati su server in Svizzera.

14. Modifiche alle CG
TowShare Group GmbH si riserva il diritto di modificare le CG in qualsiasi momento. Le modifiche saranno comunicate in tempo e pubblicate sul sito web.

15. Manleva
L'utente solleva TowShare Group GmbH e l'azienda di rimozione da ogni reclamo e richiesta derivante dall'utilizzo dei servizi.

16. Diritto applicabile e foro competente
Queste CG sono disciplinate dal diritto svizzero. Il foro competente è Zurigo, Svizzera.`,
};
