import { Paragraph } from 'components/Paragraph/Paragraph';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import contractSignature from 'assets/img/signature-falschparker-v2.png';
import { useContext } from 'react';
import { appContext } from 'views/App';

const StyledSignature = styled.div`
  position: relative;
  width: 100%;
  height: 70px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  &::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: var(--black);
    bottom: 0;
    left: 0;
  }
`;
const StyledSignatureContainer = styled.div`
  display: flex;
  gap: 5px;
`;
const StyledImage = styled.img`
  height: 75px;
  width: auto;
`;
const StyledParagraph = styled(Paragraph)`
  padding: 10px 0;
  white-space: pre-line;
`;

export const ContractSignatureContainer = () => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;

  const { t } = useTranslation();
  return (
    <>
      <StyledSignatureContainer>
        <StyledSignature>
          <StyledImage
            src={contractSignature}
            alt="Falschparker Signature"
            width={130}
          />
        </StyledSignature>
        <StyledSignature />
      </StyledSignatureContainer>
      <StyledSignatureContainer>
        <StyledParagraph
          color="var(--black)"
          dimension="s"
          className="userSignature"
        >
          {t('contractor')}:
          <br />
          {t('contractorShortAddress')}
        </StyledParagraph>
        <StyledParagraph color="var(--black)" dimension="s">
          {t('client')}:
          <br />
          {userData?.companyName} {userData?.firstName} {userData?.lastName}
        </StyledParagraph>
      </StyledSignatureContainer>
    </>
  );
};
