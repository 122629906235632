import { gql } from '@apollo/client';

export const UPDATE_PARKING_ZONE_TICKET_FINE = gql`
  mutation UpdateParkingZoneTicketFine($id: ID!, $ticketFineValue: Float) {
    updateParkingZoneTicketFine(id: $id, ticket_fine_value: $ticketFineValue) {
      id
      ticketFineValue: ticket_fine_value
    }
  }
`;
