import { ReportProps } from 'types/Report';
import { ActionButtons } from './ActionButtons/ActionButtons';
import { DownloadReceipt } from './DownloadReceipt/DownloadReceipt';
import { DownloadContract } from './DownloadContract/DownloadContract';
import { DownloadGasStationReceipt } from './DownloadGasStationContract/DownloadGasStationReceipt/DownloadGasStationReceipt';
import { DownloadGasStationContract } from './DownloadGasStationContract/DownloadGasStationContract';
import { DeleteReport } from './DeleteReport/DeleteReport';
import { PaidOnSpot } from './PaidOnSpot/PaidOnSpot';
import { useContext, useEffect, useRef, useState } from 'react';
import { appContext } from 'views/App';
import { ZURICH_POSITION } from 'data/position';
import { CREATE_REPORT_LOG } from 'graphql/mutations/createReportLog';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useGetLocation } from 'hooks/useGetLocation';
import { useAccountType } from 'hooks/useAccountType';
import { useTranslation } from 'react-i18next';
import { Popup } from 'components/Popup/Popup';

interface ReportButtonsProps {
  report?: ReportProps;
  handleReportDataChange: (newReportData: ReportProps) => void;
  handleRefetchReportData: () => void;
}
export const ReportButtons = ({
  report,
  handleReportDataChange,
  handleRefetchReportData,
}: ReportButtonsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { position } = useGetLocation();
  const appContextStore = useContext(appContext);
  const setIsLoading = appContextStore?.setIsLoading;
  const pdfLink = useRef<HTMLAnchorElement>();
  const { isThisAccountType: isUserAccount } = useAccountType('user');
  const isNotUserAccount = !isUserAccount;
  const [isScreenAlwaysOnPopupOpened, setIsScreenAlwaysOnPopupOpened] =
    useState<boolean>(false);

  const handleDownloadFile = async (link: string) => {
    setIsLoading(false);
    pdfLink?.current.setAttribute('href', link);
    pdfLink?.current.click();
  };
  const handleCreateLog = (information: string) => {
    createReportLog({
      variables: {
        id: report.id,
        information: information,
        latitude: position?.latitude || ZURICH_POSITION.latitude,
        longitude: position?.longitude || ZURICH_POSITION.longitude,
      },
    });
  };
  const [createReportLog, { loading }] = useMutation(CREATE_REPORT_LOG, {
    onCompleted: (data) => {
      setIsLoading(false);
      if (data) {
        if (data.createReportLog.information === 'Report User Cancellation') {
          navigate(-1);
        } else if (
          data.createReportLog.information ===
          'Report Driver Destination Arrival'
        ) {
          if (isUserAccount) {
            navigate(`/add-towing-media/${report.hash}`);
          } else {
            if (report?.user.id === report?.driverMap.driver.id) {
              navigate(`/select-parking/${report.hash}`);
            } else {
              navigate(`/add-towing-media/${report.hash}`);
            }
          }
        } else {
          if (isNotUserAccount) {
            setIsScreenAlwaysOnPopupOpened(true);
          }
        }
      }
    },
    onError: (error) => {
      throw new Error(error.message);
    },
  });

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);
  if (!report) return;
  return (
    <>
      <ActionButtons
        report={report}
        handleCreateLog={(information) => handleCreateLog(information)}
        handleRefetchReportData={handleRefetchReportData}
      />
      <DownloadReceipt
        reportHash={report?.hash}
        reportStatus={report?.status}
        handleDownloadFile={(fileUrl) => handleDownloadFile(fileUrl)}
        reportType={report?.type}
      />
      <DownloadContract
        handleDownloadFile={(fileUrl) => handleDownloadFile(fileUrl)}
        isReportParkingZone={Boolean(report?.parkingZone)}
        reportStatus={report?.status}
        reportHash={report?.hash}
        reportType={report?.type}
      />
      <DownloadGasStationReceipt
        handleDownloadFile={(fileUrl) => handleDownloadFile(fileUrl)}
        reportHash={report?.hash}
      />
      <DownloadGasStationContract
        handleDownloadFile={(fileUrl) => handleDownloadFile(fileUrl)}
        reportHash={report?.hash}
      />
      <DeleteReport reportHash={report?.hash} reportStatus={report?.status} />
      <PaidOnSpot
        reportPaymentStatus={report?.paymentStatus}
        reportHash={report?.hash}
        handlePayOnSpot={() =>
          handleReportDataChange({
            ...report,
            paymentStatus: 'paidOnTheSpot',
          })
        }
      />
      <a
        href="https://example.com"
        rel="noopener noreferrer"
        style={{ display: 'none' }}
        ref={pdfLink}
      />
      <Popup
        isPopupOpened={isScreenAlwaysOnPopupOpened}
        onDecline={() => setIsScreenAlwaysOnPopupOpened(false)}
        content={t('screenAlwaysOn')}
        confirmText="ok"
        type="info"
      />
    </>
  );
};
