import { Heading } from 'components/Heading/Heading';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { ParkingTicketsLayout } from 'views/ParkingTickets/ParkingTicketsLayout/ParkingTicketsLayout';
import styled from 'styled-components';
import roadSign from 'assets/img/roadSign.png';
import { device } from 'styles/devices';
import { useTranslation } from 'react-i18next';

const StyledContent = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 25px;
  white-space: pre-line;
  @media ${device.laptop} {
    flex-direction: row;
    gap: 15px;
  }
`;

const StyledLeftContent = styled.div`
  text-align: center;
  p {
    font-size: var(--text-s);
  }
  @media ${device.laptop} {
    min-width: 280px;
    width: 280px;
    p {
      font-size: var(--text-m);
    }
  }
`;
const StyledRoadSignContainer = styled.div`
  font-style: italic;
  margin-bottom: 20px;
  border: 1px solid black;
  padding: 15px 25px;
  .roadSignTitle {
    margin: 10px 0 5px;
  }
`;
const StyledBottomText = styled.div`
  padding: 0 25px;
  .bottomTitle {
    margin-bottom: 5px;
  }
`;
const StyledRoadSignImage = styled.img`
  width: 150px;
  height: 150px;
  margin: 0 auto;
  @media ${device.laptop} {
    width: 200px;
    height: 200px;
  }
`;
const StyledRightContent = styled.div`
  .secondTitle {
    margin-top: 20px;
  }
  p {
    font-size: var(--text-m);
  }
  h2 {
    font-size: var(--heading-m);
  }
  @media ${device.laptop} {
    p {
      font-size: var(--text-l);
    }
    h2 {
      font-size: var(--heading-l);
    }
    .secondTitle {
      margin-top: 30px;
    }
  }
`;

export const CirculationCompensation = () => {
  const { t } = useTranslation();
  return (
    <ParkingTicketsLayout>
      <StyledContent>
        <StyledLeftContent>
          <StyledRoadSignContainer>
            <StyledRoadSignImage src={roadSign} alt="Road sign" />
            <Heading
              color="black"
              textAlign="center"
              isWithPaddingBottom={false}
              dimension="m"
              className="roadSignTitle"
            >
              {t('roadSignTitle')}
            </Heading>
            <Paragraph color="black" dimension="m">
              {t('roadSignTextContent')}
            </Paragraph>
          </StyledRoadSignContainer>
          <StyledBottomText>
            <Heading
              color="black"
              isWithPaddingBottom={false}
              className="bottomTitle"
            >
              {t('circulationCompensationBottomTitle')}
            </Heading>
            <Paragraph color="black" dimension="m">
              {t('circulationCompensationBottomText')}
            </Paragraph>
          </StyledBottomText>
        </StyledLeftContent>
        <StyledRightContent>
          <Heading color="black" dimension="l">
            {t('circulationCompensationFirstTitle')}
          </Heading>
          <Paragraph color="black" dimension="l">
            {t('circulationCompensationFirstText')}
          </Paragraph>
          <Heading color="black" dimension="l" className="secondTitle">
            {t('circulationCompensationSecondTitle')}
          </Heading>
          <Paragraph color="black" dimension="l">
            {t('circulationCompensationSecondText')}
          </Paragraph>
        </StyledRightContent>
      </StyledContent>
    </ParkingTicketsLayout>
  );
};
