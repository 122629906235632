import { Button } from 'components/Button/Button';
import { CalendarInput } from 'components/CalendarInput/CalendarInput';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { Layout } from 'components/Layout/Layout';
import { Heading } from 'components/Heading/Heading';
import { Input } from 'components/Input/Input';
import { UploadInput } from 'components/UploadInput/UploadInput';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getFormattedDate } from 'utils/getFormattedDate';
import greenCarSmall from 'assets/img/green-car-small.png';
import { useUploadMutation } from 'hooks/useUploadMutation';
import { CREATE_BECOME_DRIVER_APPLICATION } from 'graphql/mutations/createBecomeDriverApplication';
import { useNavigate } from 'react-router-dom';
import { TermPopup } from 'components/TermPopup/TermPopup';
import { appContext } from 'views/App';

const StyledContainer = styled.div`
  padding-top: 20px;
  .button {
    margin: 26px 0;
  }
`;

export const DriverSignUp = () => {
  const appContextStore = useContext(appContext);
  const setIsLoading = appContextStore?.setIsLoading;

  const { t } = useTranslation();
  const [isTermsConditionsAccepted, setIsTermsConditionsAccepted] = useState<
    boolean | null
  >(null);
  const [isTermPopupOpened, setIsTermPopupOpened] = useState<boolean>(false);
  const methods = useForm({
    mode: 'onBlur',
  });
  const { handleSubmit, setValue, getValues } = methods;
  const navigate = useNavigate();
  const { mutate } = useUploadMutation();
  const onSubmit = () => {
    if (isTermsConditionsAccepted) {
      setIsLoading(true);
      const address = `${getValues('street')} ${getValues(
        'streetNumber',
      )}, ${getValues('zipCode')} ${getValues('city')}`;
      mutate(CREATE_BECOME_DRIVER_APPLICATION, {
        variables: {
          firstName: getValues('firstName'),
          lastName: getValues('lastName'),
          street: getValues('street'),
          streetNumber: getValues('streetNumber'),
          zipCode: getValues('zipCode'),
          city: getValues('city'),
          fullAddress: address,
          dateOfBirth: `${getValues('driverDateOfBirth')} 00:00:00`,
          licensePhotoFront: getValues('licenseFront')[0],
          governmentIdFront: getValues('govermentIdFront')[0],
          licensePhotoBack: getValues('licenseBack')[0],
          governmentIdBack: getValues('govermentIdBack')[0],
        },
      }).then((result) => {
        setIsLoading(false);
        if (!result) return;
        navigate('/success-driver');
      });
    } else {
      setIsTermsConditionsAccepted(false);
      setIsTermPopupOpened(true);
    }
  };

  const handleTermConfirm = () => {
    setIsTermsConditionsAccepted(true);
    setIsTermPopupOpened(false);
  };
  return (
    <Layout
      isWithMapBackground
      mode="top"
      isBackBtn
      topContent={<img src={greenCarSmall} />}
      topContentSize="s"
      outsideElements={
        <TermPopup
          isTermPopupOpened={isTermPopupOpened}
          handleTermConfirm={handleTermConfirm}
          handleTermDecline={() => setIsTermPopupOpened(false)}
        />
      }
    >
      <StyledContainer>
        <Heading color="var(--green)" dimension="xl">
          {t('becomeADriver')}
        </Heading>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input
              name={'firstName'}
              label={t('firstNameLabel')}
              placeholder={t('firstNamePlaceholder')}
              isRequired
              requiredErrorMessage={t('requiredError')}
              bottomMarting="16"
            />
            <Input
              name={'lastName'}
              label={t('lastNameLabel')}
              placeholder={t('lastNamePlaceholder')}
              isRequired
              requiredErrorMessage={t('requiredError')}
              bottomMarting="16"
            />
            <CalendarInput
              handleCalendarValueChange={(selectedDate) =>
                setValue('driverDateOfBirth', getFormattedDate(selectedDate))
              }
            />
            <Input
              name={'street'}
              label={t('street')}
              placeholder={t('streetPlaceholder')}
              isRequired
              requiredErrorMessage={t('requiredError')}
              bottomMarting="16"
            />
            <Input
              name={'streetNumber'}
              label={t('streetNumber')}
              placeholder={t('streetNumberPlaceholder')}
              isRequired
              requiredErrorMessage={t('requiredError')}
              bottomMarting="16"
            />
            <Input
              name={'zipCode'}
              label={t('zipCode')}
              placeholder={t('zipCodePlaceholder')}
              isRequired
              requiredErrorMessage={t('requiredError')}
              bottomMarting="16"
            />
            <Input
              name={'city'}
              label={t('city')}
              placeholder={t('cityPlaceholder')}
              isRequired
              requiredErrorMessage={t('requiredError')}
              bottomMarting="16"
            />
            <UploadInput
              label={t('licenseFront')}
              name="licenseFront"
              bottomMarting="16"
            />
            <UploadInput
              label={t('licenseBack')}
              name="licenseBack"
              bottomMarting="16"
            />
            <UploadInput
              label={t('govermentIdFront')}
              name="govermentIdFront"
              bottomMarting="16"
            />
            <UploadInput
              label={t('govermentIdBack')}
              name="govermentIdBack"
              bottomMarting="16"
            />
            <Button type="submit" className="button">
              {t('requestForVerification')}
            </Button>
            <Checkbox
              text={t('termsCondition')}
              onToggle={() => setIsTermsConditionsAccepted((prev) => !prev)}
              anchor="/agb"
              error={isTermsConditionsAccepted === false}
              isChecked={isTermsConditionsAccepted}
            />
          </form>
        </FormProvider>
      </StyledContainer>
    </Layout>
  );
};
