import styled from 'styled-components/macro';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { useTranslation } from 'react-i18next';

const StyledPaymentContainer = styled.div`
  border-radius: 12px;
  border: 1px solid var(--grey-opacity);
  background: var(--grey-opacity);
  padding: 0px 10px 0px 16px;
  color: var(--black);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
`;

const StyledPaymentContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-items: flex-start;
  gap: 5px;
  padding: 8px 0px;
`;

const StyledCheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

interface VerifyCarOwnerPaymentsProps {
  handleSelectedPaymentChange: (paymentId: number) => void;
  selectedPaymentMethod: number;
}

export const VerifyCarOwnerPayments = ({
  handleSelectedPaymentChange,
  selectedPaymentMethod,
}: VerifyCarOwnerPaymentsProps) => {
  const { t } = useTranslation();
  return (
    <StyledPaymentContainer>
      <StyledPaymentContent>
        <Paragraph color="var(--black)" dimension="s">
          {t('paymentMethod')}
        </Paragraph>
        <StyledCheckboxContainer>
          <Checkbox
            text={t('invoice')}
            onToggle={() => {
              handleSelectedPaymentChange(1);
            }}
            mode="dark"
            isChecked={selectedPaymentMethod === 1}
            ckeckboxId="invoice"
          />
          <Checkbox
            text={t('paymentOnTheSpotCard')}
            onToggle={() => {
              handleSelectedPaymentChange(2);
            }}
            mode="dark"
            isChecked={selectedPaymentMethod === 2}
            ckeckboxId="paymentOnTheSpotCard"
          />
          <Checkbox
            text={t('paymentOnTheSpotCash')}
            onToggle={() => {
              handleSelectedPaymentChange(3);
            }}
            mode="dark"
            isChecked={selectedPaymentMethod === 3}
            ckeckboxId="paymentOnTheSpotCash"
          />
        </StyledCheckboxContainer>
      </StyledPaymentContent>
    </StyledPaymentContainer>
  );
};
