import { InputHTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components/macro';

type FontSize = 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
type FontWeight = 'lighter' | 'normal' | 'bolder' | 'bold';
type TextAlign = 'left' | 'center' | 'right';
type TextTransform = 'none' | 'capitalize' | 'uppercase';

export interface ParagraphProps
  extends InputHTMLAttributes<HTMLParagraphElement> {
  children?: ReactNode;
  dimension?: FontSize;
  weight?: FontWeight;
  color?: string;
  textAlign?: TextAlign;
  textTransform?: TextTransform;
}

const StyledParagraph = styled.p<ParagraphProps>`
  font-size: ${(props) => `var(--text-${props.dimension})`};
  font-weight: ${(props) => props.weight};
  color: ${(props) => `${props.color}`};
  text-align: ${(props) => props.textAlign};
  text-transform: ${(props) => props.textTransform};
  letter-spacing: 0.3px;
`;

export const Paragraph = ({
  children,
  dimension = 'm',
  weight = 'normal',
  color = 'var(--white)',
  textAlign = 'left',
  textTransform = 'none',
  ...props
}: ParagraphProps) => {
  return (
    <StyledParagraph
      dimension={dimension}
      weight={weight}
      color={color}
      textAlign={textAlign}
      textTransform={textTransform}
      {...props}
    >
      {children}
    </StyledParagraph>
  );
};
