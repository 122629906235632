import { AppWrapper } from 'AppWrapper';
import { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { ConfirmTopContent } from './ConfirmTopContent/ConfirmTopContent';
import { ConfirmContinueButton } from './ConfirmContinueButton/ConfirmContinueButton';
import { ConfirmButtonContainer } from './ConfirmButtonContainer/ConfirmButtonContainer';

interface ConfirmProps {
  title: string | ReactNode;
  subtitle?: string;
  card?: ReactNode;
  onContinueClick?: () => void;
  onConfirmClick?: () => void;
  confirmText?: string;
  onDeclineClick?: () => void;
  declineText?: string;
  confirmButtonId?: string;
  customerContent?: ReactNode;
}

const StyledContainer = styled.div`
  width: 100vw;
  height: 100%;
  background-color: var(--black-background);
  top: 0;
  padding: 20px;
  overflow-y: auto;
  .declineButton {
    background-color: var(--white-text);
    margin-right: 8px;
  }
  .confirmButton {
    margin-left: 8px;
  }
  .title {
    margin-top: 50px;
  }
`;

const StyledContent = styled.div`
  padding: 15vh 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const Confirm = ({
  title,
  subtitle,
  card,
  onContinueClick,
  onConfirmClick,
  confirmText,
  onDeclineClick,
  declineText,
  confirmButtonId,
  customerContent,
}: ConfirmProps) => {
  return (
    <StyledContainer>
      <StyledContent>
        <ConfirmTopContent
          card={card}
          customerContent={customerContent}
          title={title}
          subtitle={subtitle}
        />
        <ConfirmContinueButton
          onContinueClick={onContinueClick}
          confirmButtonId={confirmButtonId}
          confirmText={confirmText}
        />
        <ConfirmButtonContainer
          onConfirmClick={onConfirmClick}
          confirmButtonId={confirmButtonId}
          confirmText={confirmText}
          onDeclineClick={onDeclineClick}
          declineText={declineText}
        />
      </StyledContent>
      <AppWrapper />
    </StyledContainer>
  );
};
