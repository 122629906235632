import { ButtonHTMLAttributes, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ArrowLeftIcon } from '../Icons/ArrowLeftIcon/ArrowLeftIcon';
interface BackButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  as?: 'back' | 'text';
  disabled?: boolean;
  children?: ReactNode;
  textColor?: 'black' | 'white-text' | 'white';
  link?: string;
}
const Button = styled.button`
  display: flex;
  border: none;
  background: transparent;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 11px;
  left: 20px;
  z-index: 1003;
  &.withIcon {
    background: var(--white);
    padding: 9px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2));
  }
`;
const ButtonTitle = styled.span<BackButtonProps>`
  margin-left: 12px;
  color: #fff;
  text-transform: capitalize;
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
`;

export const BackButton = ({
  as,
  disabled,
  children,
  textColor = 'black',
  link,
  ...props
}: BackButtonProps) => {
  const navigate = useNavigate();
  return (
    <Button
      className={as === 'back' && 'withIcon'}
      onClick={() => (link ? navigate(link) : navigate(-1))}
      disabled={disabled}
      {...props}
    >
      {as === 'back' && <ArrowLeftIcon stroke="var(--black)" />}
      {as === 'text' && <ArrowLeftIcon stroke="var(--white-always)" />}
      {as === 'text' && (
        <ButtonTitle textColor={textColor}>{children}</ButtonTitle>
      )}
    </Button>
  );
};
