import { ParkingZone } from 'types/ParkingZone';
import { ClientAddress } from './ClientAddress/ClientAddress';

interface ClientAddressListProps {
  handleOnListClick: () => void;
  clientParkingZones?: ParkingZone[];
}
export const ClientAddressList = ({
  handleOnListClick,
  clientParkingZones,
}: ClientAddressListProps) => {
  return (
    <ul
      onClick={(e) => {
        e.preventDefault();
        handleOnListClick();
      }}
    >
      {clientParkingZones?.map((parking, i) => (
        <ClientAddress
          key={parking.id}
          parkingIndex={i}
          clientParking={parking}
        />
      ))}
    </ul>
  );
};
