import { IconProps } from '../types';

export const ArrowRightIcon = ({
  width = '9',
  height = '18',
  stroke = 'var(--green)',
  className,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 9 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.910156 16.9201L7.43016 10.4001C8.20016 9.63008 8.20016 8.37008 7.43016 7.60008L0.910156 1.08008"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
