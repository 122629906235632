import { IconButton } from 'components/IconButton/IconButton';
import { Heading } from 'components/Heading/Heading';
import { FilterIcon } from 'components/Icons/FilterIcon/FilterIcon';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
`;

interface ClientReportsHeadingProps {
  handleIsClientDetailsClose: () => void;
}

export const ClientReportsHeading = ({
  handleIsClientDetailsClose,
}: ClientReportsHeadingProps) => {
  const { t } = useTranslation();
  return (
    <StyledHeadingContainer>
      <Heading color="var(--black)" isWithPaddingBottom={false}>
        {t('clientReportsTitle')}
      </Heading>
      <IconButton onClick={handleIsClientDetailsClose}>
        <FilterIcon stroke={'var(--black)'} />
      </IconButton>
    </StyledHeadingContainer>
  );
};
