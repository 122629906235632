/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import localForage from 'localforage';

export const useUploadingFileProgress = () => {
  const [uploadedFilesCount, setUploadedFilesCount] = useState<number>(0);
  const [interval, setInterval] = useState<number>(0);

  useEffect(() => {
    setTimeout(() => {
      localForage
        .getItem('uploadedFilesAmount')
        .then(function (value) {
          setUploadedFilesCount(Number(value));
        })
        .catch(function (err) {
          console.log(err);
        });
      setInterval((prev) => prev + 1);
    }, 1000);
  }, [interval]);
  return { uploadedFilesCount };
};
