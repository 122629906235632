import { gql } from '@apollo/client';

export const UPDATE_PARKING_ZONE_GAS_STATION_IN_STORE_FINE = gql`
  mutation UpdateParkingZoneGasStationInStoreFine(
    $id: ID!
    $gasStationInStoreFineValue: Float
  ) {
    updateParkingZoneGasStationInStoreFine(
      id: $id
      gas_station_in_store_fine_value: $gasStationInStoreFineValue
    ) {
      id
      gasStationInStoreFineValue: gas_station_in_store_fine_value
    }
  }
`;
