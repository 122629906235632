import { Paragraph } from 'components/Paragraph/Paragraph';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

const StyledAccordionButton = styled.button`
  background-color: var(--grey-opacity);
  height: 61px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px;
  border-radius: 12px;
  border: none;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  .addText {
    margin-bottom: 5px;
  }
`;

interface AccordionElementButtonProps {
  addButtonTitle?: string;
  onAddButtonClick: () => void;
}

export const AccordionElementButton = ({
  addButtonTitle,
  onAddButtonClick,
}: AccordionElementButtonProps) => {
  const { t } = useTranslation();
  if (!addButtonTitle) return;
  return (
    <StyledAccordionButton onClick={onAddButtonClick}>
      <Paragraph color="var(--black)" dimension="s" className="addText">
        {t('add')}
      </Paragraph>
      <Paragraph color="var(--black)" dimension="l" weight="bolder">
        {addButtonTitle}
      </Paragraph>
    </StyledAccordionButton>
  );
};
