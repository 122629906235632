import { Status } from 'components/Status/Status';
import { ReportProps } from 'types/Report';
import { ReportDetail } from '../ReportDetail/ReportDetail';
import { useAccountType } from 'hooks/useAccountType';
import { getPaymentStatusColor } from 'utils/getPaymentStatusColor';
import { useTranslation } from 'react-i18next';
import { getCashbackStatusColor } from 'utils/getCashbackStatusColor';
import { appContext } from 'views/App';
import { useContext } from 'react';

interface PaymentInformationProps {
  report?: ReportProps;
}

export const PaymentInformation = ({ report }: PaymentInformationProps) => {
  const { isThisAccountType: isUserAccount } = useAccountType('user');
  const isNotUserAccount = !isUserAccount;
  const { isThisAccountType: isDriverAccount } = useAccountType('driver');
  const isNotDriverAcoount = !isDriverAccount;
  const { t } = useTranslation();
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;
  return (
    <>
      {isNotUserAccount && report?.carOwner?.paymentType && (
        <ReportDetail
          title="paymentMethod"
          value={
            <>
              {report?.carOwner?.paymentType === 'Invoice' && t('invoice')}
              {report?.carOwner?.paymentType === 'Payment on the spot (Cash)' &&
                t('paymentOnTheSpotCash')}
              {report?.carOwner?.paymentType ===
                'Payment on the spot (Credit Card)' &&
                t('paymentOnTheSpotCard')}
            </>
          }
        />
      )}
      {isNotUserAccount && report?.parkingZone?.user?.companyName && (
        <ReportDetail
          title="clientName"
          value={report?.parkingZone?.user?.companyName}
        />
      )}
      {isNotDriverAcoount && (
        <>
          {report?.paymentStatus && (
            <ReportDetail
              title="paymentStatus"
              customerValue={
                <Status
                  customText={t(report?.paymentStatus)}
                  color={getPaymentStatusColor(report?.paymentStatus)}
                  mode={'report'}
                  size="s"
                  className="paymentStatus"
                />
              }
            />
          )}
          {report?.carOwner?.price > 0 && (
            <ReportDetail
              title="priceCarOwner"
              value={
                <>
                  {report?.carOwner?.price?.toString().includes('.')
                    ? report?.carOwner?.price
                    : `${report?.carOwner?.price}.00`}
                  CH
                </>
              }
            />
          )}
          {userData?.cashback &&
            report?.userRewards &&
            report?.userRewards?.length > 0 &&
            report?.status != 'cancelled' && (
              <ReportDetail
                title="cashback"
                value={
                  <Status
                    customText={
                      report?.userRewards[0]?.status === 'new'
                        ? `${report?.userRewards[0]?.amount} CHF`
                        : t(report?.userRewards[0]?.status)
                    }
                    mode={'report'}
                    size="s"
                    color={getCashbackStatusColor(
                      report?.userRewards[0]?.status,
                    )}
                    className="cashbackStatus"
                  />
                }
              />
            )}
        </>
      )}
    </>
  );
};
