import { ReactNode } from 'react';
import styled from 'styled-components';
import { CloseButton } from '../CloseButton/CloseButton';
import { Paragraph } from '../Paragraph/Paragraph';
import { Button } from '../Button/Button';
import { useTranslation } from 'react-i18next';
import { Overlay } from '../Overlay/Overlay';

interface PopupProps {
  type: 'info' | 'action';
  children?: ReactNode;
  content?: string | ReactNode;
  confirmText?: string;
  declineText?: string;
  onDecline?: () => void;
  onConfirm?: () => void;
  isPopupOpened: boolean;
  isCloseButton?: boolean;
  customerContent?: ReactNode;
  isWithOutButton?: boolean;
  className?: string;
  confirmButtonId?: string;
}
const PopupWrapper = styled.div`
  position: relative;
  width: calc(100% - 48px);
  max-width: 600px;
  background: var(--white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
`;
const PopupContainer = styled.div`
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 1005;
  width: 100%;
`;
const PopupContent = styled.div`
  padding: 35px 33px 31px 33px;
`;
const PopupButtons = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0px 16px 20px 16px;
  .declineButton {
    margin-right: 17px;
  }
`;
const PopupClose = styled.div`
  position: absolute;
  right: 0;
  top: -40px;
`;
export const Popup: React.FC<PopupProps> = ({
  type,
  children,
  content,
  confirmText = 'confirm',
  declineText = 'decline',
  onDecline,
  onConfirm,
  isPopupOpened = false,
  isCloseButton = true,
  customerContent,
  isWithOutButton = false,
  className,
  confirmButtonId,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      {isPopupOpened && (
        <div>
          <PopupContainer className={className}>
            <PopupWrapper>
              {isCloseButton && (
                <PopupClose>
                  <CloseButton onClick={onDecline} />
                </PopupClose>
              )}
              <PopupContent>
                {content ? (
                  <Paragraph
                    dimension="xl"
                    color="var(--black)"
                    textAlign="center"
                  >
                    {content}
                  </Paragraph>
                ) : (
                  <></>
                )}
                {customerContent}
              </PopupContent>
              <PopupButtons>
                {type === 'action' && (
                  <Button
                    width="100%"
                    mode="inverted"
                    onBtnClick={onDecline}
                    className="declineButton"
                  >
                    {t(declineText)}
                  </Button>
                )}
                {!isWithOutButton && (
                  <Button
                    width="100%"
                    mode={type === 'action' ? 'default' : 'inverted'}
                    onBtnClick={type === 'action' ? onConfirm : onDecline}
                    id={confirmButtonId}
                  >
                    {t(confirmText)}
                  </Button>
                )}
              </PopupButtons>
              {children}
            </PopupWrapper>
          </PopupContainer>
          <Overlay />
        </div>
      )}
    </div>
  );
};
