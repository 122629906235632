import { gql } from '@apollo/client';

export const REPORTS = gql`
  query Reports(
    $order: SortOrder!
    $page: Int
    $first: Int!
    $status: [String]
    $type: [String]
    $idOrPlate: String
  ) {
    reports(
      orderBy: [{ column: CREATED_AT, order: $order }]
      page: $page
      first: $first
      status: $status
      type: $type
      idOrPlate: $idOrPlate
    ) {
      data {
        id
        type
        hash
        plate
        carName: car_name
        status
        street
        streetNumber: street_number
        zipCode: zip_code
        city
        signature
        createdAt: created_at
        updated_at
        driverMap {
          driver {
            firstName: first_name
            lastName: last_name
            avatar
          }
        }
        photos {
          photo
        }
        user {
          firstName: first_name
          lastName: last_name
          companyName: company_name
        }
        parkingZone {
          name
          user {
            id
            firstName: first_name
            lastName: last_name
            companyName: company_name
          }
        }
        userRewards {
          amount
          status
        }
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;
