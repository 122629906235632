import { Card } from 'components/Cards/Card/Card';
import { UserInfo } from 'components/UserInfo/UserInfo';
import styled from 'styled-components/macro';
import successImg from 'assets/img/success.png';

interface StyledSuccessVerifyCardProps {
  background?: string;
}

const StyledSuccessVerifyCard = styled.div<StyledSuccessVerifyCardProps>`
  padding: 18px 24px;
  &:after {
    content: '';
    position: absolute;
    background: ${(props) => `url(${props.background}) no-repeat top center`};
    background-size: contain;
    top: 20px;
    right: 20px;
    width: 141px;
    height: 141px;
  }
`;

export const SuccessVerifyCard = () => {
  return (
    <Card>
      <StyledSuccessVerifyCard background={successImg}>
        <UserInfo layout={'horizontal-column'} color="var(--black)" />
      </StyledSuccessVerifyCard>
    </Card>
  );
};
