import ContentLoader from 'react-content-loader';

export const UserNameSkeleton = () => (
  <ContentLoader width="140" height="20" animate opacity={0.2}>
    <rect x="0" y="2.5" rx="4" ry="4" width="65" height="15" />{' '}
    <rect x="75" y="2.5" rx="4" ry="4" width="65" height="15" />
  </ContentLoader>
);
export const UserRoleSkeleton = () => (
  <ContentLoader width="90" height="17.6" animate opacity={0.2}>
    <rect x="0" y="2.5" rx="4" ry="4" width="100%" height="13" />
  </ContentLoader>
);
