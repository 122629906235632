import { gql } from '@apollo/client';

export const UPDATE_PARKING_ZONE_GAS_STATION_FINE = gql`
  mutation UpdateParkingZoneGasStationFine(
    $id: ID!
    $gasStationFineValue: Float
  ) {
    updateParkingZoneGasStationFine(
      id: $id
      gas_station_fine_value: $gasStationFineValue
    ) {
      id
      gasStationFineValue: gas_station_fine_value
    }
  }
`;
