import { IconProps } from '../types';
export const AddIcon = ({
  size = '52',
  stroke = 'var(--white)',
  fill = 'var(--green)',
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 53 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="26.5" cy="26" r="26" fill={fill} />
      <path
        d="M19 26H35"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27 34V18"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
