import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import person from 'assets/img/person.svg';
import { User } from 'types/User';
import { ClientMainInformation } from './ClientMainInformation/ClientMainInformation';
import { ClientParkingZones } from './ClientParkingZones/ClientParkingZones';
import { ClientCashbackStatus } from './ClientCashbackStatus/ClientCashbackStatus';
import { ClientContactInformation } from './ClientContactInformation/ClientContactInformation';

const StyledClient = styled.li`
  border: 1px solid var(--grey-light);
  border-radius: 16px;
  margin-bottom: 16px;
  padding: 16px;
  :last-child {
    margin-bottom: 0;
  }
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
`;

const StyledAvatarImg = styled.img`
  position: relative;
  z-index: 1;
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  object-fit: cover;
  border-radius: 50%;
  background: ${(props) => props.theme.primary};
  margin-right: 10%;
  margin-left: 3%;
`;
const StyledClientInfo = styled.div`
  position: relative;
  width: 100%;
`;

interface ClientListItemProps {
  client: User;
}

export const ClientListItem = ({ client }: ClientListItemProps) => {
  const avatar = client?.avatar === 'no photo' ? person : client?.avatar;
  return (
    <StyledClient>
      <StyledLink to={`/reports/${client.id}`}>
        <StyledAvatarImg src={avatar} alt="Client Image" />
        <StyledClientInfo>
          <ClientMainInformation
            clientId={client.id}
            clientFirstName={client.firstName}
            clientLastName={client.lastName}
            clientCompanyName={client?.companyName}
          />
          <ClientParkingZones clientParkingZones={client.parkingZones} />
          <ClientContactInformation
            clientPhoneNumber={`${client?.phonePrefix} ${client?.phoneNumber}`}
          />
          <ClientCashbackStatus clientCashback={client?.cashback} />
        </StyledClientInfo>
      </StyledLink>
    </StyledClient>
  );
};
