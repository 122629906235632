import { gql } from '@apollo/client';

export const UPDATE_DRIVER_LOCALIZATION = gql`
  mutation UpdateDriverLocalization($latitude: Float!, $longitude: Float!) {
    updateDriverLocalization(latitude: $latitude, longitude: $longitude) {
      id
      latitude
      longitude
      createdAt: created_at
      updatedAt: updated_at
      driver {
        id
      }
    }
  }
`;
