import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { useState, useEffect } from 'react';
import { getCookie } from 'utils/getCookie';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';

const { link } = createNetworkStatusNotifier();

export const useProvideAuth = () => {
  const [authToken, setAuthToken] = useState(null);
  const [isSignedIn, setIsSignedIn] = useState(null);
  useEffect(() => {
    if (authToken) return;
    const sessionCookie = getCookie('PHPSESSID');
    setAuthToken(sessionCookie);

    if (sessionCookie) {
      setIsSignedIn(true);
    } else {
      setIsSignedIn(false);
    }
  }, []);

  const getAuthHeaders = () => {
    if (!authToken) return null;
    return {
      Authorization: `Bearer ${authToken}`,
      SwVersion: process.env.REACT_APP_SW_VERSION,
    };
  };

  const createApolloClient = () => {
    return new ApolloClient({
      link: link.concat(
        createHttpLink({
          uri: process.env.REACT_APP_GRAPHQL,
          headers: getAuthHeaders(),
        }),
      ),
      cache: new InMemoryCache({
        addTypename: false,
      }),
    });
  };

  return {
    authToken,
    setAuthToken,
    isSignedIn,
    setIsSignedIn,
    createApolloClient,
  };
};
