export const circulationCompensation = {
  roadSignTitle: 'Richterliches Verbot',
  roadSignTextContent:
    'Unberechtigtes Parkieren wird gebüsst nach den Regeln des Strassenverkehrsgesetzes. Zuwiederhandlungen gegen dieses so markierte Parkierverbot werden nach den im Strassenverkehrsgesetz vorgesehenen Sanktionen geahndet.',
  circulationCompensationBottomTitle: 'Rechtliches',
  circulationCompensationBottomText:
    'Das Bundesgericht hat die rechtliche Grundlage für das Ausstellen von Umtriebsentschädigungen geschaffen.',
  circulationCompensationFirstTitle:
    'Sehr geehrte Fahrzeuglenkerin, sehr geehrter Fahrzeuglenker,',
  circulationCompensationFirstText:
    'unser Parkplatzwächter hat festgestellt, dass Sie Ihr Fahrzeug unberechtigt auf einem Privatparkplatz abgestellt haben, für das ein richterliches Parkverbot besteht. Aufgrund der dadurch entstandenen Ärger und Umtriebe wird Ihnen eine Umtriebsentschädigung auferlegt. \n\n Wir möchten darauf hinweisen, dass eine Nichtbezahlen dieser Umtriebsentschädigung zusätzliche Kosten nach sich ziehen kann ggf. ein Strafverfahren eingeleitet wird.',
  circulationCompensationSecondTitle:
    'Auskunftssperre Fahrzeughalter / Ausländische Fahrzeuge',
  circulationCompensationSecondText:
    ' Sofern Ihre Fahrzeughalterdaten beim Strassenverkehrsamt mit einer Auskunftsperre versehen sind oder Sie ein Fahrzeug mit ausländischen Kennzeichen besitzen, bitten wir Sie darum, uns Ihre Adresse unter Angabe des Fahrzeugkennzeichens an die folgende Emailadresse ue@falsch-parker.ch innert zwei Tagen zu senden.\n\nAndernfalls behalten wir uns vor, Fehlverhalten direkt bei den Strafverfolgungsbehörden zu melden.\n\nVielen Dank für Ihre Kenntnisnahme. \n Falsch-Parker.ch',
};
