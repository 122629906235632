import { SearchBar } from 'components/SearchBar/SearchBar';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

const StyledTopBar = styled.div`
  padding-top: 50px;
`;

interface TopBarProps {
  handleCashbackOnStatus: (status?: boolean) => void;
  handleStatusChange: (status?: string) => void;
  handleSearchValueChange: (searchValue?: string) => void;
}
export const TopBar = ({
  handleCashbackOnStatus,
  handleStatusChange,
  handleSearchValueChange,
}: TopBarProps) => {
  const methods = useForm();
  const { watch } = methods;
  useEffect(() => {
    handleSearchValueChange(watch('search'));
  }, [watch('search')]);
  return (
    <StyledTopBar>
      <FormProvider {...methods}>
        <SearchBar
          handleStatus={(status) => {
            handleStatusChange(status);
            if (status === 'all') {
              handleCashbackOnStatus(undefined);
            } else if (status === 'cashbackOn') {
              handleCashbackOnStatus(true);
            } else {
              handleCashbackOnStatus(false);
            }
          }}
          isWithStatusDropdown={true}
          isClientsList={true}
        />
      </FormProvider>
    </StyledTopBar>
  );
};
