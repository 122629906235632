import { useLazyQuery } from '@apollo/client';
import { ReportList } from 'components/ReportList/ReportList';
import { REPORTS } from 'graphql/queries/reports';
import { useContext, useEffect, useState } from 'react';
import { NoReportsYet } from '../NoReportsYet/NoReportsYet';
import { GAS_STATION_REPORTS } from 'graphql/queries/gasStationReports';
import { ReportProps } from 'types/Report';
import * as Sentry from '@sentry/react';
import {
  ACTIVE_STATUS_INDEXES,
  ALL_STATUS_INDEXES,
  GAS_STATION_STATUS_INDEXES,
  INACTIVE_STATUS_INDEXES,
} from 'data/statusIndexes';
import { appContext } from 'views/App';

interface ReportsHistoryProps {
  order?: string;
  status?: string;
  searchValue?: string;
  isActiveStatuses?: boolean;
  isDeactiveStatuses?: boolean;
  isTickets?: boolean;
  isReportsListEnd?: boolean;
  isGasStation?: boolean;
}

export const SuperAdminReportsHistory = ({
  order,
  status,
  searchValue,
  isActiveStatuses = false,
  isDeactiveStatuses = false,
  isTickets = false,
  isGasStation,
  isReportsListEnd,
}: ReportsHistoryProps) => {
  const appContextStore = useContext(appContext);
  const setIsError = appContextStore?.setIsError;
  const setIsLoading = appContextStore?.setIsLoading;

  const [reportsData, setReportsData] = useState<ReportProps[] | undefined>(
    undefined,
  );

  const [reports, { loading, fetchMore }] = useLazyQuery(REPORTS, {
    fetchPolicy: 'network-only',
    onError: (error) => {
      Sentry.captureException(error);
      setReportsData([]);
      setIsError(true);
      throw new Error(error.message);
    },
    onCompleted: (data) => {
      const reports = data.reports.data;
      setHasMorePages(data.reports.paginatorInfo.hasMorePages);
      setReportsData(reports);
    },
  });
  const [
    gasStationReports,
    {
      loading: loadingGasStationReports,
      fetchMore: fetchMoreGasStationReports,
    },
  ] = useLazyQuery(GAS_STATION_REPORTS, {
    fetchPolicy: 'network-only',
    onError: (error) => {
      Sentry.captureException(error);
      setReportsData([]);
      setIsError(true);
      throw new Error(error.message);
    },
    onCompleted: (data) => {
      const reports = data.gasStationReports.data;
      setHasMorePages(data.gasStationReports.paginatorInfo.hasMorePages);
      setReportsData(reports);
    },
  });

  const [page, setPage] = useState<number>(1);
  const [hasMorePages, setHasMorePages] = useState<boolean>(true);
  const [statusIndexs, setStatusIndexs] =
    useState<string[]>(ALL_STATUS_INDEXES);

  useEffect(() => {
    isActiveStatuses && setStatusIndexs(ACTIVE_STATUS_INDEXES);
    isDeactiveStatuses && setStatusIndexs(INACTIVE_STATUS_INDEXES);
    isTickets && setStatusIndexs(ALL_STATUS_INDEXES);
    isGasStation && setStatusIndexs(GAS_STATION_STATUS_INDEXES);
  }, [isActiveStatuses, isDeactiveStatuses, isTickets]);

  useEffect(() => {
    if (!order) return;
    setPage(1);
    if (isGasStation) {
      gasStationReports({
        variables: {
          order,
          first: 5,
          page: 1,
          idOrPlate: searchValue?.toUpperCase(),
          paymentStatus: status ? [status] : statusIndexs,
        },
      });
    } else {
      reports({
        variables: {
          order,
          first: 5,
          page: 1,
          status: status ? [status] : statusIndexs,
          type: [isTickets ? '2' : '1'],
          idOrPlate: searchValue?.toUpperCase(),
        },
      });
    }
  }, [order, isGasStation, status, statusIndexs, searchValue, isTickets]);

  useEffect(() => {
    if (!isReportsListEnd) return;
    if (hasMorePages) {
      setIsLoading(true);
      if (isGasStation) {
        fetchMoreGasStationReports({
          variables: {
            order,
            first: 5,
            page: page + 1,
            idOrPlate: searchValue?.toUpperCase(),
            paymentStatus: status ? [status] : statusIndexs,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            setIsLoading(false);
            setPage((prev) => prev + 1);
            setReportsData((prev) => [
              ...prev,
              ...fetchMoreResult.gasStationReports.data,
            ]);
            setHasMorePages(
              fetchMoreResult.gasStationReports.paginatorInfo.hasMorePages,
            );
          },
        });
      } else {
        fetchMore({
          variables: {
            order,
            first: 5,
            page: page + 1,
            status: status ? [status] : statusIndexs,
            type: [isTickets ? '2' : '1'],
            idOrPlate: searchValue?.toUpperCase(),
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            setIsLoading(false);
            setPage((prev) => prev + 1);
            setReportsData((prev) => [
              ...prev,
              ...fetchMoreResult.reports.data,
            ]);
            setHasMorePages(fetchMoreResult.reports.paginatorInfo.hasMorePages);
          },
        });
      }
    }
  }, [isReportsListEnd]);

  useEffect(() => {
    setIsLoading(loading || loadingGasStationReports);
  }, [loading, loadingGasStationReports]);

  if (!reportsData?.length && !loading) return <NoReportsYet />;
  return <ReportList reportList={reportsData} />;
};
