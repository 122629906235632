import { useEffect, useState, MouseEvent, useRef, useContext } from 'react';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar, Day } from '@hassanmojab/react-modern-calendar-datepicker';
import { Input } from '../Input/Input';
import { useTranslation } from 'react-i18next';
import { CalendarIcon } from '../Icons/CalendarIcon/CalendarIcon';
import styled from 'styled-components';
import { useClickOutside } from 'hooks/useClickOutside';
import { appContext } from 'views/App';

interface CalendarInputProps {
  handleCalendarValueChange?: (selectedDate?: Day) => void;
}
interface StyledCalendarContainer {
  isCalendarOpened?: boolean;
}

const StyledCalendarContainer = styled.div<StyledCalendarContainer>`
  position: absolute;
  right: 24px;
  display: ${({ isCalendarOpened }) => (isCalendarOpened ? 'block' : 'none')};
  .calendar {
    button {
      color: #000;
    }
  }
`;

export const CalendarInput = ({
  handleCalendarValueChange,
}: CalendarInputProps) => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;

  const calendarRef = useRef(null);
  const { t } = useTranslation();
  const [isCalendarOpened, setIsCalendarOpened] = useState<boolean>(false);
  const [selectedDay, setSelectedDay] = useState<Day | null>(null);

  useEffect(() => {
    handleCalendarValueChange(selectedDay);
  }, [selectedDay]);

  const handleCalendarClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setIsCalendarOpened((prevIsCalendarOpened) => !prevIsCalendarOpened);
  };

  useClickOutside(calendarRef, () => {
    setIsCalendarOpened(false);
  });

  const handleCalendarChange = (value: Day) => {
    setSelectedDay(value);
    setIsCalendarOpened(false);
  };

  return (
    <div ref={calendarRef}>
      <Input
        name={'driverDateOfBirth'}
        label={t('dateOfBirth')}
        isRequired
        requiredErrorMessage={t('requiredError')}
        icon={<CalendarIcon />}
        onIconClick={(e: MouseEvent<HTMLElement>) => handleCalendarClick(e)}
        type="date"
        bottomMarting="16"
      />
      <StyledCalendarContainer isCalendarOpened={isCalendarOpened}>
        <Calendar
          value={selectedDay}
          onChange={handleCalendarChange}
          colorPrimary={userData?.appSettings?.primary_color || 'var(--green)'}
          calendarClassName="calendar"
        />
      </StyledCalendarContainer>
    </div>
  );
};
