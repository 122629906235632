import { IconProps } from '../types';

export const EditGasStationIcon = ({
  size = '24',
  fill = 'var(--black)',
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2188 19.5312C9.92641 19.5312 8.875 18.4798 8.875 17.1875C8.875 16.8657 8.96691 16.2795 9.76172 14.9094C10.1632 14.2173 10.5593 13.6426 10.5759 13.6185C10.7218 13.4073 10.9621 13.2812 11.2188 13.2812C11.4754 13.2812 11.7157 13.4073 11.8616 13.6185C11.8782 13.6426 12.2743 14.2173 12.6758 14.9094C13.4706 16.2795 13.5625 16.8657 13.5625 17.1875C13.5625 18.4798 12.5111 19.5312 11.2188 19.5312ZM11.2188 15.5142C11.185 15.5707 11.151 15.6285 11.117 15.687C10.4544 16.828 10.4379 17.1742 10.4375 17.1884C10.4375 17.6183 10.788 17.9688 11.2188 17.9688C11.6493 17.9688 11.9996 17.6186 12 17.1881C11.9994 17.1702 11.9792 16.8197 11.3157 15.6788C11.2833 15.623 11.2509 15.5681 11.2188 15.5142Z"
        fill={fill}
      />
      <path
        d="M12.7812 5.625H9.65625C8.36391 5.625 7.3125 6.67641 7.3125 7.96875V9.53125C7.3125 10.8236 8.36391 11.875 9.65625 11.875H12.7812C14.0736 11.875 15.125 10.8236 15.125 9.53125V7.96875C15.125 6.67641 14.0736 5.625 12.7812 5.625ZM13.5625 9.53125C13.5625 9.96203 13.212 10.3125 12.7812 10.3125H9.65625C9.22547 10.3125 8.875 9.96203 8.875 9.53125V7.96875C8.875 7.53797 9.22547 7.1875 9.65625 7.1875H12.7812C13.212 7.1875 13.5625 7.53797 13.5625 7.96875V9.53125Z"
        fill={fill}
      />
      <path
        d="M20.2309 8.06355L18.25 6.08266V5.625C18.25 3.90188 16.8481 2.5 15.125 2.5H7.3125C5.58938 2.5 4.1875 3.90188 4.1875 5.625V20.9375H3.40625C2.97477 20.9375 2.625 21.2873 2.625 21.7188C2.625 22.1502 2.97477 22.5 3.40625 22.5H19.0312C19.4627 22.5 19.8125 22.1502 19.8125 21.7188C19.8125 21.2873 19.4627 20.9375 19.0312 20.9375H18.25V19.3969C18.4945 19.4836 18.7574 19.5312 19.0312 19.5312C20.3236 19.5312 21.375 18.4798 21.375 17.1875V10.8257C21.375 9.78227 20.9687 8.80133 20.2309 8.06355ZM19.8125 17.1875C19.8125 17.6183 19.462 17.9688 19.0312 17.9688C18.6005 17.9688 18.25 17.6183 18.25 17.1875V13.4375H19.8125V17.1875ZM19.8125 11.875H17.4688C17.0373 11.875 16.6875 12.2248 16.6875 12.6562V20.9375H5.75V5.625C5.75 4.76344 6.45094 4.0625 7.3125 4.0625H15.125C15.9866 4.0625 16.6875 4.76344 16.6875 5.625V9.53125C16.6875 9.96273 17.0373 10.3125 17.4688 10.3125C17.9002 10.3125 18.25 9.96273 18.25 9.53125V8.29234L19.126 9.16836C19.5687 9.61102 19.8125 10.1996 19.8125 10.8256V11.875Z"
        fill={fill}
      />
    </svg>
  );
};
