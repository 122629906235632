import { gql } from '@apollo/client';

export const UPDATE_PARKING_ZONE_TOWING_CASHBACK = gql`
  mutation UpdateParkingZoneTowingCashback(
    $id: ID!
    $towingCashbackValue: Float
  ) {
    updateParkingZoneTowingCashback(
      id: $id
      towing_cashback_value: $towingCashbackValue
    ) {
      id
      towingCashbackValue: towing_cashback_value
    }
  }
`;
