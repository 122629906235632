import { gql } from '@apollo/client';

export const ME = gql`
  query {
    me {
      id
      firstName: first_name
      lastName: last_name
      email
      rank
      phonePrefix: phone_prefix
      phoneNumber: phone_number
      refCode: ref_code
      avatar
      verified
      companyName: company_name
      companyAddress: company_address
      cashback
      gasStationUser: gas_station_user
      qrCode: qr_code
      billingInformations {
        id
        name
        bankName: bank_name
        accountName: account_number
        iban
      }
      parkingZones {
        id
        type
        name
        fullAddress: address_full
        streetName: street_name
        streetNumber: street_number
        zipCode: zip_code
        city
        latitude
        longitude
        signature
        contract
      }
      appSettings {
        id
        primary_color
        secondary_color
        logo
      }
    }
  }
`;
