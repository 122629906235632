import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(input: { username: $email, password: $password }) {
      accessToken: access_token
      user {
        id
        firstName: first_name
        lastName: last_name
        email
        rank
        phonePrefix: phone_prefix
        phoneNumber: phone_number
        refCode: ref_code
        avatar
        verified
        companyName: company_name
        companyAddress: company_address
      }
    }
  }
`;
