import { gql } from '@apollo/client';

export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword(
    $oldPassword: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    updatePassword(
      input: {
        old_password: $oldPassword
        password: $password
        password_confirmation: $passwordConfirmation
      }
    ) {
      status
      message
    }
  }
`;
