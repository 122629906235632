import { gql } from '@apollo/client';

export const ADD_PARKING_ZONE_PHOTOS = gql`
  mutation AddParkingZonePhotos($parkingZoneId: Int!, $images: [Upload!]) {
    addParkingZonePhotos(parking_zone_id: $parkingZoneId, images: $images) {
      id
      photo
    }
  }
`;
