import { Heading } from 'components/Heading/Heading';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { BillingInformation as BillingInformationType } from 'types/general';

const StyledBillingInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 0 8px;
`;
const StyledBillingInformationItem = styled.div`
  width: 100%;
`;

interface BillingInformationProps {
  userBillingInformation?: BillingInformationType;
}

export const BillingInformation = ({
  userBillingInformation,
}: BillingInformationProps) => {
  const { t } = useTranslation();
  if (!userBillingInformation) return;
  return (
    <StyledBillingInformation>
      <Heading color={'var(--black)'} isWithPaddingBottom={false}>
        {t('bankAccount')}
      </Heading>
      <StyledBillingInformationItem>
        <Paragraph color={'var(--sub--black)'} dimension="s">
          {t('bankAccountName')}
        </Paragraph>
        <Paragraph color={'var(--black)'}>
          {userBillingInformation?.name}
        </Paragraph>
      </StyledBillingInformationItem>
      <StyledBillingInformationItem>
        <Paragraph color={'var(--sub--black)'} dimension="s">
          {t('bankName')}
        </Paragraph>
        <Paragraph color={'var(--black)'}>
          {userBillingInformation?.bankName}
        </Paragraph>
      </StyledBillingInformationItem>
      <StyledBillingInformationItem>
        <Paragraph color={'var(--sub--black)'} dimension="s">
          {t('iban')}
        </Paragraph>
        <Paragraph color={'var(--black)'}>
          {userBillingInformation?.iban}
        </Paragraph>
      </StyledBillingInformationItem>
    </StyledBillingInformation>
  );
};
