import { IconProps } from '../types';
export const RetakeIcon = ({
  width = '28',
  height = '24',
  fill = 'var(--green)',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.666 2.66667H20.4394L17.9993 0H9.99935L7.55935 2.66667H3.33268C2.62544 2.66667 1.94716 2.94762 1.44706 3.44772C0.946967 3.94781 0.666016 4.62609 0.666016 5.33333V21.3333C0.666016 22.0406 0.946967 22.7189 1.44706 23.219C1.94716 23.719 2.62544 24 3.33268 24H24.666C25.3733 24 26.0515 23.719 26.5516 23.219C27.0517 22.7189 27.3327 22.0406 27.3327 21.3333V5.33333C27.3327 4.62609 27.0517 3.94781 26.5516 3.44772C26.0515 2.94762 25.3733 2.66667 24.666 2.66667ZM24.666 21.3333H3.33268V5.33333H8.73268L11.1727 2.66667H16.826L19.3327 5.33333H24.666V21.3333ZM13.9993 20C12.5593 20 11.146 19.5333 9.99935 18.6667L11.9193 16.7467C12.546 17.1333 13.266 17.3333 13.9993 17.3333C15.0602 17.3333 16.0776 16.9119 16.8278 16.1618C17.5779 15.4116 17.9993 14.3942 17.9993 13.3333C17.9993 12.2725 17.5779 11.2551 16.8278 10.5049C16.0776 9.75476 15.0602 9.33333 13.9993 9.33333C12.3193 9.33333 10.7993 10.4 10.2393 12H12.666L8.66602 16L4.66602 12H7.46602C7.64046 11.1417 7.98243 10.3262 8.47235 9.6002C8.96227 8.87418 9.59052 8.25189 10.3212 7.76891C11.0518 7.28594 11.8705 6.95177 12.7305 6.78551C13.5904 6.61925 14.4747 6.62418 15.3327 6.8C16.9529 7.12899 18.3931 8.04827 19.3736 9.37937C20.3541 10.7105 20.8051 12.3584 20.639 14.0033C20.4728 15.6482 19.7014 17.1727 18.4745 18.2808C17.2475 19.389 15.6526 20.0017 13.9993 20Z"
        fill={fill}
      />
    </svg>
  );
};
