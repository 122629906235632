import bankImg from 'assets/img/bank.png';
import { Accordion } from 'components/Accordion/Accordion';
import { RedeemDetailsCard } from 'components/Cards/RedeemDetailsCard/RedeemDetailsCard';
import { Layout } from 'components/Layout/Layout';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ParkingAndLicensesImg } from 'styles/common';
import { appContext } from 'views/App';

const StyledContainer = styled.div`
  padding-top: 75px;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 580px;
`;

export const BankTransfer = () => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Layout
      topContent={<RedeemDetailsCard />}
      topContentPosition="center"
      isBackBtn
      backButtonMode="text"
      viewTitle={t('bankTransfer')}
      customerTopHeight={150}
      backBtnLink="/cashback"
    >
      <StyledContainer>
        <Accordion
          title={t('bankAccount')}
          icon={<ParkingAndLicensesImg src={bankImg} alt="Bank" />}
          isAccordionOpened={true}
          elements={
            userData?.billingInformations[0] &&
            userData?.billingInformations.map((bank) => bank.name)
          }
          elementTitle={t('bankName')}
          addButtonTitle={!userData?.billingInformations[0] && t('newBank')}
          onAddButtonClick={() => navigate('/bank-details')}
          isAllElementClick={true}
          onClick={() =>
            navigate(
              `/edit-bank-details/${userData?.billingInformations[0]?.id}`,
            )
          }
        />
      </StyledContainer>
    </Layout>
  );
};
