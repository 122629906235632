import { IconProps } from '../types';

export const FilterIcon = ({ size = '25', stroke }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1143 7H16.1143"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.11429 7H2.11429"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1143 10.5C12.0473 10.5 13.6143 8.933 13.6143 7C13.6143 5.067 12.0473 3.5 10.1143 3.5C8.18129 3.5 6.61429 5.067 6.61429 7C6.61429 8.933 8.18129 10.5 10.1143 10.5Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.1143 18H18.1143"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.11429 18H2.11429"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1143 21.5C16.0473 21.5 17.6143 19.933 17.6143 18C17.6143 16.067 16.0473 14.5 14.1143 14.5C12.1813 14.5 10.6143 16.067 10.6143 18C10.6143 19.933 12.1813 21.5 14.1143 21.5Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
