import { BackButton } from 'components/BackButton/BackButton';
import { MediaNav } from 'components/MediaNav/MediaNav';
import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { appContext } from 'views/App';

const StyledContainer = styled.div`
  width: 100vw;
  height: 100%;
`;
const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const PhotoPreview = () => {
  const appContextStore = useContext(appContext);
  const reportDetails = appContextStore?.reportDetails;
  const setReportDetails = appContextStore?.setReportDetails;

  const { photo } = useParams();
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!reportDetails) return;
    if (!selectedImage) {
      const image = reportDetails.images.find((image) => image.name === photo);
      setSelectedImage(image);
    }
  }, [reportDetails]);

  const handleRemoveClick = () => {
    const images = reportDetails.images.filter(
      (image) => image !== selectedImage,
    );
    setReportDetails({ ...reportDetails, images });
    navigate(-1);
  };

  const handleRetakeClicl = (image: File) => {
    if (image) {
      setSelectedImage(image);
      const images = reportDetails.images.filter(
        (image) => image !== selectedImage,
      );
      setReportDetails({
        ...reportDetails,
        images: [...images, image],
      });
    }
  };

  if (!selectedImage) return;
  return (
    <StyledContainer>
      <BackButton as="back" />
      <StyledImage
        src={selectedImage ? URL.createObjectURL(selectedImage) : ''}
      />
      <MediaNav
        onRetakeClick={(image) => handleRetakeClicl(image)}
        onDeleteClick={handleRemoveClick}
      />
    </StyledContainer>
  );
};
