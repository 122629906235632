import { ReactNode } from 'react';
import { ConfrimHeading } from '../ConfirmHeading/ConfirmHeading';
import styled from 'styled-components/macro';

const StyledTopContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
`;

interface ConfirmTopContentProps {
  card: ReactNode;
  title: string | ReactNode;
  subtitle: string;
  customerContent?: ReactNode;
}
export const ConfirmTopContent = ({
  card,
  title,
  subtitle,
  customerContent,
}: ConfirmTopContentProps) => (
  <StyledTopContent>
    {card}
    <ConfrimHeading title={title} subtitle={subtitle} />
    {customerContent}
  </StyledTopContent>
);
