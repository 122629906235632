import { Button } from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';

const StyledButtonsContainer = styled.div`
  display: flex;
  margin-top: 16px;
  .button {
    :first-child {
      margin-right: 8px;
    }
    :last-child {
      margin-left: 8px;
    }
  }
`;

interface ContractSignatureButtonContainerProps {
  handleNextClick: () => void;
  isVerifyCarOwnerPage?: boolean;
}

export const ContractSignatureButtonContainer = ({
  handleNextClick,
  isVerifyCarOwnerPage,
}: ContractSignatureButtonContainerProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <StyledButtonsContainer>
      <Button
        mode="inverted"
        onBtnClick={() => navigate(-1)}
        className="button"
      >
        {t('cancelButton')}
      </Button>
      <Button
        onBtnClick={handleNextClick}
        className="button"
        id={
          isVerifyCarOwnerPage
            ? 'createCarOwnerSignatureButton'
            : 'contractSignature'
        }
      >
        {t('next')}
      </Button>
    </StyledButtonsContainer>
  );
};
