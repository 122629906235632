import { SuccessView } from 'views/ParkingTickets/SuccessView/SuccessView';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PaymentFailed } from './PaymentFailed/PaymentFailed';
import { useTranslation } from 'react-i18next';

export const PaymentFeedback = () => {
  const { t } = useTranslation();
  const params = useParams();
  const [paymentStatus, setPaymentStatus] = useState<string | undefined>(
    undefined,
  );
  useEffect(() => {
    if (params?.paymentStatus) {
      setPaymentStatus(params.paymentStatus);
    }
  }, [params]);
  return (
    <>
      {paymentStatus === 'success' ? (
        <SuccessView
          title={t('successPaymentTitle')}
          subtitle={t('successPaymentSubtitle')}
        />
      ) : (
        <PaymentFailed />
      )}
    </>
  );
};
