import { gql } from '@apollo/client';

export const CREATE_USER_BILLING_INFORMATION = gql`
  mutation CreateUserBillingInformation(
    $name: String!
    $bankName: String!
    $accountNumber: String!
    $iban: String!
  ) {
    createUserBillingInformation(
      input: {
        name: $name
        bank_name: $bankName
        account_number: $accountNumber
        iban: $iban
      }
    ) {
      id
    }
  }
`;
