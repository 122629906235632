export const getStatusIndex = (status: string) => {
  switch (status) {
    case 'new':
      return '1';
    case 'accepted':
      return '2';
    case 'inProgress':
      return '3';
    case 'towedAway':
      return '4';
    case 'finished':
      return '5';
    case 'emptyRun':
      return '6';
    case 'cancelled':
      return '7';
    case 'issued':
      return '8';
  }
};
