export const getRole = (rank: string): string => {
  switch (rank) {
    case '1':
      return 'user';
    case '4':
      return 'driver';
    case '6':
      return 'superAdmin';
  }
};
