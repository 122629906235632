import { Heading } from 'components/Heading/Heading';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

const StyledParagraph = styled(Paragraph)`
  margin-bottom: 16px;
`;

export const ContractSignatureHeading = () => {
  const { t } = useTranslation();
  return (
    <>
      <Heading color="var(--black)" weight="bold">
        {t('inputSignature')}
      </Heading>
      <StyledParagraph color="var(--black)" dimension="s">
        {t('signatureInfo')}
      </StyledParagraph>
    </>
  );
};
