import { ParkingTicketsLayout } from 'views/ParkingTickets/ParkingTicketsLayout/ParkingTicketsLayout';
import styled from 'styled-components';
import successCircle from 'assets/img/success-circle.svg';
import { Heading } from 'components/Heading/Heading';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { device } from 'styles/devices';

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  min-height: calc(100vh - 80px - 60px);
  @media ${device.laptop} {
    min-height: calc(100vh - 247px - 92px);
    h2 {
      font-size: 40px;
    }
    p {
      font-size: 20px;
    }
  }
`;
const StyledImage = styled.img`
  margin-bottom: 15px;
  width: 200px;
  height: 200px;
  margin: 0 auto;
`;
const StyledMainContent = styled.div`
  margin: auto 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
interface SuccessViewProps {
  title: string;
  subtitle?: string;
  handleButtonClick?: () => void;
}
export const SuccessView = ({ title, subtitle }: SuccessViewProps) => {
  return (
    <ParkingTicketsLayout>
      <StyledContent>
        <StyledMainContent>
          <StyledImage src={successCircle} alt="Success" />
          <Heading
            color="var(--black)"
            textAlign="center"
            dimension="xl"
            isWithPaddingBottom={false}
          >
            {title}
          </Heading>
          {subtitle && (
            <Paragraph color="var(--black)" textAlign="center" dimension="l">
              {subtitle}
            </Paragraph>
          )}
        </StyledMainContent>
      </StyledContent>
    </ParkingTicketsLayout>
  );
};
