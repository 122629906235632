import { gql } from '@apollo/client';

export const UPDATE_PARKING_ZONE_GAS_STATION_IN_STORE_CASHBACK = gql`
  mutation UpdateParkingZoneGasStationInStoreCashback(
    $id: ID!
    $gasStationInStoreCashbackValue: Float
  ) {
    updateParkingZoneGasStationInStoreCashback(
      id: $id
      gas_station_in_store_cashback_value: $gasStationInStoreCashbackValue
    ) {
      id
      gasStationInStoreCashbackValue: gas_station_in_store_cashback_value
    }
  }
`;
