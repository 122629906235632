import { Heading } from 'components/Heading/Heading';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import verifyCarOwnerImage from 'assets/img/verify-car-owner.png';

const StyledHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 24px;
`;

export const VerifyCarOwnerHeading = () => {
  const { t } = useTranslation();
  return (
    <StyledHeadingContainer>
      <Heading
        color="var(--green)"
        dimension="xl"
        weight="bold"
        isWithPaddingBottom={false}
      >
        {t('verifyCarOwner')}
      </Heading>
      <img src={verifyCarOwnerImage} alt="Verify Car Owner" />
    </StyledHeadingContainer>
  );
};
