import { ReportNotification } from 'types/Notification';
import { Notification } from '../Notification/Notification';
import { useAccountType } from 'hooks/useAccountType';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

interface NotificationListProps {
  userNotifications?: ReportNotification[];
}

export const NotificationList = ({
  userNotifications,
}: NotificationListProps) => {
  const [lang, setLang] = useState<string>('de');
  const navigate = useNavigate();
  const { isThisAccountType: isUserAccount } = useAccountType('user');
  const { isThisAccountType: isDriverAccount } = useAccountType('driver');
  const { isThisAccountType: isAdminAccount } = useAccountType('superAdmin');
  useEffect(() => {
    const lang = localStorage.getItem('language');
    setLang(lang);
  }, []);
  return (
    <ul>
      {userNotifications?.map((notification, i) => {
        if (notification.report) {
          return (
            <Notification
              key={i}
              text={lang === 'en' ? notification.textEn : notification.text}
              date={notification.createdAt}
              avatar={
                (isUserAccount &&
                  notification.report.driverMap.driver.avatar) ||
                (isAdminAccount && notification.report?.user.avatar) ||
                (isDriverAccount && notification.report?.user.avatar)
              }
              onNotificationClick={() =>
                navigate(`/report/${notification.report.hash}/all`)
              }
            />
          );
        }
      })}
    </ul>
  );
};
