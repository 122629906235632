import { gql } from '@apollo/client';

export const CREATE_GAS_STATION_PRESIGNED_REQUEST = gql`
  mutation CreateGasStationPresignedRequest($files: [String!]) {
    createGasStationPresignedRequest(files: $files) {
      fileName
      id
      url
    }
  }
`;
