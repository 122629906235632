import { Paragraph } from 'components/Paragraph/Paragraph';
import { Button, ButtonProps } from 'components/Button/Button';
import { UserInfo } from 'components/UserInfo/UserInfo';
import { UploadInput } from 'components/UploadInput/UploadInput';
import { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { appContext } from 'views/App';

const StyledButton = ({ className, children, onBtnClick }: ButtonProps) => (
  <Button mode="alt" className={className} onBtnClick={onBtnClick}>
    {children}
  </Button>
);
const OverstyledButton = styled(StyledButton)<{
  isUserAppSettingsColor: boolean;
}>`
  width: max-content;
  margin-left: auto;
  position: absolute;
  top: -5px;
  right: 20px;
  color: ${({ isUserAppSettingsColor }) =>
    isUserAppSettingsColor ? '#fff' : 'var(--green)'};
  background: ${({ isUserAppSettingsColor }) =>
    isUserAppSettingsColor
      ? 'rgba(255, 255, 255, 0.2)'
      : 'rgba(1, 213, 75, 0.2)'};
`;

const StyledAvatarContainer = styled.div`
  margin-top: 50px;
`;

interface EditProfileTopBarProps {
  onSubmit: (avatar?: File) => void;
}

export const EditProfileTopBar = ({ onSubmit }: EditProfileTopBarProps) => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;

  const { t } = useTranslation();
  const methods = useForm({
    mode: 'onChange',
  });
  const { watch } = methods;
  return (
    <div>
      <OverstyledButton
        isUserAppSettingsColor={Boolean(userData?.appSettings?.primary_color)}
        onBtnClick={() =>
          onSubmit(
            watch('profilePhoto') && watch('profilePhoto')[0]
              ? watch('profilePhoto')[0]
              : undefined,
          )
        }
      >
        {t('done')}
      </OverstyledButton>
      <StyledAvatarContainer>
        <UserInfo
          layout={'avatar'}
          align="center"
          size="m"
          user={
            watch('profilePhoto') && watch('profilePhoto')[0]
              ? {
                  ...userData,
                  avatar: watch('profilePhoto')[0]
                    ? URL.createObjectURL(watch('profilePhoto')[0])
                    : '',
                }
              : userData
          }
        />
        <FormProvider {...methods}>
          <UploadInput
            name="profilePhoto"
            mode="transparent"
            content={
              <Paragraph
                color={
                  userData?.appSettings?.primary_color ? '#fff' : 'var(--green)'
                }
                textAlign="center"
              >
                {t('changeProfilePhoto')}
              </Paragraph>
            }
          ></UploadInput>
        </FormProvider>
      </StyledAvatarContainer>
    </div>
  );
};
