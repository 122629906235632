import { IconProps } from '../types';

export const GradientCircle = ({ size = '10' }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="10"
        height="9"
        rx="4.5"
        fill="url(#paint0_linear_559_13342)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_559_13342"
          x1="2.47706"
          y1="-1.12499"
          x2="7.81589"
          y2="-0.824542"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AFEFAA" />
          <stop offset="1" stopColor="#8DE9BD" />
        </linearGradient>
      </defs>
    </svg>
  );
};
