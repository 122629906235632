import { gql } from '@apollo/client';

export const DRIVER_ARRIVED = gql`
  mutation DriverArrived(
    $hash: String!
    $type: Int!
    $name: String!
    $adressFull: String!
    $streetName: String!
    $streetNumber: String!
    $zipCode: String!
    $city: String!
  ) {
    driverArrived(
      hash: $hash
      input: {
        type: $type
        name: $name
        address_full: $adressFull
        street_name: $streetName
        street_number: $streetNumber
        zip_code: $zipCode
        city: $city
      }
    ) {
      id
    }
  }
`;
