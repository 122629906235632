import { Button } from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';

const StyledBottomContainer = styled.div`
  height: 26px;
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  margin-top: 16px;
  .cancelButton {
    margin-right: 16px;
  }
`;

export const ContractButtonContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div>
      <StyledButtonsContainer>
        <Button
          mode="inverted"
          onBtnClick={() => navigate(-1)}
          className="cancelButton"
        >
          {t('cancelButton')}
        </Button>
        <Button onBtnClick={() => navigate('/contract-signature')}>
          {t('sign')}
        </Button>
      </StyledButtonsContainer>
      <StyledBottomContainer />
    </div>
  );
};
