import { IconProps } from '../types';

export const PlayIcon = ({
  size = '53',
  fill = 'var(--black)',
  stroke = 'var(--green)',
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 53 52"
      fill="none"
    >
      <circle cx="26.5" cy="26" r="26" fill={stroke} />
      <g clipPath="url(#clip0_581_24076)">
        <path
          d="M42 26C42 30.2435 40.3143 34.3131 37.3137 37.3137C34.3131 40.3143 30.2435 42 26 42C21.7565 42 17.6869 40.3143 14.6863 37.3137C11.6857 34.3131 10 30.2435 10 26C10 21.7565 11.6857 17.6869 14.6863 14.6863C17.6869 11.6857 21.7565 10 26 10C30.2435 10 34.3131 11.6857 37.3137 14.6863C40.3143 17.6869 42 21.7565 42 26ZM23.58 20.186C23.4305 20.0795 23.2545 20.0163 23.0714 20.0032C22.8884 19.9901 22.7052 20.0276 22.542 20.1117C22.3788 20.1957 22.242 20.3231 22.1463 20.4798C22.0507 20.6365 22.0001 20.8164 22 21V31C22.0001 31.1836 22.0507 31.3635 22.1463 31.5202C22.242 31.6769 22.3788 31.8043 22.542 31.8883C22.7052 31.9724 22.8884 32.0099 23.0714 31.9968C23.2545 31.9837 23.4305 31.9205 23.58 31.814L30.58 26.814C30.7096 26.7215 30.8153 26.5994 30.8882 26.4578C30.9611 26.3162 30.9991 26.1593 30.9991 26C30.9991 25.8407 30.9611 25.6838 30.8882 25.5422C30.8153 25.4006 30.7096 25.2785 30.58 25.186L23.58 20.186Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_581_24076">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(10 10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
