import { useMutation } from '@apollo/client';
import { Button } from 'components/Button/Button';
import { Popup } from 'components/Popup/Popup';
import { ACCEPT_REPORT } from 'graphql/mutations/acceptReport';
import { REJECT_REPORT } from 'graphql/mutations/rejectReport';
import { useAccountType } from 'hooks/useAccountType';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ReportProps } from 'types/Report';
import { appContext } from 'views/App';
import * as Sentry from '@sentry/react';

const StyledButtonsContainer = styled.div`
  display: flex;
  margin-top: 37px;
  .cancelButton {
    margin-right: 16px;
  }
`;

interface ActionButtonsProps {
  report?: ReportProps;
  handleCreateLog: (information: string) => void;
  handleRefetchReportData?: () => void;
}

export const ActionButtons = ({
  report,
  handleCreateLog,
  handleRefetchReportData,
}: ActionButtonsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isThisAccountType: isUserAccount } = useAccountType('user');
  const isNotUserAccount = !isUserAccount;
  const appContextStore = useContext(appContext);
  const setIsError = appContextStore?.setIsError;
  const setIsLoading = appContextStore?.setIsLoading;
  const [isPopupOpened, setIsPopupOpened] = useState<boolean>(false);
  const [isAcceptPopupOpened, setIsAcceptPopupOpened] =
    useState<boolean>(false);
  const [isDeclinePopupOpened, setIsDeclinePopupOpened] =
    useState<boolean>(false);
  const [isReportAcceptedPopup, setIsReportAcceptedPopup] =
    useState<boolean>(false);
  const [isErrorPopupOpened, setIsErrorPopupOpened] = useState<boolean>(false);

  const [acceptReport, { loading: acceptReportLoading }] = useMutation(
    ACCEPT_REPORT,
    {
      onCompleted: () => {
        handleCreateLog('Driver Report Accepted');
        handleRefetchReportData();
      },
      onError: (error) => {
        if (
          error.message.includes(
            'Driver already has an active report. Report ID:',
          )
        ) {
          setIsErrorPopupOpened(true);
        } else if (error.message.includes('This report is already active.')) {
          setIsReportAcceptedPopup(true);
        } else {
          setIsError(true);
          setIsLoading(false);
        }
        throw new Error(error.message);
      },
    },
  );

  const [rejectReport, { loading: rejectReportLoading }] = useMutation(
    REJECT_REPORT,
    {
      onCompleted: () => {
        handleCreateLog('Report User Cancellation');
      },
      onError: (error) => {
        Sentry.captureException(error);
        setIsDeclinePopupOpened(false);
        setIsError(true);
        setIsLoading(false);
        throw new Error(error.message);
      },
    },
  );

  const handleAcceptReport = () => {
    setIsAcceptPopupOpened(false);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log('Latitude: ', latitude, ' Longitude: ', longitude);
          acceptReport({
            variables: {
              hash: report?.hash,
              driverLatitude: latitude,
              driverLongitude: longitude,
            },
          });
        },
        (error) => {
          console.error('Error getting location', error);
        },
        {
          enableHighAccuracy: true, // Try to get more accurate results
          timeout: 10000, // Maximum time to wait for location (10 seconds)
          maximumAge: 10, // Prevent cached locations from being used
        },
      );
    }
  };

  useEffect(() => {
    setIsLoading(rejectReportLoading || acceptReportLoading);
  }, [rejectReportLoading, acceptReportLoading]);

  return (
    <>
      {isNotUserAccount &&
        (report?.status === 'new' || report?.status === 'accepted') &&
        report?.type === 1 && (
          <StyledButtonsContainer>
            <Button
              mode="inverted"
              onBtnClick={() => setIsDeclinePopupOpened(true)}
              className="cancelButton"
            >
              {t('decline')}
            </Button>
            <Button
              onBtnClick={
                report?.status === 'new'
                  ? () => setIsAcceptPopupOpened(true)
                  : () => setIsPopupOpened(true)
              }
            >
              {report?.status === 'accepted'
                ? t('destinationArrived')
                : t('accept')}
            </Button>
            <Popup
              isPopupOpened={isPopupOpened}
              onConfirm={() =>
                handleCreateLog('Report Driver Destination Arrival')
              }
              onDecline={() => setIsPopupOpened(false)}
              content={t('confirmOrderArrival')}
              declineText="cancel"
              type="action"
              confirmButtonId="destinationArrivedStartButton"
            />
            <Popup
              isPopupOpened={isAcceptPopupOpened}
              onConfirm={handleAcceptReport}
              onDecline={() => setIsAcceptPopupOpened(false)}
              content={t('driverAcceptConfirm')}
              declineText="cancelButton"
              type="action"
              confirmButtonId="acceptReportButton"
            />
            <Popup
              isPopupOpened={isDeclinePopupOpened}
              onConfirm={() => {
                rejectReport({ variables: { hash: report?.hash } });
                setIsDeclinePopupOpened(false);
              }}
              onDecline={() => setIsDeclinePopupOpened(false)}
              content={t('driverDectineConfirm')}
              declineText="no"
              type="action"
              confirmText="yes"
              confirmButtonId="cancelReportButton"
            />
            <Popup
              isPopupOpened={isReportAcceptedPopup}
              content={t('acceptedReportError')}
              type="info"
              onDecline={() => navigate('/')}
            />
            <Popup
              isPopupOpened={isErrorPopupOpened}
              onDecline={() => setIsErrorPopupOpened(false)}
              content={t('driverAcceptError')}
              confirmText="ok"
              type="info"
            />
          </StyledButtonsContainer>
        )}
    </>
  );
};
