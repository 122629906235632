import { IconProps } from '../types';

export const CashbackIcon = ({
  size = '24',
  stroke = 'var(--black)',
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 2.5H15C20 2.5 22 4.5 22 9.5V15.5C22 20.5 20 22.5 15 22.5H9C4 22.5 2 20.5 2 15.5V9.5C2 4.5 4 2.5 9 2.5Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.57007 15.77L15.11 9.23001"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.98001 10.87C9.65932 10.87 10.21 10.3193 10.21 9.64001C10.21 8.9607 9.65932 8.41 8.98001 8.41C8.3007 8.41 7.75 8.9607 7.75 9.64001C7.75 10.3193 8.3007 10.87 8.98001 10.87Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.52 16.59C16.1993 16.59 16.75 16.0393 16.75 15.36C16.75 14.6807 16.1993 14.13 15.52 14.13C14.8407 14.13 14.29 14.6807 14.29 15.36C14.29 16.0393 14.8407 16.59 15.52 16.59Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
