import { useLazyQuery } from '@apollo/client';
import { UserInformationContainer } from './UserInformationContainer/UserInformationContainer';
import { Gallery } from 'components/Gallery/Gallery';
import { REPORT } from 'graphql/queries/report';
import { useAccountType } from 'hooks/useAccountType';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useGetLocation } from 'hooks/useGetLocation';
import { getAddress } from 'utils/getAddress';
import { GAS_STATION_REPORT } from 'graphql/queries/gasStationReport';
import { User } from 'types/User';
import { ReportProps } from 'types/Report';
import { appContext } from '../App';
import { UserDriverInformation } from './UserDriverInformation/UserDriverInformation';
import { ExecuteOrder } from './ExecuteOrder/ExecuteOrder';
import { ReportDetails } from './ReportDetails/ReportDetails';
import { ReportButtons } from './ReportButtons/ReportButtons';
import { ReportLayout } from './ReportLayout/ReportLayout';

const StyledDetails = styled.div`
  padding: 26px 26px 0;
`;

export const Report = () => {
  const appContextStore = useContext(appContext);
  const reportDetails = appContextStore?.reportDetails;
  const setReportDetails = appContextStore?.setReportDetails;
  const setIsLoading = appContextStore?.setIsLoading;
  const setCarOwner = appContextStore?.setCarOwner;
  const { t } = useTranslation();
  const { hash, type } = useParams();
  const { isThisAccountType: isUserAccount } = useAccountType('user');
  const isNotUserAccount = !isUserAccount;
  const { isThisAccountType: isDriverAccount } = useAccountType('driver');
  const { getLocation } = useGetLocation();

  const [reportData, setReportData] = useState<ReportProps | undefined>(
    undefined,
  );
  const [driver, setDriver] = useState<User | null>(null);
  const distance = reportData?.driverMap?.distance;
  const eta =
    Math.floor(distance / 0.66) <= 1
      ? `1 ${t('minute')}`
      : `${Math.floor(distance / 0.66)} ${t('minutes')}`;

  const [report, { loading, refetch }] = useLazyQuery(REPORT, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (!data) return;
      handleAddress(data?.report);
      setDriver(data?.report?.driverMap?.driver);
    },
    onError: (error) => {
      throw new Error(error.message);
    },
  });
  const [gasStationReport, { loading: gasStationReportLoading }] = useLazyQuery(
    GAS_STATION_REPORT,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        if (!data) return;
        handleAddress(data.gasStationReport);
      },
      onError: (error) => {
        throw new Error(error.message);
      },
    },
  );

  useEffect(() => {
    if (!hash && !type) return;
    setReportDetails({ ...reportDetails, hash });
    if (type === 'all') {
      report({ variables: { hash } });
    } else {
      gasStationReport({ variables: { hash } });
    }
  }, [hash, type]);

  const handleAddress = async (reportData: ReportProps) => {
    const positionLog = reportData?.logs && [
      reportData?.logs[0]?.latitude,
      reportData?.logs[0]?.longitude,
    ];
    const reportPhotos = reportData?.photos.map((photo) => photo.photo);
    const images = isNotUserAccount
      ? [
          ...reportPhotos,
          reportData?.carOwner?.driverIdPhoto,
          reportData?.carOwner?.platePhoto,
        ]
      : [...reportPhotos];
    const imagesWithBill = reportData?.bill
      ? [...images, reportData.bill]
      : [...images];
    const filteredImages = imagesWithBill.filter(
      (image) => image && image !== 'no photo',
    );
    if (reportData?.logs && reportData?.logs[0] && positionLog) {
      const address = await getAddress(positionLog);
      if (address) {
        const logAddress = `${address.street}, ${address.streetNumber}`;
        setReportData({
          ...reportData,
          logAddress,
          images: filteredImages,
        });
      } else {
        setReportData({ ...reportData, images: filteredImages });
      }
    } else {
      setReportData({
        ...reportData,
        images: filteredImages,
      });
    }
  };

  useEffect(() => {
    getLocation();
    setCarOwner(null);
  }, []);

  useEffect(() => {
    setIsLoading(loading || gasStationReportLoading);
  }, [loading, gasStationReportLoading]);

  return (
    <ReportLayout>
      <Gallery galleryImages={reportData?.images} />
      <StyledDetails>
        <ReportDetails report={reportData} eta={eta} />
        <UserDriverInformation report={reportData} />
        <ExecuteOrder
          reportHash={reportData?.hash}
          reportStatus={reportData?.status}
          reportType={reportData?.type}
        />
        {!isDriverAccount &&
          reportData?.status !== 'new' &&
          reportData?.type === 1 && (
            <UserInformationContainer user={driver} role="driver" />
          )}
        {isNotUserAccount && reportData?.type === 1 && (
          <UserInformationContainer user={reportData?.user} role="user" />
        )}
        <ReportButtons
          report={reportData}
          handleRefetchReportData={() => refetch({ hash })}
          handleReportDataChange={(newReportData) =>
            setReportData({ ...newReportData })
          }
        />
      </StyledDetails>
    </ReportLayout>
  );
};
