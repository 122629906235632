import { Heading, HeadingProps } from 'components/Heading/Heading';
import { useAccountType } from 'hooks/useAccountType';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledHeading = ({ children, className }: HeadingProps) => (
  <Heading color="var(--black)" textAlign="center" className={className}>
    {children}
  </Heading>
);

const OverstyledHeading = styled(StyledHeading)`
  padding-top: 20vh;
`;

export const NoReportsYet = () => {
  const { t } = useTranslation();
  const { isThisAccountType } = useAccountType('user');
  return (
    <OverstyledHeading>
      {t(isThisAccountType ? 'userNoReportsYet' : 'driverNoReportsYet')}
    </OverstyledHeading>
  );
};
