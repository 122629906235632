import { useAccountType } from 'hooks/useAccountType';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { UserInfo } from 'components/UserInfo/UserInfo';
import { useEffect, useRef, useState } from 'react';
import { getPhotosFromReportMedia } from 'utils/getPhotosFromReportMedia';
import { ReportProps } from 'types/Report';
import { ReportPhotoWrapper } from './ReportPhotoWrapper/ReportPhotoWrapper';
import { ReportDetails } from './ReportDetails/ReportDetails';

interface ReportListItemProps {
  report: ReportProps;
}
interface ReportListItemContainerProps {
  isNotUserAccount: boolean;
}

const ReportListItemContainer = styled.div<ReportListItemContainerProps>`
  padding: 16px;
  background: rgba(230, 230, 230, 0.2);
  border: 1px solid var(--sub--black-opacity);
  border-radius: 16px;
  margin-bottom: 16px;
  :last-child {
    margin-bottom: 0;
  }
  .paymentStatus {
    max-width: none;
    width: max-content;
  }
  .report-date {
    margin-bottom: 4px;
  }
`;
const ReportListItemWrapper = styled.div`
  position: relative;
  display: flex;
  padding: 0px;
  width: 100%;
`;
const ReportUserAvatar = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
`;

export const ReportListItem = ({ report }: ReportListItemProps) => {
  const navigate = useNavigate();
  const { isThisAccountType: isUserAccount } = useAccountType('user');
  const images = getPhotosFromReportMedia(report?.photos);
  const isNotUserAccount = !isUserAccount;
  const location = useLocation();
  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      { rootMargin: '50px' },
    );
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, []);

  const handleOnReportClick = () => {
    if (isNotUserAccount) {
      navigate(
        report.status === 'inProgress'
          ? `/select-parking/${report?.hash}`
          : !report?.type
          ? `/report/${report?.hash}/station`
          : `/report/${report?.hash}/all`,
      );
    } else {
      if (location.pathname === '/history/active') {
        navigate(`/active-report/${report?.hash}`);
      } else {
        navigate(
          !report.type
            ? `/report/${report?.hash}/station`
            : `/report/${report?.hash}/all`,
        );
      }
    }
  };

  if (!report) return;
  return (
    <ReportListItemContainer
      ref={ref}
      id={report?.hash}
      onClick={handleOnReportClick}
      isNotUserAccount={isNotUserAccount}
    >
      <ReportListItemWrapper>
        <ReportPhotoWrapper
          reportType={report?.type}
          image={images?.length && images[0]}
          isIntersecting={isIntersecting}
        />
        <ReportDetails report={report} />
        {isUserAccount && report?.driverMap && (
          <ReportUserAvatar>
            <UserInfo
              layout={'avatar'}
              size="xs"
              user={report?.driverMap?.driver}
            />
          </ReportUserAvatar>
        )}
      </ReportListItemWrapper>
    </ReportListItemContainer>
  );
};
