import { Button } from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledBottomContainer = styled.div`
  height: 86px;
`;

export const EditProfileBottomContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div>
      <Button onBtnClick={() => navigate('/change-password')}>
        {t('changePassword')}
      </Button>
      <StyledBottomContainer />
    </div>
  );
};
