export const faq = {
  faqFisrtQuestion: 'Was ist eine Umtriebsentschädigung?',
  faqFirstAnswer:
    'Die Umtriebsentschädigung ist eine Schadenersatzform in der Schweiz, die bei der unrechtmässigen Nutzung von Privatgrundstücken, wie etwa Falschparken auf Privatparkplätzen, Anwendung findet. Sie deckt den Aufwand des Geschädigten zur Durchsetzung seiner Rechte',
  faqSecondQuestion: 'Wie hoch ist die Umtriebsentschädigung?',
  faqSecondAnswer:
    'Eine Umtriebsentschädigung beträgt zwischen 40 und 80 CHF, wobei die genaue Höhe vom Kunden selbst festgelegt werden kann.',
  faqThirdQuestion:
    'Braucht man ein richterliches Verbot, damit man eine Umtriebsentschädigung austellen darf?',
  faqThirdAnswer:
    'Nein, ein richterliches Verbot ist nicht notwendig, um eine Umtriebsentschädigung auszustellen. Ist der Parkplatz aber zusätzlich mit einem audienzrichterlichen Verbot belegt, muss der Falschparker zu der bereits erwähnten Umtriebsentschädigung zusätzlich mit einer Anzeige und einem Strafantrag wegen Missachtung eines gerichtlichen Verbotes rechnen.',
  faq4Question: 'Was kostet die App oder ein Fahrzeug abschleppen zu lassen?',
  faq4Answer:
    'Unsere Dienstleistung ist für unsere Kunden vollständig kostenlos. Es entstehen keine Kosten für sie. Die Gebühren werden beim Falschparker direkt eingefordert.',
  faq5Question: 'Wann darf man ein Fahrzeug abschleppen lassen?',
  faq5Answer:
    'Auf Parkplätzen, die eindeutig durch visuelle und signaltechnische Merkmale von öffentlichen Bereichen abgegrenzt sind – erkennbar an gelben Markierungen, privaten Beschriftungen – sowie an Zufahrten zu Höfen, Tiefgaragen usw. Insbesondere, wenn der Fahrzeuglenker nicht ermittelt werden kann und der Parkplatz benötigt wird.',
  faq6Question: 'Was ist ein Zessionsvertrag?',
  faq6Answer:
    'Eine Zession ist eine Forderungsabtretung oder Sicherungsabtretung. Dabei ändert sich das Schuldverhältnis, indem die Forderung an uns übertragen wird. Der Zedent (Abtretender) überträgt also die Forderung an den sogenannten Zessionar (Abtretungsempfänger).',
};
