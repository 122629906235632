import { IconProps } from '../types';

export const TruckIcon = ({
  size = '24',
  fill = 'var(--white)',
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.3193 11.5118L18.2692 6.78533C17.8417 6.28733 17.2178 6.00008 16.5615 6.00008H13.5C12.6713 6.00008 12 6.67133 12 7.50008V13.5001H10.5V6.75008C10.5 6.47183 10.3305 6.21758 10.122 6.09908L4.872 3.09908C4.68375 2.99108 4.38225 2.96483 4.13025 3.11183C3.876 3.25808 3.75 3.52508 3.75 3.75008V8.25008H2.25V7.50008C2.25 7.08608 1.91475 6.75008 1.5 6.75008C1.08525 6.75008 0.75 7.08608 0.75 7.50008V8.25008C0.75 9.07883 1.42125 9.75008 2.25 9.75008H3.75C4.57875 9.75008 5.25 9.07883 5.25 8.25008V5.79233L8.05725 7.46408L6.9285 8.79008C6.81375 8.92583 6.75 9.09758 6.75 9.27608V13.5001H3C2.17125 13.5001 1.5 14.1713 1.5 15.0001V17.2501C1.5 18.0788 2.17125 18.7501 3 18.7501H3.75C3.75 20.4046 5.0955 21.7501 6.75 21.7501C8.4045 21.7501 9.75 20.4046 9.75 18.7501H13.5C13.5 20.4046 14.8455 21.7501 16.5 21.7501C18.1545 21.7501 19.5 20.4046 19.5 18.7501H21C21.8288 18.7501 22.5 18.0788 22.5 17.2501V12.0008C22.5 11.8216 22.4355 11.6483 22.3193 11.5118ZM6.75 20.2501C5.92275 20.2501 5.25 19.5773 5.25 18.7501C5.25 17.9933 5.895 17.2501 6.75 17.2501C7.60125 17.2501 8.25 17.9903 8.25 18.7501C8.25 19.5773 7.57725 20.2501 6.75 20.2501ZM16.5 20.2501C15.6727 20.2501 15 19.5773 15 18.7501C15 17.9933 15.645 17.2501 16.5 17.2501C17.3512 17.2501 18 17.9903 18 18.7501C18 19.5773 17.3272 20.2501 16.5 20.2501ZM19.9268 12.0001H14.25V8.62508C14.25 8.41808 14.418 8.25008 14.625 8.25008H17.07C17.184 8.25008 17.2913 8.30183 17.3625 8.39108L20.0483 11.7481C20.1293 11.8493 20.0573 12.0001 19.9268 12.0001Z"
        fill={fill}
      />
    </svg>
  );
};
