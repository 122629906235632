import { Paragraph } from 'components/Paragraph/Paragraph';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

const StyledOrderNumber = styled.div`
  padding-right: 2px;
`;

interface ReportOrderNumberProps {
  reportId?: number;
}

export const ReportOrderNumber = ({ reportId }: ReportOrderNumberProps) => {
  const { t } = useTranslation();
  return (
    <StyledOrderNumber>
      <Paragraph
        textTransform={'capitalize'}
        dimension={'xs'}
        color={'var(--sub--black)'}
        className="paragraph"
      >
        {t('orderNumber')}
      </Paragraph>
      <Paragraph
        weight={'bolder'}
        dimension={'m'}
        color={'var(--black)'}
        className="paragraph"
      >
        {reportId}
      </Paragraph>
    </StyledOrderNumber>
  );
};
