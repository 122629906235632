import { IconProps } from '../types';

export const TickCircleIcon = ({
  size = '26',
  stroke = 'var(--white)',
  className,
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      className={className}
    >
      <path
        d="M8.00065 14.6666C11.6673 14.6666 14.6673 11.6666 14.6673 7.99992C14.6673 4.33325 11.6673 1.33325 8.00065 1.33325C4.33398 1.33325 1.33398 4.33325 1.33398 7.99992C1.33398 11.6666 4.33398 14.6666 8.00065 14.6666Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.16602 7.99995L7.05268 9.88661L10.8327 6.11328"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
