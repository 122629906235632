import { IconProps } from '../types';

export const CircleIcon = ({
  size = '14',
  fill = 'var(--white)',
  stroke = 'var(--black)',
  onIconClick,
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onIconClick}
    >
      <path
        d="M0.5 7C0.5 3.41015 3.41015 0.5 7 0.5C10.5899 0.5 13.5 3.41015 13.5 7C13.5 10.5899 10.5899 13.5 7 13.5C3.41015 13.5 0.5 10.5899 0.5 7Z"
        stroke={stroke}
      />
      <rect width="10.2439" height="10" rx="5" fill={fill} x="2" y="2" />
    </svg>
  );
};
