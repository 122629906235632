import { Layout } from 'components/Layout/Layout';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { TopBar } from './TopBar/TopBar';
import { ClientList } from './ClientList/ClientList';
import { ClientListHeading } from './ClientListHeading/ClientListHeading';

const StyledBottomContainer = styled.div`
  height: 46px;
`;

export const Clients = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useState<string | undefined>(undefined);
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [isContentEnd, setIsContentEnd] = useState<boolean>(false);
  const [isCashbackOnStatus, setIsCashbackOnStatus] = useState<
    boolean | undefined
  >(undefined);

  return (
    <>
      <Layout
        userBarContent={
          <TopBar
            handleCashbackOnStatus={(status) => setIsCashbackOnStatus(status)}
            handleStatusChange={(status) => setStatus(status)}
            handleSearchValueChange={(searchValue) =>
              setSearchValue(searchValue)
            }
          />
        }
        isMenuBar
        mode="top"
        isBackBtn
        backButtonMode="text"
        viewTitle={t('clients')}
        handleContentEnd={(isContentEnd) => setIsContentEnd(isContentEnd)}
      >
        <ClientListHeading />
        <ClientList
          isContentEnd={isContentEnd}
          status={status}
          searchValue={searchValue}
          isCashbackOnStatus={isCashbackOnStatus}
        />
        <StyledBottomContainer />
      </Layout>
    </>
  );
};
