import { Anchor } from 'components/Anchor/Anchor';
import { Layout } from 'components/Layout/Layout';
import { Heading } from 'components/Heading/Heading';
import { Button } from 'components/Button/Button';
import { Input } from 'components/Input/Input';
import { PasswordInput } from 'components/PasswordInput/PasswordInput';
import { emailValidation, passwordValidation } from 'data/regexPatterns';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import greenCarSmall from 'assets/img/green-car-small.png';
import { useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useMutation } from '@apollo/client';
import { REGISTER } from 'graphql/mutations/register';
import { useAuthContext } from 'context/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { Popup } from 'components/Popup/Popup';
import { PhoneNumberInput } from 'components/PhoneNumberInput/PhoneNumberInput';
import { REF_USER } from 'graphql/mutations/refUser';
import { appContext } from 'views/App';

const StyledContainer = styled.div`
  height: 100%;
  padding-top: 25px;
`;
const StyledForm = styled.form`
  padding-bottom: 15px;
`;
const StyledButton = styled.div`
  padding-top: 20px;
`;

const StyledAnchorContainer = styled.div`
  padding-top: 30px;
`;
const StyledBottomContainer = styled.div`
  min-height: 40px;
  height: 40px;
`;

export const SignUp = () => {
  const appContextStore = useContext(appContext);
  const setUserData = appContextStore?.setUserData;
  const newUser = appContextStore?.newUser;
  const setNewUser = appContextStore?.setNewUser;
  const setIsError = appContextStore?.setIsError;
  const setIsLoading = appContextStore?.setIsLoading;

  const [isPopupOpned, setIsPopupOpened] = useState<boolean>(false);
  const { setIsSignedIn, setAuthToken } = useAuthContext();
  const navigate = useNavigate();
  const { refCode, userType } = useParams();
  const [isCompanyClient, setIsCompanyClient] = useState<boolean>(false);
  const [register, { loading }] = useMutation(REGISTER, {
    onCompleted: (data) => {
      if (!data) return;
      setIsSignedIn(true);
      setAuthToken(data.register.tokens.accessToken);
      setUserData({
        ...data.register.tokens.user,
        role: 'user',
      });
      setNewUser(null);
      document.cookie = `PHPSESSID=${data.register.tokens.accessToken}; path=/`;
      if (getValues('refCode')) {
        refUser({
          variables: { refCode: getValues('refCode') },
          context: {
            headers: {
              Authorization: `Bearer ${data.register.tokens.accessToken}`,
            },
          },
        });
      } else {
        navigate('/signup-success');
      }
    },
    onError: (error) => {
      if (
        error?.graphQLErrors[0].extensions?.validation['input.email'][0] ===
        'User with this email already exists'
      ) {
        setIsPopupOpened(true);
      } else {
        Sentry.captureException(error);
        setIsError(true);
        setIsLoading(false);
      }
      throw new Error(error.message);
    },
  });
  const [refUser] = useMutation(REF_USER, {
    onCompleted: (data) => {
      if (!data) return;
      setIsLoading(false);
      navigate('/signup-success');
    },
    onError: () => setIsError(true),
  });
  const { t } = useTranslation();
  const methods = useForm({
    mode: 'onSubmit',
  });
  const { handleSubmit, getValues, watch, setError, clearErrors, setValue } =
    methods;

  const onSubmit = async () => {
    (document.activeElement as HTMLElement).blur();
    const fullAddress = `${getValues('companyStreet')} ${getValues(
      'companyStreetNumber',
    )}, ${getValues('companyZipCode')} ${getValues('companyCity')}`;
    await register({
      variables: {
        firstName: getValues('firstName'),
        lastName: getValues('lastName'),
        email: getValues('email'),
        phonePrefix: '+41',
        phoneNumber: getValues('phoneNumber'),
        password: getValues('password'),
        passwordConfirmation: getValues('password'),
        companyName: isCompanyClient ? getValues('companyName') : null,
        companyAddress: fullAddress,
      },
    });
  };

  useEffect(() => {
    const password = getValues('password');
    const confirmPassword = getValues('confirmPassword');
    if (
      confirmPassword.length >= password.length &&
      confirmPassword !== password
    ) {
      setError('confirmPassword', {
        type: 'pattern',
        message: t('confirmPasswordValidationError'),
      });
    } else {
      clearErrors('confirmPassword');
    }
  }, [watch('confirmPassword')]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (!newUser) return;
    setValue('firstName', newUser?.firstName);
    setValue('lastName', newUser?.lastName);
    setValue('email', newUser?.email);
    setValue('phoneNumber', newUser?.phoneNumber);
    setValue('companyName', newUser?.companyName);
    setValue('companyStreet', newUser?.companyStreet);
    setValue('companyStreetNumber', newUser?.companyStreetNumber);
    setValue('companyZipCode', newUser?.companyZipCode);
    setValue('companyCity', newUser?.companyCity);
  }, [newUser]);

  useEffect(() => {
    if (refCode) setValue('refCode', refCode);
  }, [refCode]);

  useEffect(() => {
    if (userType) {
      if (userType === 'company') {
        setIsCompanyClient(true);
      } else {
        setIsCompanyClient(false);
      }
    }
  }, [userType]);

  return (
    <Layout
      isWithMapBackground
      mode="top"
      topContent={<img src={greenCarSmall} />}
      topContentSize="s"
      isBackBtn
      backBtnLink="/"
      isWithAppWrapper={false}
      outsideElements={
        <div>
          <Popup
            content={t('userExist')}
            isPopupOpened={isPopupOpned}
            type="info"
            onDecline={() => setIsPopupOpened(false)}
            confirmText={t('ok')}
          />
        </div>
      }
    >
      <StyledContainer>
        <Heading color="var(--green)" dimension="xl">
          {t('signUp')}
        </Heading>
        <FormProvider {...methods}>
          <StyledForm onSubmit={handleSubmit(onSubmit)} id="registrationForm">
            {isCompanyClient && (
              <Input
                name="companyName"
                label={t('companyName')}
                placeholder={t('companyNamePlaceholder')}
                type="text"
                bottomMarting="10"
              />
            )}
            <Input
              name="firstName"
              label={t('firstNameLabel')}
              placeholder={t('firstNamePlaceholder')}
              isRequired
              requiredErrorMessage={t('requiredError')}
              type="text"
              bottomMarting="10"
            />
            <Input
              name="lastName"
              label={t('lastNameLabel')}
              placeholder={t('lastNamePlaceholder')}
              isRequired
              requiredErrorMessage={t('requiredError')}
              type="text"
              bottomMarting="10"
            />
            <Input
              name="companyStreet"
              label={t('street')}
              placeholder={t('streetPlaceholder')}
              type="text"
              bottomMarting="10"
            />
            <Input
              name="companyStreetNumber"
              label={t('streetNumber')}
              placeholder={t('streetNumberPlaceholder')}
              type="text"
              bottomMarting="10"
            />
            <Input
              name="companyZipCode"
              label={t('zipCode')}
              placeholder={t('zipCodePlaceholder')}
              type="text"
              bottomMarting="10"
            />
            <Input
              name="companyCity"
              label={t('city')}
              placeholder={t('cityPlaceholder')}
              type="text"
              bottomMarting="10"
            />
            <Input
              name="email"
              label={t('emailLabel')}
              placeholder={t('emailPlaceholder')}
              validation={emailValidation}
              validationErrorMessage={t('emailValidationError')}
              isRequired
              requiredErrorMessage={t('requiredError')}
              type="email"
              bottomMarting="10"
            />
            <PasswordInput
              placeholder={t('passwordPlaceholder')}
              isRequired
              requiredErrorMessage={t('requiredError')}
              validation={passwordValidation}
              validationErrorMessage={t('passwordValidationError')}
              bottomMarting="10"
            />
            <PasswordInput
              placeholder={t('сonfirmPasswordPlaceholder')}
              isRequired
              requiredErrorMessage={t('requiredError')}
              validationErrorMessage={t('confirmPasswordValidationError')}
              name="confirmPassword"
              label={t('confirmPassword')}
              bottomMarting="10"
            />
            <PhoneNumberInput bottomMarting="10" />
            <Input
              name="refCode"
              label={t('referCodeTitle')}
              placeholder={t('referCodePlaceholder')}
              type="text"
              bottomMarting="10"
            />
            <StyledButton>
              <Button type="submit">{t('signUpButton')}</Button>
            </StyledButton>
          </StyledForm>
        </FormProvider>
        <StyledAnchorContainer>
          <Anchor
            link="/sign-in"
            linkTitle={t('signin')}
            label={t('signInLabel')}
            size="s"
            textAlign="center"
            textColor="white-text"
          />
        </StyledAnchorContainer>
        <StyledBottomContainer />
      </StyledContainer>
    </Layout>
  );
};
