import { ReportProps } from 'types/Report';
import { ReportDetail } from '../ReportDetail/ReportDetail';
import { DateAndTime } from './DateAndTime/DateAndTime';

interface TimeInformationProps {
  report?: ReportProps;
}
export const TimeInformation = ({ report }: TimeInformationProps) => {
  return (
    <>
      {(report?.status == 'finished' ||
        report?.status == 'cancelled' ||
        report?.status == 'towedAway') && (
        <>
          {report?.updateDate.placement && (
            <ReportDetail
              title={
                report?.type === 2 ? 'ticketOrderPlacement' : 'orderPlacement'
              }
              value={<DateAndTime date={report?.updateDate.placement} />}
            />
          )}
          {report?.updateDate.acceptance && (
            <ReportDetail
              title="orderAcceptence"
              value={<DateAndTime date={report?.updateDate.acceptance} />}
            />
          )}
          {report?.updateDate.pickup && (
            <ReportDetail
              title="timeOfPickup"
              value={<DateAndTime date={report?.updateDate.pickup} />}
            />
          )}
          {report?.updateDate.arrival && (
            <ReportDetail
              title="arrivalTime"
              value={<DateAndTime date={report?.updateDate.arrival} />}
            />
          )}
          {report?.updateDate.execution && (
            <ReportDetail
              title="timeOfExecution"
              value={<DateAndTime date={report?.updateDate.execution} />}
            />
          )}
          {report?.updateDate.cancellation && (
            <ReportDetail
              title="cancelatioTime"
              value={<DateAndTime date={report?.updateDate.cancellation} />}
            />
          )}
        </>
      )}
    </>
  );
};
