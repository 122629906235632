import { BackButton } from 'components/BackButton/BackButton';
import { MediaNav } from 'components/MediaNav/MediaNav';
import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { appContext } from 'views/App';

const StyledContainer = styled.div`
  width: 100vw;
  height: calc(100vh - 124px);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledVideo = styled.video`
  width: 100%;
  height: max-content;
`;

export const VideoPreview = () => {
  const appContextStore = useContext(appContext);
  const reportDetails = appContextStore?.reportDetails;
  const setReportDetails = appContextStore?.setReportDetails;

  const { video } = useParams();
  const [selectedVideo, setSelectedVideo] = useState<File | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!reportDetails) return;
    if (!selectedVideo) {
      const thisVideo = reportDetails.videos.find(({ name }) => name === video);
      setSelectedVideo(thisVideo);
    }
  }, [reportDetails]);

  const handleRemoveClick = () => {
    const videos = reportDetails.videos.filter(
      (video) => video !== selectedVideo,
    );
    setReportDetails({ ...reportDetails, videos });
    navigate(-1);
  };

  const handleRetakeClick = (video: File) => {
    if (video) {
      setSelectedVideo(video);
      const videos = reportDetails.videos.filter(
        (video) => video !== selectedVideo,
      );
      setReportDetails({ ...reportDetails, videos: [...videos, video] });
    }
  };

  if (!selectedVideo) return;
  return (
    <StyledContainer>
      <BackButton as="back" />
      <StyledVideo
        controls
        src={selectedVideo ? URL.createObjectURL(selectedVideo) : ''}
        autoPlay
      />
      <MediaNav
        onRetakeClick={(video) => handleRetakeClick(video)}
        onDeleteClick={handleRemoveClick}
      />
    </StyledContainer>
  );
};
