import { Paragraph } from 'components/Paragraph/Paragraph';
import { Status } from 'components/Status/Status';
import { useTranslation } from 'react-i18next';
import { getPaymentStatusColor } from 'utils/getPaymentStatusColor';

interface ReportPaymentStatusProps {
  paymentStatus?: string;
}

export const ReportPaymentStatus = ({
  paymentStatus,
}: ReportPaymentStatusProps) => {
  const { t } = useTranslation();
  return (
    <div>
      <Paragraph
        color={'var(--sub--black)'}
        textTransform={'capitalize'}
        dimension={'xs'}
        className="paragraph"
      >
        {t('paymentStatus')}
      </Paragraph>
      <Status
        customText={t(paymentStatus)}
        mode={'report'}
        size="s"
        color={getPaymentStatusColor(paymentStatus)}
        className="paymentStatus"
      />
    </div>
  );
};
