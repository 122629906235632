import styled from 'styled-components/macro';
import { Heading, HeadingProps } from '../Heading/Heading';
import { Paragraph, ParagraphProps } from '../Paragraph/Paragraph';

interface CompanyInfoProps {
  isWithTitle?: boolean;
}

const StyledHeading = ({ children, className }: HeadingProps) => (
  <Heading color="var(--black)" weight="bold" className={className}>
    {children}
  </Heading>
);
const StyledParagraph = ({ children, className }: ParagraphProps) => (
  <Paragraph color="var(--black)" weight="bolder" className={className}>
    {children}
  </Paragraph>
);

const OverstyledHeading = styled(StyledHeading)`
  border-bottom: 1px solid var(--secondary-grey);
  line-height: 25px;
  margin-bottom: 16px;
`;

const OverstyledParagraph = styled(StyledParagraph)<CompanyInfoProps>`
  line-height: 21px;
  padding-bottom: ${({ isWithTitle }) => (isWithTitle ? '0' : '30')};
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: var(--black);
  font-size: var(--text-m);
  font-weight: 600;
  line-height: 21px;
`;

export const CompanyInfo = ({ isWithTitle = true }: CompanyInfoProps) => (
  <div>
    {isWithTitle && <OverstyledHeading>TowShare Group GmbH</OverstyledHeading>}
    <StyledLink href="https://falsch-parker.ch/">falsch-parker.ch</StyledLink>
    <OverstyledParagraph>
      Schützengasse 4 <br />
      8001 Zürich
    </OverstyledParagraph>
  </div>
);
