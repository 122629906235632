import { Dropdown } from 'components/Dropdown/Dropdown';
import { Heading } from 'components/Heading/Heading';
import { sortByOptions } from 'data/sortOptions';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

const StyledHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;

  @media (max-width: 380px) {
    .title {
      font-size: 1.5rem;
    }
    p {
      font-size: 1rem;
    }
  }
`;

interface HistoryHeadingProps {
  handleSortBy: (option: string) => void;
}
export const HistoryHeading = ({ handleSortBy }: HistoryHeadingProps) => {
  const { t } = useTranslation();
  const { type } = useParams();
  return (
    <StyledHeadingContainer>
      <Heading
        color="var(--black)"
        isWithPaddingBottom={false}
        className="title"
      >
        {t(
          (type === 'all' && 'allHistoryTitle') ||
            (type === 'tickets' && 'ticketHistoryTitle') ||
            (type === 'active' && 'towingActiveHistoryTitle') ||
            (type === 'deactive' && 'towingDeactiveHistoryTitle') ||
            (type === 'station' && 'gasStationButtonTitle'),
        )}
      </Heading>
      <Dropdown
        options={sortByOptions}
        mode="primary"
        label={t('sortBy')}
        handleSelectedOption={(option) => handleSortBy(option)}
      />
    </StyledHeadingContainer>
  );
};
