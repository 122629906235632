import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Popup } from '../Popup/Popup';
import { useContext } from 'react';
import { appContext } from 'views/App';

const StyledLink = styled.a`
  text-decoration: none;
  color: var(--black);
`;

export const ErrorPopup = () => {
  const appContextStore = useContext(appContext);
  const isError = appContextStore?.isError;
  const setIsError = appContextStore?.setIsError;

  const { t } = useTranslation();
  return (
    <Popup
      isPopupOpened={isError}
      onDecline={() => setIsError(false)}
      type="info"
      content={
        <span>
          <span>{t('backendErrorPartOne')}</span>
          <StyledLink href="falsch-parker.ch">falsch-parker.ch</StyledLink>
          <span>{t('backendErrorPartTwo')}</span>
        </span>
      }
      confirmText="ok"
    />
  );
};
