import { SearchBar } from 'components/SearchBar/SearchBar';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components/macro';
import { ClientInformation } from '../ClientInformation/ClientInformation';

const StyledClientTopBar = styled.div`
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
interface ClientTopBarProps {
  handleStatusChange: (status: string) => void;
  handleSearchValueChange: (searchValue?: string) => void;
}
export const ClientTopBar = ({
  handleStatusChange,
  handleSearchValueChange,
}: ClientTopBarProps) => {
  const methods = useForm();
  const { watch } = methods;

  useEffect(() => {
    handleSearchValueChange(watch('search'));
  }, [watch('search')]);

  return (
    <StyledClientTopBar>
      <ClientInformation />
      <FormProvider {...methods}>
        <SearchBar
          handleStatus={(status) => handleStatusChange(status)}
          isWithStatusDropdown={true}
          isClientsReports={true}
        />
      </FormProvider>
    </StyledClientTopBar>
  );
};
