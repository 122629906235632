import { gql } from '@apollo/client';

export const CREATE_CAR_OWNER_FOR_TICKET = gql`
  mutation CreateCarOwnerForTicket(
    $fullName: String!
    $contactDetails: String!
    $fullAddress: String!
    $gender: String
    $paymentType: Int
    $qrCodeId: String!
  ) {
    createCarOwnerForTicket(
      input: {
        full_name: $fullName
        contact_details: $contactDetails
        address_full: $fullAddress
        payment_type: $paymentType
        gender: $gender
      }
      qr_code_id: $qrCodeId
    ) {
      id
    }
  }
`;
