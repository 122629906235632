import { IconProps } from '../types';

export const TransactionIcon = ({
  size = '24',
  fill = 'var(--white)',
  stroke = 'var(--white)',
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_747_28968)">
        <path
          d="M7.62644 19.3365L7.62018 19.3427L7.61415 19.3492C7.20024 19.7935 6.8318 20.0593 6.5304 20.1894C6.23391 20.3174 6.03295 20.3035 5.89904 20.2458C5.76147 20.1865 5.60038 20.0405 5.46876 19.7177C5.33647 19.3934 5.25 18.9247 5.25 18.3V7.04C5.25 5.01737 5.49812 3.9484 6.05763 3.35034C6.60604 2.76414 7.581 2.5 9.47 2.5H17.03C18.9192 2.5 19.8938 2.76423 20.4408 3.35002C20.9989 3.94758 21.245 5.01607 21.24 7.03876V7.04V18.29C21.24 18.9151 21.1541 19.384 21.0223 19.7085C20.8913 20.0315 20.7309 20.177 20.5944 20.2359C20.4614 20.2933 20.261 20.3075 19.9636 20.1793C19.6615 20.049 19.2917 19.783 18.8752 19.3385C18.3713 18.7981 17.7131 18.5296 17.0503 18.5657C16.3875 18.6018 15.7624 18.9401 15.32 19.53L15.3196 19.5305L14.3113 20.8782C14.3111 20.8786 14.3108 20.8789 14.3106 20.8792C13.9859 21.3075 13.5959 21.4825 13.245 21.4825C12.8942 21.4825 12.5041 21.3076 12.1795 20.8793C12.1792 20.8789 12.1789 20.8786 12.1787 20.8782L11.1705 19.5307C11.1705 19.5306 11.1704 19.5306 11.1704 19.5305C11.1704 19.5305 11.1704 19.5305 11.1704 19.5305C10.2744 18.3319 8.66291 18.2433 7.63146 19.3314L7.62644 19.3365ZM10.5 11.25H16C16.6861 11.25 17.25 10.6861 17.25 10C17.25 9.31386 16.6861 8.75 16 8.75H10.5C9.81386 8.75 9.25 9.31386 9.25 10C9.25 10.6861 9.81386 11.25 10.5 11.25Z"
          fill={fill}
          stroke={stroke}
        />
      </g>
      <defs>
        <filter
          id="filter0_d_747_28968"
          x="-2.75"
          y="0"
          width="32"
          height="32"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_747_28968"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_747_28968"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
