import { useMutation } from '@apollo/client';
import { Popup } from 'components/Popup/Popup';
import { Layout } from 'components/Layout/Layout';
import { Input } from 'components/Input/Input';
import { Heading } from 'components/Heading/Heading';
import { Button } from 'components/Button/Button';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { emailValidation } from 'data/regexPatterns';
import { FORGOT_PASSWORD } from 'graphql/mutations/forgotPassword';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import greenCarFull from 'assets/img/green-car-full.png';
import { appContext } from 'views/App';

const StyledContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-top: 120px;
  .forgotPasswordError {
    padding-top: 10px;
  }
`;

const StyledTopContent = styled.div`
  padding-bottom: 20px;
`;

export const ForgotPassword = () => {
  const appContextStore = useContext(appContext);
  const setIsLoading = appContextStore?.setIsLoading;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isResetFailed, setIsResetFailed] = useState<boolean>(false);
  const [isPopupOpened, setIsPopupOpened] = useState<boolean>(false);
  const methods = useForm({
    mode: 'onSubmit',
  });
  const { handleSubmit, getValues, watch } = methods;
  const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD, {
    onCompleted: (data) => {
      if (data) {
        if (data.forgotPassword === 'User not found') {
          setIsResetFailed(true);
        } else {
          setIsPopupOpened(true);
        }
      }
    },
  });
  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);
  const onSubmit = () => {
    forgotPassword({ variables: { email: getValues('email') } });
  };
  useEffect(() => {
    setIsResetFailed(false);
  }, [watch('email')]);
  return (
    <Layout
      isWithMapBackground
      mode="medium"
      topContent={<img src={greenCarFull} alt="Green car" />}
      topContentPosition="center"
      isBackBtn
      isWithAppWrapper={false}
      outsideElements={
        <Popup
          content={t('resetPasswordSuccess')}
          isPopupOpened={isPopupOpened}
          type="info"
          onDecline={() => navigate('/sign-in')}
          confirmText={t('ok')}
        />
      }
    >
      <FormProvider {...methods}>
        <StyledContainer onSubmit={handleSubmit(onSubmit)}>
          <StyledTopContent>
            <Heading textAlign="center" color="var(--green)" dimension="xl">
              {t('resetPasswordTitle')}
            </Heading>
            <Input
              name="email"
              label={t('email')}
              placeholder={t('emailPlaceholder')}
              isRequired
              requiredErrorMessage={t('requiredError')}
              validation={emailValidation}
              validationErrorMessage={t('emailValidationError')}
            />
            <Paragraph
              color="var(--red)"
              dimension="xs"
              className="forgotPasswordError"
            >
              {isResetFailed ? t('resetPasswordError') : ''}
            </Paragraph>
          </StyledTopContent>
          <Button type="submit">{t('resetPassword')}</Button>
        </StyledContainer>
      </FormProvider>
    </Layout>
  );
};
