import { IconProps } from '../types';

export const MapIcon = ({
  size = '24',
  fill = 'var(--green)',
  stroke = 'var(--green)',
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1459_15765)">
        <path
          d="M8.65039 8.49023C8.65039 8.21311 8.86979 7.99023 9.15039 7.99023C9.42425 7.99023 9.65039 8.21638 9.65039 8.49023C9.65039 8.76409 9.42425 8.99023 9.15039 8.99023C8.87653 8.99023 8.65039 8.76409 8.65039 8.49023Z"
          fill={fill}
          stroke={stroke}
        />
        <path
          d="M20.999 5.2337C20.9983 5.23458 20.9974 5.2355 20.9964 5.23645L5.23682 20.9961C5.23668 20.9962 5.23645 20.9963 5.2361 20.9965C5.23497 20.9971 5.2335 20.9977 5.23203 20.998C5.23183 20.998 5.23165 20.998 5.23148 20.9981C3.50827 20.2528 2.5 18.6143 2.5 16.19V7.81C2.5 4.87614 4.87614 2.5 7.81 2.5H16.19C18.6153 2.5 20.2542 3.50914 20.999 5.2337ZM9.12 13.3C9.74588 13.3 10.3843 13.0788 10.8766 12.6004C11.913 11.6146 13.2103 9.91124 12.7272 7.8277C12.2819 5.88395 10.569 5.04 9.12 5.04C7.67217 5.04 5.95911 5.88284 5.51308 7.81638C5.01874 9.91178 6.31819 11.6165 7.36671 12.6035C7.85318 13.0646 8.48748 13.3 9.12 13.3Z"
          fill={fill}
          stroke={stroke}
        />
        <path
          d="M13.9973 15.7736L13.9977 15.7739L19.089 20.8553C18.3086 21.2749 17.3456 21.5 16.1909 21.5H8.26798L13.9944 15.7736C13.9949 15.7731 13.9954 15.7726 13.9959 15.7722C13.9963 15.7726 13.9968 15.7731 13.9973 15.7736Z"
          fill={fill}
          stroke={stroke}
        />
        <path
          d="M21.4998 8.257V16.1899C21.4998 17.3468 21.2738 18.3184 20.8552 19.0883L15.7633 13.9963C15.7628 13.9958 15.7623 13.9953 15.7619 13.9949C15.7623 13.9945 15.7628 13.994 15.7633 13.9935L21.4998 8.257Z"
          fill={fill}
          stroke={stroke}
        />
      </g>
      <defs>
        <clipPath id="clip0_1459_15765">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
