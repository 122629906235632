import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Paragraph } from 'components/Paragraph/Paragraph';
import logo from 'assets/img/logoWithoutBackground.png';
import { UserInfo } from 'components/UserInfo/UserInfo';
import { User } from 'types/User';
import { Button, ButtonProps } from 'components/Button/Button';
import car from 'assets/img/green-car.png';
import { CallOutgoing } from 'components/Icons/CallOutgoing/CallOutgoing';
import { MessageTextIcon } from 'components/Icons/MessageTextIcon/MessageTextIcon';

interface UserInformationContainerProps {
  user?: User;
  role?: 'user' | 'driver';
}
const StyledContainer = styled.div`
  background-color: ${(props) => props.theme.primary};
  width: 100%;
  height: 159px;
  border-radius: 20px;
  margin-bottom: 16px;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
`;

const StyledContent = styled.div`
  height: 123px;
  background-color: var(--grey-dark);
  border-radius: 20px;
  padding: 16px 0 16px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  margin-top: 16px;
`;

const StyledButton = ({ children, className }: ButtonProps) => (
  <Button className={className} mode="alt">
    {children}
  </Button>
);

const OverstyledButton = styled(StyledButton)`
  width: 110px;
  background-color: var(--white);
  border: 1px solid var(--secondary--green-opacity);
  border-radius: 42px;
  font-size: var(--text-m);
  :first-child {
    margin-right: 4px;
  }
  :last-child {
    margin-left: 4px;
  }
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  .icon {
    margin-left: 5px;
  }
`;

export const UserInformationContainer = ({
  user,
  role,
}: UserInformationContainerProps) => {
  const { t } = useTranslation();
  return (
    <StyledContainer>
      <StyledHeader>
        <Paragraph color="var(--black-text)" weight="bold">
          {t(
            `${role === 'driver' ? 'driveInformation' : 'customerInformation'}`,
          )}
        </Paragraph>
        <img src={logo} alt="Falsh Parker Logo" />
      </StyledHeader>
      <StyledContent>
        <div>
          <UserInfo layout="horizontal" user={{ ...user, role }} />
          <StyledButtonContainer>
            <OverstyledButton>
              <StyledLink href={`tel:${user?.phonePrefix}${user?.phoneNumber}`}>
                {t('call')}
                <CallOutgoing size="16" className="icon" />
              </StyledLink>
            </OverstyledButton>
            <OverstyledButton>
              <StyledLink
                href={`whatsapp://send?phone=${user?.phonePrefix}${user?.phoneNumber}`}
              >
                {t('message')}
                <MessageTextIcon size="18" className="icon" />
              </StyledLink>
            </OverstyledButton>
          </StyledButtonContainer>
        </div>
        <img src={car} alt="Car" height="69" width="auto" />
      </StyledContent>
    </StyledContainer>
  );
};
