import { CompanyInfo } from 'components/CompanyInfo/CompanyInfo';
import { Map } from 'components/Map/Map';

export const MainInformation = () => {
  return (
    <div>
      <CompanyInfo />
      <Map position={[47.376471, 8.540403]} height="300px" />
    </div>
  );
};
