import { Route, Routes } from 'react-router-dom';
import { ActivateParking } from 'views/ActivateParking/ActivateParking';
import { ActivateParkingUuid } from 'views/ActivateParking/ActivateParkingUuid/ActivateParkingUuid';
import { AddTowingMedia } from 'views/Report/AddTowingMedia/AddTowingMedia';
import { CarOwnerContractSuccess } from 'views/Report/VerifyCarOwner/CarOwnerContractSuccess/CarOwnerContractSuccess';
import { ClientReports } from 'views/Clients/ClientReports/ClientReports';
import { ConfirmPickedCar } from 'views/ConfirmPickedCar/ConfirmPickedCar';
import { Contract } from 'views/Contract/Contract';
import { ContractSignature } from 'views/ContractSignature/ContractSignature';
import { FailedReport } from 'views/New/CreateNewReport/FailedReport/FailedReport';
import { ForgotPassword } from 'views/Root/ForgotPassword/ForgotPassword';
import { Dashboard } from 'views/Root/Dashboard/Dashboard';
import { Notifications } from 'views/Root/Notifications/Notifications';
import { OrderAddress } from 'views/New/CreateNewReport/OrderAddress/OrderAddress';
import { PageNotFound } from 'views/Root/PageNotFound/PageNotFound';
import { CirculationCompensation } from 'views/ParkingTickets/CirculationCompensation/CirculationCompensation';
import { CarDriverDetails } from 'views/ParkingTickets/TicketOrPlateNumber/TicketDetails/CarDriverDetails/CarDriverDetails';
import { CheckDetails } from 'views/ParkingTickets/TicketOrPlateNumber/TicketDetails/CarDriverDetails/CheckDetails/CheckDetails';
import { SuccessCarOwnerAdding } from 'views/ParkingTickets/TicketOrPlateNumber/TicketDetails/CarDriverDetails/CheckDetails/SuccessCarOwnerAdding/SuccessCarOwnerAdding';
import { ObjectionRaise } from 'views/ParkingTickets/TicketOrPlateNumber/TicketDetails/ObjectionRaise/ObjectionRaise';
import { SuccessObjectionAdding } from 'views/ParkingTickets/TicketOrPlateNumber/TicketDetails/ObjectionRaise/SuccessObjectionAdding/SuccessObjectionAdding';
import { PaymentAGB } from 'views/ParkingTickets/TicketOrPlateNumber/TicketDetails/PaymentInformation/PaymentAGB/PaymentAGB';
import { PaymentFeedback } from 'views/ParkingTickets/TicketOrPlateNumber/TicketDetails/PaymentInformation/PaymentFeedback/PaymentFeedback';
import { SuccessPayment } from 'views/ParkingTickets/TicketOrPlateNumber/TicketDetails/PaymentInformation/PaymentFeedback/SuccessPayment/SuccessPayment';
import { TicketDetails } from 'views/ParkingTickets/TicketOrPlateNumber/TicketDetails/TicketDetails';
import { TicketOrPlateNumber } from 'views/ParkingTickets/TicketOrPlateNumber/TicketOrPlateNumber';
import { PhotoPreview } from 'views/New/CreateNewReport/PhotoPreview/PhotoPreview';
import { PlateCheck } from 'views/New/PlateCheck/PlateCheck';
import { PlateStatus } from 'views/New/PlateCheck/PlateStatus/PlateStatus';
import { AboutUs } from 'views/Profile/AboutUs/AboutUs';
import { AGB } from 'views/Profile/AGB/AGB';
import { BankDetails } from 'views/Profile/Cashback/BankTransfer/BankDetails/BankDetails';
import { SuccessfulBankDetails } from 'views/Profile/Cashback/BankTransfer/BankDetails/SuccessfulBankDetails/SuccessfulBankDetails';
import { BankTransfer } from 'views/Profile/Cashback/BankTransfer/BankTransfer';
import { Cashback } from 'views/Profile/Cashback/Cashback';
import { DataProtection } from 'views/Profile/DataProtection/DataProtection';
import { ChangePassword } from 'views/Profile/EditProfile/ChangePassword/ChangePassword';
import { EditProfile } from 'views/Profile/EditProfile/EditProfile';
import { FAQ } from 'views/Profile/FAQ/FAQ';
import { HelpAndSupport } from 'views/Profile/HelpAndSupport/HelpAndSupport';
import { Imprint } from 'views/Profile/Imprint/Imprint';
import { Language } from 'views/Profile/Language/Language';
import { LicenseDetails } from 'views/Profile/Licenses/LicenseDetails/LicenseDetails';
import { Licenses } from 'views/Profile/Licenses/Licenses';
import { Profile } from 'views/Profile/Profile';
import { ParkingDetails } from 'views/Profile/Parking/ParkingDetails/ParkingDetails';
import { ReferFriendFlow } from 'views/Profile/ReferFriendFlow/ReferFriendFlow';
import { QrCodeScanner } from 'views/New/CreateNewReport/QrCodeScanner/QrCodeScanner';
import { PaymentInformation } from 'views/ParkingTickets/TicketOrPlateNumber/TicketDetails/PaymentInformation/PaymentInformation';
import { SelectParkingZone } from 'views/New/CreateNewReport/SelectParkingZone/SelectParkingZone';
import { SignIn } from 'views/Root/SignIn/SignIn';
import { SignUp } from 'views/Root/SignUpTypeSelect/SignUp/SignUp';
import { SignUpTypeSelect } from 'views/Root/SignUpTypeSelect/SignUpTypeSelect';
import { SuccessfulContract } from 'views/SuccessfulContract/SuccessfulContract';
import { SuccessfulLicenseAdding } from 'views/Profile/Licenses/LicenseDetails/SuccessfulLicenseAdding/SuccessfulLicenseAdding';
import { SuccessfulOrder } from 'views/New/CreateNewReport/SuccessfulOrder/SuccessfulOrder';
import { UserReportAddress } from 'views/New/CreateNewReport/UserReportAddress/UserReportAddress';
import { VerifyCarOwner } from 'views/Report/VerifyCarOwner/VerifyCarOwner';
import { VideoPreview } from 'views/New/CreateNewReport/VideoPreview/VideoPreview';
import { Welcome } from 'views/Root/Welcome/Welcome';
import { History } from 'views/History/History';
import { Report } from 'views/Report/Report';
import { Clients } from 'views/Clients/Clients';
import { Parking } from 'views/Profile/Parking/Parking';
import { SuccessfulSignUp } from 'views/Root/SignUpTypeSelect/SignUp/SuccessfulSignUp/SuccessfulSignUp';
import { DriverSignUp } from 'views/Root/SignUpTypeSelect/DriverSignUp/DriverSignUp';
import { SuccessfulBecomeDriver } from 'views/Root/SignUpTypeSelect/DriverSignUp/SuccessfulBecomeDriver/SuccessfulBecomeDriver';
import { SuccessfulDriverOrder } from 'views/New/CreateNewReport/SuccessfulDriverOrder/SuccessfulDriverOrder';
import { ConfirmationPickup } from 'views/ConfirmationPickup/ConfirmationPickup';
import { CreateNewReport } from 'views/New/CreateNewReport/CreateNewReport';

export const AppRoutes = () => {
  return (
    <>
      <Routes data-testid="router-all">
        {/* Miscelaneous routes, need to be put into new or existing routes  */}
        {/* // create car owner or create first parking */}
        <Route path="/contract-success" element={<SuccessfulContract />} />
        <Route
          path="/ticket-payment/:paymentStatus"
          element={<PaymentFeedback />}
        />
        {/* // When user gets qr code ticket, and they check it to submit a payment */}
        <Route path="/clients" element={<Clients />} />
        <Route path="/reports/:id" element={<ClientReports />} />
        {/* scan qr code to activate temporary parking permission */}
        <Route path="/parking/:uuid" element={<ActivateParkingUuid />} />
        {/* scan qr code to activate temporary parking permission */}
        <Route path="/activate-parking" element={<ActivateParking />} />
        <Route path="/ticket/:ticketId" element={<TicketOrPlateNumber />} />
        <Route path="/ticket-details/:qrCodeId" element={<TicketDetails />} />
        <Route
          path="/car-driver-details/:qrCodeId"
          element={<CarDriverDetails />}
        />
        <Route path="/check-details/:qrCodeId" element={<CheckDetails />} />
        <Route path="/success-payment" element={<SuccessPayment />} />
        <Route path="/objection-raise/:qrCodeId" element={<ObjectionRaise />} />
        <Route
          path="/payment-information/:qrCodeId"
          element={<PaymentInformation />}
        />
        <Route
          path="/success-objection-raise"
          element={<SuccessObjectionAdding />}
        />
        <Route path="/success-car-owner" element={<SuccessCarOwnerAdding />} />
        <Route
          path="/circulation-compensation"
          element={<CirculationCompensation />}
        />
        <Route path="/payment-agb" element={<PaymentAGB />} />

        {/* Root routes, accessible to public from /  */}
        <Route path="/" element={<Welcome />} />
        {/* change route location */}
        <Route path="/" element={<Dashboard />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUpTypeSelect />} />
        <Route path="/sign-up/:userType" element={<SignUp />} />
        <Route path="/sign-up/:userType/:refCode" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/signup-success" element={<SuccessfulSignUp />} />
        <Route path="/driver-signup" element={<DriverSignUp />} />
        <Route path="/success-driver" element={<SuccessfulBecomeDriver />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/*" element={<PageNotFound />} />

        {/* Profile Routes, accessible from /profile  */}
        <Route path="/profile" element={<Profile />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/help-and-support" element={<HelpAndSupport />} />
        <Route path="/language" element={<Language />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/agb" element={<AGB />} />
        <Route path="/data-protection" element={<DataProtection />} />
        <Route path="/imprint" element={<Imprint />} />
        <Route path="/edit-profile" element={<EditProfile />} />
        <Route path="/refer-friend-flow" element={<ReferFriendFlow />} />
        {/* // rename to parking details */}
        <Route path="/parking-details" element={<ParkingDetails />} />
        <Route path="/first-parking-details" element={<ParkingDetails />} />
        <Route path="/parking" element={<Parking />} />
        <Route path="/edit-license/:id" element={<LicenseDetails />} />
        <Route path="/edit-parking/:id" element={<ParkingDetails />} />
        <Route path="/licenses" element={<Licenses />} />
        <Route path="/license-details" element={<LicenseDetails />} />
        {/* //first parking only */}
        <Route path="/contract-signature" element={<ContractSignature />} />
        <Route path="/success-license" element={<SuccessfulLicenseAdding />} />
        <Route path="/contract" element={<Contract />} />
        <Route path="/cashback" element={<Cashback />} />
        <Route path="/bank-details" element={<BankDetails />} />
        <Route path="/edit-bank-details/:id" element={<BankDetails />} />
        <Route path="/bank-transfer" element={<BankTransfer />} />
        <Route
          path="/success-bank-details"
          element={<SuccessfulBankDetails />}
        />

        {/* View routes, accessible from /view  */}
        <Route path="/history/:type" element={<History />} />
        <Route path="/report/:hash/:type" element={<Report />} />
        <Route path="/verify-car-owner/:hash" element={<VerifyCarOwner />} />
        {/* // also used in Profile to create new parking*/}
        <Route path="/verify-signature/:hash" element={<ContractSignature />} />
        <Route path="/add-towing-media/:hash" element={<AddTowingMedia />} />
        {/* //fix path; picked up car not select parking */}
        <Route path="/select-parking/:hash" element={<ConfirmPickedCar />} />
        <Route
          path="/car-owner-contract-success"
          element={<CarOwnerContractSuccess />}
        />
        {/* // Used By View and New */}
        <Route path="/active-report/:hash" element={<ConfirmationPickup />} />

        {/* New routes, accessible from /new  */}
        <Route path="/plate-check" element={<PlateCheck />} />
        <Route path="/plate-status/:status" element={<PlateStatus />} />
        <Route path="/add-report/:type" element={<CreateNewReport />} />
        <Route path="/success-order" element={<SuccessfulOrder />} />
        <Route path="/failed-report" element={<FailedReport />} />
        <Route path="/qr-code-scanner" element={<QrCodeScanner />} />
        <Route path="/photo-preview/:photo" element={<PhotoPreview />} />
        <Route path="/video-preview/:video" element={<VideoPreview />} />
        <Route path="/order-address" element={<OrderAddress />} />
        <Route path="/user-report-address" element={<UserReportAddress />} />
        {/* // Used By View and New */}
        <Route path="/confirmation-pickup" element={<ConfirmationPickup />} />
        <Route
          path="/success-driver-order"
          element={<SuccessfulDriverOrder />}
        />
        <Route path="/select-parking-zone" element={<SelectParkingZone />} />
      </Routes>
    </>
  );
};
