import { gql } from '@apollo/client';

export const REGISTER = gql`
  mutation Register(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phonePrefix: String!
    $phoneNumber: String!
    $password: String!
    $passwordConfirmation: String!
    $companyName: String
    $companyAddress: String
  ) {
    register(
      input: {
        first_name: $firstName
        last_name: $lastName
        email: $email
        phone_prefix: $phonePrefix
        phone_number: $phoneNumber
        password: $password
        password_confirmation: $passwordConfirmation
        company_name: $companyName
        company_address: $companyAddress
      }
    ) {
      tokens {
        accessToken: access_token
        user {
          id
          firstName: first_name
          lastName: last_name
          email
          phonePrefix: phone_prefix
          phoneNumber: phone_number
          refCode: ref_code
          avatar
          verified
          companyName: company_name
          companyAddress: company_address
        }
      }
    }
  }
`;
