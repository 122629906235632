import { useLazyQuery } from '@apollo/client';
import { LICENSES } from 'graphql/queries/licenses';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Accordion } from 'components/Accordion/Accordion';
import licenseImg from 'assets/img/license.png';
import { ParkingAndLicensesImg } from 'styles/common';
import { Layout } from 'components/Layout/Layout';
import styled from 'styled-components';
import { PARKING_ZONES } from 'graphql/queries/parkingZones';
import { NoParkingContent } from './NoParkingContent/NoParkingContent';
import { ParkingAndLicensesCard } from 'components/Cards/ParkingAndLicensesCard/ParkingAndLicensesCard';
import { appContext } from 'views/App';

const StyledContainer = styled.div`
  padding-top: 75px;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 580px;
  gap: 15px;
  .addPropertyButton {
    text-transform: none;
  }
`;

interface License {
  id: number;
  carName: string;
  beneficiary: string;
  plate: string;
  parkingsMap: { parking: { id: string } }[];
}

export const Licenses = () => {
  const appContextStore = useContext(appContext);
  const setIsLoading = appContextStore?.setIsLoading;

  const { t } = useTranslation();
  const [licenses, setLicenses] = useState<License[] | null>(null);
  const [hasUserParking, setHasUserParking] = useState<boolean>(false);
  const navigate = useNavigate();
  const [licensesQuery, { loading: licensesLoading }] = useLazyQuery(LICENSES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLicenses(data?.licenses);
    },
  });
  const [parkingZones, { loading: parkingZonesLoading }] = useLazyQuery(
    PARKING_ZONES,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const properties = data?.parkingZones;
        setHasUserParking(Boolean(properties?.length));
      },
    },
  );

  useEffect(() => {
    licensesQuery();
    parkingZones();
  }, []);

  useEffect(() => {
    setIsLoading(licensesLoading || parkingZonesLoading);
  }, [licensesLoading, parkingZonesLoading]);

  const handleEditLicense = (element: string) => {
    const selectedLicense = licenses.find((license) =>
      element.includes(license.plate),
    );
    navigate(`/edit-license/${selectedLicense.id}`);
  };

  return (
    <Layout
      topContent={<ParkingAndLicensesCard />}
      topContentPosition="center"
      isBackBtn
      backButtonMode="text"
      viewTitle={t('licenseTitle')}
      backBtnLink="/profile"
      customerTopHeight={150}
    >
      <StyledContainer>
        {licensesLoading || parkingZonesLoading ? (
          <></>
        ) : (
          <>
            {hasUserParking ? (
              <Accordion
                title={t('addLicenses')}
                icon={<ParkingAndLicensesImg src={licenseImg} alt="License" />}
                isAccordionOpened={true}
                onClick={(element) => handleEditLicense(element)}
                elements={licenses?.map((license) => `${license?.plate}`)}
                addButtonTitle={t('newLicenseDetails')}
                onAddButtonClick={() => navigate('/license-details')}
              />
            ) : (
              <NoParkingContent />
            )}
          </>
        )}
      </StyledContainer>
    </Layout>
  );
};
