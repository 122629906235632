import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { Layout } from 'components/Layout/Layout';
import { Button } from 'components/Button/Button';
import { useAuthContext } from 'context/auth';
import { profileMenu } from 'data/profile';
import { LOGOUT } from 'graphql/mutations/logout';
import { useEffect } from 'react';
import { useAccountType } from 'hooks/useAccountType';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { deleteCookie } from 'utils/deleteCookie';
import { ProfileListSkeleton } from 'skeletons/ProfileListSkeleton';
import { ProfileUserBar } from './ProfileUserBar/ProfileUserBar';
import { SidebarList } from './SidebarList/SidebarList';
import { appContext } from 'views/App';
import * as Sentry from '@sentry/react';

const StyledProfileContainer = styled.div`
  padding-bottom: 20px;
`;
const StyledBottomContainer = styled.div`
  height: 46px;
`;

export const Profile = () => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;
  const setUserData = appContextStore?.setUserData;
  const setIsError = appContextStore?.setIsError;
  const setIsLoading = appContextStore?.setIsLoading;
  const setTheme = appContextStore?.setTheme;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setIsSignedIn, setAuthToken } = useAuthContext();
  const { handleAccountType } = useAccountType();

  const [logout, { loading }] = useMutation(LOGOUT, {
    onError: (error) => {
      Sentry.captureException(error);
      setIsError(true);
      setIsLoading(false);
      throw new Error(error.message);
    },
  });

  const handleLogout = () => {
    logout();
    setIsSignedIn(false);
    deleteCookie('PHPSESSID');
    setAuthToken(null);
    setIsLoading(false);
    setUserData(null);
    navigate('/');
    setTheme({
      primary: 'rgba(1, 213, 75, 1)',
      secondary: 'rgba(1, 213, 75, 1)',
    });
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <Layout isMenuBar userBarContent={<ProfileUserBar />}>
      {userData ? (
        <StyledProfileContainer>
          {profileMenu.map(
            (menu) =>
              handleAccountType(menu.role) && (
                <SidebarList
                  menuData={menu.items}
                  title={menu.title}
                  key={menu.title}
                />
              ),
          )}
          <Button mode="inverted" onBtnClick={handleLogout} id="logout-button">
            {t('logout')}
          </Button>
          <StyledBottomContainer />
        </StyledProfileContainer>
      ) : (
        <ProfileListSkeleton />
      )}
    </Layout>
  );
};
