import { Button } from 'components/Button/Button';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { ParkingZone } from 'types/ParkingZone';
import { appContext } from 'views/App';
import { Popup } from 'components/Popup/Popup';
import { TicketPrice } from './TicketPrice/TicketPrice';
import { TicketCashback } from './TicketCashback/TicketCashback';
import { GasStationInStorePrice } from './GasStationInStorePrice/GasStationInStorePrice';
import { GasStationInStoreCashback } from './GasStationInStoreCashback/GasStationInStoreCashback';
import { GasStationNotInStorePrice } from './GasStationNotInStorePrice/GasStationNotInStorePrice';
import { GasStationNotInStoreCashback } from './GasStationNotInStoreCashback/GasStationInStoreCashback';
import { TowingPrice } from './TowingPrice/TowingPrice';
import { TowingCashback } from './TowingCashback/TowingCashback';
import { TowingEmptyRunCashback } from './TowingEmptyRunCashback/TowingEmptyRunCashback';

const StyledFormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-top: 1px solid var(--grey);
  padding: 20px 0;
`;

interface IndividualPricesProps {
  isGasStationOn: boolean;
  isCashbackOn: boolean;
  selectedParking?: ParkingZone;
}

export const IndividualPrices = ({
  isGasStationOn,
  isCashbackOn,
  selectedParking,
}: IndividualPricesProps) => {
  const appContextStore = useContext(appContext);
  const isError = appContextStore?.isError;
  const isLoading = appContextStore?.isLoading;
  const { t } = useTranslation();

  const [isSaveButtonClicked, setIsSaveButtonClicked] =
    useState<boolean>(false);
  const [isSuccessfullSavePopupOpened, setIsSuccesfullSavePopupOpened] =
    useState<boolean>(false);

  const handlePopupClose = () => {
    setIsSaveButtonClicked(false);
    setIsSuccesfullSavePopupOpened(false);
  };

  useEffect(() => {
    if (!isSaveButtonClicked) return;
    if (isError) return;
    if (isLoading) setIsSuccesfullSavePopupOpened(false);

    if (isSaveButtonClicked && !isLoading) {
      setIsSuccesfullSavePopupOpened(true);
    }
  }, [isSaveButtonClicked, isLoading]);

  if (!selectedParking) return;
  return (
    <>
      <StyledFormSection>
        <Paragraph color="var(--black)" dimension="xl">
          {t('ticketSection')}
        </Paragraph>
        <TicketPrice
          selectedParkingId={selectedParking?.id || ''}
          value={selectedParking?.ticketFineValue}
          isSaveButtonClecked={isSaveButtonClicked}
        />
        <TicketCashback
          selectedParkingId={selectedParking?.id || ''}
          value={selectedParking?.ticketCashbackValue}
          isSaveButtonClecked={isSaveButtonClicked}
          isCashbackOn={isCashbackOn}
        />
      </StyledFormSection>
      {isGasStationOn ? (
        <StyledFormSection>
          <Paragraph color="var(--black)" dimension="xl">
            {t('gasStationSection')}
          </Paragraph>
          <GasStationInStorePrice
            selectedParkingId={selectedParking?.id || ''}
            value={selectedParking?.gasStationInStoreFineValue}
            isSaveButtonClecked={isSaveButtonClicked}
          />
          <GasStationInStoreCashback
            selectedParkingId={selectedParking?.id || ''}
            value={selectedParking?.gasStationInStoreCashbackValue}
            isSaveButtonClecked={isSaveButtonClicked}
            isCashbackOn={isCashbackOn}
          />
          <GasStationNotInStorePrice
            selectedParkingId={selectedParking?.id || ''}
            value={selectedParking?.gasStationFineValue}
            isSaveButtonClecked={isSaveButtonClicked}
          />
          <GasStationNotInStoreCashback
            selectedParkingId={selectedParking?.id || ''}
            value={selectedParking?.gasStationCashbackValue}
            isSaveButtonClecked={isSaveButtonClicked}
            isCashbackOn={isCashbackOn}
          />
        </StyledFormSection>
      ) : (
        <></>
      )}
      <StyledFormSection>
        <Paragraph color="var(--black)" dimension="xl">
          {t('towingSection')}
        </Paragraph>
        <TowingPrice
          selectedParkingId={selectedParking?.id || ''}
          value={selectedParking?.towingFineValue}
          isSaveButtonClecked={isSaveButtonClicked}
        />
        <TowingCashback
          selectedParkingId={selectedParking?.id || ''}
          value={selectedParking?.towingCashbackValue}
          isSaveButtonClecked={isSaveButtonClicked}
          isCashbackOn={isCashbackOn}
        />
        <TowingEmptyRunCashback
          selectedParkingId={selectedParking?.id || ''}
          value={selectedParking?.towingEmptyRunCashbackValue}
          isSaveButtonClecked={isSaveButtonClicked}
          isCashbackOn={isCashbackOn}
        />
      </StyledFormSection>
      <Button onBtnClick={() => setIsSaveButtonClicked(true)}>
        {t('save')}
      </Button>
      <Popup
        type="info"
        isPopupOpened={isSuccessfullSavePopupOpened}
        onDecline={handlePopupClose}
        onConfirm={handlePopupClose}
        content={t('confirmChangeSave')}
      />
    </>
  );
};
