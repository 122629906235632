import { ContractPointProps } from 'views/Contract/ContractPoint/ContractPoint';

export const contractPointsData: ContractPointProps[] = [
  {
    title: 'contractFirstPointTitle',
    content: 'contractFirstPointContent',
  },
  {
    title: 'contractSecondPointTitle',
    content: 'contractSecondPointContent',
  },
  {
    title: 'contractThirdPointTitle',
    content: 'contractThirdPointContent',
  },
  {
    title: 'contractFourthPointTitle',
    content: 'contractFourthPointContent',
  },
  {
    title: 'contractFifthPointTitle',
    content: 'contractFifthPointContent',
  },
  {
    title: 'contractSixthPointTitle',
    content: 'contractSixthPointContent',
  },
  {
    title: 'contractSeventhPointTitle',
    content: 'contractSeventhPointContent',
  },
  {
    title: 'contractEighthPointTitle',
    content: 'contractEighthPointContent',
  },
  {
    title: 'contractNinthPointTitle',
    content: 'contractNinthPointContent',
  },
  {
    title: 'contractTenthPointTitle',
    content: 'contractTenthPointContent',
  },
  {
    title: 'contractEleventhPointTitle',
    content: 'contractEleventhPointContent',
  },
  {
    title: 'contract12PointTitle',
    content: 'contract12PointContent',
  },
];
