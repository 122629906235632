import { Paragraph } from 'components/Paragraph/Paragraph';
import { useTranslation } from 'react-i18next';
import { ParkingZone } from 'types/ParkingZone';

interface ClientAddressProps {
  clientParking: ParkingZone;
  parkingIndex: number;
  handleOnParkingClick?: () => void;
}

export const ClientAddress = ({
  clientParking,
  parkingIndex,
  handleOnParkingClick,
}: ClientAddressProps) => {
  const { t } = useTranslation();
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        handleOnParkingClick && handleOnParkingClick();
      }}
    >
      <Paragraph dimension={'s'} color={'var(--sub--black)'}>
        {`${t('address')} ${parkingIndex + 1}`}
      </Paragraph>
      <Paragraph color="var(--black)" weight="bold">
        {clientParking?.streetName} {clientParking?.streetNumber}
      </Paragraph>
      <Paragraph color="var(--sub--black)">
        {clientParking?.zipCode} {clientParking?.city}
      </Paragraph>
      <Paragraph color="var(--sub--black)">{clientParking?.name}</Paragraph>
    </div>
  );
};
