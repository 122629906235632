import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CloseIcon } from 'components/Icons/CloseIcon/CloseIcon';
import { Popup } from 'components/Popup/Popup';
import { IconButton, IconButtonProps } from '../IconButton/IconButton';
import { appContext } from 'views/App';

interface CloseReportButtonProps {
  onConfirmClick: () => void;
}

const StyledIconButton = ({
  className,
  children,
  onClick,
}: IconButtonProps) => (
  <IconButton className={className} onClick={onClick}>
    {children}
  </IconButton>
);

const OverstyledIconButton = styled(StyledIconButton)`
  position: absolute;
  right: 20px;
  top: 11px;
  z-index: 1003;
  background-color: var(--white);
  padding: 9px;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CloseReportButton = ({
  onConfirmClick,
}: CloseReportButtonProps) => {
  const appContextStore = useContext(appContext);
  const isError = appContextStore?.isError;

  const { t } = useTranslation();
  const [isPopupOpened, setIsPopupOpened] = useState<boolean>(false);
  useEffect(() => {
    if (isError) {
      setIsPopupOpened(false);
    }
  }, [isError]);
  return (
    <div>
      <OverstyledIconButton onClick={() => setIsPopupOpened(true)}>
        <CloseIcon />
      </OverstyledIconButton>
      <Popup
        isPopupOpened={isPopupOpened}
        type="action"
        content={t('closeReportContent')}
        onDecline={() => setIsPopupOpened(false)}
        onConfirm={onConfirmClick}
        confirmText={t('yes')}
        declineText={t('no')}
      />
    </div>
  );
};
