import { gql } from '@apollo/client';

export const GAS_STATION_REPORT = gql`
  query GasStationReport($hash: String!) {
    gasStationReport(hash: $hash) {
      id
      hash
      plate
      carName: car_name
      street
      streetNumber: street_number
      zipCode: zip_code
      city
      createdAt: created_at
      paymentStatus: payment_status
      updated_at
      bill
      price
      description
      issueTime
      issueDate
      inStore: in_store
      photos {
        photo
      }
      user {
        id
        firstName: first_name
        lastName: last_name
        email
        phonePrefix: phone_prefix
        phoneNumber: phone_number
        avatar
      }
      userRewards {
        amount
        status
      }
      parkingZone {
        name
        user {
          id
          firstName: first_name
          lastName: last_name
          companyName: company_name
        }
      }
    }
  }
`;
