import { AppWrapper } from 'AppWrapper';
import { BackButton } from 'components/BackButton/BackButton';
import { LoadingAnimation } from 'components/LoadingAnimation/LoadingAnimation';
import { useWindowHeight } from 'hooks/useWindowHeight';
import { ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { appContext } from 'views/App';

const StyledBox = styled.div<{ height: number }>`
  min-height: ${({ height }) => `${height} px`};
  height: 100%;
`;
const StyledMainContainer = styled.div<{ height: number }>`
  background-color: var(--black-darkmode);
  min-height: ${({ height }) => `${height} px`};
  height: 100%;
  .deleteReportButton {
    margin-top: 12px;
  }
  .paymentStatus {
    width: max-content;
    max-width: max-content;
  }
  .downloadButton {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
`;
const StyledTopContainer = styled.div`
  position: fixed;
  height: 50px;
  width: 100%;
  background-color: var(--black-darkmode);
  top: 0;
  z-index: 1002;
`;
const StyledDetailsContainer = styled.div<{ height: number }>`
  height: ${({ height }) => `calc(${height}px - 70px)`};
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: var(--white);
  border-radius: 32px 32px 0px 0px;
`;
const StyledBottomContainer = styled.div`
  height: 46px;
`;
const StyledContentContainer = styled.div`
  height: 100%;
  overflow: auto;
  padding-top: 50px;
  position: relative;
`;

interface ReportLayoutProps {
  children?: ReactNode;
}

export const ReportLayout = ({ children }: ReportLayoutProps) => {
  const { windowHeight } = useWindowHeight();
  const { t } = useTranslation();
  const appContextStore = useContext(appContext);
  const isLoading = appContextStore?.isLoading;
  return (
    <StyledBox height={windowHeight}>
      <StyledMainContainer height={windowHeight}>
        <BackButton as="text">{t('orderDetails')}</BackButton>
        <StyledTopContainer />
        <StyledDetailsContainer height={windowHeight} />
        <StyledContentContainer>
          {children}
          <StyledBottomContainer />
        </StyledContentContainer>
      </StyledMainContainer>
      <AppWrapper />
      {isLoading && <LoadingAnimation />}
    </StyledBox>
  );
};
