import styled from 'styled-components/macro';
import { ReportOrderNumber } from '../ReportOrderNumber/ReportOrderNumber';
import { Status } from 'components/Status/Status';
import { getPaymentStatusColor } from 'utils/getPaymentStatusColor';
import { ReportDetail } from '../ReportDetail/ReportDetail';
import { ReportMainDetails } from '../ReportMainDetails/ReportMainDetails';
import { ReportCashbackStatus } from '../ReportCashbackStatus/ReportCashbackStatus';
import { ReportProps } from 'types/Report';
import { getReportStatusColor } from 'utils/getReportStatusColor';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { ReportPaymentStatus } from '../ReportPaymentStatus/ReportPaymentStatus';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAccountType } from 'hooks/useAccountType';

const StyledDetails = styled.div`
  padding-left: 6px;
  min-width: 185px;
  width: 100%;
  .paragraph {
    margin-bottom: 4px;
  }
  .lastParagraph {
    margin-top: 4px;
  }
  p {
    word-break: break-all;
  }
`;
const StyledFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

interface ReportDetailsProps {
  report: ReportProps;
}

export const ReportDetails = ({ report }: ReportDetailsProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isUserReportsPage = location.pathname.includes('reports');
  const isGasStationReport = location.pathname.includes('station');
  const { isThisAccountType: isUserAccount } = useAccountType('user');
  const isNotUserAccount = !isUserAccount;
  return (
    <StyledDetails>
      <StyledFlexContainer>
        <ReportOrderNumber reportId={report?.id} />
        {isGasStationReport && report?.paymentStatus && (
          <Status
            customText={t(report?.paymentStatus)}
            mode={'report'}
            size="xxs"
            color={getPaymentStatusColor(report?.paymentStatus)}
            className="paymentStatus"
          />
        )}
        {report?.status && (
          <Status
            status={t(report?.status)}
            mode="report"
            size="xs"
            color={getReportStatusColor(report?.status)}
          />
        )}
      </StyledFlexContainer>
      {(isUserAccount || isUserReportsPage) && report?.driverMap && (
        <ReportDetail
          detailLabel="driverName"
          detailValue={`${report?.driverMap?.driver?.firstName} ${report?.driverMap?.driver?.lastName}`}
        />
      )}
      {isNotUserAccount && !isUserReportsPage && report?.user && (
        <ReportDetail
          detailLabel="userName"
          detailValue={`${report?.user?.firstName} ${report?.user?.lastName}`}
        />
      )}
      <ReportMainDetails
        reportPate={report?.plate}
        reportStreet={report?.street}
        reportStreetNumber={report?.streetNumber}
        reportZipCode={report?.zipCode}
        reportCity={report?.city}
      />
      {isNotUserAccount && !isUserReportsPage && (
        <ReportDetail
          detailLabel="clientName"
          detailValue={
            report?.parkingZone?.user?.companyName
              ? report?.parkingZone?.user?.companyName
              : report?.parkingZone?.name
              ? report?.parkingZone?.name
              : `${report?.user?.firstName} ${report?.user?.lastName}`
          }
        />
      )}
      <Paragraph className="report-date" dimension={'s'} color={'var(--black)'}>
        {report?.createdAt}
      </Paragraph>
      {isUserReportsPage && (
        <ReportDetail
          detailLabel="paymentMethod"
          detailValue={report?.carOwner?.paymentType}
        />
      )}
      {((isUserAccount && !isGasStationReport) || isUserReportsPage) &&
        report?.paymentStatus && (
          <ReportPaymentStatus paymentStatus={report?.paymentStatus} />
        )}
      {isUserAccount && (
        <ReportDetail
          detailLabel="userName"
          detailValue={`${report?.user?.firstName} ${report?.user?.lastName}`}
        />
      )}
      {isUserReportsPage &&
        report?.userRewards?.length > 0 &&
        report?.status != 'cancelled' && (
          <ReportCashbackStatus
            cashbackStatus={report?.userRewards[0]?.status}
            cashbackAmount={report?.userRewards[0]?.amount}
          />
        )}
      {report?.price && (
        <ReportDetail
          detailLabel="priceGasStation"
          detailValue={`${report?.price} CHF`}
        />
      )}
      {report?.issueDate && report?.issueTime && (
        <ReportDetail
          detailLabel="billTime"
          detailValue={`${report?.issueDate}, ${report?.issueTime}`}
        />
      )}
    </StyledDetails>
  );
};
