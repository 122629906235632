import { Layout } from 'components/Layout/Layout';
import styled from 'styled-components';
import greenCarFull from 'assets/img/green-car-full.png';
import { Heading } from 'components/Heading/Heading';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { Anchor } from 'components/Anchor/Anchor';

const StyledContent = styled.div`
  padding-top: 130px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const StyledAnchorContainer = styled.div`
  padding-top: 30px;
`;

export const SignUpTypeSelect = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Layout
      isWithAppWrapper={false}
      isWithMapBackground
      mode="standard"
      topContent={<img src={greenCarFull} />}
      topContentPosition="center"
      isBackBtn
    >
      <StyledContent>
        <Heading color="var(--white-always)">{t('selectUserType')}</Heading>
        <Button mode="inverted" onBtnClick={() => navigate('/sign-up/company')}>
          {t('companyClient')}
        </Button>
        <Button mode="inverted" onBtnClick={() => navigate('/sign-up/user')}>
          {t('userClient')}
        </Button>
        <StyledAnchorContainer>
          <Anchor
            link="/sign-in"
            linkTitle={t('signin')}
            label={t('signInLabel')}
            size="s"
            textAlign="center"
            textColor="white-text"
          />
        </StyledAnchorContainer>
      </StyledContent>
    </Layout>
  );
};
