import { IconProps } from '../types';

export const CoinsIcon = ({
  size = '24',
  stroke = 'var(--white)',
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 11.8992C8 12.6692 8.6 13.2992 9.33 13.2992H10.83C11.47 13.2992 11.99 12.7492 11.99 12.0792C11.99 11.3492 11.67 11.0892 11.2 10.9192L8.8 10.0792C8.32 9.90922 8 9.64922 8 8.91922C8 8.24922 8.52 7.69922 9.16 7.69922H10.66C11.4 7.70922 12 8.32922 12 9.09922"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 13.3496V14.0896"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 6.91016V7.69016"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99 18.48C14.4028 18.48 17.98 14.9028 17.98 10.49C17.98 6.07724 14.4028 2.5 9.99 2.5C5.57724 2.5 2 6.07724 2 10.49C2 14.9028 5.57724 18.48 9.99 18.48Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9805 20.38C13.8805 21.65 15.3505 22.48 17.0305 22.48C19.7605 22.48 21.9805 20.26 21.9805 17.53C21.9805 15.87 21.1605 14.4 19.9105 13.5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
