import { Heading } from 'components/Heading/Heading';
import { Paragraph, ParagraphProps } from 'components/Paragraph/Paragraph';
import { ReactNode } from 'react';
import styled from 'styled-components/macro';

const StyledParagraph = ({ className, children }: ParagraphProps) => (
  <Paragraph
    className={className}
    dimension="l"
    textAlign="center"
    color="var(--white-text)"
  >
    {children}
  </Paragraph>
);
const OverstyledParagraph = styled(StyledParagraph)`
  width: 80%;
  margin: 0 auto;
`;

interface ConfrimHeadingProps {
  title: string | ReactNode;
  subtitle: string;
}

export const ConfrimHeading = ({ title, subtitle }: ConfrimHeadingProps) => {
  return (
    <div>
      <Heading
        dimension="xl"
        textAlign="center"
        color="var(--white-text)"
        className="title"
      >
        {title}
      </Heading>
      <OverstyledParagraph
        dimension="l"
        textAlign="center"
        color="var(--white-text)"
      >
        {subtitle}
      </OverstyledParagraph>{' '}
    </div>
  );
};
