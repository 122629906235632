import { IconProps } from '../types';

export const ClientsIcon = ({
  size = '24',
  stroke = 'var(--white)',
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1992 21H7.19922C3.19922 21 2.19922 20 2.19922 16V8C2.19922 4 3.19922 3 7.19922 3H17.1992C21.1992 3 22.1992 4 22.1992 8V16C22.1992 20 21.1992 21 17.1992 21Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1992 8H19.1992"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1992 12H19.1992"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.1992 16H19.1992"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.69867 11.2919C9.69831 11.2919 10.5087 10.4815 10.5087 9.48187C10.5087 8.48224 9.69831 7.67188 8.69867 7.67188C7.69904 7.67188 6.88867 8.48224 6.88867 9.48187C6.88867 10.4815 7.69904 11.2919 8.69867 11.2919Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1992 16.3317C12.0592 14.8817 10.9092 13.7417 9.45922 13.6117C8.95922 13.5617 8.44922 13.5617 7.93922 13.6117C6.48922 13.7517 5.33922 14.8817 5.19922 16.3317"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
