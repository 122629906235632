import { Button } from 'components/Button/Button';
import styled from 'styled-components';

const StyledButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;

interface ConfirmButtonContainerProps {
  onDeclineClick?: () => void;
  declineText?: string;
  onConfirmClick?: () => void;
  confirmText?: string;
  confirmButtonId?: string;
}

export const ConfirmButtonContainer = ({
  onDeclineClick,
  declineText,
  onConfirmClick,
  confirmText,
  confirmButtonId,
}: ConfirmButtonContainerProps) => {
  if (!onConfirmClick || !onDeclineClick) return;
  return (
    <StyledButtonContainer>
      <Button
        onBtnClick={onDeclineClick}
        mode="inverted"
        className="declineButton"
      >
        {declineText}
      </Button>
      <Button
        onBtnClick={onConfirmClick}
        className="confirmButton"
        id={confirmButtonId}
      >
        {confirmText}
      </Button>
    </StyledButtonContainer>
  );
};
