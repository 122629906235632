import { gql } from '@apollo/client';

export const CREATE_REPORT_NEW = gql`
  mutation CreateReportNew(
    $fullAddress: String!
    $carName: String!
    $city: String!
    $latitude: Float!
    $longitude: Float!
    $plate: String!
    $street: String!
    $streetNumber: String!
    $type: Int!
    $zipCode: String!
    $images: [Int!]!
    $parkingZoneId: ID
    $qrCodeId: String
    $notes: String
  ) {
    createReportNew(
      input: {
        address_full: $fullAddress
        car_name: $carName
        city: $city
        latitude: $latitude
        longitude: $longitude
        plate: $plate
        street: $street
        street_number: $streetNumber
        type: $type
        zip_code: $zipCode
        parkingZone: { connect: $parkingZoneId }
        notes: $notes
        qr_code_id: $qrCodeId
      }
      images: $images
    ) {
      id
      hash
    }
  }
`;
