import styled from 'styled-components';
import { HistoryListItem } from '../HistoryListItem/HistoryListItem';
import { CashbackPrevHistoryItem } from 'types/Cashback';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { useEffect, useState } from 'react';

interface HistoryListProps {
  history: CashbackPrevHistoryItem[];
}
const StyledGroup = styled.div`
  margin-top: 15px;
`;
const StyledGroupTitle = styled.p`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid var(--sub--black);
  line-height: 0.1em;
  margin: 25px 0;
  font-size: var(--text-m);
  font-weight: bold;
`;
const StyledTitle = styled.span`
  background: var(--white);
  padding: 0px 10px;
`;

export const PrevHistoryList = ({ history }: HistoryListProps) => {
  const groups = history?.map((item) => item.group);
  const [selectedGroup, setSelectedGroup] = useState<string>('all');
  const [selectedHistory, setSelectedHistory] =
    useState<CashbackPrevHistoryItem[]>(history);
  useEffect(() => {
    if (selectedGroup === 'all') {
      setSelectedHistory(history);
    } else {
      const newHistory = history.filter((item) => item.group === selectedGroup);
      setSelectedHistory(newHistory);
    }
  }, [selectedGroup]);
  if (!selectedHistory || selectedHistory?.length <= 0) return;
  return (
    <StyledGroup>
      <Dropdown
        options={['all', ...groups]}
        mode="full"
        defaultOption={'all'}
        handleSelectedOption={(option) => setSelectedGroup(option)}
      />

      {selectedHistory.map((item) => (
        <div key={item?.group}>
          <StyledGroupTitle>
            <StyledTitle>{item.group}</StyledTitle>
          </StyledGroupTitle>
          <ul>
            {item.history?.map((listItem, index) => (
              <HistoryListItem
                key={index}
                title={`Report ${
                  listItem.report?.id
                    ? listItem.report?.id
                    : listItem.gasStationReport?.id
                }`}
                date={listItem.createdAt}
                amount={listItem.amount}
              />
            ))}
          </ul>
        </div>
      ))}
    </StyledGroup>
  );
};
