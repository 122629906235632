export const agb = {
  agbContent: `
Allgemeine Geschäftsbedingungen (AGB) der TowShare Group GmbH und der FalschParker App
Datum der Änderung: 4. September 2024

Wichtige Informationen im Überblick

Diese Allgemeinen Nutzungsbedingungen ("Bedingungen") gelten für die mobilen Anwendungen (App) und/oder Webseiten der TowShare Group GmbH in der Schweiz. Sie ermöglichen es Ihnen, Dienstleistungen von Drittanbietern oder direkt über die FalschParker App anzufordern. Diese Dienstleistungen werden unter den Marken der TowShare Group erbracht oder bereitgestellt. Weitere Informationen finden Sie auf dieser Seite.

Bei der Inanspruchnahme von Dienstleistungen durch Drittanbieter schliessen Sie einen Vertrag direkt mit der TowShare Group GmbH ab, die als technologische Vermittlungsplattform fungiert. Sie können diese Dienstleistungen über die Plattform anfordern und erhalten. In einigen Kantonen tritt die TowShare Group GmbH zudem als Anbieter von Abschleppdiensten auf.

Bitte lesen Sie diese Bedingungen sorgfältig durch. Sie enthalten wichtige Informationen über die angebotenen Dienste, Ihre Rechte und weitere relevante Details. TowShare Group GmbH, einer in der Schweiz ansässigen Gesellschaft mit beschränkter Haftung, ab.

Sie schließen einen Vertrag mit der TowShare Group GmbH, einer in der Schweiz ansässigen Gesellschaft mit beschränkter Haftung, ab.

Die FalschParker App ist Eigentum der:
- Firmenname: TowShare Group GmbH
- Strasse: Schützengasse 4
- Postleitzahl: 8001
- Stadt: Zürich
- Land: Schweiz
- Email: info@falsch-parker.ch



1. Geltungsbereich
Die FalschParker App und deren Dienstleistungen sind für Sie als Benutzer/User stets kostenlos. Diese Allgemeinen Geschäftsbedingungen (AGB) regeln die Beziehung zwischen der TowShare Group GmbH und den Nutzern, die die Plattform verwenden, um Umtriebsentschädigungen für unrechtmässig abgestellte Fahrzeuge auf ihrem Parkplatz oder Privatgrundstück ausstellen. Des Weiteren regeln diese AGB die Verwaltung und Kontrolle von Parkplätzen sowie das Abschleppen von Falschparkern. Diese Dienstleistungen werden von der TowShare Group GmbH im Auftrag von Immobilieneigentümern, Verwaltungen, Facility Service Provider oder Mietern durchgeführt.

2. Nutzungsberechtigung
Der Nutzer bestätigt, dass er Eigentümer oder Mieter des Parkplatzes oder Privatgrundstücks ist, auf dem das Fahrzeug unrechtmäßig abgestellt wurde.

3. Geänderte Nutzungsbedingungen
Die TowShare Group GmbH behält sich das Recht vor, jederzeit Änderungen an den AGB vorzunehmen. Die jeweils aktuelle Version ist auf der Webseite der TowShare Group GmbH  und der FalschParker App einsehbar.

4. Parkplatzkontrollen und Umtriebsentschädigungen
Die Benutzer sowie auch Parkguards der TowShare Group GmbH führen regelmässige Parkplatzkontrollen im Auftrag von Immobilieneigentümern oder Mietern durch. Bei Verstössen gegen die Parkregeln wird eine Umtriebsentschädigung erhoben. Diese Umtriebsentschädigung wird Falschparkern auferlegt und dient der Deckung der entstandenen Verwaltungskosten.

5. Recht zur Erhebung und Abtretung der Forderungen
Die TowShare Group GmbH ist berechtigt, Umtriebsentschädigungen im Namen der Immobilieneigentümer oder Mieter zu erheben und tritt dabei als Rechnungsteller und Dienstleister auf. Die Kunden (Immobilieneigentümer oder Mieter) übertragen der TowShare Group GmbH das Recht zur Geltendmachung dieser Forderungen.

6. Cashback-Regelung
Immobilieneigentümer oder Mieter können eine Rückvergütung (Cashback) für erhobene Umtriebsentschädigungen erhalten. Die Höhe und Bedingungen des Cashbacks werden individuell vertraglich festgelegt.

7. Haftungsausschluss
Die TowShare Group GmbH übernimmt keine Haftung für Schäden oder Verluste, die vor dem Abschleppen eines Fahrzeugs entstanden sind oder durch widerrechtliches Parken verursacht wurden.

8. Dokumentation und richterliches Verbot
Im Fall von Falschparkern dokumentiert die TowShare Group GmbH alle Vorgänge, einschliesslich der Erfassung von Geodaten und Zeitstempeln. Wo vorhanden, wird auf ein richterliches Verbot hingewiesen.

9. Einspruchsverfahren für Falschparker
Falschparker können innerhalb von 10 Werktagen nach Erhalt einer Umtriebsentschädigung schriftlich Einspruch erheben, entweder über Umtriebsentschädigung auf https://falsch-parker.ch oder per E-Mail an info@falsch-parker.ch.

10. Kostenübertragung
Der Nutzer überträgt der TowShare Group GmbH mittels Zession das Recht zur Berechnung und Durchsetzung der Kosten gegenüber dem Falschparker. Es entstehen keine Kosten für den Nutzer selbst.

11. Zugang gewähren
Der Nutzer verpflichtet sich, dem Abschleppunternehmen den Zugang zum Fahrzeug zu ermöglichen, um die Dienstleistung auszuführen.

12. Verfügbarkeit der Plattform
Die TowShare Group GmbH garantiert nicht, dass die Plattform jederzeit verfügbar oder fehlerfrei sein wird. Es können technische oder betriebliche Gründe für Einschränkungen bestehen.

13. Datenschutz
Die TowShare Group GmbH verarbeitet personenbezogene Daten gemäss den geltenden Datenschutzbestimmungen und der unternehmenseigenen Datenschutzerklärung. Alle Daten werden vertraulich behandelt, entsprechen den höchsten Verschlüsselungsstandards und werden auf Servern in der Schweiz gespeichert.

14. Änderungen der AGB
Die TowShare Group GmbH behält sich das Recht vor, die AGB jederzeit zu ändern. Die Änderungen werden rechtzeitig kommuniziert und auf der Webseite veröffentlicht.

15. Freistellung
Der Nutzer stellt die TowShare Group GmbH und das Abschleppunternehmen von allen Ansprüchen und Forderungen frei, die sich aus der Nutzung der Dienstleistungen 
ergeben.

16. Anwendbares Recht und Gerichtsstand
Diese AGB unterliegen dem Schweizer Recht. Gerichtsstand ist Zürich, Schweiz.`,
};
