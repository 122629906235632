import { CompanyInfo } from 'components/CompanyInfo/CompanyInfo';
import { Layout } from 'components/Layout/Layout';
import { Heading, HeadingProps } from 'components/Heading/Heading';
import { Paragraph, ParagraphProps } from 'components/Paragraph/Paragraph';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

const StyledHeading = ({ children, className }: HeadingProps) => (
  <Heading color="var(--black)" weight="bold" className={className}>
    {children}
  </Heading>
);

const StyledParagraph = ({ children, className }: ParagraphProps) => (
  <Paragraph color="var(--black)" className={className} weight="bolder">
    {children}
  </Paragraph>
);

const OverstyledHeading = styled(StyledHeading)`
  border-bottom: 1px solid var(--secondary-grey);
  line-height: 25px;
  margin-bottom: 16px;
`;

const OverstyledParagraph = styled(StyledParagraph)`
  line-height: 21px;
  margin-top: 16px;
`;

const StyledMailLink = styled.a`
  text-decoration: underline;
  text-decoration-color: var(--grey);
  color: var(--black);
`;

export const Imprint = () => {
  const { t } = useTranslation();
  return (
    <Layout mode="top" isBackBtn backButtonMode="text" viewTitle={t('imptint')}>
      <OverstyledHeading>{t('imptintTitle')}</OverstyledHeading>
      <CompanyInfo isWithTitle={false} />
      <OverstyledParagraph>UID CHE-492.819.659</OverstyledParagraph>
      <StyledMailLink href="mailto:info@falsch-parker.ch">
        <OverstyledParagraph>info@falsch-parker.ch</OverstyledParagraph>
      </StyledMailLink>
    </Layout>
  );
};
