import { ReactNode, useState } from 'react';
import styled from 'styled-components';
import { AccordionMainComponent } from './AccordionMainComponent/AccordionMainComponent';
import { AccordionElementList } from './AccordionElementList/AccordionElementList';
import { Paragraph } from 'components/Paragraph/Paragraph';

interface AccordionProps {
  title: string;
  icon?: ReactNode;
  isAccordionOpened?: boolean;
  elements?: string[];
  addButtonTitle?: string;
  onAddButtonClick?: () => void;
  selectedElements?: string[];
  onClick?: (element: string) => void;
  isAllElementClick?: boolean;
  onTitleClick?: (element: string) => void;
  isWithSelectIcon?: boolean;
  elementTitle?: string;
  accordionClassName?: string;
  elementWeight?: React.ComponentProps<typeof Paragraph>['weight'];
  elementTextTransform?: React.ComponentProps<
    typeof Paragraph
  >['textTransform'];
}

const StyledContainer = styled.div`
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding-bottom: 20px;
  }
`;

const AccordionMainComponentStyled = styled(AccordionMainComponent)`
  margin-bottom: 10px;

  @media (max-width: 768px) {
    margin-bottom: 5px;
  }
`;

const AccordionElementListStyled = styled(AccordionElementList)`
  margin-top: 10px;

  @media (max-width: 768px) {
    margin-top: 5px;
  }
`;

export const Accordion = ({
  title,
  icon,
  elements,
  isAccordionOpened = false,
  addButtonTitle,
  onAddButtonClick,
  selectedElements,
  onClick,
  isAllElementClick = true,
  onTitleClick,
  isWithSelectIcon = false,
  elementTitle,
  accordionClassName,
  elementWeight,
  elementTextTransform,
}: AccordionProps) => {
  const [isOpened, setIsOpened] = useState<boolean>(isAccordionOpened);
  return (
    <StyledContainer>
      <AccordionMainComponentStyled
        icon={icon}
        title={title}
        accordionClassName={accordionClassName}
        isOpened={isOpened}
        handleIsOpenedChange={(isOpened) => setIsOpened(isOpened)}
      />
      <AccordionElementListStyled
        isAccordionOpened={isOpened}
        accordionElements={elements}
        selectedAccordionElements={selectedElements}
        onTitleClick={onTitleClick}
        isAllElementClick={isAllElementClick}
        onElementClick={onClick}
        accordionElementTitle={elementTitle}
        isWithSelectIcon={isWithSelectIcon}
        addButtonTitle={addButtonTitle}
        onAddButtonClick={onAddButtonClick}
        weight={elementWeight}
        textTransform={elementTextTransform}
      />
    </StyledContainer>
  );
};
