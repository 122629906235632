import ContentLoader, { BulletList } from 'react-content-loader';

export const ProfileListSkeleton = () => (
  <div>
    <ContentLoader width="100%" height={50} animate={false}>
      <rect x="0" y="17" rx="4" ry="4" width="40%" height="20" />
      <rect x="0" y="47" rx="4" ry="4" width="100%" height="2" />
    </ContentLoader>
    <BulletList animate={false} />
    <ContentLoader width="100%" height={60} animate={false}>
      <rect x="0" y="25" rx="4" ry="4" width="40%" height="20" />
      <rect x="0" y="55" rx="4" ry="4" width="100%" height="2" />
    </ContentLoader>
    <BulletList animate={false} />
    <ContentLoader width="100%" height={60} animate={false}>
      <rect x="0" y="25" rx="4" ry="4" width="40%" height="20" />
      <rect x="0" y="55" rx="4" ry="4" width="100%" height="2" />
    </ContentLoader>
    <BulletList animate={false} />
  </div>
);
