import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IconProps } from 'components/Icons/types';
import { ArrowRightIcon } from 'components/Icons/ArrowRightIcon/ArrowRightIcon';
import { IosSwitch } from 'components/IosSwitch/IosSwitch';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { ThemeContext } from 'context/ThemeContext';
import { useContext } from 'react';
import { SidebarItemMainComponent } from '../SidebarItemMainComponent/SidebarItemMainComponent';

export interface SidebarListItemProps {
  isActive?: boolean;
  type: string;
  name: string;
  path?: string;
  icon?: (props: IconProps) => JSX.Element;
}

const SidebarItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 16px;
  a {
    color: var(--black);
    text-decoration: none;
    width: 100%;
  }
  .name {
    padding-left: 8px;
  }
`;
const SidebarItemElement = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 100%;
`;
const SidebarItemName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SidebarListItem: React.FC<SidebarListItemProps> = ({
  type,
  name,
  path,
  icon,
}) => {
  const { t } = useTranslation();
  const props = {
    stroke: 'var(--black)',
  };
  const { toggleTheme, isDarkMode } = useContext(ThemeContext);

  return (
    <SidebarItem>
      <SidebarItemMainComponent path={path}>
        <SidebarItemElement>
          <SidebarItemName>
            {icon && icon(props)}
            <Paragraph color="var(--black)" dimension="l" className="name">
              {t(name)}
            </Paragraph>
          </SidebarItemName>
          {type === 'link' && <ArrowRightIcon stroke="var(--black)" />}
          {name === 'darkMode' && type === 'switch' && (
            <IosSwitch isActive={isDarkMode} onToggle={toggleTheme} />
          )}
        </SidebarItemElement>
      </SidebarItemMainComponent>
    </SidebarItem>
  );
};
