import styled from 'styled-components/macro';
import { Popup } from '../Popup/Popup';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const StyledPopupLink = styled(Link)`
  color: ${(props) => props.theme.primary};
  text-decoration: none;
  font-weight: 700;
`;

interface TermPopupProps {
  isTermPopupOpened: boolean;
  handleTermConfirm: () => void;
  handleTermDecline: () => void;
}

export const TermPopup = ({
  handleTermConfirm,
  isTermPopupOpened,
  handleTermDecline,
}: TermPopupProps) => {
  const { t } = useTranslation();
  return (
    <Popup
      isPopupOpened={isTermPopupOpened}
      type="action"
      content={
        <span>
          <span>{t('please')} </span>
          <StyledPopupLink to="/agb">
            {t('signUpTermAndConditions')}
          </StyledPopupLink>
          <span> {t('mark')}</span>
        </span>
      }
      onConfirm={handleTermConfirm}
      onDecline={handleTermDecline}
    />
  );
};
