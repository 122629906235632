export const ALL_STATUSES = [
  'all',
  'new',
  'accepted',
  'inProgress',
  'towedAway',
  'finished',
  'cancelled',
  'emptyRun',
];

export const ACTIVE_STATUSES = ['all', 'new', 'accepted', 'inProgress'];
export const INACTIVE_STATUSES = [
  'all',
  'towedAway',
  'finished',
  'cancelled',
  'emptyRun',
];

export const CLIENT_STATUSES = ['all', 'cashbackOn', 'cashbackOff'];
export const CLIENT_REPORT_STATUSES = ['all', 'paid', 'open', 'notPaid'];
export const GAS_STATION_REPORT_STATUSES = [
  'all',
  'paid',
  'open',
  'notPaid',
  'paidOnTheSpot',
];
