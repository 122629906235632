import { gql } from '@apollo/client';

export const CREATE_OBJECTION_TO_TICKET = gql`
  mutation CreateObjectionToTicket(
    $qrCodeId: String!
    $email: String!
    $text: String
    $files: [Upload!]
  ) {
    createObjectionToTicket(
      qr_code_id: $qrCodeId
      email: $email
      text: $text
      files: $files
    )
  }
`;
