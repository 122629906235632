import { Paragraph } from 'components/Paragraph/Paragraph';
import { useTranslation } from 'react-i18next';

interface ClientContactInformationProps {
  clientPhoneNumber?: string;
}

export const ClientContactInformation = ({
  clientPhoneNumber,
}: ClientContactInformationProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Paragraph dimension={'s'} color={'var(--sub--black)'}>
        {t('contactNumber')}
      </Paragraph>
      <Paragraph color="var(--black)" weight="bold">
        {clientPhoneNumber}
      </Paragraph>
    </>
  );
};
