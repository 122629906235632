import {
  HistoryListItem,
  HistoryListItemProps,
} from '../HistoryListItem/HistoryListItem';

interface HistoryListProps {
  historyList?: HistoryListItemProps[];
}

export const HistoryList = ({ historyList }: HistoryListProps) => (
  <ul>
    {historyList?.map((item, index) => (
      <HistoryListItem
        key={index}
        title={item.title}
        date={item.date}
        amount={item.amount}
      />
    ))}
  </ul>
);
