import { Paragraph } from 'components/Paragraph/Paragraph';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

const StyledParagraph = styled(Paragraph)`
  padding: 10px 0;
  white-space: pre-line;
`;

const StyledSpan = styled.span`
  display: flex;
`;

const StyledSubcontent = styled.span`
  display: flex;
  padding-top: 16px;
`;
const StyledPoint = styled.span`
  position: relative;
  height: 10px;
  width: 10px;
  min-width: 10px;
  margin-right: 20px;
  &::after {
    content: '';
    position: absolute;
    top: 5px;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: var(--black);
  }
`;
const StyledSubPoint = styled.span`
  font-weight: bold;
  margin-right: 10px;
`;
const StyledStrong = styled.strong`
  margin-right: 25px;
`;

export interface ContractPointProps {
  point?: number;
  title: string;
  content?: string;
  subcontent?: string[];
  pointsSubcontent?: string[];
  bottomContent?: string;
}

export const ContractPoint = ({
  point,
  title,
  content,
  subcontent,
  pointsSubcontent,
  bottomContent,
}: ContractPointProps) => {
  const { t } = useTranslation();
  return (
    <StyledParagraph color="var(--black)" dimension="s">
      <StyledSpan>
        <StyledStrong>{point}.</StyledStrong>
        <span>
          <strong>{t(title)}</strong>
          <br />
          <br />
          {content && <>{t(content)}</>}
          {subcontent &&
            subcontent.map((item) => (
              <StyledSubcontent key={item}>
                <StyledPoint />
                {t(item)}
              </StyledSubcontent>
            ))}
          {pointsSubcontent &&
            pointsSubcontent.map((item, index) => (
              <StyledSubcontent key={item}>
                <StyledSubPoint>
                  {point}.{index + 1}
                </StyledSubPoint>
                {t(item)}
              </StyledSubcontent>
            ))}
        </span>
      </StyledSpan>
      {bottomContent && (
        <>
          <br />
          <StyledSpan>{t(bottomContent)}</StyledSpan>
        </>
      )}
    </StyledParagraph>
  );
};
