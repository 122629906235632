import { useMutation } from '@apollo/client';
import { Button } from 'components/Button/Button';
import { DownloadDocumentIcon } from 'components/Icons/DownloadDocumentIcon/DownloadDocumentIcon';
import { GET_USER_CONTRACT } from 'graphql/mutations/getUserContract';
import { useAccountType } from 'hooks/useAccountType';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { appContext } from 'views/App';
import * as Sentry from '@sentry/react';

interface DownloadContractProps {
  handleDownloadFile?: (fileUrl: string) => void;
  isReportParkingZone?: boolean;
  reportStatus?: string;
  reportHash?: string;
  reportType?: number;
}

export const DownloadContract = ({
  handleDownloadFile,
  isReportParkingZone,
  reportStatus,
  reportHash,
  reportType,
}: DownloadContractProps) => {
  const { t } = useTranslation();
  const appContextStore = useContext(appContext);
  const setIsLoading = appContextStore?.setIsLoading;
  const setIsError = appContextStore?.setIsError;
  const { isThisAccountType: isSuperAdminAccount } =
    useAccountType('superAdmin');
  const isNotActiveReport =
    reportStatus === 'finished' ||
    reportStatus === 'towedAway' ||
    reportStatus === 'cancelled' ||
    reportStatus === 'emptyRun';

  const [getUserContract] = useMutation(GET_USER_CONTRACT, {
    onCompleted: (data) => {
      if (!data) return;
      handleDownloadFile(data.getUserContract);
    },
    onError: (error) => {
      Sentry.captureException(error);
      setIsLoading(false);
      setIsError(true);
      throw new Error(error.message);
    },
  });
  return (
    <>
      {isSuperAdminAccount &&
        isReportParkingZone &&
        (isNotActiveReport || reportType === 2) && (
          <Button
            onBtnClick={() => {
              getUserContract({
                variables: { hash: reportHash },
              });
              setIsLoading(true);
            }}
            className="downloadButton"
          >
            {t('downloadContract')} <DownloadDocumentIcon />
          </Button>
        )}
    </>
  );
};
