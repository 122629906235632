import { UserInfo } from 'components/UserInfo/UserInfo';
import { SearchBar } from 'components/SearchBar/SearchBar';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

const StyledUserBarContainer = styled.div`
  padding-bottom: 20px;
`;

interface HistoryTopBarProps {
  handleStatusChange: (status: string) => void;
  handleSearchValueChange: (searchValue?: string) => void;
}

export const HistoryTopBar = ({
  handleStatusChange,
  handleSearchValueChange,
}: HistoryTopBarProps) => {
  const { type } = useParams();
  const methods = useForm();
  const { watch } = methods;

  useEffect(() => {
    handleSearchValueChange(watch('search'));
  }, [watch('search')]);
  return (
    <div>
      <StyledUserBarContainer>
        <UserInfo layout="horizontal" />
      </StyledUserBarContainer>
      <FormProvider {...methods}>
        <SearchBar
          handleStatus={(status) => handleStatusChange(status)}
          isWithStatusDropdown={
            !type || type === 'all' || type === 'deactive' || type === 'station'
          }
          isAllStatuses={type !== 'active' && type !== 'deactive'}
          isDeactiveStatuses={type === 'deactive'}
          isGasStationReports={type === 'station'}
        />
      </FormProvider>
    </div>
  );
};
