export const agb = {
  agbContent: `
Conditions Générales (CG) de TowShare Group GmbH et de l'application FalschParker
Date de modification : 4 septembre 2024

Informations importantes en résumé

Ces Conditions Générales d'Utilisation ("Conditions") s'appliquent aux applications mobiles (App) et/ou sites web de TowShare Group GmbH en Suisse. Elles vous permettent de demander des services de tiers ou directement via l'application FalschParker. Ces services sont fournis sous les marques du groupe TowShare. Vous trouverez plus d'informations sur cette page.

En sollicitant des services de tiers, vous concluez un contrat directement avec TowShare Group GmbH, qui agit comme une plateforme technologique de médiation. Vous pouvez demander et recevoir ces services via la plateforme. Dans certains cantons, TowShare Group GmbH intervient également comme prestataire de services de remorquage.

Veuillez lire attentivement ces Conditions. Elles contiennent des informations importantes sur les services proposés, vos droits et d'autres détails pertinents. Vous concluez un contrat avec TowShare Group GmbH, une société à responsabilité limitée basée en Suisse.

L'application FalschParker est la propriété de :
- Nom de l'entreprise : TowShare Group GmbH
- Rue : Schützengasse 4
- Code postal : 8001
- Ville : Zurich
- Pays : Suisse
- Email : info@falsch-parker.ch

1. Champ d'application
L'application FalschParker et ses services sont toujours gratuits pour vous en tant qu'utilisateur. Ces Conditions Générales (CG) régissent la relation entre TowShare Group GmbH et les utilisateurs qui utilisent la plateforme pour émettre des frais administratifs pour des véhicules garés de manière illégale sur leur place de parking ou propriété privée. Ces CG couvrent également la gestion et le contrôle des parkings ainsi que le remorquage des contrevenants. Ces services sont fournis par TowShare Group GmbH pour le compte des propriétaires immobiliers, gestionnaires, prestataires de services ou locataires.

2. Droit d'utilisation
L'utilisateur confirme qu'il est propriétaire ou locataire de la place de parking ou de la propriété privée où le véhicule a été stationné illégalement.

3. Modification des Conditions
TowShare Group GmbH se réserve le droit de modifier les CG à tout moment. La version actuelle est consultable sur le site web de TowShare Group GmbH et sur l'application FalschParker.

4. Contrôle des parkings et frais administratifs
Les utilisateurs et les agents de TowShare Group GmbH effectuent des contrôles réguliers des parkings pour le compte des propriétaires ou locataires. En cas de violation des règles de stationnement, des frais administratifs sont appliqués. Ces frais couvrent les coûts administratifs encourus.

5. Droit de recouvrement et cession des créances
TowShare Group GmbH est autorisée à percevoir les frais administratifs au nom des propriétaires ou locataires, agissant comme facturateur et prestataire de services. Les clients (propriétaires ou locataires) accordent à TowShare Group GmbH le droit de recouvrer ces créances.

6. Règlement de cashback
Les propriétaires ou locataires peuvent recevoir un remboursement (cashback) pour les frais administratifs perçus. Les conditions de cashback sont établies par contrat.

7. Clause de non-responsabilité
TowShare Group GmbH décline toute responsabilité pour les dommages ou pertes survenus avant le remorquage ou causés par un stationnement illégal.

8. Documentation et interdiction judiciaire
En cas d'infraction, TowShare Group GmbH documente toutes les actions, y compris la collecte de géodonnées et d'horodatages. Le cas échéant, une interdiction judiciaire est indiquée.

9. Procédure de contestation pour contrevenants
Les contrevenants peuvent contester les frais administratifs par écrit dans les 10 jours ouvrables après réception, soit via le site https://falsch-parker.ch, soit par email à info@falsch-parker.ch.

10. Transfert des coûts
L'utilisateur cède à TowShare Group GmbH le droit de facturer et d'exiger les frais auprès du contrevenant. Aucun coût n'est à la charge de l'utilisateur.

11. Accès au véhicule
L'utilisateur s'engage à permettre l'accès au véhicule à l'entreprise de remorquage pour la prestation de service.

12. Disponibilité de la plateforme
TowShare Group GmbH ne garantit pas la disponibilité continue ou sans erreur de la plateforme, des restrictions pouvant exister pour des raisons techniques ou opérationnelles.

13. Protection des données
TowShare Group GmbH traite les données personnelles conformément aux lois en vigueur et à sa propre politique de confidentialité. Toutes les données sont traitées de manière confidentielle, respectent les standards de cryptage les plus élevés et sont stockées sur des serveurs en Suisse.

14. Modifications des CG
TowShare Group GmbH se réserve le droit de modifier les CG à tout moment. Les modifications sont communiquées à l'avance et publiées sur le site.

15. Indemnisation
L'utilisateur indemnise TowShare Group GmbH et l'entreprise de remorquage de toutes les réclamations et exigences découlant de l'utilisation des services.

16. Droit applicable et juridiction
Ces CG sont régies par le droit suisse. Le for juridique est Zurich, Suisse.`,
};
