import { Layout } from 'components/Layout/Layout';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { HelpAndSupportMainContent } from './HelpAndSupportMainContent/HelpAndSupportMainContent';
import { CompanyContact } from 'components/CompanyContact/CompanyContact';

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;

export const HelpAndSupport = () => {
  const { t } = useTranslation();
  return (
    <Layout
      mode="top"
      isBackBtn
      backButtonMode="text"
      viewTitle={t('helpAndSupport')}
    >
      <StyledContainer>
        <HelpAndSupportMainContent />
        <CompanyContact />
      </StyledContainer>
    </Layout>
  );
};
