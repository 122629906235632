import { CompanyInfo } from 'components/CompanyInfo/CompanyInfo';
import { Heading, HeadingProps } from 'components/Heading/Heading';
import { Paragraph, ParagraphProps } from 'components/Paragraph/Paragraph';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledHeading = ({ children, className }: HeadingProps) => (
  <Heading color="var(--black)" weight="bold" className={className}>
    {children}
  </Heading>
);
const StyledParagraph = ({ children, className }: ParagraphProps) => (
  <Paragraph color="var(--black)" weight="bolder" className={className}>
    {children}
  </Paragraph>
);

const OverstyledHeading = styled(StyledHeading)`
  border-bottom: 1px solid var(--secondary-grey);
  line-height: 25px;
  margin-bottom: 16px;
`;

const OverstyledParagraph = styled(StyledParagraph)`
  line-height: 21px;
  padding-bottom: 70px;
`;

export const HelpAndSupportMainContent = () => {
  const { t } = useTranslation();
  return (
    <div>
      <OverstyledHeading>{t('falschParkerHelp')}</OverstyledHeading>
      <OverstyledParagraph>{t('helpContent')}</OverstyledParagraph>
      <CompanyInfo />
    </div>
  );
};
