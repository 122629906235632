export const circulationCompensation = {
  roadSignTitle: 'Divieto giudiziario',
  roadSignTextContent:
    'Il parcheggio non autorizzato è sanzionato secondo le regole del codice della strada. Le infrazioni contro questo divieto di parcheggio così segnalato sono punite secondo le sanzioni previste dal codice della strada.',
  circulationCompensationBottomTitle: 'Informazioni legali',
  circulationCompensationBottomText:
    "La Corte Federale ha stabilito la base giuridica per l'emissione delle compensazioni amministrative.",
  circulationCompensationFirstTitle: 'Gentile conducente,',
  circulationCompensationFirstText:
    "Il nostro sorvegliante ha constatato che avete parcheggiato il vostro veicolo senza autorizzazione su un parcheggio privato, soggetto a un divieto giudiziario di parcheggio. A causa del disagio e degli oneri causati, vi è stata imposta una compensazione amministrativa.\n\n Vi informiamo che il mancato pagamento di questa compensazione può comportare costi aggiuntivi e, eventualmente, l'avvio di un procedimento penale.",
  circulationCompensationSecondTitle:
    'Blocco delle informazioni per il titolare del veicolo / Veicoli stranieri',
  circulationCompensationSecondText:
    "Se le informazioni sul titolare del vostro veicolo sono bloccate presso l'Ufficio della motorizzazione o se possedete un veicolo con targa straniera, vi preghiamo di comunicarci il vostro indirizzo specificando la targa del veicolo all'indirizzo email seguente: info@falsch-parker.ch entro due giorni.\n\nIn caso contrario, ci riserviamo il diritto di segnalare direttamente la violazione alle autorità giudiziarie competenti.\n\nGrazie per la vostra attenzione. \n Falsch-Parker.ch",
};
