import styled from 'styled-components';
import {
  SidebarListItem,
  SidebarListItemProps,
} from '../SidebarListItem/SidebarListItem';
import { SidebarHeading } from '../SidebarHeading/SidebarHeading';
import { useContext } from 'react';
import { appContext } from 'views/App';

interface SidebarListProps {
  title?: string;
  menuData: SidebarListItemProps[];
}
const SideBarListContrainer = styled.ul`
  padding-bottom: 25px;
`;

export const SidebarList = ({ title, menuData }: SidebarListProps) => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;

  return (
    <SideBarListContrainer>
      <SidebarHeading title={title} />
      {menuData.map((item) => {
        if (item.name === 'cashback' && !userData.cashback) return;
        return (
          <SidebarListItem
            key={item.name}
            isActive={item.isActive}
            type={item.type}
            name={item.name}
            path={item.path}
            icon={item.icon}
          />
        );
      })}
    </SideBarListContrainer>
  );
};
