import { gql } from '@apollo/client';

export const ACCEPT_REPORT = gql`
  mutation AcceptReport(
    $hash: String!
    $driverLatitude: Float!
    $driverLongitude: Float!
  ) {
    acceptReport(
      hash: $hash
      driverLatitude: $driverLatitude
      driverLongitude: $driverLongitude
    ) {
      id
    }
  }
`;
