import { gql } from '@apollo/client';

export const CREATE_CAR_OWNER = gql`
  mutation CreateCarOwner(
    $fullName: String
    $contactDetails: String
    $fullAddress: String
    $hash: String!
    $signature: Upload
    $paymentType: Int
    $price: Float
  ) {
    createCarOwner(
      input: {
        full_name: $fullName
        contact_details: $contactDetails
        address_full: $fullAddress
        payment_type: $paymentType
        price: $price
      }
      hash: $hash
      signature: $signature
    ) {
      id
    }
  }
`;
