export const ALL_STATUS_INDEXES: string[] = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
];
export const ACTIVE_STATUS_INDEXES: string[] = ['1', '2', '3'];
export const INACTIVE_STATUS_INDEXES: string[] = ['4', '5', '6', '7', '8'];
export const GAS_STATION_STATUS_INDEXES: string[] = ['1', '2', '3', '4'];
