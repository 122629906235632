import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { agb as agbDE } from './de/agb';
import { circulationCompensation as circulationCompensationDE } from './de/circulationCompensation';
import { dataProtection as dataProtectionDE } from './de/dataProtection';
import { faq as faqDE } from './de/faq';
import { main as mainDE } from './de/main';
import { qrCodeTickets as qrCodeTicketsDE } from './de/qrCodeTickets';
import { agb as agbEN } from './en/agb';
import { circulationCompensation as circulationCompensationEN } from './en/circulationCompensation';
import { dataProtection as dataProtectionEN } from './en/dataProtection';
import { faq as faqEN } from './en/faq';
import { main as mainEN } from './en/main';
import { qrCodeTickets as qrCodeTicketsEN } from './en/qrCodeTickets';

import { agb as agbFR } from './fr/agb';
import { circulationCompensation as circulationCompensationFR } from './fr/circulationCompensation';
import { dataProtection as dataProtectionFR } from './fr/dataProtection';
import { faq as faqFR } from './fr/faq';
import { main as mainFR } from './fr/main';
import { qrCodeTickets as qrCodeTicketsFR } from './fr/qrCodeTickets';

import { agb as agbIT } from './it/agb';
import { circulationCompensation as circulationCompensationIT } from './it/circulationCompensation';
import { dataProtection as dataProtectionIT } from './it/dataProtection';
import { faq as faqIT } from './it/faq';
import { main as mainIT } from './it/main';
import { qrCodeTickets as qrCodeTicketsIT } from './it/qrCodeTickets';

const translationEN = {
  ...mainEN,
  ...agbEN,
  ...faqEN,
  ...dataProtectionEN,
  ...qrCodeTicketsEN,
  ...circulationCompensationEN,
};
const translationFR = {
  ...mainFR,
  ...agbFR,
  ...faqFR,
  ...dataProtectionFR,
  ...qrCodeTicketsFR,
  ...circulationCompensationFR,
};
const translationIT = {
  ...mainIT,
  ...agbIT,
  ...faqIT,
  ...dataProtectionIT,
  ...qrCodeTicketsIT,
  ...circulationCompensationIT,
};
const translationDE = {
  ...mainDE,
  ...agbDE,
  ...faqDE,
  ...dataProtectionDE,
  ...qrCodeTicketsDE,
  ...circulationCompensationDE,
};

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: { ...translationEN } },
    de: { translation: { ...translationDE } },
    fr: { translation: { ...translationFR } },
    it: { translation: { ...translationIT } },
  },
  lng: 'de',
  fallbackLng: 'de',
  interpolation: { escapeValue: false },
});
