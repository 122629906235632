import { SelectButton } from 'components/SelectButton/SelectButton';
import { ParkingZone } from 'types/ParkingZone';
import { SelectParkingZoneItemContent } from '../SelectParkingZoneItemContent/SelectParkingZoneItemContent';

interface SelectParkingZoneProps {
  parkingZones: ParkingZone[];
  handleSelectedParkingChange: (parking: ParkingZone) => void;
  selectedParking?: ParkingZone;
}

export const SelectParkingZoneList = ({
  parkingZones,
  handleSelectedParkingChange,
  selectedParking,
}: SelectParkingZoneProps) => {
  const handleButtonClick = (e, parking: ParkingZone) => {
    e.preventDefault();
    handleSelectedParkingChange(parking);
  };
  return (
    <>
      {parkingZones?.map((parking) => (
        <SelectButton
          key={parking.id}
          content={
            <SelectParkingZoneItemContent
              parkingName={parking?.name}
              parkingFullAddress={parking?.fullAddress}
            />
          }
          onClick={(e) => handleButtonClick(e, parking)}
          isSelected={selectedParking === parking}
        />
      ))}
    </>
  );
};
