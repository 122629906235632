import { useQuery } from '@apollo/client';
import { USER_NOTIFICATIONS } from 'graphql/queries/userNotifications';
import { useEffect, useState } from 'react';

export const useUserNewNotifications = () => {
  const [isNewNotifications, setIsNewNotifications] = useState<boolean>(false);
  const { data } = useQuery(USER_NOTIFICATIONS, {
    variables: { orderBy: 'DESC' },
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    if (!data) return;
    const newNotifications = data.userNotifications.filter(
      (notification) => !notification.displayed,
    );
    setIsNewNotifications(newNotifications.length);
  }, [data]);
  return { isNewNotifications };
};
