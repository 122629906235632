import { SuccessVerifyCard } from 'components/Cards/SuccessVerifyCard/SuccessVerifyCard';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Confirm } from 'views/Confirm/Confirm';

export const SuccessfulBecomeDriver = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Confirm
      title={t('verificationRequested')}
      subtitle={t('successDriverSubtitle')}
      card={<SuccessVerifyCard />}
      onContinueClick={() => navigate('/')}
    />
  );
};
