import { Card } from 'components/Cards/Card/Card';
import { Paragraph } from 'components/Paragraph/Paragraph';
import { UserInfo } from 'components/UserInfo/UserInfo';
import { Status } from 'components/Status/Status';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import coinsImage from 'assets/img/bag-of-money.png';

interface RewardCardProps {
  totalReward?: number;
  isTotalRewards?: boolean;
}

interface StyledRewardCardProps {
  background?: string;
}

const StyledRewardCard = styled.div<StyledRewardCardProps>`
  padding: 20px;
  display: flex;
  flex-direction: column;
  &:after {
    content: '';
    position: absolute;
    background: ${(props) => `url(${props.background}) no-repeat top center`};
    background-size: contain;
    right: 0;
    top: 56%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 134px;
    height: 161px;
  }
  .totalRewards {
    padding-top: 10px;
  }
`;

export const RewardCard = ({
  totalReward = 100,
  isTotalRewards,
}: RewardCardProps) => {
  const { t } = useTranslation();
  return (
    <Card contentPosition="flex-start">
      <StyledRewardCard background={coinsImage}>
        <UserInfo layout={'horizontal'} color="var(--black)" />
        {isTotalRewards && (
          <>
            <Paragraph
              color={'var(--black)'}
              dimension={'s'}
              className="totalRewards"
            >
              Total CashBack
            </Paragraph>
            <Paragraph weight={'bold'} dimension={'xxl'} color="var(--black)">
              {totalReward} CHF
            </Paragraph>
            <Status status={t('defaultPayment')} />
          </>
        )}
      </StyledRewardCard>
    </Card>
  );
};
