import { Paragraph } from 'components/Paragraph/Paragraph';
import styled from 'styled-components/macro';

const TextContainer = styled.div`
  padding-right: 5px;
  .complexButtonText {
    margin-bottom: 3px;
  }
`;
const StyledTitle = styled.span`
  line-height: 20px;
`;
const StyledSubtitle = styled.span`
  line-height: 14px;
`;

interface ComplexButtonTextContentProps {
  mode?: 'light' | 'dark';
  title?: string;
  subtitle?: string;
}

export const ComplexButtonTextContent = ({
  mode,
  title,
  subtitle,
}: ComplexButtonTextContentProps) => {
  return (
    <TextContainer>
      <Paragraph
        weight="bolder"
        color={mode === 'dark' ? 'var(--white)' : 'var(--black)'}
        dimension={mode === 'dark' ? 'm' : 'l'}
        className={'complexButtonText'}
      >
        <StyledTitle>{title}</StyledTitle>
      </Paragraph>
      <Paragraph
        dimension={mode === 'dark' ? 'xs' : 's'}
        color={mode === 'dark' ? 'var(--white)' : 'var(--sub--black)'}
      >
        <StyledSubtitle>{subtitle}</StyledSubtitle>
      </Paragraph>
    </TextContainer>
  );
};
