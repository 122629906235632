import { useLazyQuery, useMutation } from '@apollo/client';
import { Button } from 'components/Button/Button';
import { MapIcon } from 'components/Icons/MapIcon/MapIcon';
import { Layout } from 'components/Layout/Layout';
import { Input } from 'components/Input/Input';
import { CREATE_PARKING_ZONE } from 'graphql/mutations/createParkingZone';
import { PARKING_ZONES } from 'graphql/queries/parkingZones';
import { useUploadMutation } from 'hooks/useUploadMutation';
import { useEffect, useState, MouseEvent, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { getAddress } from 'utils/getAddress';
import { UPDATE_PARKING_ZONE } from 'graphql/mutations/updateParkingZone';
import { PARKING_ZONE } from 'graphql/queries/parkingZone';
import { Popup } from 'components/Popup/Popup';
import { getPosition } from 'utils/getPosition';
import { CREATE_PARKING_ZONE_PRESIGNED_REQUEST } from 'graphql/mutations/createParkingZonePresignedRequest';
import { ADD_PARKING_ZONE_PHOTOS } from 'graphql/mutations/addParkingZonePhotos';
import { GenerateQrCodeButton } from './GenerateQrCodeButton/GenerateQrCodeButton';
import { appContext } from 'views/App';
import * as Sentry from '@sentry/react';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .notesInput {
    height: max-content;
  }
`;
const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .generateButton {
    text-transform: none;
  }
`;

export const ParkingDetails = () => {
  const appContextStore = useContext(appContext);
  const userData = appContextStore?.userData;
  const parkingData = appContextStore?.parkingData;
  const setParkingData = appContextStore?.setParkingData;
  const setIsError = appContextStore?.setIsError;
  const setIsLoading = appContextStore?.setIsLoading;

  const { t } = useTranslation();
  const [isParkingZone, setIsParkingZone] = useState<boolean>(false);
  const [isSavePopupOpened, setIsSavePopupOpened] = useState<boolean>(false);
  const [isGPSOff, setIsGPSOff] = useState<boolean>(false);
  const [isWrongAddress, setIsWrongAddress] = useState<boolean>(false);
  const methods = useForm({ mode: 'onSubmit' });
  const { handleSubmit, getValues, setValue } = methods;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isFirstParking = pathname === '/first-parking-details';
  const [parkingZones, { data }] = useLazyQuery(PARKING_ZONES, {
    fetchPolicy: 'network-only',
  });
  const isEditPathname = pathname.includes('edit-parking');

  const [updateParkingZone, { loading: updateLicenseLoading }] = useMutation(
    UPDATE_PARKING_ZONE,
    {
      onCompleted: () => {
        if (parkingData?.images && parkingData?.images?.length > 0) {
          setIsLoading(true);
          mutate(ADD_PARKING_ZONE_PHOTOS, {
            variables: {
              parkingZoneId: Number(parkingData?.id),
              images: [...parkingData.images],
            },
          }).then((result) => {
            if (result) {
              const newPhotos = result.data.addParkingZonePhotos;
              setParkingData({
                ...parkingData,
                photos: parkingData?.photos
                  ? [...parkingData.photos, ...newPhotos]
                  : [...newPhotos],
                images: undefined,
              });
              setIsLoading(false);
              setIsSavePopupOpened(true);
            }
          });
        } else {
          setIsSavePopupOpened(true);
        }
      },
      onError: (error) => {
        setIsError(true);
        setIsLoading(false);
        throw new Error(error.message);
      },
    },
  );

  const [
    createParkingZonePresignedRequest,
    { loading: createParkingZonePresignedRequestLoading },
  ] = useMutation(CREATE_PARKING_ZONE_PRESIGNED_REQUEST, {
    onCompleted: async (data) => {
      if (!data) return;
      const fileIds = data.createParkingZonePresignedRequest.map(
        (file) => file.id,
      );
      setParkingData({ ...parkingData, files: fileIds ? [...fileIds] : [] });
      if (isParkingZone) {
        setIsLoading(true);
        mutate(CREATE_PARKING_ZONE, {
          variables: {
            ...parkingData,
            images: fileIds ? [...fileIds] : [],
          },
        }).then((result) => {
          if (result) {
            setIsLoading(false);
            navigate('/contract-success');
          }
        });
      } else {
        navigate('/contract');
      }
    },
    onError: (error) => {
      setIsError(true);
      setIsLoading(false);
      throw new Error(error.message);
    },
  });

  const [parkingZone, { data: parkingZoneData, loading: parkingZoneLoading }] =
    useLazyQuery(PARKING_ZONE, {
      fetchPolicy: 'network-only',
    });

  const { mutate } = useUploadMutation();

  const onSubmit = async () => {
    const parking = {
      name: getValues('parkingName'),
      streetName: getValues('street'),
      streetNumber: getValues('streetNumber'),
      zipCode: getValues('zipCode'),
      city: getValues('city'),
    };
    const fullAddress = `${parking.streetName} ${parking.streetNumber}, ${parking.zipCode} ${parking.city}`;
    const position = await getPosition(fullAddress);
    if (position) {
      if (isEditPathname) {
        updateParkingZone({
          variables: {
            id: pathname.replace('/edit-parking/', ''),
            ...parking,
            fullAddress: `${parking.streetName} ${parking.streetNumber}, ${parking.zipCode} ${parking.city}`,
            latitude: position.lat,
            longitude: position.lng,
          },
        });
      } else {
        setParkingData({
          ...parkingData,
          ...parking,
          isFirstParking,
          fullAddress: `${parking.streetName} ${parking.streetNumber}, ${parking.zipCode} ${parking.city}`,
          type: 1,
          latitude: position.lat,
          longitude: position.lng,
        });
        const filesNames = parkingData?.images?.map((file) => file.name);
        if (filesNames) {
          createParkingZonePresignedRequest({
            variables: { files: [...filesNames] },
          });
        } else {
          if (isParkingZone) {
            setIsLoading(true);
            mutate(CREATE_PARKING_ZONE, {
              variables: {
                ...parkingData,
                ...parking,
                fullAddress: `${parking.streetName} ${parking.streetNumber}, ${parking.zipCode} ${parking.city}`,
                type: 1,
                latitude: position.lat,
                longitude: position.lng,
                images: [],
              },
            })
              .then((result) => {
                if (result) {
                  setIsLoading(false);
                  navigate('/contract-success');
                }
              })
              .catch((error) => {
                Sentry.captureException(error);
                setIsLoading(false);
                setIsError(true);
                throw new Error(error.message);
              });
          } else {
            navigate('/contract');
          }
        }
      }
    } else {
      setIsWrongAddress(true);
    }
  };

  const handleLocationClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          const address = await getAddress([latitude, longitude]);
          if (address) {
            setValue('street', address.street);
            setValue('streetNumber', address.streetNumber);
            setValue('zipCode', address.zipCode);
            setValue('city', address.city);
          }
        },
        (error) => {
          if (error) setIsGPSOff(true);
        },
      );
    } else {
      setIsGPSOff(true);
    }
  };

  useEffect(() => {
    if (isEditPathname) {
      const parkingId = pathname.replace('/edit-parking/', '');
      parkingZone({ variables: { id: parkingId } });
    } else {
      parkingZones();
      if (!parkingData) return;
      setValue('parkingName', parkingData.name);
      setValue('street', parkingData.streetName);
      setValue('streetNumber', parkingData.streetNumber);
      setValue('zipCode', parkingData.zipCode);
      setValue('city', parkingData.city);
    }
  }, [isEditPathname]);

  useEffect(() => {
    if (!data) return;
    setIsParkingZone(Boolean(data.parkingZones[0]));
  }, [data]);

  useEffect(() => {
    if (!parkingZoneData) {
      {
        if (userData) {
          const address = userData.companyAddress.split(',') || '';
          const addressFirstPart = address[0].split(' ');
          if (addressFirstPart && addressFirstPart.length < 3) {
            setValue('street', addressFirstPart[0]);
            setValue('streetNumber', addressFirstPart[1]);
          } else {
            setValue('street', `${addressFirstPart[0]} ${addressFirstPart[1]}`);
            setValue('streetNumber', addressFirstPart[2]);
          }
          const addressSecondPart =
            address && address[1] ? address[1].replace(' ', '').split(' ') : '';
          if (addressSecondPart && addressSecondPart?.length > 1) {
            setValue('zipCode', addressSecondPart[0]);
            setValue('city', addressSecondPart[1]);
          }
        }
      }
    } else {
      setParkingData({
        ...parkingData,
        photos: parkingZoneData.parkingZone.photos,
        id: parkingZoneData.parkingZone.id,
        uuid: parkingZoneData.parkingZone.uuid,
      });
      setValue('parkingName', parkingZoneData.parkingZone.name);
      setValue('street', parkingZoneData.parkingZone.streetName);
      setValue('streetNumber', parkingZoneData.parkingZone.streetNumber);
      setValue('zipCode', parkingZoneData.parkingZone.zipCode);
      setValue('city', parkingZoneData.parkingZone.city);
    }
  }, [parkingZoneData, userData]);

  useEffect(() => {
    setIsLoading(
      updateLicenseLoading ||
        parkingZoneLoading ||
        createParkingZonePresignedRequestLoading,
    );
  }, [
    updateLicenseLoading,
    parkingZoneLoading,
    createParkingZonePresignedRequestLoading,
  ]);

  return (
    <Layout
      mode="top"
      isBackBtn
      backButtonMode="text"
      viewTitle={t(isEditPathname ? 'editProperty' : 'addPropertyDetails')}
      outsideElements={
        <div>
          <Popup
            type="info"
            content={t('changeSave')}
            isPopupOpened={isSavePopupOpened}
            onDecline={() => {
              setIsSavePopupOpened(false);
              setIsWrongAddress(false);
            }}
            confirmText="Ok"
          />
          <Popup
            type="info"
            content={t('autoGPSOff')}
            isPopupOpened={isGPSOff}
            onDecline={() => setIsGPSOff(false)}
            confirmText="Ok"
          />
          <Popup
            type="info"
            content={t('wrongAddress')}
            isPopupOpened={isWrongAddress}
            onDecline={() => setIsWrongAddress(false)}
            confirmText="Ok"
          />
        </div>
      }
    >
      <FormProvider {...methods}>
        <StyledForm onSubmit={handleSubmit(onSubmit)} id="new-parking-form">
          <div>
            <Input
              mode="light"
              name="parkingName"
              label={t('propertyName')}
              placeholder={t('propertyNamePlaceholder')}
              isRequired
              requiredErrorMessage={t('requiredError')}
              bottomMarting="16"
            />
            <Input
              mode="light"
              name="street"
              label={t('street')}
              placeholder={t('streetPlaceholder')}
              isRequired
              requiredErrorMessage={t('requiredError')}
              icon={
                <MapIcon
                  fill={userData?.appSettings?.primary_color}
                  stroke={userData?.appSettings?.primary_color}
                />
              }
              onIconClick={handleLocationClick}
              bottomMarting="16"
            />
            <Input
              mode="light"
              name="streetNumber"
              label={t('streetNumber')}
              placeholder={t('streetNumberPlaceholder')}
              isRequired
              requiredErrorMessage={t('requiredError')}
              bottomMarting="16"
            />
            <Input
              mode="light"
              name="zipCode"
              label={t('zipCode')}
              placeholder={t('zipCodePlaceholder')}
              isRequired
              requiredErrorMessage={t('requiredError')}
              bottomMarting="16"
            />
            <Input
              mode="light"
              name="city"
              label={t('city')}
              placeholder={t('cityPlaceholder')}
              isRequired
              requiredErrorMessage={t('requiredError')}
              bottomMarting="16"
            />
          </div>
          <StyledButtonContainer>
            {isEditPathname && parkingData?.uuid && (
              <GenerateQrCodeButton parkingUuid={parkingData.uuid} />
            )}
            <Button type="submit">
              {t(isEditPathname ? 'save' : 'signAContract')}
            </Button>
          </StyledButtonContainer>
        </StyledForm>
      </FormProvider>
    </Layout>
  );
};
