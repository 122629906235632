import { Button } from 'components/Button/Button';
import { useAccountType } from 'hooks/useAccountType';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';

const StyledButtonContainer = styled.div`
  margin-bottom: 16px;
`;

interface ExecuteOrderProps {
  reportHash?: string;
  reportStatus?: string;
  reportType?: number;
}

export const ExecuteOrder = ({
  reportHash,
  reportStatus,
  reportType,
}: ExecuteOrderProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isThisAccountType: isUserAccount } = useAccountType('user');
  const isNotUserAccount = !isUserAccount;
  return (
    <>
      {isNotUserAccount && reportStatus === 'towedAway' && reportType === 1 && (
        <StyledButtonContainer>
          <Button
            mode="inverted"
            onBtnClick={(e) => {
              e.preventDefault();
              navigate(`/verify-car-owner/${reportHash}`);
            }}
            id="startCreatingCarOwner"
          >
            {t('executeOrder')}
          </Button>
        </StyledButtonContainer>
      )}
    </>
  );
};
