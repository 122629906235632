import { Layout } from 'components/Layout/Layout';
import { Heading, HeadingProps } from 'components/Heading/Heading';
import { Paragraph, ParagraphProps } from 'components/Paragraph/Paragraph';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

const StyledHeading = ({ children, className }: HeadingProps) => (
  <Heading color="var(--black)" weight="bold" className={className}>
    {children}
  </Heading>
);

const StyledParagraph = ({ children, className }: ParagraphProps) => (
  <Paragraph color="var(--black)" className={className}>
    {children}
  </Paragraph>
);

const OverstyledHeading = styled(StyledHeading)`
  border-bottom: 1px solid var(--secondary-grey);
  line-height: 25px;
  margin-bottom: 16px;
`;

const OverstyledParagraph = styled(StyledParagraph)`
  line-height: 21px;
  padding-bottom: 32px;
`;

export const FAQ = () => {
  const { t } = useTranslation();
  return (
    <Layout mode="top" isBackBtn backButtonMode="text" viewTitle={t('FAQ')}>
      <OverstyledHeading>{t('faqFisrtQuestion')}</OverstyledHeading>
      <OverstyledParagraph>{t('faqFirstAnswer')}</OverstyledParagraph>
      <OverstyledHeading>{t('faqSecondQuestion')}</OverstyledHeading>
      <OverstyledParagraph>{t('faqSecondAnswer')}</OverstyledParagraph>
      <OverstyledHeading>{t('faqThirdQuestion')}</OverstyledHeading>
      <OverstyledParagraph>{t('faqThirdAnswer')}</OverstyledParagraph>
      <OverstyledHeading>{t('faq4Question')}</OverstyledHeading>
      <OverstyledParagraph>{t('faq4Answer')}</OverstyledParagraph>
      <OverstyledHeading>{t('faq5Question')}</OverstyledHeading>
      <OverstyledParagraph>{t('faq5Answer')}</OverstyledParagraph>
      <OverstyledHeading>{t('faq6Question')}</OverstyledHeading>
      <OverstyledParagraph>{t('faq6Answer')}</OverstyledParagraph>
    </Layout>
  );
};
