import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { appContext } from 'views/App';
import { Popup } from '../Popup/Popup';

export const NewNotificationPopup = () => {
  const appContextStore = useContext(appContext);
  const newNotification = appContextStore?.newNotification;
  const setNewNotification = appContextStore?.setNewNotification;

  const [isPopupOpened, setIsPopupOpened] = useState<boolean>(false);
  const language = localStorage.getItem('language');
  const navigate = useNavigate();
  useEffect(() => {
    if (!newNotification) return;
    setIsPopupOpened(true);
  }, [newNotification]);
  const handleDecline = () => {
    setIsPopupOpened(false);
    setNewNotification(null);
  };
  const handleConfirm = () => {
    navigate(`/report/${newNotification?.reportHash}/all`);
    setIsPopupOpened(false);
    setNewNotification(null);
  };
  return (
    <Popup
      type={'action'}
      content={
        language === 'en' ? newNotification?.textEn : newNotification?.text
      }
      isPopupOpened={isPopupOpened}
      onDecline={handleDecline}
      onConfirm={handleConfirm}
    />
  );
};
