import { useLazyQuery } from '@apollo/client';
import homeImg from 'assets/img/home.png';
import { Accordion } from 'components/Accordion/Accordion';
import { ParkingAndLicensesCard } from 'components/Cards/ParkingAndLicensesCard/ParkingAndLicensesCard';
import { Layout } from 'components/Layout/Layout';
import { PARKING_ZONES } from 'graphql/queries/parkingZones';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ParkingAndLicensesImg } from 'styles/common';
import { ParkingZone } from 'types/ParkingZone';
import { appContext } from 'views/App';
import * as Sentry from '@sentry/react';

const StyledContainer = styled.div`
  padding-top: 75px;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 580px;
`;

export const Parking = () => {
  const appContextStore = useContext(appContext);
  const setParkingData = appContextStore?.setParkingData;
  const setIsError = appContextStore?.setIsError;
  const setIsLoading = appContextStore?.setIsLoading;

  const { t } = useTranslation();
  const [parkings, setParkings] = useState<ParkingZone[] | null>(null);
  const navigate = useNavigate();

  const [parkingZones, { loading: parkingZonesLoading }] = useLazyQuery(
    PARKING_ZONES,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const parkings = data.parkingZones;
        setParkings(parkings);
      },
      onError: (error) => {
        Sentry.captureException(error);
        setIsLoading(false);
        setIsError(true);
        throw new Error(error.message);
      },
    },
  );

  useEffect(() => {
    parkingZones();
    setParkingData(null);
  }, []);

  useEffect(() => {
    setIsLoading(parkingZonesLoading);
  }, [parkingZonesLoading]);

  const handleEditParking = (element) => {
    const selectedParking = parkings.find(
      (propery) => element === propery.name,
    );
    navigate(`/edit-parking/${selectedParking.id}`);
  };

  return (
    <Layout
      topContent={<ParkingAndLicensesCard />}
      topContentPosition="center"
      isBackBtn
      backButtonMode="text"
      viewTitle={t('propertyAndLicenses')}
      backBtnLink="/profile"
      customerTopHeight={150}
    >
      <StyledContainer>
        <Accordion
          title={t('addProperty')}
          icon={<ParkingAndLicensesImg src={homeImg} alt="Home" />}
          isAccordionOpened={true}
          elements={parkings?.map((parking) => parking?.name)}
          elementTitle={t('parkingTitle')}
          onClick={(element) => handleEditParking(element)}
          addButtonTitle={t('newPropertyDetails')}
          onAddButtonClick={() => navigate('/parking-details')}
        />
      </StyledContainer>
    </Layout>
  );
};
