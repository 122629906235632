import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DeleteIcon } from '../Icons/DeleteIcon/DeleteIcon';
import { RetakeIcon } from '../Icons/RetakeIcon/RetakeIcon';
import { UploadInput } from '../UploadInput/UploadInput';
import { Paragraph } from '../Paragraph/Paragraph';

interface MediaNavProps {
  onRetakeClick: (image: File) => void;
  onDeleteClick: () => void;
}
const MediaNavContainer = styled.ul`
  display: flex;
  background: var(--black-always);
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: space-around;
  padding: 24px 0px 45px 0px;
  z-index: 1;
  .buttonText {
    margin-top: 3px;
  }
`;
const StyledListItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const MediaNav = ({ onDeleteClick, onRetakeClick }: MediaNavProps) => {
  const { t } = useTranslation();
  const methods = useForm();
  return (
    <FormProvider {...methods}>
      <MediaNavContainer>
        <UploadInput
          name="photo"
          mode="transparent"
          handleMediaChange={(images) => {
            if (images?.length > 0) onRetakeClick(images[0]);
          }}
          addedMedia={[]}
          content={
            <StyledListItem>
              <RetakeIcon />
              <Paragraph
                dimension={'xs'}
                color={'var(--green)'}
                textTransform={'capitalize'}
                className="buttonText"
              >
                {t('retake')}
              </Paragraph>
            </StyledListItem>
          }
        />
        <StyledListItem onClick={onDeleteClick}>
          <DeleteIcon />
          <Paragraph
            dimension={'xs'}
            color={'var(--green)'}
            textTransform={'capitalize'}
            className="buttonText"
          >
            {t('delete')}
          </Paragraph>
        </StyledListItem>
      </MediaNavContainer>
    </FormProvider>
  );
};
